<template>
    <div class="row h-100">
        <div class="col-lg-10 pt-2 pb-2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-8 offset-lg-2">
                                    <div class="card-wrapper card-space">
                                        <div class="card card-bg no-after">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-2 d-flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-info-circle w-100 m-auto" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                        </svg>
                                                    </div>
                                                    <div class="col-sm-10">
                                                        <h3 class="card-title text-truncate">
                                                            Inviti outbound
                                                        </h3>
                                                        <ul class="pl-4 mb-0">
                                                            <li class="card-text">
                                                                Questo sistema di notifica outbound permette l'invio di <b>SMS</b> con un testo di invito agli screening e la creazione automatica degli appuntamenti in agenda.
                                                            </li>
                                                            <!--<li class="card-text">
                                                                Le informazioni dovranno essere caricate tramite un <b>file xls</b> che sarà messo a confronto con la <b>tabella Geco</b> nel sistema per il recupero dei recapiti.
                                                            </li>-->
                                                            <li class="card-text">
                                                                La formattazione delle colonne del file è importante. Rispettare la struttura che segue.
                                                            </li>
                                                            <li class="card-text">
                                                                I campi EMAIL e PID non sono obbligatori
                                                            </li> 
                                                            <li class="card-text">
                                                                I campi STRUTTURA, DATA E ORARIO non sono obbligatori per lo screening Colon retto (Invio solleciti non rispondenti e Provetta non riconsegnata)
                                                            </li> 
                                                            <li class="card-text">
                                                                Il campo ORARIO non è obbligatorio per lo screening HCV 
                                                            </li>                                                                                                                                                                                     
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="table-responsive mt-2">
                                                    <table class="table table-striped table-hover table-sm m-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    NOME
                                                                </th>
                                                                <th scope="col">
                                                                    COGNOME
                                                                </th>                                                                
                                                                <th scope="col">
                                                                    CODICE_FISCALE
                                                                </th>
                                                                <th scope="col">
                                                                    STRUTTURA
                                                                </th>
                                                                <th scope="col">
                                                                    DATA
                                                                </th>
                                                                <th scope="col">
                                                                    ORARIO
                                                                </th>
                                                                <th scope="col">
                                                                    CELLULARE
                                                                </th>  
                                                                <th scope="col">
                                                                    EMAIL
                                                                </th> 
                                                                <th scope="col">
                                                                    PID
                                                                </th>                                                                                                                                                                                                 
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    MARIO
                                                                </td>
                                                                <td>
                                                                    ROSSI
                                                                </td>                                                                
                                                                <td>
                                                                    MRRLXX60B25H501O
                                                                </td>
                                                                <td>
                                                                    STRUTTURA_TEST  
                                                                </td>
                                                                <td>
                                                                    07/10/2022
                                                                </td>
                                                                <td>
                                                                    10:00
                                                                </td>
                                                                <td>
                                                                    3331230000
                                                                </td>  
                                                                <td>
                                                                    mario_rossi@test.it
                                                                </td>
                                                                <td>
                                                                    X0000000
                                                                </td>                                                                                                                                                                                                 
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr class="w-25 mb-4">

                            <div class="row">
                                <div class="col-lg-8 offset-lg-2">
                                    <div class="card-space mb-4">
                                        <div class="card card-bg no-after">
                                            <div class="card-body">

                                                <div class="row mb-3">
                                                    <div class="col-sm-2 d-flex">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-cloud-upload w-100 m-auto" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                                                            <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                                        </svg>
                                                    </div>
                                                    <div class="col-sm-10 d-flex justify-content-start align-items-center">
                                                        <div>
                                                            <h3 class="card-title text-truncate">
                                                                Importa xls
                                                            </h3>
                                                            <p class="card-text m-0">
                                                                Con il bottone che segue potrai caricare il file in formato <b>.xls</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <label for="contacts_xls" class="btn btn-primary w-100 m-0">
                                                    Seleziona un file
                                                    <input
                                                        type        = "file"
                                                        name        = "contacts_xls"
                                                        id          = "contacts_xls"
                                                        accept      = ".xls,.xlsx"
                                                        class       = "sr-only"
                                                        @change     = "upload_xls"
                                                    />
                                                </label>

                                                <p class="card-text m-0 mt-3 text-success" v-if="
                                                    file.name
                                                ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                    </svg> {{file.name}}
                                                </p>

                                                <div class="alert alert-danger mt-3" v-if="validations.create.appointments.KO">
                                                    {{validations.create.appointments.KO}}
                                                </div>                    
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="
                                        file.pazienti
                                    ">
                                        <div class="card-space mb-4">
                                            <div class="card card-bg no-after">
                                                <div class="card-body">

                                                    <div class="row mb-3">
                                                        <div class="col-sm-2 d-flex">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-table w-100 m-auto" viewBox="0 0 16 16">
                                                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                                                            </svg>
                                                        </div>
                                                        <div class="col-sm-10 d-flex justify-content-start align-items-center">
                                                            <div>
                                                                <h3 class="card-title text-truncate m-0">
                                                                    Dati pazienti
                                                                </h3>
                                                                <p class="card-text m-0">
                                                                    La tabella che segue è messa in confronto con quella del sistema Geco, consulta la correttezza dei dati e procedi. I recapiti sono modificabili.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="table-responsive">
                                                        <table class="table table-striped table-hover table-sm m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        NOME
                                                                    </th>
                                                                    <th scope="col">
                                                                        COGNOME
                                                                    </th>                                                                    
                                                                    <th scope="col">
                                                                        CODICE_FISCALE
                                                                    </th>
                                                                    <th scope="col">
                                                                        STRUTTURA
                                                                    </th>
                                                                    <th scope="col">
                                                                        DATA
                                                                    </th>
                                                                    <th scope="col">
                                                                        ORARIO
                                                                    </th>
                                                                    <th scope="col">
                                                                        CELLULARE
                                                                    </th>
                                                                    <th scope="col">
                                                                        EMAIL
                                                                    </th>
                                                                    <th scope="col">
                                                                        PID
                                                                    </th>                                                                   
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    v-for   = "paziente of file.pazienti"
                                                                    :key    = "`paziente_${paziente.CODICE_FISCALE}`"
                                                                    :class  = "{
                                                                        'table-success': paziente.status == 1,
                                                                        'table-danger':  paziente.status == 0,
                                                                        'table-warning': paziente.status == 2,
                                                                    }"
                                                                >
                                                                    <td>
                                                                        {{paziente.NOME}}
                                                                    </td>
                                                                    <td>
                                                                        {{paziente.COGNOME}}
                                                                    </td>                                                                    
                                                                    <td style="font-family: consolas">
                                                                        {{paziente.CODICE_FISCALE}}
                                                                    </td>
                                                                    <td>
                                                                        {{paziente.STRUTTURA}}
                                                                    </td>
                                                                    <td>
                                                                        {{paziente.DATA | data_slash}}
                                                                    </td>
                                                                    <td>
                                                                        {{paziente.ORARIO | data_ora}}
                                                                    </td>
                                                                    <!--<td v-if="
                                                                        paziente.status == 1
                                                                    ">
                                                                        <input
                                                                            type    = "phone"
                                                                            v-model = "paziente.recapito"
                                                                            style   = "
                                                                                max-width:  7em;
                                                                                border:     none;
                                                                                background: inherit;
                                                                            "
                                                                            min-length  = "8"
                                                                            max-length  = "10"
                                                                        >
                                                                    </td>
                                                                    <td v-else-if="
                                                                        paziente.status == 0
                                                                    ">
                                                                        <input
                                                                            type        = "phone"
                                                                            v-model     = "paziente.recapito"
                                                                            placeholder = "CF inesistente"
                                                                            style       = "
                                                                                max-width:  7em;
                                                                                border:     none;
                                                                                background: inherit;
                                                                            "
                                                                            min-length  = "8"
                                                                            max-length  = "10"
                                                                        >
                                                                    </td>
                                                                    <td v-else-if="
                                                                        paziente.status == 2
                                                                    ">
                                                                        <input
                                                                            type        = "phone"
                                                                            v-model     = "paziente.recapito"
                                                                            placeholder = "Non presente"
                                                                            style       = "
                                                                                max-width:  7em;
                                                                                border:     none;
                                                                                background: inherit;
                                                                            "
                                                                            min-length  = "8"
                                                                            max-length  = "10"
                                                                        >
                                                                    </td>-->
                                                                    <td>
                                                                        {{paziente.CELLULARE}}
                                                                    </td>                                                                    
                                                                    <td>
                                                                        {{paziente.EMAIL}}
                                                                    </td>
                                                                    <td>
                                                                        {{paziente.PID}}
                                                                    </td>                                                                    
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-space mb-4">
                                            <div class="card card-bg no-after">
                                                <div class="card-body">

                                                    <div class="row mb-3">
                                                        <div class="col-sm-2 d-flex">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-file-earmark-medical w-100 m-auto" viewBox="0 0 16 16">
                                                                <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>
                                                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                                            </svg>
                                                        </div>
                                                        <div class="col-sm-10 d-flex justify-content-start align-items-center">
                                                            <div>
                                                                <h3 class="card-title text-truncate m-0">
                                                                    Screening & Test
                                                                </h3>
                                                                <p class="card-text m-0">
                                                                    Ogni appuntamento sarà associato ad un determinato screening con conseguente test.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <label class="ml-2 mb-1 font-weight-bold">
                                                        Screening
                                                    </label>

                                                    <div class="form-group border mb-1">

                                                        <select
                                                            class   = "form-control form-control-sm"
                                                            id      = "screenings"
                                                            v-model = "form.id_screening"
                                                        >
                                                            <option disabled value="" selected>
                                                                Seleziona uno screening
                                                            </option>
                                                            <option
                                                                v-for   = "screening of DB.screenings"
                                                                :key    = "`screening_${screening.id}`"
                                                                :value  = "screening.id"
                                                            >
                                                                {{screening.title}}
                                                            </option>
                                                        </select>

                                                    </div>

                                                    <small class="form-text text-muted">
                                                        Ogni appuntamento verrà associato a questo screening.
                                                    </small>

                                                    <div class="mt-2" v-if="
                                                        form.id_screening
                                                    ">

                                                        <label class="ml-2 mb-1 font-weight-bold">
                                                            Test
                                                        </label>

                                                        <div class="form-group border mb-1">

                                                            <select
                                                                class   = "form-control form-control-sm"
                                                                id      = "tests"
                                                                v-model = "form.id_test"
                                                            >
                                                                <option disabled value="" selected>
                                                                    Seleziona un test
                                                                </option>
                                                                <option
                                                                    v-for   = "screening_test of screening.screenings_tests"
                                                                    :key    = "`test_${screening_test.test.id}`"
                                                                    :value  = "screening_test.test.id"
                                                                >
                                                                    {{screening_test.test.title}}
                                                                </option>
                                                            </select>

                                                        </div>

                                                        <small class="form-text text-muted">
                                                            Ogni appuntamento verrà associato a questo test.
                                                        </small>

                                                    </div>

                                                    <div class="mt-2" v-if="form.id_screening && form.id_screening == 3">

                                                        <label class="ml-2 mb-1 font-weight-bold">
                                                            Tipo SMS
                                                        </label>

                                                        <div class="form-group border mb-1">

                                                            <select
                                                                class   = "form-control form-control-sm"
                                                                id      = "sms"
                                                                v-model = "form.id_sms"
                                                            >
                                                                <option disabled value="" selected>
                                                                    Seleziona tipo sms
                                                                </option>
                                                                <option :value = "1">
                                                                    Invio solleciti non rispondenti
                                                                </option> 
                                                                <option :value = "2">
                                                                    Provetta non riconsegnata
                                                                </option>                                                                                                                                                        
                                                            </select>

                                                        </div>

                                                        <small class="form-text text-muted">
                                                            Ogni appuntamento verrà associato a questo test.
                                                        </small>

                                                    </div>                                                    

                                                    <div class="alert alert-success mt-3" v-if="
                                                        validations.create.appointments.OK
                                                    ">
                                                        {{validations.create.appointments.OK}}
                                                    </div>
                                                    <div class="alert alert-danger mt-3" v-if="
                                                        validations.create.appointments.KO
                                                    ">
                                                        {{validations.create.appointments.KO}}
                                                    </div>

                                                    <button
                                                        class       = "btn btn-primary btn-block mt-3"
                                                        v-if        = "form.id_test && form.id_screening != 3"
                                                        :disabled   = "
                                                            validations.create.appointments.OK ||
                                                            loaders.create.appointments
                                                        "
                                                        @click      = "create_appointments"
                                                    >
                                                        <span v-show="
                                                            !loaders.create.appointments
                                                        ">
                                                            Crea appuntamenti & notifica i pazienti
                                                        </span>
                                                        <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="
                                                            loaders.create.appointments
                                                        ">
                                                            <div class="progress-spinner-inner"></div>
                                                            <div class="progress-spinner-inner"></div>
                                                            <span class="sr-only">Caricamento...</span>
                                                        </div>
                                                    </button>

                                                    <button
                                                        class       = "btn btn-primary btn-block mt-3"
                                                        v-if        = "form.id_test && form.id_sms && form.id_screening == 3"
                                                        :disabled   = "
                                                            validations.create.appointments.OK ||
                                                            loaders.create.appointments
                                                        "
                                                        @click      = "create_appointments"
                                                    >
                                                        <span v-show="
                                                            !loaders.create.appointments
                                                        ">
                                                            Crea appuntamenti & notifica i pazienti
                                                        </span>
                                                        <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="
                                                            loaders.create.appointments
                                                        ">
                                                            <div class="progress-spinner-inner"></div>
                                                            <div class="progress-spinner-inner"></div>
                                                            <span class="sr-only">Caricamento...</span>
                                                        </div>
                                                    </button>                                                   

                                                </div>
                                            </div>
                                        </div>
                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">                                    
                                    <i class="text-muted">
                                        Nessuna funzione
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import * as XLSX from 'xlsx'

    import Swal from 'sweetalert2'

    export default {
        data() {
            return {
                file: {
                    name:       null,
                    pazienti:   null
                },
                form: {
                    id_screening:   null,
                    id_test:        null
                },
                DB: {
                    screenings: [],
                    settings: [],
                    max_sms_mammografico: null,
                    max_sms_citologico: null,
                    max_sms_colonretto: null,
                    max_sms_hcv: null,                    
                    appointments: []
                },
                validations: {
                    create: {
                        appointments: {
                            OK: false,
                            KO: false
                        }
                    }
                },
                loaders: {
                    create: {
                        appointments: false
                    }
                }
            }
        },
        methods: {       
            upload_xls(event) {
                this.validations.create.appointments.KO = false
                // File selezionato dal dispositivo
                const file = event.target.files[0]
                
                // Mostriamo il nome del file nella view
                this.file.name = file.name

                // Richiamo del File reader
                const fr = new FileReader()

                fr.onloadend = async e => {

                    const data = new Uint8Array(
                        e.target.result
                    )

                    const workbook = XLSX.read(
                        data,
                        {
                            type: "array",
                            cellDates: true
                        }
                    )
                    let pazienti

                    // Iterazione per ogni foglio del file excel per popolare variabile dei pazienti
                    for(
                        const foglio of workbook.SheetNames
                    ) {

                        pazienti = XLSX.utils.sheet_to_json(
                            workbook.Sheets[
                                foglio
                            ]
                        )

                    }
                    
                    try {

                        pazienti = (
                            await this.axios.post(
                                "admin/appointments/outbound/check/xls_data",
                                pazienti,
                                {
                                    headers:{
                                        Authorization: `bearer ${this.$store.state.auth.token}`
                                    }
                                }
                            )
                        ).data.pazienti

                        for (const key in pazienti) {
                            
                            if(pazienti[key].PID){
                                var pidString = pazienti[key].PID.toString();
                                if(pidString.length > 30){
                                    this.validations.create.appointments.KO = `Il PID (${pidString}) contiene più di 30 caratteri!`
                                    this.$toast.open({
                                        message: `Il PID (${pidString}) contiene più di 30 caratteri!`,
                                        type:    `error`
                                    })
                                    return false;
                                } 
                            }
                        }                       

                        this.$toast.open({
                            message: "Upload del file avvenuta con successo!",
                            type: "success"
                        })

                        // Mostriamo nella view il risultato del confronto
                        this.file.pazienti = pazienti

                    }
                    catch(e) {
                        if(
                            e.response.status     == 401 &&
                            e.response.statusText == "Unauthorized"
                        ) {

                            this.$toast.open({
                                message: `La tua sessione è scaduta, effettua nuovamente il login`,
                                type:    `error`
                            })

                            this.$router.push('/sessionescaduta')
                        }
                        else {
                            this.validations.create.appointments.KO = e.response.data.message
                            this.$toast.open({
                                message: `Non è momentaneamente possibile scaricare le tipologie di utenza. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                                type:    `error`
                            })
                            setTimeout(() => {
                                this.$router.go()
                            }, 20000);                            
                        }
                    }                    



                    // Chiamata API per confronto con tabella Geco
                    /*try {

                        pazienti = (
                            await this.axios.post(
                                "admin/appointments/outbound/compare/geco",
                                pazienti,
                                {
                                    headers:{
                                        Authorization: `bearer ${this.$store.state.auth.token}`
                                    }
                                }
                            )
                        ).data.pazienti

                        this.$toast.open({
                            message: "Confronto con la tabella Geco avvenuto con successo",
                            type: "success"
                        })

                        // Mostriamo nella view il risultato del confronto
                        this.file.pazienti = pazienti

                    }
                    catch(e) {
                        if(
                            e.response.status     == 401 &&
                            e.response.statusText == "Unauthorized"
                        ) {

                            this.$toast.open({
                                message: `La tua sessione è scaduta, effettua nuovamente il login`,
                                type:    `error`
                            })

                            this.$router.push('/sessionescaduta')
                        }
                        else {
                            this.$toast.open({
                                message: `Non è momentaneamente possibile scaricare le tipologie di utenza. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                                type:    `error`
                            })
                        }
                    }*/

                    

                }

                // Elabora il file
                fr.readAsArrayBuffer(
                    file
                )

            },
            async read_screenings() {
                try {              

                    this.DB.screenings = (
                        await this.axios.get(`user/screenings/read/actives`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data.screenings_db

                    

                }             
                catch(e) {
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le tipologie di utenza. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            async create_appointments() {

                this.loaders.create.appointments        = true
                this.validations.create.appointments.OK = false
                this.validations.create.appointments.KO = false

                try {

                    this.DB.settings = (
                        await this.axios.get(`admin/settings/read/settings`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data.setting_db  
                    
                    for(let max_sms of this.DB.settings) {
                        if(max_sms.id_screening == 2){
                            this.DB.max_sms_mammografico = max_sms.max_sms
                        } else if(max_sms.id_screening == 1){
                            this.DB.max_sms_citologico = max_sms.max_sms
                        } else if(max_sms.id_screening == 3){
                            this.DB.max_sms_colonretto = max_sms.max_sms
                        } else {
                            this.DB.max_sms_hcv = max_sms.max_sms
                        }
                    }                       

                    var totSmsSent;
                    var counterSms;
                    var max_sms;
                    
                    if(this.form.id_screening == 2){
                        const sent_sms = await this.axios.post(`user/appointments/read/counter_sms_sent_mammografico`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        const confirm_sms = await this.axios.post(`user/appointments/read/counter_sms_confirm_mammografico`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                        
                        const reminder_sms = await this.axios.post(`user/appointments/read/counter_sms_reminder_mammografico`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })                    
                        

                        let counter_sent_sms;
                        let counter_confirm_sms;
                        let counter_reminder_sms;

                        if(sent_sms.data.appointments_db.length == 0){
                            counter_sent_sms = 0;
                        } else {
                            for(let sentSms of sent_sms.data.appointments_db) {
                                counter_sent_sms = sentSms.counter;
                            }
                        } 

                        if(confirm_sms.data.appointments_db.length == 0){
                            counter_confirm_sms = 0;
                        } else {
                            for(let confirmSms of confirm_sms.data.appointments_db) {
                                counter_confirm_sms = confirmSms.counter;
                            }    
                        }   

                        if(reminder_sms.data.appointments_db.length == 0){
                            counter_reminder_sms = 0;
                        }  else {

                            for(let reminderSms of reminder_sms.data.appointments_db) {                       
                                counter_reminder_sms = reminderSms.counter;
                            } 
                        }                                   
                        
                        totSmsSent = counter_sent_sms + counter_confirm_sms + counter_reminder_sms;

                        counterSms = this.file.pazienti.length * 2;

                        max_sms = this.DB.max_sms_mammografico;

                    } else if(this.form.id_screening == 1){

                        const sent_sms = await this.axios.post(`user/appointments/read/counter_sms_sent_citologico`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        const confirm_sms = await this.axios.post(`user/appointments/read/counter_sms_confirm_citologico`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                        
                        const reminder_sms = await this.axios.post(`user/appointments/read/counter_sms_reminder_citologico`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })                    
                        

                        let counter_sent_sms;
                        let counter_confirm_sms;
                        let counter_reminder_sms;

                        if(sent_sms.data.appointments_db.length == 0){
                            counter_sent_sms = 0;
                        } else {
                            for(let sentSms of sent_sms.data.appointments_db) {
                                counter_sent_sms = sentSms.counter;
                            }
                        } 

                        if(confirm_sms.data.appointments_db.length == 0){
                            counter_confirm_sms = 0;
                        } else {
                            for(let confirmSms of confirm_sms.data.appointments_db) {
                                counter_confirm_sms = confirmSms.counter;
                            }    
                        }   

                        if(reminder_sms.data.appointments_db.length == 0){
                            counter_reminder_sms = 0;
                        }  else {

                            for(let reminderSms of reminder_sms.data.appointments_db) {                       
                                counter_reminder_sms = reminderSms.counter;
                            } 
                        }                                   
                        
                        totSmsSent = counter_sent_sms + counter_confirm_sms + counter_reminder_sms;

                        counterSms = this.file.pazienti.length * 2;

                        max_sms = this.DB.max_sms_citologico;    

                    } else if(this.form.id_screening == 3){

                        const sent_sms = await this.axios.post(`user/appointments/read/counter_sms_sent_colonretto`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        const confirm_sms = await this.axios.post(`user/appointments/read/counter_sms_confirm_colonretto`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        const reminder_sms = await this.axios.post(`user/appointments/read/counter_sms_reminder_colonretto`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })                    


                        let counter_sent_sms;
                        let counter_confirm_sms;
                        let counter_reminder_sms;

                        if(sent_sms.data.appointments_db.length == 0){
                            counter_sent_sms = 0;
                        } else {
                            for(let sentSms of sent_sms.data.appointments_db) {
                                counter_sent_sms = sentSms.counter;
                            }
                        } 

                        if(confirm_sms.data.appointments_db.length == 0){
                            counter_confirm_sms = 0;
                        } else {
                            for(let confirmSms of confirm_sms.data.appointments_db) {
                                counter_confirm_sms = confirmSms.counter;
                            }    
                        }   

                        if(reminder_sms.data.appointments_db.length == 0){
                            counter_reminder_sms = 0;
                        }  else {

                            for(let reminderSms of reminder_sms.data.appointments_db) {                       
                                counter_reminder_sms = reminderSms.counter;
                            } 
                        }                                   

                        totSmsSent = counter_sent_sms + counter_confirm_sms + counter_reminder_sms;

                        counterSms = this.file.pazienti.length;

                        max_sms = this.DB.max_sms_colonretto; 

                    } else {

                        const sent_sms = await this.axios.post(`user/appointments/read/counter_sms_sent_hcv`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        const confirm_sms = await this.axios.post(`user/appointments/read/counter_sms_confirm_hcv`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        const reminder_sms = await this.axios.post(`user/appointments/read/counter_sms_reminder_hcv`, {year: 0}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })                    


                        let counter_sent_sms;
                        let counter_confirm_sms;
                        let counter_reminder_sms;

                        if(sent_sms.data.appointments_db.length == 0){
                            counter_sent_sms = 0;
                        } else {
                            for(let sentSms of sent_sms.data.appointments_db) {
                                counter_sent_sms = sentSms.counter;
                            }
                        } 

                        if(confirm_sms.data.appointments_db.length == 0){
                            counter_confirm_sms = 0;
                        } else {
                            for(let confirmSms of confirm_sms.data.appointments_db) {
                                counter_confirm_sms = confirmSms.counter;
                            }    
                        }   

                        if(reminder_sms.data.appointments_db.length == 0){
                            counter_reminder_sms = 0;
                        }  else {

                            for(let reminderSms of reminder_sms.data.appointments_db) {                       
                                counter_reminder_sms = reminderSms.counter;
                            } 
                        }                                   

                        totSmsSent = counter_sent_sms + counter_confirm_sms + counter_reminder_sms;

                        counterSms = this.file.pazienti.length * 2;

                        max_sms = this.DB.max_sms_hcv;                    
                    }

                    max_sms = max_sms - totSmsSent;


                    if(max_sms >= counterSms){
                        let res = await this.axios.post(
                            "admin/appointments/outbound/create/appointments",
                            {
                                id_screening:   this.form.id_screening,
                                id_test:        this.form.id_test,
                                id_sms:         this.form.id_sms,
                                pazienti:       this.file.pazienti
                            },
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )

                        this.validations.create.appointments.OK = res.data.message

                    } else {
                        const message = `Con questo upload il limite degli sms annuali verrà superato e alcuni sms di invio appuntamento e conferma non verrano inviati. Procedere comunque?`
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: "btn btn-success",
                                cancelButton: "btn btn-danger"
                            },
                            buttonsStyling: false
                            });
                            swalWithBootstrapButtons.fire({
                            title: message,
                            text: "",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Si, procedi!",
                            cancelButtonText: "No, annulla caricamento!",
                            reverseButtons: true
                            }).then(async (result) => {
                            if (result.isConfirmed) {
                                let res = await this.axios.post(
                                    "admin/appointments/outbound/create/appointments",
                                    {
                                        id_screening:   this.form.id_screening,
                                        id_test:        this.form.id_test,
                                        id_sms:         this.form.id_sms,
                                        pazienti:       this.file.pazienti
                                    },
                                    {
                                        headers:{
                                            Authorization: `bearer ${this.$store.state.auth.token}`
                                        }
                                    }
                                )

                                this.validations.create.appointments.OK = res.data.message                                
                            }
                        });
                        

                        //this.validations.create.appointments.KO = message                        
                    }

                }
                catch(e) {

                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')

                    }
                    else {

                        const message = `Non è possibile creare gli appuntamenti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`

                        this.validations.create.appointments.KO = message

                        this.$toast.open({
                            message,
                            type:    `error`
                        })

                    }

                }
                finally {
                    this.loaders.create.appointments = false
                }
            }
        },
        mounted() {
            this.read_screenings()
        },
        computed: {
            screening() {
                return (
                    this.DB.screenings.filter(
                        screening => screening.id == this.form.id_screening
                    )
                )[0]
            }
        }
    }
</script>