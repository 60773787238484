<template>

  <div id="app">

    <navbarScreening/>

    <div v-bind:class="{'container-fluid': isLogged}">
      <div v-bind:class="{'row': isLogged && isAdmin}">
        <div v-if="isLogged && isAdmin" class="d-none d-lg-block col-lg-2 border-right p-0">
          <aside-left />
        </div>
        <div v-bind:class="{'col-lg-10': isLogged && isAdmin}">
          <router-view />
        </div>
      </div>
    </div>

  </div>

</template>

<script>

  import navbarScreening from '@/components/navbarScreening.vue'
  import asideLeft       from '@/components/asideLeft.vue'
  import store           from '@/store'

  export default {
    created() {
      if (sessionStorage.getItem('reloaded') != null) {
          //console.log('page was reloaded');
      } else {
          store.dispatch('doCentralLogoutPatient')
          this.$router.push('/')
      }
      sessionStorage.setItem('reloaded', 'yes');      
    },  
    components: {
      navbarScreening,
      asideLeft
    },

    computed: {
      isLogged() {
        return store.getters.isLogged
      },
      isAdmin() {
        return store.getters.isAdmin
      }
    }

  }
  
</script>