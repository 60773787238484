<template>
    <div class="row h-100">
        <div class="col-lg-10 pt-2 pb-2">
            <loader v-if="loaders.appointments" />
            <!--<div v-show="loaders.reading">  --> 
            <div >            
                <div class="container-fluid" v-if="view.mode == `calendar`" v-show="!loaders.appointments">
                    
                    <div id="style">
                        
                    </div>

                    <div class="row mt-2 mb-2">
                        <div class="col-lg-4">
                            <strong>Screening:</strong> <span class="text-uppercase">{{ titleScreening }}</span>
                        </div>
                        <div class="col-lg-4 text-center">
                            <strong>Test:</strong> <span class="text-uppercase">{{ titleTest }}</span>
                        </div>      
                        <div class="col-lg-4 text-right">
                            <strong>Esito SMS:</strong> <span class="text-uppercase">{{ titleSms }}</span>
                        </div>                                
                    </div>

                    <vue-cal

                        locale                  = "it"
                        :active-view            = "calendar.config.activeView"
                        :time                   = "false"
                        :min-date               = "calendar.config.minDate"
                        :events                 = "calendar.config.events"
                        :time-from              = "calendar.config.timeFrom"
                        :time-to                = "calendar.config.timeTo"
                        :on-event-click         = "readEventDetail"
                        events-on-month-view    = "short"
                        :disable-views          = "[
                            'years',
                            window.width < 800 ? 'month' : null,
                            window.width < 800 ? 'week' : null
                        ]"
                        events-count-on-year-view
                        @view-change         = "change_calendar"                    

                    >
                        <!--<template #cell-content="{ view, specialHours, events }">
                            <span v-if="view.id === 'week'">
                                {{ specialHours.length }}
                            </span>
                            <span v-if="events.length === 0">
                                Appuntamenti ❌
                            </span>                        
                        </template>-->
                        <template #cell-content="{ events }">
                            <span v-if="events.length === 0">
                                Appuntamenti ❌
                            </span>                        
                        </template>                   

                    </vue-cal>

                </div>
                <div v-if="view.mode == `standard` && !loaders.appointments" class="container-fluid">
                    
                    <div class="row pb-3">
                        <div class="col-sm-3 col-lg-3 mb-3 mb-lg-0 col-xl-3">
                            <div class="card card-body mb-3 mb-lg-0 d-flex justify-content-center">

                                <form>
                                    <div>
                                        <p class="mb-2 font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar4-week mb-1" viewBox="0 0 16 16">
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z"/>
                                                <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                                            </svg>
                                            Data
                                        </p>                                   
                                        <div class="form-group row mb-1">
                                            <label for="filter_from_date" class="col-form-label">Dal</label>
                                            
                                            <div class="col-md-8 col-lg-12 col-xl-8 mt-4">
                                                <input type="date" class="form-control" id="filter_from_date" v-model="filters.date.from" @change="search_appointments_by_filters_of_standard_view" required>
                                            </div>

                                        </div>
                                        <div class="form-group row mb-0">

                                            <label for="filter_to_date" class="col-form-label">Al</label>
                                            
                                            <div class="col-md-8 col-lg-12 col-xl-8 mt-4">
                                                <input type="date" class="form-control" id="filter_to_date" :min="filters.date.from" v-model="filters.date.to" @change="search_appointments_by_filters_of_standard_view" required>
                                            </div>

                                        </div>
                                    </div>    
                                </form>

                            </div>                           
                        </div>
                        <div class="col-sm-3 col-lg-3 mb-3 mb-lg-0">
                            <div class="card card-body mb-3 mb-lg-0 d-flex justify-content-center">

                                <form>
                                    <div>
                                        <p class="mb-3 font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg mb-1" viewBox="0 0 16 16">
                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                                </svg>
                                            Stato
                                        </p>
                                        <div class="custom-control custom-radio text-truncate">
                                            <input type="radio" class="custom-control-input" :id="`status_1`" v-model="filters.status" :value="1" @change="search_appointments_by_filters_of_standard_view">
                                            <label class="custom-control-label text-uppercase ml-1" :for="`status_1`"> Confermato</label>
                                        </div>
                                        <div class="custom-control custom-radio text-truncate">
                                            <input type="radio" class="custom-control-input" :id="`status_2`" v-model="filters.status" :value="2" @change="search_appointments_by_filters_of_standard_view">
                                            <label class="custom-control-label text-uppercase ml-1" :for="`status_2`"> Annullato</label>
                                        </div>   
                                        <div class="custom-control custom-radio text-truncate">
                                            <input type="radio" class="custom-control-input" :id="`status_3`" v-model="filters.status" :value="3" @change="search_appointments_by_filters_of_standard_view">
                                            <label class="custom-control-label text-uppercase ml-1" :for="`status_3`"> Attesa conferma</label>
                                        </div> 
                                        <div class="custom-control custom-radio text-truncate">
                                            <input type="radio" class="custom-control-input" :id="`status_4`" v-model="filters.status" :value="4" @change="search_appointments_by_filters_of_standard_view">
                                            <label class="custom-control-label text-uppercase ml-1" :for="`status_4`"> Tutti</label>
                                        </div>                                                                                                                               
                                    </div>
                                </form>

                            </div>
                        </div> 
                        <div class="col-sm-3 col-lg-3 mb-3 mb-lg-0">
                            <div class="card card-body mb-3 mb-lg-0 d-flex justify-content-center">

                                <form>
                                    <div>
                                        <p class="mb-3 font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-hospital mb-1" viewBox="0 0 16 16">
                                                <path d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5Zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9h-.5Zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Z"/>
                                                <path d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1V1Zm2 14h2v-3H7v3Zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm0-14H6v1h4V1Zm2 7v7h3V8h-3Zm-8 7V8H1v7h3Z"/>
                                            </svg>
                                            Screening
                                        </p>
                                        <div class="custom-control custom-radio text-truncate border">
                                            <select class="form-control" id="screenings" v-model="filters.screening" @change="search_appointments_by_filters_of_standard_view">
                                                <option value="" selected>
                                                    TUTTI
                                                </option>
                                                <option v-for="screening of screenings" class="text-uppercase form-control form-control-sm" :key="`screening_${screening.id}`" :value="screening.id">
                                                    {{screening.title}}
                                                </option>
                                            </select>                                           
                                        </div>
                                    </div> 
                                    <div class="mt-3">
                                        <p class="mb-2 font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-hospital mb-1" viewBox="0 0 16 16">
                                                <path d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5Zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9h-.5Zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Z"/>
                                                <path d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1V1Zm2 14h2v-3H7v3Zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm0-14H6v1h4V1Zm2 7v7h3V8h-3Zm-8 7V8H1v7h3Z"/>
                                            </svg>
                                            Test
                                        </p>
                                        <div class="custom-control custom-radio text-truncate border">
                                            <select class="form-control" id="tests" v-model="filters.test" @change="search_appointments_by_filters_of_standard_view">
                                                <option value="" selected>
                                                    TUTTI
                                                </option>
                                                <option v-for="test of tests" :selected="test.test.id" :key="`test_${test.test.id}`" :value="test.test.id">
                                                    {{test.test.title}}
                                                </option>
                                            </select>                                           
                                        </div>
                                    </div>                                                                         
                                </form>

                            </div>
                        </div> 

                        <div class="col-sm-3 col-lg-3 mb-3 mb-lg-0">
                            <div class="card card-body mb-3 mb-lg-0 d-flex justify-content-center">

                                <form>
                                    <div>
                                        <p class="mb-3 font-weight-bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-hospital mb-1" viewBox="0 0 16 16">
                                                <path d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5Zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9h-.5Zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Z"/>
                                                <path d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1V1Zm2 14h2v-3H7v3Zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm0-14H6v1h4V1Zm2 7v7h3V8h-3Zm-8 7V8H1v7h3Z"/>
                                            </svg>
                                            Struttura
                                        </p>
                                        <div class="custom-control custom-radio text-truncate border">
                                            
                                            <select class="form-control" id="structures" v-model="filters.structure" @change="search_appointments_by_filters_of_standard_view">
                                                <option value="" selected>
                                                    TUTTI
                                                </option>
                                                <option v-for="structure of DB.structures" class="text-uppercase form-control form-control-sm" :key="`structure_${structure.id}`" :value="structure.id">
                                                    {{structure.title}} - {{structure.typeTitle}}
                                                </option>
                                            </select>                                           
                                        </div>
                                    </div> 
                                </form>
                            </div>                               
                        </div>
                    </div>
                    <div class="alert alert-danger" v-if="appointments.length < 1 && !loaders.reading">
                        Nessun appuntamento con i parametri ricercati
                    </div>
                    <!--<div class="alert alert-info" v-show="loaders.reading">
                        Ricerca appuntamenti..
                    </div>-->
                    <div class="card card-body mb-3" v-for="(appointment, index) in appointments" :key="`appointment_${index}`" >
                        <div class="row font-weight-bold mb-2 bg-primary">
                            <div class="col-4">
                                <span class="text-uppercase">
                                    DATA: {{appointment.date | data_slash}}
                                </span>
                            </div>
                            <div class="col-4 text-center">
                                <span class="text-uppercase">
                                    MATTINA
                                </span>
                            </div>                           
                            <div class="col-4 text-right f-family-monospace">
                                <span class="f-size-1-em">
                                    Totale appuntamenti: {{appointment.morning.length}}
                                </span>
                            </div>
                        </div>                           

                        <div class="alert alert-danger m-0" v-if="appointment.morning.length < 1">
                            Nessun appuntamento
                        </div>

                        <div v-else>
                            <div class="table-responsive m-0">
                                <table class="table table-sm table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Ora</th>
                                            <th scope="col">Nome e cognome</th>
                                            <th scope="col">Cod. fiscale</th>
                                            <th scope="col">Screening</th>
                                            <th scope="col">Test</th>
                                            <th scope="col">Struttura</th>
                                            <th scope="col">Stato</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        <tr v-for="(appointment_detail, index2) in appointment.morning" :key="`appointment_detail_${index2}`" style="cursor:pointer" @click="readEventDetail(appointment_detail)">
                                            <th width="2%" scope="row">{{appointment_detail.id_appointment}}</th>
                                            <th width="10%" scope="row">
                                                <div v-if="appointment_detail.titleScreening == 'HCV'">
                                                    DALLE 08 ALLE 10
                                                </div> 
                                                <div v-else>
                                                    {{appointment_detail.time | data_ora}}
                                                </div>                                                
                                            </th>
                                            <th width="15%" scope="row" class="text-uppercase">{{appointment_detail.name}} {{appointment_detail.surname}}</th>
                                            <th width="13%" scope="row" class="text-uppercase">{{appointment_detail.CF}}</th>
                                            <th width="15%" scope="row" :style="`color: ${appointment_detail.colorScreening}`">{{appointment_detail.titleScreening}}</th>
                                            <th width="15%" scope="row">{{appointment_detail.titleTest}}</th>
                                            <th width="15%" scope="row">{{appointment_detail.titleStructure}}</th>
                                            <th width="15%" scope="row">
                                                <div style="color: #00CC00" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                    CONFERMATO
                                                </div> 
                                                <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && appointment_detail.appointment_rejected">
                                                    ANNULLATO
                                                </div>    
                                                <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms == 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                    SCADUTO
                                                </div>   
                                                <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.reply_sms && appointment_detail.reply_sms != '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                    ANNULLATO
                                                </div>   
                                                <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.sent_sms == 2">
                                                    ANNULLATO
                                                </div>    
                                                <div style="color: #00CC00" v-if="appointment_detail.reminder_reply_sms == '1' && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                    CONFERMATO
                                                </div>   
                                                <div style="color: #FF8000" v-if="!appointment_detail.reply_sms && !appointment_detail.appointment_rejected">
                                                    ATTESA CONFERMA
                                                </div>                                                                                                                                                                                                                                                                                                                                                                               
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                            

                        </div>

                        <div class="row font-weight-bold mb-2 bg-primary">
                            <div class="col-4">
                                <span class="text-uppercase">
                                    DATA: {{appointment.date | data_slash}}
                                </span>
                            </div>
                            <div class="col-4 text-center">
                                <span class="text-uppercase">
                                    POMERIGGIO
                                </span>
                            </div>                           
                            <div class="col-4 text-right f-family-monospace">
                                <span class="f-size-1-em">
                                    Totale appuntamenti: {{appointment.afternoon.length}}
                                </span>
                            </div>
                        </div>                           

                        <div class="alert alert-danger m-0" v-if="appointment.afternoon.length < 1">
                            Nessun appuntamento
                        </div>

                        <div v-else>
                            <div class="table-responsive m-0">
                                <table class="table table-sm table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Ora</th>
                                            <th scope="col">Nome e cognome</th>
                                            <th scope="col">Cod. fiscale</th>
                                            <th scope="col">Screening</th>
                                            <th scope="col">Test</th>
                                            <th scope="col">Struttura</th>
                                            <th scope="col">Stato</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        <tr v-for="(appointment_detail, index2) in appointment.afternoon" :key="`appointment_detail_${index2}`" style="cursor:pointer" @click="readEventDetail(appointment_detail)">
                                            <th width="2%" scope="row">{{appointment_detail.id_appointment}}</th>
                                            <th width="10%" scope="row">
                                                <div v-if="appointment_detail.titleScreening == 'HCV'">
                                                    DALLE 08 ALLE 10
                                                </div> 
                                                <div v-else>
                                                    {{appointment_detail.time | data_ora}}
                                                </div>                                                  
                                            </th>
                                            <th width="15%" scope="row" class="text-uppercase">{{appointment_detail.name}} {{appointment_detail.surname}}</th>
                                            <th width="13%" scope="row" class="text-uppercase">{{appointment_detail.CF}}</th>
                                            <th width="15%" scope="row" :style="`color: ${appointment_detail.colorScreening}`">{{appointment_detail.titleScreening}}</th>
                                            <th width="15%" scope="row">{{appointment_detail.titleTest}}</th>
                                            <th width="15%" scope="row">{{appointment_detail.titleStructure}}</th>
                                            <th width="15%" scope="row">
                                                <div style="color: #00CC00" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                    CONFERMATO
                                                </div> 
                                                <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && appointment_detail.appointment_rejected">
                                                    ANNULLATO
                                                </div>    
                                                <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms == 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                    SCADUTO
                                                </div>   
                                                <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.reply_sms && appointment_detail.reply_sms != '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                    ANNULLATO
                                                </div>   
                                                <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.sent_sms == 2">
                                                    ANNULLATO
                                                </div>    
                                                <div style="color: #00CC00" v-if="appointment_detail.reminder_reply_sms == '1' && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                    CONFERMATO
                                                </div>   
                                                <div style="color: #FF8000" v-if="!appointment_detail.reply_sms && !appointment_detail.appointment_rejected">
                                                    ATTESA CONFERMA
                                                </div>                                                                                                                                                                                                                                                                                                                                                                               
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                            

                        </div>


                    </div>


                    <!-- Scarico -->
                    <vue-html2pdf
                        :float-layout                = "true"
                        :show-layout                 = "false"
                        :enable-download             = "false"
                        :preview-modal               = "true"
                        :paginate-elements-by-height = "1400"
                        filename                     = "appuntamenti screeening asl roma 2"
                        :pdf-quality                 = "2"
                        :manual-pagination           = "true"

                        pdf-format        = "a4"
                        pdf-orientation   = "landscape"
                        pdf-content-width = "100%"

                        :html-to-pdf-options = "pdf.config_rapporto"
                        ref = "html2Pdf"

                     >

                        <section slot="pdf-content" class="container">

                            <div class="row mb-4">
                                <div class="col-lg-4">
                                    <img src="../../../assets/LOGO_ASL_ROMA_2.png" style="width:200px">
                                </div>
                                <div class="col-lg-8 text-right">
                                    <p class="m-0">
                                        <small>
                                            Data:
                                        </small>
                                    </p>
                                    <p class="mt-neg-5_5">
                                        <small class="text-monospace">
                                            {{pdf.today | data_slash}}
                                        </small>
                                    </p>
                                    <p class="mb-2">
                                        <small>
                                            Ricerca dalla data <b>{{filters.date.from | data_slash}}</b> alla data <b>{{filters.date.to | data_slash}}</b>
                                        </small>
                                    </p>                                   
                                </div>
                            </div>

                            <div class="text-danger" v-if="appointments.length < 1 && !loaders.reading">
                                Nessun appuntamento trovato!
                            </div>                       
                            <section class="mb-3 pdf-item" v-for="(appointment, index) in appointments" :key="`appointment_${index}`">

                                <div v-if="appointment.morning.length > 0">
                                    <div class="row font-weight-bold mb-2 bg-primary">
                                        <div class="col-4">
                                            <span class="text-uppercase">
                                                DATA: {{appointment.date | data_slash}}
                                            </span>
                                        </div>
                                        <div class="col-4 text-center">
                                            <span class="text-uppercase">
                                                MATTINA
                                            </span>
                                        </div>                                       
                                        <div class="col-4 text-right f-family-monospace">
                                            <span class="f-size-1-em">
                                                Totale appuntamenti: {{appointment.morning.length}}
                                            </span>
                                        </div>
                                    </div>                                      

                                    <div class="container-fluid">

                                        <table class="table table-sm table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Ora</th>
                                                    <th scope="col">Nome e cognome</th>
                                                    <th scope="col">Cod. fiscale</th>
                                                    <th scope="col">Screening</th>
                                                    <th scope="col">Test</th>
                                                    <th scope="col">Struttura</th>
                                                    <th scope="col">Stato</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(appointment_detail, index2) in appointment.morning" :key="`appointment_detail_${index2}`" style="height:40px; cursor:pointer" @click="readEventDetail(appointment_detail)">
                                                    
                                                    <th width="2%" scope="row">{{appointment_detail.id_appointment}}</th>
                                                    <th width="10%" scope="row">
                                                        <div v-if="appointment_detail.titleScreening == 'HCV'">
                                                            DALLE 08 ALLE 10
                                                        </div> 
                                                        <div v-else>
                                                            {{appointment_detail.time | data_ora}}
                                                        </div>                                                          
                                                    </th>
                                                    <th width="15%" scope="row" class="text-uppercase text-truncate">{{appointment_detail.name}} {{appointment_detail.surname}}</th>
                                                    <th width="13%" scope="row" class="text-uppercase">{{appointment_detail.CF}}</th>
                                                    <th width="15%" scope="row" :style="`color: ${appointment_detail.colorScreening}`">{{appointment_detail.titleScreening}}</th>
                                                    <th width="15%" scope="row">{{appointment_detail.titleTest}}</th>
                                                    <th width="15%" scope="row" class="text-truncate">{{appointment_detail.titleStructure}}</th>
                                                    <th width="15%" scope="row" class="text-truncate">
                                                        <div style="color: #00CC00" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                            CONFERMATO
                                                        </div> 
                                                        <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && appointment_detail.appointment_rejected">
                                                            ANNULLATO
                                                        </div>    
                                                        <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms == 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                            SCADUTO
                                                        </div>   
                                                        <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.reply_sms && appointment_detail.reply_sms != '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                            ANNULLATO
                                                        </div>   
                                                        <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.sent_sms == 2">
                                                            ANNULLATO
                                                        </div>    
                                                        <div style="color: #00CC00" v-if="appointment_detail.reminder_reply_sms == '1' && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                            CONFERMATO
                                                        </div>   
                                                        <div style="color: #FF8000" v-if="!appointment_detail.reply_sms && !appointment_detail.appointment_rejected">
                                                            ATTESA CONFERMA
                                                        </div>                                                                                                                                                                                                                                                                                                                                                                               
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

 
                                <div v-if="appointment.afternoon.length > 0">
                                    <div class="row font-weight-bold mb-2 bg-primary">
                                        <div class="col-4">
                                            <span class="text-uppercase">
                                                DATA: {{appointment.date | data_slash}}
                                            </span>
                                        </div>
                                        <div class="col-4 text-center">
                                            <span class="text-uppercase">
                                                POMERIGGIO
                                            </span>
                                        </div>                                       
                                        <div class="col-4 text-right f-family-monospace">
                                            <span class="f-size-1-em">
                                                Totale appuntamenti: {{appointment.afternoon.length}}
                                            </span>
                                        </div>
                                    </div>                                      

                                    <div class="container-fluid">

                                        <table class="table table-sm table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Ora</th>
                                                    <th scope="col">Nome e cognome</th>
                                                    <th scope="col">Cod. fiscale</th>
                                                    <th scope="col">Screening</th>
                                                    <th scope="col">Test</th>
                                                    <th scope="col">Struttura</th>
                                                    <th scope="col">Stato</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(appointment_detail, index2) in appointment.afternoon" :key="`appointment_detail_${index2}`" style="height:40px; cursor:pointer" @click="readEventDetail(appointment_detail)">
                                                    
                                                    <th width="2%" scope="row">{{appointment_detail.id_appointment}}</th>
                                                    <th width="10%" scope="row">
                                                        <div v-if="appointment_detail.titleScreening == 'HCV'">
                                                            DALLE 08 ALLE 10
                                                        </div> 
                                                        <div v-else>
                                                            {{appointment_detail.time | data_ora}}
                                                        </div>  
                                                    </th>
                                                    <th width="15%" scope="row" class="text-uppercase text-truncate">{{appointment_detail.name}} {{appointment_detail.surname}}</th>
                                                    <th width="13%" scope="row" class="text-uppercase">{{appointment_detail.CF}}</th>
                                                    <th width="15%" scope="row" :style="`color: ${appointment_detail.colorScreening}`">{{appointment_detail.titleScreening}}</th>
                                                    <th width="15%" scope="row">{{appointment_detail.titleTest}}</th>
                                                    <th width="15%" scope="row" class="text-truncate">{{appointment_detail.titleStructure}}</th>
                                                    <th width="15%" scope="row" class="text-truncate">
                                                        <div style="color: #00CC00" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                            CONFERMATO
                                                        </div> 
                                                        <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && appointment_detail.appointment_rejected">
                                                            ANNULLATO
                                                        </div>    
                                                        <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms == 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                            SCADUTO
                                                        </div>   
                                                        <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.reply_sms && appointment_detail.reply_sms != '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                            ANNULLATO
                                                        </div>   
                                                        <div style="color: #CE0000" v-if="appointment_detail.reminder_reply_sms != '1' && appointment_detail.sent_sms == 2">
                                                            ANNULLATO
                                                        </div>    
                                                        <div style="color: #00CC00" v-if="appointment_detail.reminder_reply_sms == '1' && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected">
                                                            CONFERMATO
                                                        </div>   
                                                        <div style="color: #FF8000" v-if="!appointment_detail.reply_sms && !appointment_detail.appointment_rejected">
                                                            ATTESA CONFERMA
                                                        </div>                                                                                                                                                                                                                                                                                                                                                                               
                                                    </th>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>                               

                            </section>


                        </section>

                    </vue-html2pdf>                  
                </div> 
            </div>                       
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate pl-2">Funzioni</h3>
                            </li>
                            <li>
                                <router-link
                                    class = "list-item medium text-truncate pl-2"
                                    to    = "appuntamenti/outbound"
                                >
                                    Inviti outbound 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-send ml-3" viewBox="0 0 16 16">
                                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                                    </svg>
                                </router-link>
                            </li> 
                            <li>
                                <a class="list-item medium text-truncate pl-2" @click="changeModeView">
                                    
                                    <span v-if="view.mode == `calendar`">Standard view</span>
                                    <svg v-if="view.mode == `calendar`" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-view-stacked" viewBox="0 0 16 16">
                                        <path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z"/>
                                    </svg>

                                    <span v-if="view.mode == `standard`">Calendar view</span>
                                    <svg v-if="view.mode == `standard`" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-calendar-event" viewBox="0 0 16 16">
                                        <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                    </svg>

                                </a>
                            </li> 
                            <li v-show="view.mode == `standard`">
                                <a class="list-item medium text-truncate pl-2 " @click="createPDF('html2Pdf')">
                                    
                                    <span>Stampa PDF</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                        <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                                    </svg>

                                </a>
                            </li>
                            <li v-show="view.mode == `standard`">
                                <a class="list-item medium text-truncate pl-2 " @click="createXLS()">
                                    
                                    <span>Download XLS</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"/>
                                    </svg>

                                </a>
                            </li>                                                                                                                                             
                            <li v-if="view.mode == `calendar`">
                                <h3 class="text-truncate mt-3">Filtri di ricerca</h3>
                            </li>
                            <li class="mr-4" v-if="view.mode == `calendar`">
                                <p class="text-muted mb-2 f-s-0_8-em">
                                    Compila i seguenti campi, la ricerca avverrà in automatico
                                </p>
                                <div class="row m-0">
                                    <div class="col-lg-12 mb-1">
                                        <select class="form-control selectForm text-uppercase mb-lg-0 mt-2" id="screeningsc" v-model="form.search.selectedScreening" @change="readAppointmentsFilter(structure_id, $event.target.value, test_id)">
                                            <option value="0" disabled>Screening</option>
                                            <option v-for="screening of screenings" :selected="screening.id" :key="`screening_${screening.id}`" :value="screening.id">
                                                {{screening.title}}
                                            </option>
                                        </select>                                        
                                    </div> 
                                    <div class="col-lg-12 mb-1">
                                        <select class="form-control selectForm text-uppercase mb-lg-0 mt-2" id="testsc" :class="{ disabled : isDisabled,  selectFormDisabled : isDisabled}" v-model="form.search.selectedTest" @change="readAppointmentsFilter(structure_id, screening_id, $event.target.value)">
                                            <option value="0" disabled>Test</option>
                                            <option v-for="test of tests" :selected="test.test.id" :key="`test_${test.test.id}`" :value="test.test.id">
                                                {{test.test.title}}
                                            </option>
                                        </select>                                        
                                    </div>                                                                            
                                    <div class="col-lg-12 mb-1">
                                        <select class="form-control selectForm text-uppercase mb-lg-0 mt-2" @change="readAppointmentsFilter(structure_id, screening_id, test_id)" v-model="form.search.selectedStatus" id="smsStatus">
                                            <option value="0" disabled>Esito SMS</option>
                                            <option value="1">Inviato</option>
                                            <option value="2">Confermato</option>
                                            <option value="3">Non confermato</option>
                                            <option value="4">Da contattare</option>
                                        </select> 
                                    </div>                                 
                                    <div class="col-lg-12 mb-1">
                                        <select class="form-control selectForm text-uppercase mb-lg-0 mt-2" id="structuresc" v-model="form.search.selectedStructure" @change="readAppointmentsFilter($event.target.value, screening_id, test_id)">
                                            <option value="0" selected disabled>
                                                STRUTTURA
                                            </option>
                                            <option v-for="structure of DB.structures" class="text-uppercase form-control form-control-sm" :key="`structure_${structure.id}`" :value="structure.id">
                                                {{structure.title}}
                                            </option>
                                        </select>                                       
                                    </div>
                                    <div class="col-lg-12 mb-1">
                                        <input class="form-control text-uppercase" type="text" @input="readAppointmentsFilter(structure_id, screening_id, test_id)" v-model="form.search.cf" placeholder="Codice fiscale">
                                    </div>  
                                    <div class="col-lg-12 mb-1">
                                        <input class="form-control text-uppercase" type="text" @input="readAppointmentsFilter(structure_id, screening_id, test_id)" v-model="form.search.surname" placeholder="Cognome">
                                    </div>                                                                                                                                                          
                                </div>
                            </li>

                            <!--<li>
                                <label for="import_file" class="w-100 pr-4 c-084f9b font-weight-bold cursor-pointer">
                                    
                                    <span class="mr-2">Importa</span>
                                    <input type="file" id="import_file" class="sr-only" accept=".xls,.xlsx" @change="logFile">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-xls mb-1" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"/>
                                    </svg>

                                    <p class="m-t-neg_15-px m-0">
                                        <small class="f-s-0_6-em text-danger">
                                            {{functions.import_xls.name_file}}
                                        </small>
                                    </p>

                                </label>
                            </li>-->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import moment      from 'moment'
    import VueCal      from 'vue-cal'
    import $           from 'jquery'
    import * as XLSX   from 'xlsx'
    import _           from 'lodash'
    import VueHtml2pdf from 'vue-html2pdf'
    import 'vue-cal/dist/i18n/it.js'
    import 'vue-cal/dist/vuecal.css'

    const today = new Date()  

    export default {
        data() {
            return {
                calendar: {
                    config: {
                        activeView:   "day",
                        minDate:  new Date(),
                        timeStep: 60,
                        timeFrom: 8*60,
                        timeTo:   20*60,
                        events:   []
                    }
                },
                view: {
                    mode: `standard`
                },  
                filters: {
                    status:  false,
                    date: {
                        from: null,
                        to:   null
                    },
                    structure: ``,
                    screening: ``,
                    test: ``
                },                             
                eventsConfirmed:  [],
                functions: {
                    import_xls: {
                        name_file: null,
                        structures: {}
                    }
                },
                window: {
                    width: window.innerWidth
                },
                loaders: {
                    reading: true,
                    appointments: false
                },                                
                form: {
                    search: {
                        selectedScreening: '0',
                        selectedTest: '0',
                        selectedStatus: '0',
                        selectedStructure: '0',
                        cf:    ``,
                        surname:    ``
                    }
                },
                titleScreening: 'Tutti',
                titleSms: 'Tutti',
                titleTest: 'Tutti',
                appointments: [{date: null, items: [], morning: [], afternoon: []}],  
                appointments_detail: [],  
                screenings: [],
                tests: [] ,
                screening_id: '',
                test_id: '',
                structure_id: '',
                isDisabled: true,
                ordinamento: {
                    time:    "asc"
                },
                DB: {
                    structures: []
                },
                pdf: {
                    config: {
                        margin: [5, 5, 5, 5]
                    },
                    config_rapporto: {

                        margin: [5, 5, 25, 5],

                        jsPDF: {
                            //unit: 'in',
                            format: 'a4',
                            orientation: 'landscape'
                        },

                        filename: "rapporto"

                    },              
                    today
                },
                jsonXls: []

            }
        },
        components: {
            VueCal,
            VueHtml2pdf
        },
        methods: {   
            timeApp(date) {
                return moment(date).format("HHmm");
            },            
            createPDF(ref) {
                this.$refs[ref].generatePdf()
            },      
            async readStructures(screeningId = '0') {
                try {

                    const structures = await this.axios.get(`user/structures/read/all_structures_by_screening/${screeningId}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.structures = structures.data.structures_db
     
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le strutture. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },           
            search_appointments_by_filters_of_standard_view() {
                this.appointments = []
                this.readAppointmentsStandard(
                    moment(this.filters.date.from).format("YYYY-MM-DD 00:00"),
                    moment(this.filters.date.to).format("YYYY-MM-DD 23:59"),
                )
            },
            makeOrder(
                key
            ) {
                for(
                    const flag in this.ordinamento
                ) {
                    if(
                        flag != key
                    ) this.ordinamento[flag] = null
                    else {
                        if(
                            this.ordinamento[flag] == "asc"
                        ) this.ordinamento[flag] = "desc"
                        else this.ordinamento[flag] = "asc"
                    }
                }
            },              
            change_calendar(event) {
                this.calendar.config.events = []
                // Ricaviamo la tipologia di vista
                const {
                    view,
                    startDate
                } = event

                // Instanziamo l'oggetto query da inserire nell'url
                let query_for_url = {}

                // Esaminiamo i casi
                switch(view) {
                    case "day":
                        query_for_url.v = "d"
                        query_for_url.d = moment(startDate).format("YYYY-MM-DD")
                    break
                    case "week":
                        query_for_url.v = "w"
                        query_for_url.d = moment(moment(startDate).format("YYYY-MM-DD 05:00")).weekday(moment().weekday()).format("YYYY-MM-DD")
                    break
                    case "month":
                        query_for_url.v = "m"
                        query_for_url.d = moment().format(`YYYY-${moment(startDate).format("MM")}-DD`)
                    break
                    case "year":
                        query_for_url.v = "y"
                        query_for_url.d = moment().format(`${moment(startDate).format("YYYY")}-MM-DD`)
                    break
                }

                // Controllo per evitare errore in console
                if (!_.isEqual(this.$route.query, query_for_url)) {

                    // Impostiamo nel calendario le date di nostro interesse e il tipo di visualizzazione
                    this.$router.push({ name: "admin_appointments_index", query: {
                        ...this.$route.query,
                        ...query_for_url
                    }})
                    
                    // Richiamo funzione per la ricerca degli appuntamenti
                    this.search_appointments_by_url_params()
                }

            },

            ready_calendar() {
                this.search_appointments_by_url_params()
            },            
            search_appointments_by_url_params() {

                this.form.search.selectedScreening = '0';
                this.form.search.selectedTest = '0';
                this.form.search.selectedStatus = '0';
                this.form.search.selectedStructure = '0';
                this.form.search.cf = '';
                this.form.search.surname = '';            

                // Recuperiamo la tipologia di vista e giorno
                const {
                    v, // view
                    d  // day
                } = this.$route.query

                if(this.check_presence_of_wrong_url_params) {
                    this.readAppointments(
                        moment().weekday(1).subtract(1, "days").format("YYYY-MM-DD 00:00"),
                        moment().weekday(7).subtract(1, "days").format("YYYY-MM-DD 23:59")
                    )
                }
                else {

                    // Visualizziamo il giorno
                    this.calendar.config.selectedDate = d

                    switch(v) {

                        case "d":

                            // Impostiamo la visibilità a giorno
                            this.calendar.config.activeView = "day"
                            // Lettura appuntamenti impostando le date in giornata
                            this.readAppointments(
                                moment(d).format("YYYY-MM-DD 00:00"),
                                moment(d).format("YYYY-MM-DD 23:59")
                            )

                        break
                        case "w":

                            // Impostiamo la visibilità a mese
                            this.calendar.config.activeView = "week"
                            // Lettura appuntamenti impostando le date in giornata
                            this.readAppointments(
                                moment(d).weekday(1).subtract(1, "days").format("YYYY-MM-DD 00:00"),
                                moment(d).weekday(7).subtract(1, "days").format("YYYY-MM-DD 23:59")
                            )

                        break
                        case "m":

                            // Impostiamo la visibilità a mese
                            this.calendar.config.activeView = "month"
                            // Lettura appuntamenti impostando le date in giornata
                            this.readAppointments(
                                moment(d).format(`YYYY-MM-01 00:00`),
                                moment(d).endOf('month').format('YYYY-MM-DD 23:59')
                            )

                        break
                        case "y":

                            // Impostiamo la visibilità ad anno
                            this.calendar.config.activeView = "year"
                            // Lettura appuntamenti impostando le date in giornata
                            this.readAppointments(
                                moment(d).format(`YYYY-01-01 00:00`),
                                moment(d).endOf('year').format('YYYY-MM-DD 23:59')
                            )

                        break

                    }

                }
            },
            changeModeView() {
                const {
                    d  
                } = this.$route.query     

                // Se non è presente una specifica richiesta di prospettiva effettua lo switch
                if(this.view.mode == "calendar") {
                    // Lettura appuntamenti impostando le date in giornata
                    //this.readAppointments();               

                    this.view.mode = "standard"
                    
                    this.$router.push({
                        name: "admin_appointments_index",
                        query: {...this.$route.query, p: "s"}
                    })

                 
                    let currentDate = new Date().toJSON().slice(0, 10);
                    let currentDate1 = currentDate + ' 00:00:00';
                    let currentDate2 = currentDate + ' 23:59:59';

                    this.appointments = [];
                    this.set_params_filter_input_search_of_standard_view(currentDate1, currentDate2)
                    
                    this.readAppointmentsStandard(
                        moment(this.calendar.config.selectedDate).format("YYYY-MM-DD 00:00"),
                        moment(this.calendar.config.selectedDate).format("YYYY-MM-DD 23:59")
                    )  
                    
                    this.readStructures()
                } else {

                    this.view.mode = "calendar"

                    this.$router.push({
                        name: "admin_appointments_index",
                        query: {...this.$route.query, p: "c"}
                    })

                    //this.pushDataChart()                    

                    this.readAppointments(
                        moment(d).format("YYYY-MM-DD 00:00"),
                        moment(d).format("YYYY-MM-DD 23:59")
                    )  

                    this.readStructures()
                }

            },
            set_params_filter_input_search_of_standard_view(from_date, to_date) {
                this.filters.date.from = moment(from_date).format("YYYY-MM-DD")
                this.filters.date.to   = moment(to_date).format("YYYY-MM-DD")
            },  
            getStatusXls(appointment_detail) {
                if(appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected){
                    return 'CONFERMATO';
                } else if(appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms != 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && appointment_detail.appointment_rejected){
                    return 'ANNULLATO';
                } else if(appointment_detail.reminder_reply_sms != '1' && appointment_detail.confirm_sms == 3 && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected){
                    return 'SCADUTO';
                } else if(appointment_detail.reminder_reply_sms != '1' && appointment_detail.reply_sms && appointment_detail.reply_sms != '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected){
                    return 'ANNULLATO';
                } else if(appointment_detail.reminder_reply_sms != '1' && appointment_detail.sent_sms == 2){
                    return 'ANNULLATO';
                } else if(appointment_detail.reminder_reply_sms == '1' && appointment_detail.reply_sms == '1' && appointment_detail.status_sms == 1 && appointment_detail.sent_sms == 1 && !appointment_detail.appointment_rejected){
                    return 'CONFERMATO';
                } else if(!appointment_detail.reply_sms && !appointment_detail.appointment_rejected){
                    return 'ATTESA CONFERMA'; 
                }               
            },          
            async createXLS() {           
                
                this.jsonXls = [];
                for(let appointment of this.appointments) {
                    for(let jsonData of appointment.items) {
                        this.jsonXls.push(jsonData)
                    }    
                }  
                
                const files = JSON.parse(JSON.stringify(this.jsonXls));       

                const rows = files.map(row => ({
                    id: row.id_appointment,
                    date: moment(row.date).format("DD/MM/YYYY"),
                    time: moment(row.time).format("HH:mm"),
                    name: (row.name + ' ' + (row.surname ? row.surname : '')).toUpperCase(),
                    cf: row.CF,
                    screening: row.titleScreening,
                    test: row.titleTest,
                    structure: row.titleStructure,
                    status: this.getStatusXls(row) 
                }));

                const worksheet = XLSX.utils.json_to_sheet(rows);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

                XLSX.utils.sheet_add_aoa(worksheet, [["ID", "Data", "Ora", "Nome e cognome", "Codice fiscale", "Screening", "Test", "Struttura", "Stato"]], { origin: "A1" });
                var wscols = [
                    {wch:6},
                    {wch:10},
                    {wch:6},
                    {wch:20},
                    {wch:20},
                    {wch:20},
                    {wch:20},
                    {wch:20},
                    {wch:20}
                ];
                //const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
                worksheet["!cols"] = wscols;

                XLSX.writeFile(workbook, "Appuntamenti_screening_aslroma2.xlsx", { compression: true });
               
                //this.loaders.reading = true
                //this.loaders.appointments = true
                //this.calendar.config.events = [];    

            },             
            async readAppointmentsStandard(from_date, to_date) {           
                
                this.loaders.reading = true
                this.loaders.appointments = true
                          
                let currentDate = new Date().toJSON().slice(0, 10);
                let currentDate1 = currentDate + ' 00:00:00';
                let currentDate2 = currentDate + ' 23:59:59';

                                
                if(document.getElementById("filter_from_date") != null){
                    /*let inputElem = document.getElementById('filter_from_date').value + ' 00:00:00';
                    if(inputElem != currentDate1){
                        currentDate1 = inputElem
                    }*/
                    let inputElem2;
                    if(document.getElementById('filter_to_date').value == ''){
                        inputElem2 = currentDate + ' 23:59:59';
                        //inputElem2 = document.getElementById('filter_from_date').value + ' 23:59:59';
                    } else {
                        inputElem2 = document.getElementById('filter_to_date').value + ' 23:59:59';
                    }                    
                    if(inputElem2 != currentDate2){
                        currentDate2 = inputElem2
                    } 
                    
                }

                this.set_params_filter_input_search_of_standard_view(currentDate1, currentDate2)


                this.appointments = [];

                const status = this.filters.status;
                const structure = this.filters.structure;
                const screening = this.filters.screening;

                const test = this.filters.test;

                if(screening != ''){
                    this.readScreeningTests(screening)  
                    this.readStructures(screening)  
                }  else {
                    this.readStructures('0')  
                } 
                
                if(structure != ''){
                    this.screenings = []
                    this.readScreeningsByStructure(structure)  
                }                    

                try {
                    const appointments = await this.axios.post(`user/appointments/read/standard_search`, {from_date, to_date, status, structure, screening, test}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    
                    //let svgApp;
                    for(let appointment of appointments.data.appointments_db) {

                        var dateDetails = appointment.date
                        dateDetails = dateDetails.split('+');
                        dateDetails = dateDetails[0]

                        try {
                            const appointments_detail = await this.axios.post(`user/appointments/read/standard_search_detail`, {dateDetails, status, structure, screening, test}, {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }) 

                            let morningApps = []
                            let afternoonApps = []

                            for(let times of appointments_detail.data.appointments) {
                                if(this.timeApp(times.time) < 1359){
                                    morningApps.push(times)
                                } 
                                if(this.timeApp(times.time) > 1359){
                                    afternoonApps.push(times)
                                }                                                                 
                            }    



                            this.appointments.push({date: moment(dateDetails, "YYYY-MM-DD 00:00").add(1, 'days'), items: appointments_detail.data.appointments, morning: morningApps, afternoon: afternoonApps});

                        }
                        
                        catch(e) {
                            if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                                this.$toast.open({
                                    message: `La tua sessione è scaduta, effettua nuovamente il login`,
                                    type:    `error`
                                })

                                this.$router.push('/sessionescaduta')
                            }
                            else {
                                this.$toast.open({
                                    message: `Non è momentaneamente possibile scaricare gli appuntamenti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                                    type:    `error`
                                })
                                this.loaders.appointments = false
                            }

                        }
                        finally {
                            this.loaders.reading = false
                            this.loaders.appointments = false
                        }                
                                                  
 
                        /*if(appointment.reminder_reply_sms != '1' && appointment.confirm_sms != 3 && appointment.reply_sms == '1' && appointment.status_sms == 1 && appointment.sent_sms == 1){
                            if(!appointment.appointment_rejected){
                                this.eventsConfirmed.push({
                                    start:  `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment(appointment.time).format(`HH:mm`)}`,
                                    end:    `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment(appointment.time).add(appointment.structures_tests.appointment_duration, 'minutes').format(`HH:mm`)}`,
                                    //title:   svgApp + ' ' + fullName + ' ' + '<br /> ' + appointment.patient_screenings_tests.tests.title + ' ' + '<br /> ' + appointment.patient_screenings_tests.screenings.title + ' ' + appointment.structures.title,
                                    title:   svgApp + ' ' + fullName,
                                    class:  `bg_${appointment.patient_screenings_tests.screenings.color.slice(1)} f-s-0_8-em`,
                                    content: 'ORE: ' + moment(appointment.time).format(`HH:mm`),
                                    id_appointment: appointment.id
                                })                                
                                svgApp = '<div class="bg-success mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16"><path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> CONFERMATO</div>'   
                            }  else {
                                svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16"><path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> ANNULLATO</div>'   
                            }
                        } else if(appointment.reminder_reply_sms != '1' && appointment.confirm_sms == 3 && appointment.reply_sms == '1' && appointment.status_sms == 1 && appointment.sent_sms == 1){
                            svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hourglass-bottom" viewBox="0 0 16 16"><path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z"/></svg> SCADUTO</div>'   
                        } else if(appointment.reminder_reply_sms != '1' && appointment.reply_sms && appointment.reply_sms != '1' && appointment.status_sms == 1 && appointment.sent_sms == 1){
                            svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16"><path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> ANNULLATO</div>'   
                        } else if(appointment.reminder_reply_sms != '1' && appointment.sent_sms == '2'){
                            svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16"><path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> ANNULLATO</div>'   
                        } else if(appointment.reminder_reply_sms == '1' ){
                            svgApp = '<div class="bg-success mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16"><path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> CONFERMATO</div>'   
                        } else {
                            svgApp = '<div class="bg-warning mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hourglass-top" viewBox="0 0 16 16"><path d="M2 14.5a.5.5 0 0 0 .5.5h11a.5.5 0 1 0 0-1h-1v-1a4.5 4.5 0 0 0-2.557-4.06c-.29-.139-.443-.377-.443-.59v-.7c0-.213.154-.451.443-.59A4.5 4.5 0 0 0 12.5 3V2h1a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1h1v1a4.5 4.5 0 0 0 2.557 4.06c.29.139.443.377.443.59v.7c0 .213-.154.451-.443.59A4.5 4.5 0 0 0 3.5 13v1h-1a.5.5 0 0 0-.5.5zm2.5-.5v-1a3.5 3.5 0 0 1 1.989-3.158c.533-.256 1.011-.79 1.011-1.491v-.702s.18.101.5.101.5-.1.5-.1v.7c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13v1h-7z"/></svg>ATTESA CONFERMA</div>'   

                        }     
                        
                        const fullName = (appointment.patient_screenings_tests.user.name + ' ' + appointment.patient_screenings_tests.user.surname).toUpperCase()

                        $("div#style").append(`<style>.bg_${appointment.patient_screenings_tests.screenings.color.slice(1)}{background-color: ${appointment.patient_screenings_tests.screenings.color}; color: #fff; font-weight: bold;}</style>`)
                        this.calendar.config.events.push({
                            start:  `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment(appointment.time).format(`HH:mm`)}`,
                            end:    `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment(appointment.time).add(appointment.structures_tests.appointment_duration, 'minutes').format(`HH:mm`)}`,
                            //title:   svgApp + ' ' + fullName + ' ' + '<br /> ' + appointment.patient_screenings_tests.tests.title + ' ' + '<br /> ' + appointment.patient_screenings_tests.screenings.title + ' ' + appointment.structures.title,
                            title:   svgApp + ' ' + fullName,
                            class:  `bg_${appointment.patient_screenings_tests.screenings.color.slice(1)} f-s-0_8-em`,
                            content: 'ORE: ' + moment(appointment.time).format(`HH:mm`),
                            id_appointment: appointment.id
                        })*/


                    }
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare gli appuntamenti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                        this.loaders.appointments = false
                    }

                }
                finally {
                    this.loaders.reading = false
                }                

            },                    

            async readAppointments(from_date, to_date) {

                this.loaders.reading = true
                this.loaders.appointments = true
                this.calendar.config.events = []
                          
                let currentDate = new Date().toJSON().slice(0, 10);
                let currentDate1 = currentDate + ' 00:00:00';
                let currentDate2 = currentDate + ' 23:59:59';

                let time_from = ' 00:00';
                let time_to = ' 00:00';

                const {
                    p, 
                } = this.$route.query    
                                
                
                if(p == "s" && document.getElementById("filter_from_date") != null){
                    let inputElem = document.getElementById('filter_from_date').value + ' 00:00:00';
                    if(inputElem != currentDate1){
                        currentDate1 = inputElem
                    }
                    let inputElem2;
                    if(document.getElementById('filter_to_date').value == ''){
                        inputElem2 = document.getElementById('filter_from_date').value + ' 23:59:59';
                    } else {
                        inputElem2 = document.getElementById('filter_to_date').value + ' 23:59:59';
                    }                    
                    if(inputElem2 != currentDate2){
                        currentDate2 = inputElem2
                    }                   
                }

                this.set_params_filter_input_search_of_standard_view(currentDate1, currentDate2)

                let where = {
                    AND: []
                }

                if(p == "s" && document.getElementById("filter_from_date") != null){

                    if(document.getElementById("filter_from_date").value == document.getElementById("filter_to_date").value){
                        time_from = ' 00:00';
                        time_to = ' 23:59';
                    }
                    from_date = document.getElementById("filter_from_date").value + time_from;
                    if(document.getElementById('filter_to_date').value == ''){
                        to_date = document.getElementById("filter_from_date").value + time_to;
                    } else {
                        to_date = document.getElementById("filter_to_date").value + time_to;
                    }

                }
                if(from_date != "" && to_date != ""){
                    from_date = moment().weekday(1).subtract(1, "days").format("YYYY-MM-DD 00:00"),
                    to_date = moment().weekday(7).subtract(1, "days").format("YYYY-MM-DD 23:59")
                }    

                where.AND.push({
                    date: {
                        gte: new Date(from_date),
                        lte: new Date(to_date)
                    }
                })
                
                try {
                    const appointments = await this.axios.post(`user/appointments/read/not_rejected`, {where}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                   
                    let svgApp;
                    for(let appointment of appointments.data.appointments_db) {

                        //this.appointments.push(appointment);
                        if(appointment.patient_screenings_tests.id_screening != 3){

                            
                            if(appointment.reminder_reply_sms != '1' && appointment.confirm_sms != 3 && appointment.reply_sms == '1' && appointment.status_sms == 1 && appointment.sent_sms == 1){
                                if(!appointment.appointment_rejected){
                                
                                    svgApp = '<div class="bg-success mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16"><path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> CONFERMATO</div>'   
                                }  else {
                                    svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16"><path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> ANNULLATO</div>'   
                                }
                            } else if(appointment.reminder_reply_sms != '1' && appointment.confirm_sms == 3 && appointment.reply_sms == '1' && appointment.status_sms == 1 && appointment.sent_sms == 1){
                                svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hourglass-bottom" viewBox="0 0 16 16"><path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z"/></svg> SCADUTO</div>'   
                            } else if(appointment.reminder_reply_sms != '1' && appointment.reply_sms && appointment.reply_sms != '1' && appointment.status_sms == 1 && appointment.sent_sms == 1){
                                svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16"><path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> ANNULLATO</div>'   
                            } else if(appointment.reminder_reply_sms != '1' && appointment.sent_sms == '2'){
                                svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16"><path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> ANNULLATO</div>'   
                            } else if(appointment.reminder_reply_sms == '1' ){
                                svgApp = '<div class="bg-success mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16"><path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> CONFERMATO</div>'   
                            } else {
                                svgApp = '<div class="bg-warning mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hourglass-top" viewBox="0 0 16 16"><path d="M2 14.5a.5.5 0 0 0 .5.5h11a.5.5 0 1 0 0-1h-1v-1a4.5 4.5 0 0 0-2.557-4.06c-.29-.139-.443-.377-.443-.59v-.7c0-.213.154-.451.443-.59A4.5 4.5 0 0 0 12.5 3V2h1a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1h1v1a4.5 4.5 0 0 0 2.557 4.06c.29.139.443.377.443.59v.7c0 .213-.154.451-.443.59A4.5 4.5 0 0 0 3.5 13v1h-1a.5.5 0 0 0-.5.5zm2.5-.5v-1a3.5 3.5 0 0 1 1.989-3.158c.533-.256 1.011-.79 1.011-1.491v-.702s.18.101.5.101.5-.1.5-.1v.7c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13v1h-7z"/></svg>ATTESA CONFERMA</div>'   

                            }  

                            let name = '';
                            let surname = '';
                            
                            if(appointment.patient_screenings_tests.user.surname != null){
                                surname = (appointment.patient_screenings_tests.user.surname).toUpperCase()
                            }

                            if(appointment.patient_screenings_tests.user.name != null){
                                name = (appointment.patient_screenings_tests.user.name).toUpperCase()
                            }  
                            
                            let startTime;
                            let endTime;
                            let content;
                            
                            if(appointment.patient_screenings_tests.screenings.title == "HCV"){
                                startTime = `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment('1899-12-30T07:00:00.000Z').format(`HH:mm`)}`
                                endTime = `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment('1899-12-30T09:00:00.000Z').format(`HH:mm`)}`
                                content = 'DALLE 08 ALLE 10'
                            } else {
                                startTime = `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment(appointment.time).format(`HH:mm`)}`
                                endTime = `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment(appointment.time).add(appointment.structures_tests.appointment_duration, 'minutes').format(`HH:mm`)}`
                                content = 'ORE: ' + moment(appointment.time).format(`HH:mm`)
                            }
                            
                            const fullName = name + ' ' + surname

                            $("div#style").append(`<style>.bg_${appointment.patient_screenings_tests.screenings.color.slice(1)}{background-color: ${appointment.patient_screenings_tests.screenings.color}; color: #fff; font-weight: bold;}</style>`)
                            
                            this.calendar.config.events.push({
                                start:  startTime,
                                end:    endTime,
                                //title:   svgApp + ' ' + fullName + ' ' + '<br /> ' + appointment.patient_screenings_tests.tests.title + ' ' + '<br /> ' + appointment.patient_screenings_tests.screenings.title + ' ' + appointment.structures.title,
                                title:   svgApp + ' ' + fullName,
                                class:  `bg_${appointment.patient_screenings_tests.screenings.color.slice(1)} f-s-0_8-em`,
                                content: content,
                                id_appointment: appointment.id
                            })
                        }     

                    }

                    this.loaders.appointments = false
                    
                }
                catch(e) {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare gli appuntamenti. Riprovi più tardi`,
                            type:    `error`
                        })

                }
                finally {
                    this.loaders.reading = false
                    this.loaders.appointments = false
                }                

            },
            async readAppointmentsFilter(structureId = '', screeningId = '', testId = '') {
                
                this.loaders.reading = true
                this.loaders.appointments = true
                this.calendar.config.events = [];    
                this.screening_id = screeningId;
                this.test_id = testId;
                this.structure_id = structureId;

                if(structureId != ''){
                    this.form.search.selectedScreening = '0';
                    this.form.search.selectedTest = '0';
                    this.isDisabled = true                          
                    this.screenings = []
                    this.readScreeningsByStructure(structureId)  
                    screeningId == ''
                }     
                
                if(screeningId == ''){
                    this.form.search.selectedScreening = '0';
                    this.form.search.selectedTest = '0';
                    this.isDisabled = true                    
                } else {
                    this.form.search.selectedScreening = screeningId;
                    if(screeningId != this.form.search.selectedScreening){
                        this.form.search.selectedTest = this.test_id;
                    } 
                    this.isDisabled = false                  
                }

                if(this.isInSearching()) {

                    const where1 = '%'+this.form.search.cf+'%'; 
                    const where2 = '%'+this.form.search.surname+'%'; 
                    const where3 = this.form.search.selectedStructure;

                    let where4;
                    let where4b;
                    let where4c;
                    let where4d;
                    if(this.form.search.selectedStatus == "1"){
                        where4 = '1';
                        this.titleSms = 'Inviato'
                    } else if(this.form.search.selectedStatus == "2"){
                        where4b = '1';
                        this.titleSms = 'Confermato'
                    } else if(this.form.search.selectedStatus == "3"){
                        where4c = '0';
                        this.titleSms = 'Non confermato'
                    } else if(this.form.search.selectedStatus == "4"){
                        where4d = '3';
                        this.titleSms = 'Da contattare'
                    } 
                    const where5 = screeningId;    
                    const where6 = parseInt(testId);   
                    

                    if(screeningId != ''){
                        
                        this.readStructures(screeningId)  
                        this.readScreeningTests(screeningId)  
                        
                        for(let screening of this.screenings) {
                            // Se la funzione software corrisponde all'ID passato
                            if(screening.id == this.screening_id) {
                                this.titleScreening = screening.title
                            }
                        }

                        
                        if(this.tests.length > 0){
                            for (var i = 0; i <= this.tests.length; i++) {
                                for(let test of this.tests) {
                                    // Se la funzione software corrisponde all'ID passato
                                    
                                    if(test.id_test == this.test_id) {
                                        this.titleTest = test.test.title
                                    }
                                }                                
                                
                            }  
                        }                        
                    } else {
                        this.readStructures('0')  
                    }

                    try {
                        const appointments = await this.axios.post(`user/appointments/read/search`, {where1, where2, where3, where4, where4b, where4c, where4d, where5, where6}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }) 
                          
                        let svgApp;
                        
                    
                        for(let appointment of appointments.data.appointments_db) {
                            if(appointment.reminder_reply_sms != '1' && appointment.confirm_sms != 3 && appointment.reply_sms == '1' && appointment.status_sms == 1 && appointment.sent_sms == 1){
                                if(!appointment.appointment_rejected){
                                    svgApp = '<div class="bg-success mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16"><path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> CONFERMATO</div>'   
                                }  else {
                                    svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16"><path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> ANNULLATO</div>'   
                                }
                            } else if(appointment.reminder_reply_sms != '1' && appointment.confirm_sms == 3 && appointment.reply_sms == '1' && appointment.status_sms == 1 && appointment.sent_sms == 1){
                                svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hourglass-bottom" viewBox="0 0 16 16"><path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z"/></svg> SCADUTO</div>'   
                            } else if(appointment.reminder_reply_sms != '1' && appointment.reply_sms && appointment.reply_sms != '1' && appointment.status_sms == 1 && appointment.sent_sms == 1){
                                svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16"><path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> ANNULLATO</div>'   
                            } else if(appointment.reminder_reply_sms != '1' && appointment.sent_sms == '2'){
                                svgApp = '<div class="bg-danger mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-x" viewBox="0 0 16 16"><path d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> ANNULLATO</div>'   
                            } else if(appointment.reminder_reply_sms == '1' ){
                                svgApp = '<div class="bg-success mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16"><path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg> CONFERMATO</div>'   
                            } else {
                                svgApp = '<div class="bg-warning mr-1 p-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hourglass-top" viewBox="0 0 16 16"><path d="M2 14.5a.5.5 0 0 0 .5.5h11a.5.5 0 1 0 0-1h-1v-1a4.5 4.5 0 0 0-2.557-4.06c-.29-.139-.443-.377-.443-.59v-.7c0-.213.154-.451.443-.59A4.5 4.5 0 0 0 12.5 3V2h1a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1h1v1a4.5 4.5 0 0 0 2.557 4.06c.29.139.443.377.443.59v.7c0 .213-.154.451-.443.59A4.5 4.5 0 0 0 3.5 13v1h-1a.5.5 0 0 0-.5.5zm2.5-.5v-1a3.5 3.5 0 0 1 1.989-3.158c.533-.256 1.011-.79 1.011-1.491v-.702s.18.101.5.101.5-.1.5-.1v.7c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13v1h-7z"/></svg>ATTESA CONFERMA</div>'   

                            }     
                            
                            let name = '';
                            let surname = '';
                            
                            if(appointment.surname != null){
                                surname = (appointment.surname).toUpperCase()
                            }

                            if(appointment.name != null){
                                name = (appointment.name).toUpperCase()
                            } 
                            
                            let startTime;
                            let endTime;
                            let content;
                            
                            if(appointment.patient_screenings_tests.screenings.title == "HCV"){
                                startTime = `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment('1899-12-30T07:00:00.000Z').format(`HH:mm`)}`
                                endTime = `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment('1899-12-30T09:00:00.000Z').format(`HH:mm`)}`
                                content = 'DALLE 08 ALLE 10'
                            } else {
                                startTime = `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment(appointment.time).format(`HH:mm`)}`
                                endTime = `${moment(appointment.date).format(`YYYY-MM-DD`)} ${moment(appointment.time).add(appointment.structures_tests.appointment_duration, 'minutes').format(`HH:mm`)}`
                                content = 'ORE: ' + moment(appointment.time).format(`HH:mm`)
                            }                      
                            
                            const fullName = name + ' ' + surname

                            $("div#style").append(`<style>.bg_${appointment.color.slice(1)}{background-color: ${appointment.color}; color: #fff; font-weight: bold;}</style>`)
                            this.calendar.config.events.push({
                                start:  startTime,
                                end:    endTime,
                                //title:   svgApp + ' ' + fullName + ' ' + '<br /> ' + appointment.patient_screenings_tests.tests.title + ' ' + '<br /> ' + appointment.patient_screenings_tests.screenings.title + ' ' + appointment.structures.title,
                                title:   svgApp + ' ' + fullName,
                                class:  `bg_${appointment.color.slice(1)} f-s-0_8-em`,
                                content: content,
                                id_appointment: appointment.id
                            })
                        }

                        this.loaders.appointments = false


                    }
                    catch(e) {

                        this.$toast.open({
                            message: `Non è stato trovato nessun record`,
                            type:    `error`
                        })

                    }
                    finally {
                        this.loaders.reading = false
                        this.loaders.appointments = false
                    }
                }
            },  
            isInSearching() {
                return this.form.search.surname.length >= 3 || this.form.search.cf.length >= 3 || this.form.search.selectedStatus != 0 || this.form.search.selectedStructure != 0  || this.form.search.selectedScreening != 0  || this.form.search.selectedTest != 0
            }, 
            async readScreeningsByStructure(structureId = '') {

                try {

                    const screenings = await this.axios.get(`user/screenings/read/structure/${structureId}`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.screenings = screenings.data.screenings_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i screenings. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            }, 
            async readScreenings() {
                try {

                    const screenings = await this.axios.get(`user/screenings/read/all`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.screenings = screenings.data.screenings_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i screenings. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            }, 
            async readScreeningTests(screeningId) {

                try {

                    let screening_tests = await this.axios.get(`user/screenings_tests/read/screening/${screeningId}`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    
                    this.tests = screening_tests.data.screening_tests_db

                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i tests. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },

            readEventDetail(
                event
            ) {
                this.$router.push(
                    `/admin/appuntamenti/modifica/${event.id_appointment}`
                )
            },
            logFile(event) {

                // File selezionato dal dispositivo
                const file = event.target.files[0]
                // Mostriamo il nome del file nella view
                this.functions.import_xls.name_file = file.name
                // Richiamo del File reader
                const fr   = new FileReader()
                // Variabile per le strutture
                let strutture = {}
                // Dopo l'inizializzazione
                fr.onloadend = async e => {

                    let data      = new Uint8Array(e.target.result)
                    let workbook  = XLSX.read(data, {type: 'array', cellDates: true})

                    for(let struttura of workbook.SheetNames) {

                        const appuntamenti_caricati = XLSX.utils.sheet_to_json(workbook.Sheets[struttura])

                        strutture[struttura] = []

                        for(const appuntamento of appuntamenti_caricati) {
                            strutture[struttura].push(appuntamento)
                        }

                    }

                    this.functions.import_xls.structures = strutture

                    try {

                        await this.axios.post(`user/appointments/create/from_json`, strutture, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                        
                    }
                    catch(e) {
                        if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                            this.$toast.open({
                                message: `La tua sessione è scaduta, effettua nuovamente il login`,
                                type:    `error`
                            })

                            this.$router.push('/sessionescaduta')
                        }
                        /*else {

                            this.$toast.open({
                                message: `Non è momentaneamente possibile scaricare gli appuntamenti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                                type:    `error`
                            })

                        }*/

                    }

                }

                fr.readAsArrayBuffer(file)

            }

        },
        mounted() {

            this.readAppointmentsStandard(    
                //moment(this.calendar.config.selectedDate).weekday(1).subtract(1, "days").format("YYYY-MM-DD 00:00"),
                //moment(this.calendar.config.selectedDate).weekday(7).subtract(1, "days").format("YYYY-MM-DD 23:59")                                               
                moment(this.calendar.config.selectedDate).format("YYYY-MM-DD 00:00"),
                moment(this.calendar.config.selectedDate).format("YYYY-MM-DD 23:59")                
            )
            this.readScreenings()
            this.readStructures()

            window.onresize = () => {
                this.window.width = window.innerWidth
            }

        }
    }

</script>