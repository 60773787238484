<template>
    <div class="row">
        <div class="col-lg-10 pt-2 pb-2">
            <div class="container-fluid">
                <div class="card-wrapper card-space">
                    <div class="card card-bg no-after">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped table-hover table-sm m-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Cognome</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Telefono</th>
                                            <th scope="col">Nascita</th>
                                            <th scope="col">Screening richiesti</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <router-link
                                            tag = "tr"
                                            :to = "!function_disabled(
                                                    'Pazienti',
                                                    'read'
                                                ) ? `pazienti/modifica/${patient.CF}` : 'pazienti'
                                            "
                                            v-for   = "patient of DB.patients"
                                            :key    = "patient.id"
                                        >
                                            <td>{{patient.name}}</td>
                                            <td>{{patient.surname}}</td>
                                            <td>{{patient.email}}</td>
                                            <td>{{patient.phone}}</td>
                                            <td>{{patient.date_birth | data_slash}}</td>
                                            <td>{{patient.patients_screenings_created.length}}</td>
                                        </router-link>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">
                                    Funzioni
                                </h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">                                    
                                    <i class="text-muted">
                                        Nessuna funzione
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    export default {
        data() {
            return {
                DB: {
                    patients: []
                }
            }
        },
        methods: {
            async read_patients() {
                try {

                    this.DB.patients = (
                        await this.axios.get(
                            "user/patients/read/all", 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data.patients_db
                    
                }
                catch(e) {
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i pazienti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }
        },
        mounted() {
            this.read_patients()
        }
    }
</script>