var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_vm._m(1),(!_vm.isLogged)?_c('div',{staticClass:"bg-ASL_ROMA_2-red p-3"}):_c('div',{staticClass:"it-header-slim-wrapper px-2"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"it-header-slim-wrapper-content"},[_c('a',{staticClass:"mr-2 mb-1",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg',{staticClass:"bi bi-arrow-left-circle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"}})])]),_c('a',{staticClass:"d-none d-lg-block navbar-brand"},[_c('b',[_vm._v(_vm._s(_vm.sex == 'M' ? 'Benvenuto' : 'Benvenuta'))]),_vm._v(" "+_vm._s(_vm.username)+" ")]),_c('div',{staticClass:"nav-mobile"},[_c('nav',[_c('a',{staticClass:"it-opener d-lg-none",attrs:{"data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_c('span',[_vm._v(_vm._s(_vm.sex == 'M' ? 'Benvenuto' : 'Benvenuta')+" "+_vm._s(_vm.username))]),_c('svg',{staticClass:"bi bi-three-dots-vertical",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"}})])]),_c('div',{staticClass:"link-list-wrapper collapse",attrs:{"id":"menu1"}},[_c('ul',{staticClass:"link-list border-0 p-0 m-0"},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPatient),expression:"isPatient"}]},[_c('router-link',{staticClass:"list-item text-right pr-2",class:{
                                            'active':
                                            _vm.$route.name == 'user_screenings_index'  ||
                                            _vm.$route.name == 'user_screenings_detail' ||
                                            _vm.$route.name == 'user_tests_detail'
                                        },attrs:{"to":"/user/percorsi"}},[_vm._v(" Percorsi ")])],1),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPatient),expression:"isPatient"}]},[_c('router-link',{staticClass:"list-item text-right pr-2",class:{
                                        'active': _vm.$route.name == 'user_appointments_index'
                                    },attrs:{"to":"/user/appuntamenti"}},[_vm._v(" Appuntamenti "),_c('span',{staticClass:"badge badge-light mb-1 mr-0"},[_vm._v(_vm._s(_vm.DB.appointments.length))])])],1),_c('li',[_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/impostazioni"}},[_vm._v(" Impostazioni ")])],1),_c('li',[_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/profilo"}},[_vm._v(" Profilo ")])],1),_c('li',[_c('a',{staticClass:"list-item text-right pr-2",attrs:{"href":"#"},on:{"click":_vm.logout}},[_vm._v(" Disconnetti ")])]),_c('li',{staticClass:"d-lg-none"},[(
                                            _vm.functionEnabled(
                                                'Agenda'
                                            )
                                        )?_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/admin/appuntamenti","data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_vm._v(" Appuntamenti ")]):_vm._e()],1),_c('li',{staticClass:"d-lg-none"},[(
                                            _vm.functionEnabled(
                                                'Uploads'
                                            )
                                        )?_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/admin/uploads","data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_vm._v(" Esito uploads ")]):_vm._e()],1),_c('li',{staticClass:"d-lg-none"},[(
                                            _vm.functionEnabled(
                                                'Screenings'
                                            )
                                        )?_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/admin/screenings","data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_vm._v(" Screenings ")]):_vm._e()],1),_c('li',{staticClass:"d-lg-none"},[(
                                            _vm.functionEnabled(
                                                'Tests'
                                            )
                                        )?_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/admin/tests","data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_vm._v(" Tests ")]):_vm._e()],1),_c('li',{staticClass:"d-lg-none"},[(
                                            _vm.functionEnabled(
                                                'Strutture'
                                            )
                                        )?_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/admin/strutture","data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_vm._v(" Strutture ")]):_vm._e()],1),_c('li',{staticClass:"d-lg-none"},[(
                                            _vm.functionEnabled(
                                                'Strutture'
                                            )
                                        )?_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/admin/strutture/tipologia","data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_vm._v(" Tipi struttura ")]):_vm._e()],1),_c('li',{staticClass:"d-lg-none"},[(
                                            _vm.functionEnabled(
                                                'Pazienti'
                                            )
                                        )?_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/admin/pazienti","data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_vm._v(" Pazienti ")]):_vm._e()],1),_c('li',{staticClass:"d-lg-none"},[(
                                            _vm.functionEnabled(
                                                'Utenti'
                                            )
                                        )?_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/admin/utenti","data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_vm._v(" Utenti ")]):_vm._e()],1),_c('li',{staticClass:"d-lg-none"},[(
                                            _vm.functionEnabled(
                                                'Autorizzazioni'
                                            )
                                        )?_c('router-link',{staticClass:"list-item text-right pr-2",attrs:{"to":"/admin/autorizzazioni","data-toggle":"collapse","href":"#menu1","role":"button","aria-expanded":"false","aria-controls":"menu1"}},[_vm._v(" Autorizzazioni ")]):_vm._e()],1)])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"it-header-slim-wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"it-header-slim-wrapper-content"},[_c('a',{staticClass:"d-none d-lg-block navbar-brand"},[_vm._v("Screening - Sistema prenotazione ASL Roma 2")]),_c('div',{staticClass:"nav-mobile"},[_c('nav',[_c('a',{staticClass:"it-opener d-lg-none"},[_c('span',[_vm._v("Screening - Sistema prenotazione")])])])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 pl-lg-4"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":""}},[_c('img',{staticClass:"w-50 w-lg-50",attrs:{"src":require("../assets/LOGO_ASL_ROMA_2.png")}})])]),_c('div',{staticClass:"col-6 text-right"},[_c('a',{staticClass:"navbar-brand mr-0",attrs:{"href":""}},[_c('img',{staticClass:"w-50 w-lg-50",attrs:{"src":require("../assets/LOGO_REGIONE_LAZIO.png")}})])])])])}]

export { render, staticRenderFns }