<template>
    <div class="row">
        <div class="col-lg-10 py-2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="card-space">
                            <div class="card card-bg no-after mb-4">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate mb-1">
                                        Associa funzionalità
                                    </h3>
                                    <p class="card-text m-0">
                                        Per una tipologia di utenza è possibile associarne una funzionalità software
                                    </p>

                                    <hr class="py-2 w-25 ml-0">

                                    <form @submit.prevent="create_users_software_functions" autocomplete="off">

                                        <h5 class="mb-1 card-title">Seleziona una funzionalità software</h5>

                                        <div class="alert alert-warning m-0" v-if="
                                            DB.software_functions.length < 1
                                        ">
                                            Non sono disponibili nuove funzionalità software
                                        </div>
                                        <div v-else>
                                            
                                            <div class="form-group border mb-1">

                                                <select
                                                    class   = "form-control form-control-sm"
                                                    id      = "tests"
                                                    title   = "Scegli una opzione"
                                                    v-model = "$v.form.users_software_functions.id_software_functions.$model"
                                                >
                                                    <option
                                                        disabled
                                                        selected
                                                        value = ""
                                                    >
                                                        Seleziona una funzionalità
                                                    </option>
                                                    <option
                                                        v-for   = "software_function of DB.software_functions"
                                                        :key    = "software_function.id"
                                                        :value  = "software_function.id"
                                                    >
                                                        {{software_function.title}} | {{software_function.description}}
                                                    </option>
                                                </select>

                                            </div>
                                            
                                            <div>
                                                <div class="form-check form-check-inline">
                                                    <input
                                                        id      = "create"
                                                        type    = "checkbox"
                                                        v-model = "form.users_software_functions.create"
                                                    >
                                                    <label for="create">
                                                        Creazione
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input
                                                        id      = "read"
                                                        type    = "checkbox"
                                                        v-model = "form.users_software_functions.read"
                                                    >
                                                    <label for="read">
                                                        Lettura
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input
                                                        id      = "update"
                                                        type    = "checkbox"
                                                        v-model = "form.users_software_functions.update"
                                                    >
                                                    <label for="update">
                                                        Modifica
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input
                                                        id      = "delete"
                                                        type    = "checkbox"
                                                        v-model = "form.users_software_functions.delete"
                                                    >
                                                    <label for="delete">
                                                        Eliminazione
                                                    </label>
                                                </div>
                                            </div>
                                            
                                            <div class="alert alert-danger" v-if="
                                                validations.create.users_software_functions.KO
                                            ">
                                                {{
                                                    validations.create.users_software_functions.KO
                                                }}
                                            </div>
                                            
                                            <button class="btn btn-primary mt-3" :disabled="
                                                loaders.create.users_software_functions ||
                                                $v.form.users_software_functions.$invalid
                                            ">
                                                <span v-show="
                                                    !loaders.create.users_software_functions
                                                ">
                                                    Aggiungi
                                                </span>
                                                <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="
                                                    loaders.create.users_software_functions
                                                ">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>

                                            </button>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">
                                    Funzioni
                                </h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">                                    
                                    <i class="text-muted">
                                        Nessuna funzione
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                DB: {
                    software_functions: []
                },
                form: {
                    users_software_functions: {
                        id_software_functions:  null,
                        create:                 true,
                        read:                   true,
                        update:                 true,
                        delete:                 true,
                        id_user_type:           this.$route.params.id
                    }
                },
                loaders: {
                    create: {
                        users_software_functions: false
                    }
                },
                validations: {
                    create: {
                        users_software_functions: {
                            KO: false
                        }
                    }
                }
            }
        },
        validations() {
            return {
                form: {
                    users_software_functions: {
                        id_software_functions: {
                            required
                        }
                    }
                }
            }
        },
        methods: {
            async read_software_functions() {
                
                try {
                    this.DB.software_functions = (
                        await this.axios.get(
                            `user/software_functions/read/activable/${this.$route.params.id}`, 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data
                }
                catch(e){
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le funzionalità software. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }

            },
            async create_users_software_functions() {

                this.loaders.create.users_software_functions        = true
                this.validations.create.users_software_functions.KO = false

                try {

                    await this.axios.post(
                        "user/users_software_functions/create/single",
                        this.form.users_software_functions,
                        {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }
                    )

                    this.$toast.open({
                        message: "Utente creato con successo",
                        type: "success"
                    })

                    this.$router.push(
                        `/admin/autorizzazioni/modifica/${this.$route.params.id}`
                    )

                }
                catch(e) {

                    const {
                        message
                    } = e.response.data

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validations.create.users_software_functions.KO = message
                    this.loaders.create.users_software_functions        = false

                }
            }
        },
        mounted() {
            this.read_software_functions()
        }
    }
</script>