<template>
    <div>
        <div class="bg-cce6ff p-5" style="margin-left: -6px; margin-right: -6px">
            <div class="container-fluid">
                <h1 class="m-0">
                    PROFILO
                </h1>
                <p class="m-0" v-if="
                    !loaders.reading.profile
                ">
                    Visualizza le informazioni che ci hai comunicato nella fase di registrazione e gestisci i tuoi recapiti
                </p>
            </div>
        </div>
        <div class="container py-5" v-if="
            !loaders.reading.profile
        ">
            <div class="row">
                <div class="col-lg-3">
                    <div class="card-space mb-3">
                        <div class="card card-bg no-after">
                            <div class="card-body">

                                <h5 class="card-title m-0">
                                    Informazioni personali
                                </h5>

                                <p class="card-text pb-3">
                                    Le tue informazioni personali non possono essere modificate
                                </p>

                                <form @submit.prevent="update_personal_info" autocomplete="off">
                                    
                                    <div class="form-group mb-4">

                                        <label for="CF" class="active">
                                            Codice fiscale
                                        </label>
                                        <input
                                            type    = "text"
                                            class   = "form-control text-uppercase"
                                            id      = "CF"
                                            :value  = "form.profile.CF"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="name" class="active">
                                            Nome
                                        </label>
                                        <input
                                            type    = "text"
                                            class   = "form-control text-uppercase"
                                            id      = "name"
                                            :value  = "form.profile.name"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="surname" class="active">
                                            Cognome
                                        </label>
                                        <input
                                            type    = "text"
                                            class   = "form-control text-uppercase"
                                            id      = "surname"
                                            :value  = "form.profile.surname"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="date_birth" class="active">
                                            Nato il
                                        </label>
                                        <input
                                            type    = "text"
                                            class   = "form-control text-uppercase"
                                            id      = "date_birth"
                                            :value  = "form.profile.date_birth | data_slash"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group m-0">

                                        <label for="date_birth" class="active">
                                            Sesso
                                        </label>
                                        <input
                                            type    = "text"
                                            class   = "form-control text-uppercase"
                                            id      = "date_birth"
                                            :value  = "form.profile.sex == 'M' ? 'UOMO' : 'DONNA'"
                                            disabled
                                        >

                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card-space mb-3">
                        <div class="card card-bg no-after">
                            <div class="card-body">

                                <h5 class="card-title m-0">
                                    Recapito telefonico
                                </h5>
                                <p class="card-text pb-3">
                                    Puoi digitare il tuo nuovo recapito telefonico
                                </p>

                                <form @submit.prevent="send_OTP_phone" autocomplete="off" v-if="!utilities.OTP.sended.phone">

                                    <div class="form-group mb-4">

                                        <div class="input-group">

                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    +39
                                                </div>
                                            </div>

                                            <label for="phone_original" class="active">
                                                Cellulare
                                            </label>

                                            <input
                                                type    = "text"
                                                class   = "form-control text-uppercase"
                                                id      = "phone_original"
                                                :value  = "form.profile.phone"
                                                disabled
                                            >

                                        </div>

                                    </div>
                                    <div class="form-group">

                                        <div class="input-group">

                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    +39
                                                </div>
                                            </div>

                                            <label for="phone">
                                                Nuovo cellulare
                                            </label>

                                            <input
                                                type            = "text"
                                                class           = "form-control"
                                                id              = "phone"
                                                minlength       = "8"
                                                maxlength       = "10"
                                                v-model.trim    = "$v.form.contacts.phone.$model"
                                                v-bind:class    = "{
                                                    'is-invalid': (
                                                        (
                                                            !$v.form.contacts.phone.required    ||
                                                            !$v.form.contacts.phone.minLength   ||
                                                            !$v.form.contacts.phone.maxLength   ||
                                                            !$v.form.contacts.phone.numeric
                                                        ) && $v.form.contacts.phone.$dirty
                                                    ) || validations.update.phone.KO
                                                }"
                                            >

                                            <div class="invalid-feedback" v-show="!$v.form.contacts.phone.required && $v.form.contacts.phone.$dirty">
                                                Inserire numero cellulare
                                            </div>
                                            <div class="invalid-feedback" v-show="!$v.form.contacts.phone.minLength && $v.form.contacts.phone.$dirty">
                                                Lunghezza minima di 8 caratteri
                                            </div>
                                            <div class="invalid-feedback" v-show="!$v.form.contacts.phone.maxLength && $v.form.contacts.phone.$dirty">
                                                Lunghezza massima di 10 caratteri
                                            </div>
                                            <div class="invalid-feedback" v-show="!$v.form.contacts.phone.numeric && $v.form.contacts.phone.$dirty">
                                                Sono ammessi solo numeri
                                            </div>
                                            <div class="invalid-feedback" v-show="validations.update.phone.KO">
                                                {{
                                                    validations.update.phone.KO
                                                }}
                                            </div>

                                        </div>

                                        <small id="phone_info" class="form-text text-muted">
                                            Sarà confermato con OTP SMS
                                        </small>

                                    </div>

                                    <button class="btn btn-primary" :disabled="
                                        $v.form.contacts.phone.$invalid ||
                                        loaders.creating.OTP.phone
                                    ">
                                        
                                        <span v-show="!loaders.creating.OTP.phone">
                                            Modifica cellulare
                                        </span>

                                        <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.creating.OTP.phone">
                                            <div class="progress-spinner-inner"></div>
                                            <div class="progress-spinner-inner"></div>
                                            <span class="sr-only">Caricamento...</span>
                                        </div>

                                    </button>

                                </form>
                                <form
                                    @submit.prevent = "update_phone"
                                    autocomplete    = "off"
                                    v-else
                                >
                                    <div class="form-group">

                                        <label for="OTP_phone">
                                            OTP
                                        </label>

                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "OTP_phone"
                                            minlength       = "6"
                                            maxlength       = "6"
                                            v-model.trim    = "$v.form.OTP.phone.$model"
                                            v-bind:class    = "{
                                                'is-invalid': (
                                                    (
                                                        !$v.form.OTP.phone.required    ||
                                                        !$v.form.OTP.phone.minLength   ||
                                                        !$v.form.OTP.phone.maxLength   ||
                                                        !$v.form.OTP.phone.numeric
                                                    ) && $v.form.OTP.phone.$dirty
                                                ) || validations.update.phone.KO,
                                                'is-valid': validations.update.phone.OK
                                            }"
                                        >

                                        <div class="invalid-feedback" v-show="!$v.form.OTP.phone.required && $v.form.OTP.phone.$dirty">
                                            Inserire OTP cellulare
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.OTP.phone.minLength && $v.form.OTP.phone.$dirty">
                                            Lunghezza minima di 6 caratteri
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.OTP.phone.maxLength && $v.form.OTP.phone.$dirty">
                                            Lunghezza massima di 6 caratteri
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.OTP.phone.numeric && $v.form.OTP.phone.$dirty">
                                            Sono ammessi solo numeri
                                        </div>
                                        <div class="valid-feedback" v-show="validations.update.phone.OK">
                                            {{
                                                validations.update.phone.OK
                                            }}
                                        </div>

                                        <small id="phone_info" class="form-text text-muted">
                                           Digitare OTP ricevuto di 6 cifre
                                        </small>

                                    </div>

                                    <button class="btn btn-primary" :disabled="
                                        $v.form.contacts.phone.$invalid ||
                                        validations.update.phone.OK
                                    ">
                                        
                                        <span>
                                            Verifica OTP
                                        </span>
                                        
                                    </button>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card-space mb-3">
                        <div class="card card-bg no-after">
                            <div class="card-body">

                                <h5 class="card-title m-0">
                                    Recapito email
                                </h5>
                                <p class="card-text pb-3">
                                    Puoi digitare il tuo nuovo indirizzo email
                                </p>

                                <form @submit.prevent="send_OTP_email" autocomplete="off" v-if="!utilities.OTP.sended.email">

                                    <div class="form-group mb-4">

                                        <label for="email_original" class="active">
                                            Email
                                        </label>

                                        <input
                                            type    = "email"
                                            class   = "form-control"
                                            id      = "email_original"
                                            :value  = "form.profile.email"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group">

                                        <label for="email">
                                            Nuova email
                                        </label>

                                        <input
                                            type            = "email"
                                            class           = "form-control"
                                            id              = "email"
                                            v-model.trim    = "$v.form.contacts.email.$model"
                                            v-bind:class    = "{
                                                'is-invalid': (
                                                    (
                                                        !$v.form.contacts.email.required    ||
                                                        !$v.form.contacts.email.email
                                                    ) && $v.form.contacts.email.$dirty
                                                ) || validations.update.email.KO
                                            }"
                                        >

                                        <div class="invalid-feedback" v-show="!$v.form.contacts.email.required && $v.form.contacts.email.$dirty">
                                            Inserire indirizzo email
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.contacts.email.email && $v.form.contacts.email.$dirty">
                                            Sintassi errata
                                        </div>
                                        <div class="invalid-feedback" v-show="validations.update.email.KO">
                                            {{
                                                validations.update.email.KO
                                            }}
                                        </div>

                                        <small id="email_info" class="form-text text-muted">
                                            Sarà confermato con OTP SMS
                                        </small>

                                    </div>

                                    <button class="btn btn-primary" :disabled="
                                        $v.form.contacts.email.$invalid ||
                                        loaders.creating.OTP.email
                                    ">
                                        
                                        <span v-show="!loaders.creating.OTP.email">
                                            Modifica email
                                        </span>

                                        <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.creating.OTP.email">
                                            <div class="progress-spinner-inner"></div>
                                            <div class="progress-spinner-inner"></div>
                                            <span class="sr-only">Caricamento...</span>
                                        </div>

                                    </button>

                                </form>
                                <form
                                    @submit.prevent = "update_email"
                                    autocomplete    = "off"
                                    v-else
                                >
                                    <div class="form-group">

                                        <label for="OTP_email">
                                            OTP
                                        </label>

                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "OTP_email"
                                            minlength       = "6"
                                            maxlength       = "6"
                                            v-model.trim    = "$v.form.OTP.email.$model"
                                            v-bind:class    = "{
                                                'is-invalid': (
                                                    (
                                                        !$v.form.OTP.email.required    ||
                                                        !$v.form.OTP.email.minLength   ||
                                                        !$v.form.OTP.email.maxLength   ||
                                                        !$v.form.OTP.email.numeric
                                                    ) && $v.form.OTP.email.$dirty
                                                ) || validations.update.email.KO,
                                                'is-valid': validations.update.email.OK
                                            }"
                                        >

                                        <div class="invalid-feedback" v-show="!$v.form.OTP.email.required && $v.form.OTP.email.$dirty">
                                            Inserire OTP email
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.OTP.email.minLength && $v.form.OTP.email.$dirty">
                                            Lunghezza minima di 6 caratteri
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.OTP.email.maxLength && $v.form.OTP.email.$dirty">
                                            Lunghezza massima di 6 caratteri
                                        </div>
                                        <div class="invalid-feedback" v-show="!$v.form.OTP.email.numeric && $v.form.OTP.email.$dirty">
                                            Sono ammessi solo numeri
                                        </div>
                                        <div class="valid-feedback" v-show="validations.update.email.OK">
                                            {{
                                                validations.update.email.OK
                                            }}
                                        </div>

                                        <small id="email_info" class="form-text text-muted">
                                           Digitare OTP ricevuto di 6 cifre
                                        </small>

                                    </div>

                                    <button class="btn btn-primary" :disabled="
                                        $v.form.contacts.email.$invalid ||
                                        validations.update.email.OK
                                    ">
                                        
                                        <span>
                                            Verifica OTP
                                        </span>
                                        
                                    </button>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card-space mb-3">
                        <div class="card card-bg no-after">
                            <div class="card-body">

                                <h5 class="card-title m-0">
                                    Password
                                </h5>
                                <p class="card-text pb-3">
                                    Puoi cambiare la tua password di accesso
                                </p>

                                <form @submit.prevent="update_password" autocomplete="off">

                                    <div class="form-group mb-4">

                                        <label for="password_old">
                                            Vecchia password
                                        </label>

                                        <input
                                            :type           = "utilities.show_password.old ? 'text' : 'password'"
                                            class           = "form-control"
                                            id              = "password_old"
                                            v-model.trim    = "$v.form.password.old.$model"
                                            v-bind:class    = "{
                                                'is-invalid':   !$v.form.password.old.required && $v.form.password.old.$dirty ||validations.update.password.KO,
                                                'is-valid':     validations.update.password.OK
                                            }"
                                        >

                                        <span class="password-icon" aria-hidden="true" @click="utilities.show_password.old = !utilities.show_password.old">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" v-show="!utilities.show_password.old">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" v-show="utilities.show_password.old">
                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                            </svg>
                                        </span>

                                        <div class="invalid-feedback" v-show="!$v.form.password.old.required && $v.form.password.old.$dirty">
                                            Digitare vecchia password
                                        </div>

                                    </div>

                                    <div class="form-group mb-4">

                                        <label for="password_new">
                                            Nuova password
                                        </label>

                                        <input
                                            :type           = "utilities.show_password.new ? 'text' : 'password'"
                                            class           = "form-control"
                                            id              = "password_new"
                                            v-model.trim    = "$v.form.password.new.$model"
                                            v-bind:class    = "{
                                                'is-invalid':   (
                                                    (
                                                        !$v.form.password.new.required  ||
                                                        !$v.form.password.new.minLength ||
                                                        !$v.form.password.new.maxLength
                                                    ) && $v.form.password.new.$dirty
                                                ) ||validations.update.password.KO,
                                                'is-valid':     validations.update.password.OK
                                            }"
                                        >

                                        <span class="password-icon" aria-hidden="true" @click="utilities.show_password.new = !utilities.show_password.new">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" v-show="!utilities.show_password.new">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" v-show="utilities.show_password.new">
                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                            </svg>
                                        </span>

                                        <div class="invalid-feedback" v-if="!$v.form.password.new.required && $v.form.password.new.$dirty">
                                            Digitare nuova password
                                        </div>
                                        <div class="invalid-feedback" v-if="!$v.form.password.new.minLength">
                                            Digitare minimo 6 caratteri
                                        </div>
                                        <div class="invalid-feedback" v-if="!$v.form.password.new.maxLength">
                                            Digitare massimo 100 caratteri
                                        </div>

                                    </div>
                                    <div class="form-group">

                                        <label for="password_confirm">
                                            Ripeti password
                                        </label>

                                        <input
                                            :type           = "utilities.show_password.confirm ? 'text' : 'password'"
                                            class           = "form-control"
                                            id              = "password_confirm"
                                            v-model.trim    = "$v.form.password.confirm.$model"
                                            v-bind:class    = "{
                                                'is-invalid': (
                                                    (
                                                        (
                                                            !$v.form.password.confirm.required  ||
                                                            !$v.form.password.confirm.equal_new
                                                        ) &&
                                                        $v.form.password.confirm.$dirty
                                                    ) ||
                                                    validations.update.password.KO
                                                ),
                                                'is-valid': validations.update.password.OK
                                            }"
                                        >
                                        
                                        <div class="invalid-feedback" v-if = "
                                            validations.update.password.KO
                                        ">
                                            {{
                                                validations.update.password.KO
                                            }}
                                        </div>
                                        <div class="invalid-feedback" v-if = "
                                            !$v.form.password.confirm.equal_new
                                        ">
                                            Password diverse
                                        </div>
                                        <div class="valid-feedback">
                                            {{
                                                validations.update.password.OK
                                            }}
                                        </div>

                                        <small id="password_confirm_info" class="form-text text-muted">
                                            Password uguale alla precedente
                                        </small>

                                        <span class="password-icon" aria-hidden="true" @click="utilities.show_password.confirm = !utilities.show_password.confirm">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" v-show="!utilities.show_password.confirm">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" v-show="utilities.show_password.confirm">
                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                            </svg>
                                        </span>

                                        <div class="invalid-feedback" v-show="!$v.form.password.confirm.required && $v.form.password.confirm.$dirty">
                                            Digitare nuova password
                                        </div>

                                    </div>

                                    <button class="btn btn-primary" :disabled="
                                        $v.form.password.$invalid   ||
                                        loaders.update.password     ||
                                        validations.update.password.OK
                                    ">

                                        <span v-show="!loaders.update.password">
                                            Modifica password
                                        </span>

                                        <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.update.password">
                                            <div class="progress-spinner-inner"></div>
                                            <div class="progress-spinner-inner"></div>
                                            <span class="sr-only">Caricamento...</span>
                                        </div>

                                    </button>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {
        required,
        minLength,
        maxLength,
        numeric,
        email
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {
                    profile: null,
                    contacts: {
                        phone: null,
                        email: null
                    },
                    OTP: {
                        phone: null,
                        email: null
                    },
                    password: {
                        old:     null,
                        new:     null,
                        confirm: null
                    }
                },
                loaders: {
                    reading: {
                        profile: true
                    },
                    update: {
                        password: false,
                        phone:    false,
                        email:    false,
                        OTP: {
                            phone: false,
                            email: false
                        }
                    },
                    creating: {
                        OTP: {
                            phone: false,
                            email: false
                        }
                    }
                },
                utilities: {
                    show_password: {
                        old:     false,
                        new:     false,
                        confirm: false
                    },
                    OTP: {
                        sended: {
                            phone: false,
                            email: false
                        }
                    }
                },
                validations: {
                    update: {
                        password: {
                            KO: false,
                            OK: false
                        },
                        phone: {
                            KO: false,
                            OK: false
                        },
                        email: {
                            KO: false,
                            OK: false
                        }
                    }
                }
            }
        },
        validations() {
            return {
                form: {
                    contacts: {
                        phone: {
                            required,
                            minLength: minLength(
                                8
                            ),
                            maxLength: maxLength(
                                10
                            ),
                            numeric
                        },
                        email: {
                            required,
                            email
                        }
                    },
                    password: {
                        old: {
                            required
                        },
                        new: {
                            required,
                            minLength: minLength(
                                6
                            ),
                            maxLength: maxLength(
                                100
                            )
                        },
                        confirm: {
                            required,
                            equal_new: value => value == this.form.password.new
                        }
                    },
                    OTP: {
                        phone: {
                            required,
                            minLength: minLength(
                                6
                            ),
                            maxLength: maxLength(
                                6
                            ),
                            numeric
                        },
                        email: {
                            required,
                            minLength: minLength(
                                6
                            ),
                            maxLength: maxLength(
                                6
                            ),
                            numeric
                        }
                    }
                }
            }
        },
        methods: {
            async read_profile() {
                try {

                    this.form.profile = (
                        await this.axios.get(`user/users/read/personal_profile`,  {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data.user_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                }
                finally {
                    this.loaders.reading.profile = false
                }
            },
            async send_OTP_phone() {

                this.loaders.creating.OTP.phone = true

                try {

                    this.utilities.OTP.sended.phone = (
                        await this.axios.post(
                            "user/users/update/contact/first_step/phone",
                            {
                                phone: this.form.contacts.phone
                            },
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data

                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        
                        const {
                            message
                        } = e.response.data

                        this.$toast.open({
                            message,
                            type: "error"
                        })

                    }
                }
                finally {
                    this.loaders.creating.OTP.phone = false
                }

            },
            async send_OTP_email() {

                this.loaders.creating.OTP.email = true

                try {

                    this.utilities.OTP.sended.email = (
                        await this.axios.post(
                            "user/users/update/contact/first_step/email",
                            {
                                email: this.form.contacts.email
                            },
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data

                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        
                        const {
                            message
                        } = e.response.data

                        this.$toast.open({
                            message,
                            type: "error"
                        })

                    }
                }
                finally {
                    this.loaders.creating.OTP.email = false
                }

            },
            async update_phone() {
                
                this.loaders.update.OTP.phone = true

                try {

                    const {
                        message
                    } = (
                        await this.axios.post(
                            "user/users/update/contact/phone",
                            {
                                phone:  this.form.contacts.phone,
                                numero: this.form.OTP.phone,
                                token:  this.utilities.OTP.sended.phone
                            },
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data

                    this.$toast.open({
                        message,
                        type: "success"
                    })

                    this.validations.update.phone.OK = message

                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {console.log
                        
                        const {
                            message
                        } = e.response.data

                        this.$toast.open({
                            message,
                            type: "error"
                        })

                    }
                }
                finally {
                    this.loaders.update.OTP.phone = false
                }
                
            },
            async update_email() {
                
                this.loaders.update.OTP.email = true

                try {

                    const {
                        message
                    } = (
                        await this.axios.post(
                            "user/users/update/contact/email",
                            {
                                email:  this.form.contacts.email,
                                numero: this.form.OTP.email,
                                token:  this.utilities.OTP.sended.email
                            },
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data

                    this.$toast.open({
                        message,
                        type: "success"
                    })

                    this.validations.update.email.OK = message

                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        
                        const {
                            message
                        } = e.response.data

                        this.$toast.open({
                            message,
                            type: "error"
                        })

                    }
                }
                finally {
                    this.loaders.update.OTP.email = false
                }
                
            },
            async update_password() {

                this.loaders.update.password        = true
                this.validations.update.password.KO = false

                try {
                    await this.axios.post(
                        "user/users/update/password",
                        {
                            new: this.form.password.new,
                            old: this.form.password.old
                        },
                        {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }
                    )

                    const message = "Password modificata con successo"

                    this.validations.update.password.OK = message

                    this.$toast.open({
                        message,
                        type: "success"
                    })
                    
                }
                catch(e) {

                    console.error(
                        "ERROREEE",
                        e
                    )

                    const {
                        message
                    } = e.response.data

                    this.validations.update.password.KO = message

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                }
                finally {
                    this.loaders.update.password = false
                }
                    
            }
        },
        mounted() {
            this.read_profile()
        }
    }
</script>