import axios from 'axios'

const state = {
    token:              null,
    user:               null,
    userType:           null,
    softwareFunctions:  null
}

const getters = {
    isLogged:           state => !!state.token,
    infoUser:           state => state,
    isAdmin:            state => state.userType.id != 2,
    softwareFunctions:  state => state.softwareFunctions
}

const actions = {

    async doLogin({commit}, form) {

        // Login utente
        const infoUser = await axios.post(`auth/login`, form)
        // Update info utente globalmente
        await commit(`setInfoUser`,   infoUser.data)

    },

    async doCentralLogoutPatient({commit}) {
        commit(`removeInfoUser`)
    },

    async doInternalLogin({commit}, data) {
        // Update info utente globalmente
        await commit(`setInfoUser`,   data)
    }

}

const mutations = {
    setInfoUser(state, info) {
        state.token             = info.token
        state.user              = info.user
        state.userType          = info.user.user_type
        state.softwareFunctions = info.user.user_type.users_software_functions
        delete state.user.userType
    },
    removeInfoUser(state) {
        state.token             = null
        state.user              = null
        state.userType          = null
        state.softwareFunctions = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}