<template>
    <div class="py-3 container-fluid">
        <div class="row">
        
            <div class="offset-lg-2 col-lg-8">

                <div class="card-space mb-3" v-if="appointment.annullato">
                    <div class="card card-bg no-after ml-0">
                        <div class="card-body">
                            <div class="alert alert-danger m-0">
                                <strong>
                                    Appuntamento annullato in data <u>{{appointment.annullato.data | data_slash}}</u>
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-space mb-3" v-if="!appointment.annullato && appointment_expired">
                    <div class="card card-bg no-after ml-0">
                        <div class="card-body">
                            <div class="alert alert-danger m-0">
                                <strong>
                                    Questo appuntamento è scaduto
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4">
                        <div class="card-space pb-3 h-100">
                            <div class="card card-bg no-after ml-0 h-100">
                                <div class="card-body">
                                    <h5 class="card-title big-heading">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-circle mb-1" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                        </svg> {{appointment.user.name}} {{appointment.user.surname}}
                                    </h5>
                                    <p class="card-text m-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-balloon mb-1" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 9.984C10.403 9.506 12 7.48 12 5a4 4 0 0 0-8 0c0 2.48 1.597 4.506 4 4.984ZM13 5c0 2.837-1.789 5.227-4.52 5.901l.244.487a.25.25 0 1 1-.448.224l-.008-.017c.008.11.02.202.037.29.054.27.161.488.419 1.003.288.578.235 1.15.076 1.629-.157.469-.422.867-.588 1.115l-.004.007a.25.25 0 1 1-.416-.278c.168-.252.4-.6.533-1.003.133-.396.163-.824-.049-1.246l-.013-.028c-.24-.48-.38-.758-.448-1.102a3.177 3.177 0 0 1-.052-.45l-.04.08a.25.25 0 1 1-.447-.224l.244-.487C4.789 10.227 3 7.837 3 5a5 5 0 0 1 10 0Zm-6.938-.495a2.003 2.003 0 0 1 1.443-1.443C7.773 2.994 8 2.776 8 2.5c0-.276-.226-.504-.498-.459a3.003 3.003 0 0 0-2.46 2.461c-.046.272.182.498.458.498s.494-.227.562-.495Z"/>
                                        </svg> {{appointment.user.sex == `M` ? `Nato` : `Nata`}} il {{appointment.user.date_birth | data_mese_scritto_v2}}
                                    </p>
                                    <p class="card-text m-0"> 
                                        <svg v-if="appointment.user.sex == `F`" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-gender-female mb-1" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z"/>
                                        </svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-gender-male mb-1" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"/>
                                        </svg>
                                        Sesso {{appointment.user.sex == `M` ? `Uomo` : `Donna`}}
                                    </p>

                                    <hr>

                                    <p class="card-text text-warning m-0">
                                        <b>Richiede:</b>
                                    </p>
                                    <p class="card-text m-0">
                                        - <b>Screening</b> {{appointment.screening.title}}
                                    </p>
                                    <p class="card-text m-0">
                                        - <b>Test</b> {{appointment.test.title}}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card-space pb-3 h-100">
                            <div class="card card-bg no-after ml-0 h-100">
                                <div class="card-body">

                                    <h5 class="card-title big-heading">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-calendar mb-1" viewBox="0 0 16 16">
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                        </svg> {{appointment.date | data_mese_scritto_v2}} {{appointment.time | data_ora}}
                                    </h5>

                                    <hr>

                                    <p class="card-text">
                                        Ha effettuato una richiesta per lo screening <span class="text-lowercase">{{appointment.screening.title}}</span> con il test <span class="text-lowercase">{{appointment.test.title}}</span> per la data {{appointment.date | data_mese_scritto_v2}} alle ore {{appointment.time | data_ora}}
                                    </p>

                                    <div class="alert alert-warning alert-dismissible fade show m-0 pt-1 pb-1" role="alert" v-if="!appointment.annullato && !appointment_expired">

                                        <small class="card-text">
                                            Se ha <b>sbagliato la data</b> del suo <b>appuntamento</b> può <router-link :to="`/user/appuntamenti/annulla/${$route.params.id}`">annullarlo</router-link> per poi richiederne uno nuovo!
                                        </small>

                                        <button type="button" class="close" data-dismiss="alert" aria-label="Chiudi avviso">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card-space pb-3 h-100">
                            <div class="card card-bg no-after ml-0 h-100">
                                <div class="card-body">

                                    <h5 class="card-title big-heading">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pin-map mb-1" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
                                            <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
                                        </svg> {{appointment.structure.title}}
                                    </h5>

                                    <p class="card-text m-0">
                                        <b>{{appointment.structure.address}}</b> > <a target="_blank" :href="`http://maps.google.com/?q=${appointment.structure.address}`">indicazioni</a>
                                    </p>

                                    <hr>

                                    <p class="card-text m-0">
                                        Il suo screening sarà effettuato nella struttura <span class="text-lowercase">{{appointment.structure.title}}</span> in <span>{{appointment.structure.address}}</span>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4">
                        <div class="card-space mb-3" v-if="!appointment.annullato && !appointment_expired">
                            <div class="card card-bg no-after ml-0">
                                <div class="card-body">
                                    <p class="card-text text-warning">
                                        Gestisci la il tuo appuntamento
                                    </p>
                                    <router-link :to="`/user/appuntamenti/annulla/${$route.params.id}`" class="f-s-0_777-em d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg> Annulla appuntamento
                                    </router-link>
                                    <a href="#" class="f-s-0_777-em d-block disabled">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg> Invia riepilogo tramite email
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-space mb-3">
                            <div class="card card-bg no-after ml-0">
                                <div class="card-body">
                                
                                    <h5 class="card-title big-heading text-success ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-telephone mb-1" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                        </svg> 800 40 50 51
                                    </h5>

                                    <p class="card-text m-0">
                                        Per qualsiasi informazione contatta il numero verde.
                                    </p>

                                </div>
                            </div>
                        </div>  
                    </div>
                    <div class="col-lg-8">
                        <div class="card-space mb-3">
                            <div class="card card-bg no-after border-bottom-card border-bottom-card-with-var-from-vue ml-0" :style="{'--color-from-vue': appointment.screening.color}">
                                <div class="card-body">

                                    <h5 class="card-title big-heading">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-patch-question mb-1" viewBox="0 0 16 16">
                                            <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745z"/>
                                            <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
                                            <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"/>
                                        </svg> Screening <span class="text-lowercase">{{appointment.screening.title}}</span>
                                    </h5>

                                    <p class="card-text">
                                        {{appointment.screening.description}}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="card-space mb-3">
                            <div class="card card-bg no-after ml-0">
                                <div class="card-body">

                                    <h5 class="card-title big-heading">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-patch-question mb-1" viewBox="0 0 16 16">
                                            <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745z"/>
                                            <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
                                            <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"/>
                                        </svg> Test <span class="text-lowercase">{{appointment.test.title}}</span>
                                    </h5>

                                    <p class="card-text">
                                        {{appointment.test.description}}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
            </div>

        </div>
    </div>
</template>

<script>

    import moment from "moment"

    export default {
        data() {
            return {
                appointment: {

                }
            }
        },
        methods: {
            async readAppointment() {
                try {

                    const appointment = await this.axios.get(`user/appointments/read/single/${this.$route.params.id}`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.appointment = appointment.data.appointment
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                }
            },
            async updateAppointment() {
                alert(`In fase di sviluppo`)
            }
        },
        mounted() {
            this.readAppointment()
        },
        computed: {
            appointment_expired() {
                return moment(this.appointment.date) < moment()
            }
        }
    }
</script>