<template>
    <div class="d-flex h-main">

        <h1 class="m-auto" v-show="!message.KO">Attendi..</h1>

        <div class="card-wrapper card-space m-auto" v-show="message.KO">
            <div class="card card-bg no-after">
                <div class="card-body">
                    <div class="alert alert-danger m-0">
                        {{message.KO}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import { 
        mapActions
    } from "vuex"

    import store from "@/store"

    export default {
        data() {
            return {
                message: {
                    KO: false
                }
            }
        },
        methods: {

            ...mapActions(["doInternalLogin"]),

            async sendParams() {

                const params_URL = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                })

                const {
                    code,
                    state,
                    session_state
                } = params_URL

                try {

                    const response = await this.axios.post(`auth/register/test_spid/callback`,{code, state, session_state})

                    await this.doInternalLogin(response.data)

                    store.getters.isAdmin ? this.$router.push(`/admin/appuntamenti`) : this.$router.push(`/user/percorsi`)

                }
                catch(e){

                    const message = `Si è presentato un errore durante l'accesso tramite SPID, ${e.response.data.message ? e.response.data.message : 'effettuare un nuovo tentativo'}.`

                    this.$toast.open({
                        message,
                        type:    "error"
                    })

                    this.message.KO = message

                }
            }
        },
        mounted() {
            this.sendParams()
        }
    }
</script>