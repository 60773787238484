<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <form @submit.prevent="updateStructureTest" autocomplete="off">
                                        
                                        <div class="form-group">

                                            <label for="title_test" class="active">Test</label>
                                            <input type="text" class="form-control" id="title_test" aria-describedby="title_info" :value="form.structure_test.test.title" disabled>
                                            <small id="title_info" class="form-text text-muted">Il test non può essere modificato</small>

                                        </div>

                                        <!--<div class="form-group mt-4">

                                            <label for="appointment_duration" class="active">Durata appuntamento (minuti)</label>
                                            <input type="number" class="form-control" id="appointment_duration" min="1" aria-describedby="appointment_duration" v-model.number="$v.form.structure_test.appointment_duration.$model" v-bind:class="{'is-invalid': (!$v.form.structure_test.appointment_duration.required || !$v.form.structure_test.appointment_duration.numeric || !$v.form.structure_test.appointment_duration.minValue) && $v.form.structure_test.appointment_duration.$dirty}">

                                            <small v-show="!$v.form.structure_test.appointment_duration.$dirty || !$v.form.structure_test.appointment_duration.$invalid" id="title_info" class="form-text text-muted">Specificare la durata di un appuntamento in minuti</small>

                                            <div v-show="!$v.form.structure_test.appointment_duration.required && $v.form.structure_test.appointment_duration.$dirty" class="invalid-feedback">Durata richiesta</div>
                                            <div v-show="!$v.form.structure_test.appointment_duration.numeric && $v.form.structure_test.appointment_duration.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                            <div v-show="!$v.form.structure_test.appointment_duration.minValue && $v.form.structure_test.appointment_duration.$dirty" class="invalid-feedback">Durata minima di 1 minuto</div>

                                        </div>-->
                                        <div class="form-group mt-4">

                                                <label for="appointment_fixs" class="active">Limite appuntamenti fissabili</label>
                                                <input type="number" class="form-control" id="appointment_fixs" min="1" aria-describedby="appointment_fixs" v-model.number="$v.form.structure_test.appointment_fixs.$model" v-bind:class="{'is-invalid': (!$v.form.structure_test.appointment_fixs.numeric || !$v.form.structure_test.appointment_fixs.minValue) && $v.form.structure_test.appointment_fixs.$dirty}">

                                                <small v-show="!$v.form.structure_test.appointment_fixs.$dirty || !$v.form.structure_test.appointment_fixs.$invalid" id="title_info" class="form-text text-muted">Specificare la quantità massima di un appuntamenti fissabili in una giornata</small>

                                                <div v-show="$v.form.structure_test.appointment_fixs.$dirty" class="invalid-feedback">Quantità richiesta</div>
                                                <div v-show="!$v.form.structure_test.appointment_fixs.numeric && $v.form.structure_test.appointment_fixs.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                                <div v-show="!$v.form.structure_test.appointment_fixs.minValue && $v.form.structure_test.appointment_duration.$dirty" class="invalid-feedback">Quantità minima di 1 appuntamento</div>

                                            </div>
                                        
                                        <div class="alert alert-danger mt-3 mb-3" v-show="validation.ko">
                                            {{validation.ko}}
                                        </div>

                                        <div class="it-card-footer mt-0">

                                            <button type="submit" class="btn btn-primary">
                                                
                                                <span v-show="!loaders.updating.structure">
                                                    Modifica
                                                </span>

                                                <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.updating.structure">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                                

                                            </button>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                    
                                    <span class="mr-2">Elimina</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>

                                </a>
                                <div class="collapse bg-danger text-center text-white" id="answer">
                                    <p class="m-0 border-bottom border-white">
                                        <small class="font-weight-bold">
                                            Sicuro?
                                        </small>
                                    </p>
                                    <div class="container-fluid">

                                        <div class="row border-top">
                                            <div class="col-6 text-center border-right border-white cursor-pointer" @click="deleteStructureTest" v-bind:class="{'text-center disabled-AO': loaders.deleting.structure_test}">
                                                <small class="font-weight-bold " v-show="!loaders.deleting.structure_test">
                                                    Si
                                                </small>
                                                <div v-show="loaders.deleting.structure_test" class="progress-spinner progress-spinner-double size-sm progress-spinner-active">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                            </div>
                                            <div class="col-6 text-center cursor-pointer" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                                <small class="font-weight-bold">
                                                    No
                                                </small>
                                            </div>                                        
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        //required,
        numeric,
        minValue
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {
                    structure_test: {
                        //appointment_duration: 1,
                        appointment_fixs: null,
                        test: {
                            title: null
                        }
                    }
                },
                loaders: {
                    updating: {
                        structure_test: false
                    },
                    deleting: {
                        structure_test: false
                    }
                },
                validation: {
                    ko: false
                }
            }
        },
        validations() {
            return {
                form: {
                    structure_test: {
                        appointment_duration: {
                            //required,
                            numeric,
                            minValue: minValue(1)
                        },
                        appointment_fixs: {
                            //required,
                            numeric,
                            minValue: minValue(1)
                        }
                    }
                }
            }
        },
        methods: {

            async readStructureTest() {
                try {

                    const structure_test = await this.axios.get(`user/structures_tests/read/detail/${this.$route.params.id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.structure_test = structure_test.data.structure_test
                    
                }
                catch(e) {

                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {

                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare l'associazione. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })

                        this.$router.push(`/admin/strutture/modifica/${this.$route.params.title}`)
                        
                    }

                }
            },
            async updateStructureTest() {

                this.loaders.updating.structure_test = true
                this.validation.ko                   = false

                try {

                    const res = await this.axios.post(`user/structures_tests/update/single`, this.form.structure_test, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture/modifica/${this.$route.params.title}`)
                    
                }
                catch(e) {

                    const message = `Non è momentaneamente possibile modificare l'associazione. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`

                    this.loaders.updating.structure_test = false
                    this.validation.ko                   = message
                    
                    this.$toast.open({
                        message,
                        type: `error`
                    })

                }
            },
            async deleteStructureTest() {

                this.loaders.deleting.structure_test = true

                try {

                    const res = await this.axios.delete(`user/structures_tests/${this.$route.params.id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture/modifica/${this.$route.params.title}`)
                    
                }
                catch(e) {

                    const message = `Non è momentaneamente possibile eliminare l'associazione. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`

                    this.loaders.deleting.structure_test = false
                    this.validation.ko                   = message
                    
                    this.$toast.open({
                        message,
                        type: `error`
                    })
                }

            }

        },
        mounted() {
            this.readStructureTest()
        }
    }
</script>