<template>
    <div>
        <div class="bg-cce6ff p-5" style="margin-left: -6px; margin-right: -6px">
            <div class="container-fluid">
                <h1>
                    APPUNTAMENTI
                </h1>
                <p class="m-0">
                    {{DB.appointments.length}} {{DB.appointments.length == 1 ? `appuntamento` : `appuntamenti`}}
                </p>
            </div>
        </div>

        <div class="row py-2">
            <div class="offset-lg-2 col-lg-8">
                <div class="container-fluid">

                    <div v-if="DB.appointments.length > 0">

                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">
                                    <div class="alert alert-warning m-0" >
                                        Sono presenti degli screening che potresti prenotare! <router-link to="percorsi">scegli un percorso</router-link> per fissare un appuntamento.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-xl-4" v-for="appointment of DB.appointments" :key="`appointment_${appointment.id}`">
                                <div class="card-wrapper card-space">
                                    <div class="card card-bg no-after border-bottom-card border-bottom-card-with-var-from-vue" :style="{'--color-from-vue': appointment.patient_screenings_tests.screenings.color}">
                                        <div class="card-body">

                                            <div class="row">
                                                <div class="col-8">
                                                    <h5 class="card-title big-heading m-0 text-truncate">
                                                        {{appointment.patient_screenings_tests.screenings.title}}
                                                    </h5>
                                                </div>
                                                <div class="col-4 text-right">
                                                    <span class="badge badge-danger" v-if="appointment.appointment_rejected">
                                                        Annullato                     
                                                    </span>
                                                    <span class="badge badge-danger" v-else-if="!appointment.appointment_rejected && (new Date(appointment.date) < new Date())">Scaduto</span>
                                                </div>
                                            </div>
                                            <h5 class="card-title text-truncate">
                                                {{appointment.patient_screenings_tests.tests.title}}
                                            </h5>

                                            <div class="row">
                                                <div class="col-8">
                                                    <p class="card-text m-0">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar mb-1" viewBox="0 0 16 16">
                                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                        </svg> {{appointment.date | data_slash}} {{appointment.time | data_ora}}
                                                    </p>
                                                    <p class="card-text">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pin-map mb-1" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
                                                            <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
                                                        </svg> {{appointment.structures.title}}
                                                    </p>
                                                </div>
                                                <div class="col-4">
                                                    <div class="text-right">
                                                        <router-link :to="`appuntamenti/modifica/${appointment.id}`" tag="button" class="btn btn-primary btn-sm">
                                                            Gestisci
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="card-wrapper card-space" v-else>
                        <div class="card card-bg no-after">
                            <div class="card-body">
                                <div class="alert alert-danger m-0" >
                                    Non sono ancora presenti degli appuntamenti, <router-link to="percorsi">scegli un percorso</router-link>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                DB: {
                    appointments:   [],
                    screenings:     []
                },
                loaders: {
                    reading: {
                        appointments: true
                    }
                }
            }
        },
        methods: {
            async readAppointments() {
                try {

                    const appointments = await this.axios.get(`user/appointments/read/personal/all`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.appointments = appointments.data.appointments_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                }
            },
            async readScreenings() {
                try {

                    const screenings = await this.axios.get(`user/screenings/read/customized`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.screenings = screenings.data.screenings_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le visite. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            }
        },
        mounted() {
            this.readAppointments()
        }
    }
</script>