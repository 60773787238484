<template>
    <div class="row">
        <div class="col-lg-10 py-2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="card-space" v-if="!loaders.read.users_software_functions">
                            <div class="card card-bg no-after mb-4">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate mb-1">
                                        Modifica funzionalità
                                    </h3>
                                    <p class="card-text m-0">
                                        Per una tipologia di utenza è possibile modificare le funzionalità software
                                    </p>

                                    <hr class="py-2 w-25 ml-0">

                                    <form @submit.prevent="update_users_software_functions" autocomplete="off">

                                        <h5 class="mb-1 card-title">
                                            Modifica le proprietà della funzionalità
                                        </h5>

                                        <div class="form-group border mb-1">

                                            <select
                                                class   = "form-control form-control-sm"
                                                id      = "software_function_title"
                                                disabled
                                            >
                                                <option
                                                    disabled
                                                    selected
                                                >
                                                    {{
                                                        form.users_software_functions.software_function.title
                                                    }}
                                                </option>
                                            </select>

                                        </div>
                                        
                                        <div>
                                            <div class="form-check form-check-inline">
                                                <input
                                                    id      = "create"
                                                    type    = "checkbox"
                                                    v-model = "form.users_software_functions.create"
                                                >
                                                <label for="create">
                                                    Creazione
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input
                                                    id      = "read"
                                                    type    = "checkbox"
                                                    v-model = "form.users_software_functions.read"
                                                >
                                                <label for="read">
                                                    Lettura
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input
                                                    id      = "update"
                                                    type    = "checkbox"
                                                    v-model = "form.users_software_functions.update"
                                                >
                                                <label for="update">
                                                    Modifica
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input
                                                    id      = "delete"
                                                    type    = "checkbox"
                                                    v-model = "form.users_software_functions.delete"
                                                >
                                                <label for="delete">
                                                    Eliminazione
                                                </label>
                                            </div>
                                        </div>
                                        
                                        <div class="alert alert-danger" v-if="
                                            validations.update.users_software_functions.KO
                                        ">
                                            {{
                                                validations.update.users_software_functions.KO
                                            }}
                                        </div>
                                        
                                        <button class="btn btn-primary mt-3" :disabled="
                                            loaders.update.users_software_functions
                                        ">
                                            <span v-show="
                                                !loaders.update.users_software_functions
                                            ">
                                                Modifica
                                            </span>
                                            <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="
                                                loaders.update.users_software_functions
                                            ">
                                                <div class="progress-spinner-inner"></div>
                                                <div class="progress-spinner-inner"></div>
                                                <span class="sr-only">Caricamento...</span>
                                            </div>

                                        </button>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">
                                    Funzioni
                                </h3>
                            </li>
                            <li>
                                <a
                                    class           = "list-item medium text-truncate"
                                    data-toggle     = "collapse"
                                    href            = "#answer"
                                    role            = "button"
                                    aria-expanded   = "false"
                                    aria-controls   = "answer"
                                >
                                    
                                    <span class="mr-2">
                                        Elimina
                                    </span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>

                                </a>
                                <div class="collapse bg-danger text-center text-white" id="answer">
                                    <p class="m-0 border-bottom border-white">
                                        <small class="font-weight-bold">
                                            Sicuro?
                                        </small>
                                    </p>
                                    <div class="container-fluid">

                                        <div class="row border-top">
                                            <div
                                            class   = "col-6 text-center border-right border-white cursor-pointer"
                                            @click  = "delete_users_software_functions"
                                            :class  = "{
                                                'text-center disabled-AO': loaders.delete.users_software_functions
                                            }">
                                                <small class="font-weight-bold " v-show="!loaders.delete.users_software_functions">
                                                    Si
                                                </small>
                                                <div v-show="loaders.delete.users_software_functions" class="progress-spinner progress-spinner-double size-sm progress-spinner-active">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                            </div>
                                            <div class="col-6 text-center cursor-pointer" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                                <small class="font-weight-bold">
                                                    No
                                                </small>
                                            </div>                                        
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                form: {
                    users_software_functions: {
                        create: true,
                        read:   true,
                        update: true,
                        delete: true
                    }
                },
                loaders: {
                    update: {
                        users_software_functions: false
                    },
                    read: {
                        users_software_functions: true
                    },
                    delete: {
                        users_software_functions: false
                    }
                },
                validations: {
                    update: {
                        users_software_functions: {
                            KO: false
                        }
                    }
                }
            }
        },
        methods: {
            async read_users_software_functions() {
                
                try {
                    this.form.users_software_functions = (
                        await this.axios.get(
                            `user/users_software_functions/read/detail/${this.$route.params.id}`, 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data
                }
                catch(e){
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le funzionalità software. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
                finally {
                    this.loaders.read.users_software_functions = false
                }

            },
            async update_users_software_functions() {

                this.loaders.update.users_software_functions        = true
                this.validations.update.users_software_functions.KO = false

                try {

                    await this.axios.post(
                        `user/users_software_functions/update/detail/${this.$route.params.id}`,
                        this.form.users_software_functions,
                        {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }
                    )

                    this.$toast.open({
                        message: "Modifica effettuata con successo",
                        type: "success"
                    })

                    this.$router.push(
                        `/admin/autorizzazioni/modifica/${this.$route.params.id_user_type}`
                    )

                }
                catch(e) {

                    const {
                        message
                    } = e.response.data

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validations.update.users_software_functions.KO = message
                    this.loaders.update.users_software_functions        = false

                }
            },
            async delete_users_software_functions() {

                this.loaders.delete.users_software_functions = true

                try {

                    await this.axios.delete(
                        `user/users_software_functions/${this.$route.params.id}`,
                        {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }
                    )

                    this.$toast.open({
                        message: "Eliminazione effettuata con successo",
                        type: "success"
                    })

                    this.$router.push(
                        `/admin/autorizzazioni/modifica/${this.$route.params.id_user_type}`
                    )

                }
                catch(e) {

                    const {
                        message
                    } = e.response.data

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.loaders.delete.users_software_functions = false

                }
            }
        },
        mounted() {
            this.read_users_software_functions()
        }
    }
</script>