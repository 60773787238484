<template>
    <div class="text-center">
        <h1>
            Sessione scaduta
        </h1>
        <div class="text-center">
            <button class="btn btn-primary" @click="logout">
                Effettua nuovo login
            </button>
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    export default {
        methods: {
            async logout() {
                try {

                    await store.dispatch('doCentralLogoutPatient')

                    this.$router.push('/accesso')

                }
                catch(e) {
                    this.$toast.open({
                        message: `Non è momentaneamente possibile effettuare il logout`,
                        type:    `error`
                    })
                }
                
            }
        }
    }
</script>