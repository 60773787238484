<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">
                <div class="card-wrapper card-space">
                    <div class="card card-bg no-after">
                        <div class="card-body">
                            <form @submit.prevent="createStructure" autocomplete="off">

                                <div class="row">
                                    <div class="col-lg-6 pr-3">

                                        <div class="form-group">

                                            <label for="title">Titolo</label>
                                            <input type="text" class="form-control text-uppercase" id="title" aria-describedby="title_info" v-model.trim="$v.form.structure.title.$model" v-bind:class="{'is-invalid': (!$v.form.structure.title.required || !$v.form.structure.title.minLength) && $v.form.structure.title.$dirty}">
                                            
                                            <small v-show="!$v.form.structure.title.$dirty || !$v.form.structure.title.$invalid" id="title_info" class="form-text text-muted">Il titolo digitato dovrà essere univoco</small>
                                            
                                            <div v-show="!$v.form.structure.title.required && $v.form.structure.title.$dirty" class="invalid-feedback">Titolo richiesto</div>
                                            <div v-show="!$v.form.structure.title.minLength && $v.form.structure.title.$dirty" class="invalid-feedback">Digitare minimo 3 caratteri</div>

                                        </div>
                                        <div class="form-group">

                                            <label for="address">Indirizzo</label>
                                            <input type="text" class="form-control" id="address" aria-describedby="address" v-model.trim="$v.form.structure.address.$model" v-bind:class="{'is-invalid': (!$v.form.structure.address.required || !$v.form.structure.address.minLength) && $v.form.structure.address.$dirty}">
                                            
                                            <small v-show="!$v.form.structure.address.$dirty || !$v.form.structure.address.$invalid" id="address" class="form-text text-muted">Digitare l'indirizzo della struttura</small>
                                            
                                            <div v-show="!$v.form.structure.address.required && $v.form.structure.address.$dirty" class="invalid-feedback">Indirizzo richiesto</div>
                                            <div v-show="!$v.form.structure.address.minLength && $v.form.structure.address.$dirty" class="invalid-feedback">Digitare minimo 8 caratteri</div>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-lg-4">
                                                <div class="form-group">

                                                    <label for="CAP">CAP</label>
                                                    <input type="text" class="form-control" id="CAP" maxlength="5" aria-describedby="CAP_help" v-model.trim="$v.form.structure.CAP.$model" v-bind:class="{'is-invalid': (!$v.form.structure.CAP.required || !$v.form.structure.CAP.minLength || !$v.form.structure.CAP.maxLength) && $v.form.structure.CAP.$dirty}">
                                                    
                                                    <small v-show="!$v.form.structure.CAP.$dirty || !$v.form.structure.CAP.$invalid" id="CAP_help" class="form-text text-muted">Digitare CAP struttura</small>
                                                    
                                                    <div v-show="!$v.form.structure.CAP.required && $v.form.structure.CAP.$dirty" class="invalid-feedback">CAP richiesto</div>
                                                    <div v-show="(!$v.form.structure.CAP.minLength || !$v.form.structure.CAP.maxLength) && $v.form.structure.CAP.$dirty" class="invalid-feedback">Digitare 5 caratteri</div>

                                                </div>
                                            </div>
                                        </div>

                                        <!-- CAP QUI -->
                                        <div class="form-group">

                                            <label for="description">Descrizione</label>
                                            <textarea id="description" class="form-control border" rows="2" v-model.trim="$v.form.structure.description.$model" v-bind:class="{'is-invalid border-danger': !$v.form.structure.description.minLength && $v.form.structure.description.$dirty}"></textarea>
                                            
                                            <small v-show="!$v.form.structure.description.$dirty || !$v.form.structure.description.$invalid" id="title_info" class="form-text text-muted">Fornire una breve descrizione</small>
                                            
                                            <div v-show="!$v.form.structure.description.minLength && $v.form.structure.description.$dirty" class="invalid-feedback">Digitare minimo 8 caratteri</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-6 pl-3">

                                        <h5 class="card-title">Seleziona la tipologia di struttura</h5>

                                        <div v-for="structure_type of DB.structure_types" :key="`structure_type_${structure_type.id}`" class="form-check form-check-group">
                                            <input name="structure_type" type="radio" :id="`structure_type_${structure_type.id}`" :aria-labelledby="`structure_type_help_${structure_type.id}`" :value="structure_type.id" v-model="form.structure.id_structure_type">
                                            <label :for="`structure_type_${structure_type.id}`">{{structure_type.title}}</label>
                                            <small :id="`structure_type_help_${structure_type.id}`" class="form-text">{{structure_type.description}}</small>
                                        </div>

                                    </div>
                                </div>

                                <hr class="w-50 mr-0 ml-0 mt-4 mb-3">
                                
                                <h5 class="card-title mt-3">
                                    Imposta i giorni di apertura e degli orari
                                </h5>
                                                                 
                                <!--<div class="row">
                                    <div class="col-lg-4 pr-lg-3">

                                        <div class="form-group mb-3">

                                            <div class="form-check">
                                                <input id="monday_open" type="checkbox" v-model="form.structure.monday_open">
                                                <label for="monday_open">Lunedi aperto</label>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">

                                                <div class="form-group mb-lg-3">

                                                    <label for="monday_h_start" class="active">Apertura</label>
                                                    <input id="monday_h_start" type="time" :disabled="!form.structure.monday_open" v-model.trim="$v.form.structure.monday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.monday_h_start.required && $v.form.structure.monday_h_start.$dirty}">

                                                    <div v-show="!$v.form.structure.monday_h_start.required && $v.form.structure.monday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                                </div>

                                            </div>
                                            <div class="col-lg-6">
                                                
                                                <div class="form-group mb-lg-3">

                                                    <label for="monday_h_end" class="active">Chiusura</label>
                                                    <input id="monday_h_end" type="time" :disabled="!form.structure.monday_open" v-model.trim="$v.form.structure.monday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.monday_h_end.required && $v.form.structure.monday_h_end.$dirty}">

                                                    <div v-show="!$v.form.structure.monday_h_end.required && $v.form.structure.monday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 pr-lg-3 pl-lg-3">

                                        <div class="form-group mb-3">

                                            <div class="form-check">
                                                <input id="tuesday_open" type="checkbox" v-model="form.structure.tuesday_open">
                                                <label for="tuesday_open">Martedi aperto</label>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">

                                                <div class="form-group mb-lg-3">

                                                    <label for="tuesday_h_start" class="active">Apertura</label>
                                                    <input id="tuesday_h_start" type="time" :disabled="!form.structure.tuesday_open" v-model.trim="$v.form.structure.tuesday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.tuesday_h_start.required && $v.form.structure.tuesday_h_start.$dirty}">

                                                    <div v-show="!$v.form.structure.tuesday_h_start.required && $v.form.structure.tuesday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                                </div>

                                            </div>
                                            <div class="col-lg-6">
                                                
                                                <div class="form-group mb-lg-3">

                                                    <label for="tuesday_h_end" class="active">Chiusura</label>
                                                    <input id="tuesday_h_end" type="time" :disabled="!form.structure.tuesday_open" v-model.trim="$v.form.structure.tuesday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.tuesday_h_end.required && $v.form.structure.tuesday_h_end.$dirty}">

                                                    <div v-show="!$v.form.structure.tuesday_h_end.required && $v.form.structure.tuesday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 pl-lg-3">

                                        <div class="form-group mb-lg-3">

                                            <div class="form-check">
                                                <input id="wednesday_open" type="checkbox" v-model="form.structure.wednesday_open">
                                                <label for="wednesday_open">Mercoledi aperto</label>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">

                                                <div class="form-group mb-lg-3">

                                                    <label for="wednesday_h_start" class="active">Apertura</label>
                                                    <input id="wednesday_h_start" type="time" :disabled="!form.structure.wednesday_open" v-model.trim="$v.form.structure.wednesday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.wednesday_h_start.required && $v.form.structure.wednesday_h_start.$dirty}">

                                                    <div v-show="!$v.form.structure.wednesday_h_start.required && $v.form.structure.wednesday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                                </div>

                                            </div>
                                            <div class="col-lg-6">
                                                
                                                <div class="form-group mb-lg-3">

                                                    <label for="wednesday_h_end" class="active">Chiusura</label>
                                                    <input id="wednesday_h_end" type="time" :disabled="!form.structure.wednesday_open" v-model.trim="$v.form.structure.wednesday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.wednesday_h_end.required && $v.form.structure.wednesday_h_end.$dirty}">

                                                    <div v-show="!$v.form.structure.wednesday_h_end.required && $v.form.structure.wednesday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 pr-lg-3">

                                        <div class="form-group mb-lg-3">

                                            <div class="form-check">
                                                <input id="thursday_open" type="checkbox" v-model="form.structure.thursday_open">
                                                <label for="thursday_open">Giovedi aperto</label>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">

                                                <div class="form-group mb-lg-3">

                                                    <label for="thursday_h_start" class="active">Apertura</label>
                                                    <input id="thursday_h_start" type="time" :disabled="!form.structure.thursday_open" v-model.trim="$v.form.structure.thursday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.thursday_h_start.required && $v.form.structure.thursday_h_start.$dirty}">

                                                    <div v-show="!$v.form.structure.thursday_h_start.required && $v.form.structure.thursday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                                </div>

                                            </div>
                                            <div class="col-lg-6">
                                                
                                                <div class="form-group mb-lg-3">

                                                    <label for="thursday_h_end" class="active">Chiusura</label>
                                                    <input id="thursday_h_end" type="time" :disabled="!form.structure.thursday_open" v-model.trim="$v.form.structure.thursday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.thursday_h_end.required && $v.form.structure.thursday_h_end.$dirty}">

                                                    <div v-show="!$v.form.structure.thursday_h_end.required && $v.form.structure.thursday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 pr-lg-3 pl-lg-3">

                                        <div class="form-group mb-3">

                                            <div class="form-check">
                                                <input id="friday_open" type="checkbox" v-model="form.structure.friday_open">
                                                <label for="friday_open">Venerdi aperto</label>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">

                                                <div class="form-group mb-lg-3">

                                                    <label for="friday_h_start" class="active">Apertura</label>
                                                    <input id="friday_h_start" type="time" :disabled="!form.structure.friday_open" v-model.trim="$v.form.structure.friday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.friday_h_start.required && $v.form.structure.friday_h_start.$dirty}">

                                                    <div v-show="!$v.form.structure.friday_h_start.required && $v.form.structure.friday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                                </div>

                                            </div>
                                            <div class="col-lg-6">
                                                
                                                <div class="form-group mb-lg-3">

                                                    <label for="friday_h_end" class="active">Chiusura</label>
                                                    <input id="friday_h_end" type="time" :disabled="!form.structure.friday_open" v-model.trim="$v.form.structure.friday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.friday_h_end.required && $v.form.structure.friday_h_end.$dirty}">

                                                    <div v-show="!$v.form.structure.friday_h_end.required && $v.form.structure.friday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 pl-lg-3">

                                        <div class="form-group mb-3">

                                            <div class="form-check">
                                                <input id="saturday_open" type="checkbox" v-model="form.structure.saturday_open">
                                                <label for="saturday_open">Sabato aperto</label>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">

                                                <div class="form-group mb-lg-3">

                                                    <label for="saturday_h_start" class="active">Apertura</label>
                                                    <input id="saturday_h_start" type="time" :disabled="!form.structure.saturday_open" v-model.trim="$v.form.structure.saturday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.saturday_h_start.required && $v.form.structure.saturday_h_start.$dirty}">

                                                    <div v-show="!$v.form.structure.saturday_h_start.required && $v.form.structure.saturday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                                </div>

                                            </div>
                                            <div class="col-lg-6">
                                                
                                                <div class="form-group mb-lg-3">

                                                    <label for="saturday_h_end" class="active">Chiusura</label>
                                                    <input id="saturday_h_end" type="time" :disabled="!form.structure.saturday_open" v-model.trim="$v.form.structure.saturday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.saturday_h_end.required && $v.form.structure.saturday_h_end.$dirty}">

                                                    <div v-show="!$v.form.structure.saturday_h_end.required && $v.form.structure.saturday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-4">

                                        <div class="form-group mb-3">

                                            <div class="form-check">
                                                <input id="sunday_open" type="checkbox" v-model="form.structure.sunday_open">
                                                <label for="sunday_open">Domenica aperto</label>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">

                                                <div class="form-group mb-lg-2">

                                                    <label for="sunday_h_start" class="active">Apertura</label>
                                                    <input id="sunday_h_start" type="time" :disabled="!form.structure.sunday_open" v-model.trim="$v.form.structure.sunday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.sunday_h_start.required && $v.form.structure.sunday_h_start.$dirty}">

                                                    <div v-show="!$v.form.structure.sunday_h_start.required && $v.form.structure.sunday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                                </div>

                                            </div>
                                            <div class="col-lg-6">
                                                
                                                <div class="form-group mb-lg-2">

                                                    <label for="sunday_h_end" class="active">Chiusura</label>
                                                    <input id="sunday_h_end" type="time" :disabled="!form.structure.sunday_open" v-model.trim="$v.form.structure.sunday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.sunday_h_end.required && $v.form.structure.sunday_h_end.$dirty}">

                                                    <div v-show="!$v.form.structure.sunday_h_end.required && $v.form.structure.sunday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                                
                                <div class="alert alert-danger mt-3 mb-3" v-show="validation.ko">
                                    {{validation.ko}}
                                </div>

                                <div class="alert alert-warning mt-3 mb-3">
                                    I test di competenza di questa struttura e la tipologia di prenotazione per i pazienti potranno essere associati dopo la creazione
                                </div>

                                <div class="it-card-footer mt-0">

                                    <button type="submit" class="btn btn-primary" :disabled="$v.form.structure.$invalid || loaders.creating.structure">
                                        
                                        <span v-show="!loaders.creating.structure">
                                            Aggiungi
                                        </span>

                                        <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.creating.structure">
                                            <div class="progress-spinner-inner"></div>
                                            <div class="progress-spinner-inner"></div>
                                            <span class="sr-only">Caricamento...</span>
                                        </div>
                                        

                                    </button>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">
                                    
                                    <i class="text-muted">Nessuna funzione</i>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength,
        requiredIf
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                DB: {
                    tests:           [],
                    structure_types: []
                },
                form: {
                    structure: {
                        title:             null,
                        address:           null,
                        CAP:               null,
                        description:       null,
                        id_structure_type: null,
                        monday_open:       false,
                        monday_h_start:    ``,
                        monday_h_end:      ``,
                        tuesday_open:      false,
                        tuesday_h_start:   ``,
                        tuesday_h_end:     ``,
                        wednesday_open:    false,
                        wednesday_h_start: ``,
                        wednesday_h_end:   ``,
                        thursday_open:     false,
                        thursday_h_start:  ``,
                        thursday_h_end:    ``,
                        friday_open:       false,
                        friday_h_start:    ``,
                        friday_h_end:      ``,
                        saturday_open:     false,
                        saturday_h_start:  ``,
                        saturday_h_end:    ``,
                        sunday_open:       false,
                        sunday_h_start:    ``,
                        sunday_h_end:      ``
                    },
                    appointment_durations_arr: []
                },
                loaders: {
                    creating: {
                        structure: false
                    }
                },
                validation: {
                    ko: false
                }
            }
        },
        validations() {
            return {
                form: {
                    structure: {
                        title: {
                            required,
                            minLength: minLength(3)
                        },
                        description: {
                            minLength: minLength(8),
                            maxLength: maxLength(350)
                        },
                        address: {
                            required,
                            minLength: minLength(8)
                        },
                        CAP: {
                            required,
                            minLength: minLength(5),
                            maxLength: maxLength(5)
                        },
                        id_structure_type: {
                            required
                        },
                        monday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.monday_open
                                }
                            )
                        },
                        monday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.monday_open
                                }
                            )
                        },
                        tuesday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.tuesday_open
                                }
                            )
                        },
                        tuesday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.tuesday_open
                                }
                            )
                        },
                        wednesday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.wednesday_open
                                }
                            )
                        },
                        wednesday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.wednesday_open
                                }
                            )
                        },
                        thursday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.thursday_open
                                }
                            )
                        },
                        thursday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.thursday_open
                                }
                            )
                        },
                        friday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.friday_open
                                }
                            )
                        },
                        friday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.friday_open
                                }
                            )
                        },
                        saturday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.saturday_open
                                }
                            )
                        },
                        saturday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.saturday_open
                                }
                            )
                        },
                        sunday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.sunday_open
                                }
                            )
                        },
                        sunday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.sunday_open
                                }
                            )
                        }
                    }

                }
            }
        },
        methods: {

            async createStructure() {

                this.loaders.creating.structure = true
                this.validation.ko              = false

                try {

                    const res = await this.axios.post(`user/structures/create/single`, this.form.structure, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture`)

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko              = message
                    this.loaders.creating.structure = false

                }
            },
            async readStructureTypes() {
                try {

                    const structureTypes = await this.axios.get(`user/structure_types/read/actives`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.structure_types = structureTypes.data.structure_types_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le strutture. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            async readTests() {
                try {

                    const tests = await this.axios.get(`user/tests/read/actives`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.tests = tests.data.tests_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i tests. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            }

        },
        mounted() {
            this.readTests()
            this.readStructureTypes()
        }
    }
</script>