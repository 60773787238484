<template>
    <div class="row">
        <div class="col-lg-10 pt-2 pb-2">
            <div class="container-fluid">
                <loader v-if="loaders.reading"/>                
                <div v-else>
                    <div v-if="DB.appointments.length < 1" class="alert alert-warning m-0">
                            Ancora nessun upload
                    </div>
                    <div v-else>                     
                        <div class="row" v-show="view == 'cards'">                       
                            <div class="col-md-6 col-lg-4" v-for="appointment of DB.appointments" :key="appointment.id">
                                <div class="card-wrapper card-space">
                                    <div class="card card-bg no-after">
                                        <div class="card-body">
                                            
                                            <div class="row">
                                                <div class="col-md-8">
                                                    <h5 class="card-title">
                                                        Data creazione: <br />{{appointment.date_creation | data_slash}}
                                                        <br />
                                                        <span class="f-s-0_8-em">Tot. appuntamenti: {{appointment.counter}}</span>
                                                    </h5>
                                                </div>
                                                <div class="col-md-4 text-right font-weight-bold text-success text-truncate" :title="appointment.titleTest">
                                                    {{appointment.titleTest}}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6  f-s-0_8-em">
                                                    Screening: <br /><strong>{{appointment.titleScreening}}</strong>
                                                </div>
                                                <div class="col-md-6 text-right f-s-0_8-em">
                                                    <div v-if="appointment.id_sms == '1' && appointment.idScreening == '3'">
                                                        Invio solleciti non rispondenti
                                                    </div>
                                                    <div v-if="appointment.id_sms == '2' && appointment.idScreening == '3'">
                                                        Provetta non riconsegnata
                                                    </div>                                                      
                                                    <!--Struttura: <br /><strong>{{appointment.titleStructure}}</strong>-->
                                                </div>
                                            </div>                                    

                                            <div class="row">
                                                <div class="col-md-12 blink_me" v-if="appointment.limit == '1' && appointment.counter_to_sent_sms > 0">LIMITE INVIO SMS ANNUALE RAGGIUNTO!</div>     
                                                <div class="col-md-6">                                   
                                                    <p class="card-text m-0 mt-2 font-weight-bold">SMS:</p>
                                                    <p class="card-text m-0 mt-2 text-success font-weight-bold">
                                                        Inviati all'operatore: 
                                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_sent_sms > 0 ? `uploads/visualizza/sent_sms/${appointment.token_import}` : ''" class="text-success"> 
                                                            {{appointment.counter_sent_sms}}
                                                        </router-link>    
                                                    </p>
                                                    <p class="card-text m-0 text-info">
                                                        Da consegnare all'operatore: 
                                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_to_sent_sms > 0  ? `uploads/visualizza/to_sent_sms/${appointment.token_import}` : ''" class="text-info">  
                                                            {{appointment.counter_to_sent_sms}}
                                                        </router-link>                                                 
                                                    </p>
                                                    <p class="card-text m-0 text-danger">
                                                        Non inviati: 
                                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_sent_sms_error > 0  ? `uploads/visualizza/sent_sms_error/${appointment.token_import}` : ''" class="text-danger">  
                                                            {{appointment.counter_sent_sms_error}}
                                                        </router-link> 
                                                    </p>
                                                    <p class="card-text m-0 mt-2 text-success font-weight-bold">
                                                        Consegnati: 
                                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_sent_delivered > 0  ? `uploads/visualizza/sent_delivered/${appointment.token_import}` : ''" class="text-success">  
                                                            {{appointment.counter_sent_delivered}}
                                                        </router-link>  
                                                    </p>
                                                    <p class="card-text m-0 text-danger">
                                                        In attesa di notifiche dall'operatore: 
                                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_sent_no_delivered > 0  ? `uploads/visualizza/sent_no_delivered/${appointment.token_import}` : ''" class="text-danger">  
                                                            {{appointment.counter_sent_no_delivered}}
                                                        </router-link> 
                                                    </p>
                                                </div>
                                                <div class="col-md-6 text-right" v-if="appointment.idScreening != '3'">  
                                                    <p class="card-text m-0 mt-2 font-weight-bold">RISPOSTE:</p>
                                                    <p class="card-text m-0 mt-2 text-success font-weight-bold">
                                                        SI: 
                                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_reply > 0  ? `uploads/visualizza/reply/${appointment.token_import}` : ''" class="text-success">  
                                                            {{appointment.counter_reply}}
                                                        </router-link>                                                   
                                                    </p>
                                                    <p class="card-text m-0 text-info">
                                                        NO: 
                                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_no_reply > 0  ? `uploads/visualizza/no_reply/${appointment.token_import}` : ''" class="text-info">  
                                                            {{appointment.counter_no_reply}}
                                                        </router-link>  
                                                    </p>
                                                    <p class="card-text m-0 text-danger">
                                                        Non conforme: 
                                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_error_reply > 0  ? `uploads/visualizza/error_reply/${appointment.token_import}` : ''" class="text-danger">  
                                                            {{appointment.counter_error_reply}}
                                                        </router-link>  
                                                    </p>
                                                    <p class="card-text m-0 text-danger">
                                                        Attesa di risposta: 
                                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_without_reply > 0  ? `uploads/visualizza/without_reply/${appointment.token_import}` : ''" class="text-danger">  
                                                            {{appointment.counter_without_reply}}
                                                        </router-link>  
                                                    </p>                                                   
                                                </div>    
                                            </div>     
                                            <!--<div class="it-card-footer mt-3">
                                                <a class="btn btn-outline-primary btn-sm" @click="view = `table`">
                                                    Visualizza tutto
                                                </a>
                                            </div>-->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive" v-show="view == 'table'">
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" colSpan="5"></th>
                                        <th scope="col" colSpan="5" class="text-center border ">SMS</th>
                                        <th scope="col" colSpan="4" class="text-center border">Risposte</th>
                                    </tr>                           
                                    <tr>
                                        <th scope="col" class="border">Data creazione</th>
                                        <th scope="col" class="border">Test</th>
                                        <th scope="col" class="border">Screening</th>
                                        <th scope="col" class="border">Struttura</th>
                                        <th scope="col" class="border">Totale appuntamenti</th>
                                        <th scope="col" class="border">Inviati all'operatore</th>
                                        <th scope="col" class="border">Da consegnare all'operatore</th>
                                        <th scope="col" class="border">Non inviati</th>
                                        <th scope="col" class="border">Consegnati</th>
                                        <th scope="col" class="border">In attesa di notifiche dall'operatore</th>
                                        <th scope="col" class="border">Si</th>
                                        <th scope="col" class="border">No</th>
                                        <th scope="col" class="border">Da verificare</th>
                                        <th scope="col" class="border">Attesa di risposta</th>
                                    </tr>
                                </thead>
                                <tbody v-for="appointment of DB.appointments" :key="appointment.id">
                                    <th scope="row" class="border">{{appointment.date_creation | data_slash}}</th>
                                    <th scope="row" class="border">{{appointment.titleTest}}</th>
                                    <th scope="row" class="border">{{appointment.titleScreening}}</th>
                                    <th scope="row" class="border">{{appointment.titleStructure}}</th>
                                    <th class="font-weight-bold text-center border">{{appointment.counter}}</th>
                                    <th class="text-success font-weight-bold text-center border">
                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_sent_sms > 0 ? `uploads/visualizza/sent_sms/${appointment.token_import}` : ''" class="text-success">  
                                            {{appointment.counter_sent_sms}}
                                        </router-link> 
                                    </th>
                                    <th class="text-warning font-weight-bold text-center border">
                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_to_sent_sms > 0  ? `uploads/visualizza/to_sent_sms/${appointment.token_import}` : ''" class="text-info">  
                                            {{appointment.counter_to_sent_sms}}
                                        </router-link>                                    
                                    </th>
                                    <th class="text-danger text-center border">
                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_sent_sms_error > 0  ? `uploads/visualizza/sent_sms_error/${appointment.token_import}` : ''" class="text-danger">  
                                            {{appointment.counter_sent_sms_error}}
                                        </router-link>                                    
                                    </th>
                                    <th class="text-success font-weight-bold text-center border">
                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_sent_delivered > 0  ? `uploads/visualizza/sent_delivered/${appointment.token_import}` : ''" class="text-success">  
                                            {{appointment.counter_sent_delivered}}
                                        </router-link>                                  
                                    </th>
                                    <th class="text-danger text-center border">
                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_sent_no_delivered > 0  ? `uploads/visualizza/sent_no_delivered/${appointment.token_import}` : ''" class="text-danger">  
                                            {{appointment.counter_sent_no_delivered}}
                                        </router-link>                                  
                                    </th>
                                    <th class="text-success font-weight-bold text-center border">
                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_reply > 0  ? `uploads/visualizza/reply/${appointment.token_import}` : ''" class="text-success">  
                                            {{appointment.counter_reply}}
                                        </router-link>                                  
                                    </th>
                                    <th class="text-info text-center border">
                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_no_reply > 0  ? `uploads/visualizza/no_reply/${appointment.token_import}` : ''" class="text-info">  
                                            {{appointment.counter_no_reply}}
                                        </router-link>                                
                                    </th>
                                    <th class="text-danger text-center border">
                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_error_reply > 0  ? `uploads/visualizza/error_reply/${appointment.token_import}` : ''" class="text-danger">  
                                            {{appointment.counter_error_reply}}
                                        </router-link>                                   
                                    </th>
                                    <th class="text-danger text-center border">
                                        <router-link :to = "!function_disabled('Uploads', 'read') && appointment.counter_without_reply > 0  ? `uploads/visualizza/without_reply/${appointment.token_import}` : ''" class="text-danger">  
                                            {{appointment.counter_without_reply}}
                                        </router-link>                                   
                                    </th>                                   
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>    
                </div>    
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Filtri di ricerca</h3>
                            </li>
                            <li class="mr-4">
                                <p class="text-muted mb-3 f-s-0_8-em">
                                    Compila i campi che seguono, la ricerca avverrà in automatico
                                </p>
                                <div class="row m-0">
                                    <div class="col-lg-12 mb-1">
                                        <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readAppointmentsFilter" v-model="form.search.test" placeholder="Test">
                                    </div>
                                    <div class="col-lg-12 mb-1">
                                        <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readAppointmentsFilter" v-model="form.search.screening" placeholder="Screening">
                                    </div>
                                    <div class="col-lg-12 mb-1">
                                        <input class="form-control text-uppercase" type="text" @input="readAppointmentsFilter" v-model="form.search.structure" placeholder="Struttura">
                                    </div>                                                                                
                                </div>
                            </li>
                            <li>
                                <h3 class="text-truncate mt-4">Visualizzazione</h3>
                            </li>
                            <li v-show="view == `cards`">                                
                                <a class="list-item medium text-truncate" @click="view = `table`">
                                    
                                    <span class="mr-2">Tabellato</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table mb-1" viewBox="0 0 16 16">
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                                    </svg>

                                </a>
                            </li>
                            <li v-show="view == `table`">                                
                                <a class="list-item medium text-truncate" @click="view = `cards`">
                                    
                                    <span class="mr-2">Cards</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-text mb-1" viewBox="0 0 16 16">
                                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                        <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                                    </svg>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    export default {
        data() {
            return {
                screening_id_limit: '',
                DB: {
                    appointments: []
                },
                view: `cards`,
                loaders: {
                    reading: true
                },               
                form: {
                    search: {
                        test:      ``,
                        screening:    ``,
                        structure: ``
                    }
                },                
            }
        },
        methods: {
            async readAppointments() {
                try {

                    const appointments = await this.axios.get(`user/uploads/read/all`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.appointments = appointments.data.appointments_db
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare gli uploads. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
                finally {
                    this.loaders.reading = false
                }               
            },
            async readAppointmentsFilter() {

                this.loaders.reading = true

                if(this.isInSearching()) {

                    for(let val in this.form.search) {
                        this.form.search[val] = this.form.search[val].toUpperCase()
                    }

                    const where1 = '%'+this.form.search.test+'%'; 
                    const where2 = '%'+this.form.search.screening+'%'; 
                    const where3 = '%'+this.form.search.structure+'%';                 

                    try {
                        const appointments = await this.axios.post(`user/uploads/read/search`, {where1, where2, where3}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })                     

                        this.DB.appointments = appointments.data

                    }
                    catch(e) {

                        this.$toast.open({
                            message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`,
                            type:    `error`
                        })

                    }
                    finally {
                        this.loaders.reading = false
                    }
                }
                else {
                    this.readAppointments()
                }
            },  
            isInSearching() {
                return this.form.search.test.length >= 3 || this.form.search.screening.length >= 3 || this.form.search.structure.length >= 3
            },                      
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }
        },
        mounted() {
            this.readAppointments()
        }
    }
</script>