<template>
    <div class="row">
        <div class="col-lg-10 pt-2 pb-2">
            <div class="container-fluid">
                <div class="card-wrapper card-space">
                    <div class="card card-bg no-after">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped table-hover table-sm m-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome tipologia utente</th>
                                            <th scope="col">Descrizione</th>
                                            <th scope="col">Utenti</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <router-link
                                            tag     = "tr"
                                            v-for   = "user_type of DB.user_types"
                                            :key    = "user_type.id"
                                            :to     = "!function_disabled(
                                                'Autorizzazioni',
                                                'create'
                                            ) ? `autorizzazioni/modifica/${user_type.id}` : 'autorizzazioni'"
                                        >
                                            <td>{{user_type.title}}</td>
                                            <td>{{user_type.description}}</td>
                                            <td>{{user_type.users.length}}</td>
                                        </router-link>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">
                                    Funzioni
                                </h3>
                            </li>
                            <li>
                                <router-link
                                    class   = "list-item medium text-truncate"
                                    to      = "/admin/utenti/aggiungi"
                                    v-if    = "
                                        !function_disabled(
                                            'Autorizzazioni',
                                            'create'
                                        )
                                    "
                                >
                                    
                                    <span class="mr-2">Aggiungi</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>

                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    export default {
        data() {
            return {
                DB: {
                    user_types: []
                }
            }
        },
        methods: {
            async read_user_types() {
                try {

                    this.DB.user_types = (
                        await this.axios.get(
                            "user/user_types/read/all", 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data
                    
                }
                catch(e) {
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le tipologie di utenza. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }
        },
        mounted() {
            this.read_user_types()
        }
    }
</script>