import Vue    from 'vue'
import moment from 'moment'
import 'moment/locale/it'

Vue.filter('data_ora', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
})
Vue.filter('data_slash', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
})
Vue.filter('data_time', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
    }
})
Vue.filter('data_input', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
    }
})
Vue.filter('data_mese_scritto', function(value) {
    if (value) {
        return moment(String(value)).locale("it").format('MMMM')
    }
})
Vue.filter('data_mese_scritto_v2', function(value) {
    if (value) {
        return moment(String(value)).locale("it").format('DD MMMM YYYY')
    }
})
Vue.filter('data_giorno', function(value) {
    if (value) {
        return moment(String(value)).format('D')
    }
})