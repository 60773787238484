<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">
                <div class="card-wrapper card-space">
                    <div class="card card-bg no-after">
                        <div class="card-body">
                            <form @submit.prevent="updateStructureConfiguration" autocomplete="off">

                                <div class="row">
                                    <div class="col-lg-4">

                                        <div class="form-group">

                                            <label for="title" class="active">Data di inizio</label>
                                            <input type="date" class="form-control" id="period_date_start" aria-describedby="period_date_start_info" v-model.trim="$v.form.structure_configuration.period_date_start.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.period_date_start.required && $v.form.structure_configuration.period_date_start.$dirty}">
                                            
                                            <small v-show="!$v.form.structure_configuration.period_date_start.$dirty || !$v.form.structure_configuration.period_date_start.$invalid" id="period_date_start_info" class="form-text text-muted">Imposta una data di inizio per questa pianificazione</small>
                                            
                                            <div v-show="!$v.form.structure_configuration.period_date_start.required && $v.form.structure_configuration.period_date_start.$dirty" class="invalid-feedback">Data inizio richiesta</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-4">

                                        <div class="form-group">

                                            <label for="title" class="active">Data di termine</label>
                                            <input type="date" class="form-control" id="period_date_end" aria-describedby="period_date_end_info" v-model.trim="$v.form.structure_configuration.period_date_end.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.period_date_end.required && $v.form.structure_configuration.period_date_end.$dirty}">
                                            
                                            <small v-show="!$v.form.structure_configuration.period_date_end.$dirty || !$v.form.structure_configuration.period_date_end.$invalid" id="period_date_end_info" class="form-text text-muted">Imposta una data di termine</small>
                                            
                                            <div v-show="!$v.form.structure_configuration.period_date_end.required && $v.form.structure_configuration.period_date_end.$dirty" class="invalid-feedback">Data termine richiesta</div>

                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-4">

                                        <div class="form-group">

                                            <label for="appointment_fixs" class="active">N° appuntamenti</label>
                                            <input type="number" class="form-control" id="appointment_fixs" aria-describedby="appointment_fixs_info" v-model.number="$v.form.structure_configuration.appointment_fixs.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.appointment_fixs.required && $v.form.structure_configuration.appointment_fixs.$dirty}">
                                            
                                            <small v-show="!$v.form.structure_configuration.appointment_fixs.$dirty || !$v.form.structure_configuration.appointment_fixs.$invalid" id="period_date_end_info" class="form-text text-muted">Imposta numero appuntamenti giornalieri</small>
                                            
                                            <div v-show="!$v.form.structure_configuration.appointment_fixs.required && $v.form.structure_configuration.appointment_fixs.$dirty" class="invalid-feedback">Data termine richiesta</div>

                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4">

                                        <h5 class="mb-1 card-title">Seleziona un test da associare</h5>

                                        <div class="form-group border mb-1">

                                            <select class="form-control form-control-sm" id="tests" title="Scegli una opzione" v-model="form.structure_configuration.id_test">
                                                <option disabled value="" selected>Seleziona un test</option>
                                                <option v-for="test of DB.tests" :key="test.id" :value="test.id">{{test.title}}</option>
                                            </select>

                                        </div>

                                        <small class="form-text text-muted">Test di competenza per questa pianificazione</small>

                                    </div>
                                </div>

                                <hr class="w-50 mr-0 ml-0 mt-4 mb-3">

                                <!-- Lunedi -->
                                <div class="form-group mb-3">

                                    <div class="form-check">
                                        <input id="monday_open" type="checkbox" v-model="form.structure_configuration.monday_open">
                                        <label for="monday_open">Lunedi aperto</label>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-3">

                                        <div class="form-group mb-lg-3">

                                            <label for="monday_h_start" class="active">Apertura</label>
                                            <input id="monday_h_start" type="time" :disabled="!form.structure_configuration.monday_open" v-model.trim="$v.form.structure_configuration.monday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.monday_h_start.required && $v.form.structure_configuration.monday_h_start.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.monday_h_start.required && $v.form.structure_configuration.monday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-3">
                                        
                                        <div class="form-group mb-lg-3">

                                            <label for="monday_h_end" class="active">Chiusura</label>
                                            <input id="monday_h_end" type="time" :disabled="!form.structure_configuration.monday_open" v-model.trim="$v.form.structure_configuration.monday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.monday_h_end.required && $v.form.structure_configuration.monday_h_end.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.monday_h_end.required && $v.form.structure_configuration.monday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                        </div>

                                    </div>
                                </div>

                                <!-- Martedi -->
                                <div class="form-group mb-3">

                                    <div class="form-check">
                                        <input id="tuesday_open" type="checkbox" v-model="form.structure_configuration.tuesday_open">
                                        <label for="tuesday_open">Martedi aperto</label>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-3">

                                        <div class="form-group mb-lg-3">

                                            <label for="tuesday_h_start" class="active">Apertura</label>
                                            <input id="tuesday_h_start" type="time" :disabled="!form.structure_configuration.tuesday_open" v-model.trim="$v.form.structure_configuration.tuesday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.tuesday_h_start.required && $v.form.structure_configuration.tuesday_h_start.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.tuesday_h_start.required && $v.form.structure_configuration.tuesday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-3">
                                        
                                        <div class="form-group mb-lg-3">

                                            <label for="tuesday_h_end" class="active">Chiusura</label>
                                            <input id="tuesday_h_end" type="time" :disabled="!form.structure_configuration.tuesday_open" v-model.trim="$v.form.structure_configuration.tuesday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.tuesday_h_end.required && $v.form.structure_configuration.tuesday_h_end.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.tuesday_h_end.required && $v.form.structure_configuration.tuesday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                        </div>

                                    </div>
                                </div>

                                <!-- Mercoledi -->
                                <div class="form-group mb-lg-3">

                                    <div class="form-check">
                                        <input id="wednesday_open" type="checkbox" v-model="form.structure_configuration.wednesday_open">
                                        <label for="wednesday_open">Mercoledi aperto</label>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-3">

                                        <div class="form-group mb-lg-3">

                                            <label for="wednesday_h_start" class="active">Apertura</label>
                                            <input id="wednesday_h_start" type="time" :disabled="!form.structure_configuration.wednesday_open" v-model.trim="$v.form.structure_configuration.wednesday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.wednesday_h_start.required && $v.form.structure_configuration.wednesday_h_start.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.wednesday_h_start.required && $v.form.structure_configuration.wednesday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-3">
                                        
                                        <div class="form-group mb-lg-3">

                                            <label for="wednesday_h_end" class="active">Chiusura</label>
                                            <input id="wednesday_h_end" type="time" :disabled="!form.structure_configuration.wednesday_open" v-model.trim="$v.form.structure_configuration.wednesday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.wednesday_h_end.required && $v.form.structure_configuration.wednesday_h_end.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.wednesday_h_end.required && $v.form.structure_configuration.wednesday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                        </div>

                                    </div>
                                </div>

                                <!-- Giovedi -->
                                <div class="form-group mb-lg-3">

                                    <div class="form-check">
                                        <input id="thursday_open" type="checkbox" v-model="form.structure_configuration.thursday_open">
                                        <label for="thursday_open">Giovedi aperto</label>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-3">

                                        <div class="form-group mb-lg-3">

                                            <label for="thursday_h_start" class="active">Apertura</label>
                                            <input id="thursday_h_start" type="time" :disabled="!form.structure_configuration.thursday_open" v-model.trim="$v.form.structure_configuration.thursday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.thursday_h_start.required && $v.form.structure_configuration.thursday_h_start.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.thursday_h_start.required && $v.form.structure_configuration.thursday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-3">
                                        
                                        <div class="form-group mb-lg-3">

                                            <label for="thursday_h_end" class="active">Chiusura</label>
                                            <input id="thursday_h_end" type="time" :disabled="!form.structure_configuration.thursday_open" v-model.trim="$v.form.structure_configuration.thursday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.thursday_h_end.required && $v.form.structure_configuration.thursday_h_end.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.thursday_h_end.required && $v.form.structure_configuration.thursday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                        </div>

                                    </div>
                                </div>

                                <!-- Venerdi -->
                                <div class="form-group mb-3">

                                    <div class="form-check">
                                        <input id="friday_open" type="checkbox" v-model="form.structure_configuration.friday_open">
                                        <label for="friday_open">Venerdi aperto</label>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-3">

                                        <div class="form-group mb-lg-3">

                                            <label for="friday_h_start" class="active">Apertura</label>
                                            <input id="friday_h_start" type="time" :disabled="!form.structure_configuration.friday_open" v-model.trim="$v.form.structure_configuration.friday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.friday_h_start.required && $v.form.structure_configuration.friday_h_start.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.friday_h_start.required && $v.form.structure_configuration.friday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-3">
                                        
                                        <div class="form-group mb-lg-3">

                                            <label for="friday_h_end" class="active">Chiusura</label>
                                            <input id="friday_h_end" type="time" :disabled="!form.structure_configuration.friday_open" v-model.trim="$v.form.structure_configuration.friday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.friday_h_end.required && $v.form.structure_configuration.friday_h_end.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.friday_h_end.required && $v.form.structure_configuration.friday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                        </div>

                                    </div>
                                </div>

                                <!-- Sabato -->
                                <div class="form-group mb-3">

                                    <div class="form-check">
                                        <input id="saturday_open" type="checkbox" v-model="form.structure_configuration.saturday_open">
                                        <label for="saturday_open">Sabato aperto</label>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-3">

                                        <div class="form-group mb-lg-3">

                                            <label for="saturday_h_start" class="active">Apertura</label>
                                            <input id="saturday_h_start" type="time" :disabled="!form.structure_configuration.saturday_open" v-model.trim="$v.form.structure_configuration.saturday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.saturday_h_start.required && $v.form.structure_configuration.saturday_h_start.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.saturday_h_start.required && $v.form.structure_configuration.saturday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-3">
                                        
                                        <div class="form-group mb-lg-3">

                                            <label for="saturday_h_end" class="active">Chiusura</label>
                                            <input id="saturday_h_end" type="time" :disabled="!form.structure_configuration.saturday_open" v-model.trim="$v.form.structure_configuration.saturday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.saturday_h_end.required && $v.form.structure_configuration.saturday_h_end.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.saturday_h_end.required && $v.form.structure_configuration.saturday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                        </div>

                                    </div>
                                </div>

                                <!-- Domenica -->
                                <div class="form-group mb-3">

                                    <div class="form-check">
                                        <input id="sunday_open" type="checkbox" v-model="form.structure_configuration.sunday_open">
                                        <label for="sunday_open">Domenica aperto</label>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-3">

                                        <div class="form-group mb-lg-2">

                                            <label for="sunday_h_start" class="active">Apertura</label>
                                            <input id="sunday_h_start" type="time" :disabled="!form.structure_configuration.sunday_open" v-model.trim="$v.form.structure_configuration.sunday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.sunday_h_start.required && $v.form.structure_configuration.sunday_h_start.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.sunday_h_start.required && $v.form.structure_configuration.sunday_h_start.$dirty" class="invalid-feedback">Orario apertura richiesto</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-3">
                                        
                                        <div class="form-group mb-lg-2">

                                            <label for="sunday_h_end" class="active">Chiusura</label>
                                            <input id="sunday_h_end" type="time" :disabled="!form.structure_configuration.sunday_open" v-model.trim="$v.form.structure_configuration.sunday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure_configuration.sunday_h_end.required && $v.form.structure_configuration.sunday_h_end.$dirty}">

                                            <div v-show="!$v.form.structure_configuration.sunday_h_end.required && $v.form.structure_configuration.sunday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                        </div>

                                    </div>
                                </div>

                                <div class="alert alert-danger" v-show="validation.ko">
                                    {{validation.ko}}
                                </div>

                                <div class="it-card-footer">

                                    <button type="submit" class="btn btn-primary" :disabled="$v.form.structure_configuration.$invalid || loaders.updating.structure_configuration">
                                        
                                        <span v-show="!loaders.updating.structure_configuration">
                                            Modifica
                                        </span>

                                        <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.updating.structure_configuration">
                                            <div class="progress-spinner-inner"></div>
                                            <div class="progress-spinner-inner"></div>
                                            <span class="sr-only">Caricamento...</span>
                                        </div>
                                        

                                    </button>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">
                                    
                                    <i class="text-muted">Nessuna funzione</i>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        requiredIf,
        numeric
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                DB: {
                    tests: []
                },
                form: {
                    structure_configuration: {

                        period_date_start: null,
                        period_date_end:   null,
                        appointment_fixs:  null,

                        id_test:           null,

                        monday_open:       false,
                        monday_h_start:    ``,
                        monday_h_end:      ``,

                        tuesday_open:      false,
                        tuesday_h_start:   ``,
                        tuesday_h_end:     ``,

                        wednesday_open:    false,
                        wednesday_h_start: ``,
                        wednesday_h_end:   ``,

                        thursday_open:     false,
                        thursday_h_start:  ``,
                        thursday_h_end:    ``,

                        friday_open:       false,
                        friday_h_start:    ``,
                        friday_h_end:      ``,

                        saturday_open:     false,
                        saturday_h_start:  ``,
                        saturday_h_end:    ``,

                        sunday_open:       false,
                        sunday_h_start:    ``,
                        sunday_h_end:      ``

                    }
                },
                loaders: {
                    updating: {
                        structure_configuration: false
                    }
                },
                validation: {
                    ko: false
                }
            }
        },
        validations() {
            return {
                form: {
                    structure_configuration: {
                        period_date_start: {
                            required
                        },
                        period_date_end: {
                            required
                        },
                        appointment_fixs: {
                            required,
                            numeric
                        },
                        monday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.monday_open
                                }
                            )
                        },
                        monday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.monday_open
                                }
                            )
                        },
                        tuesday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.tuesday_open
                                }
                            )
                        },
                        tuesday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.tuesday_open
                                }
                            )
                        },
                        wednesday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.wednesday_open
                                }
                            )
                        },
                        wednesday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.wednesday_open
                                }
                            )
                        },
                        thursday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.thursday_open
                                }
                            )
                        },
                        thursday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.thursday_open
                                }
                            )
                        },
                        friday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.friday_open
                                }
                            )
                        },
                        friday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.friday_open
                                }
                            )
                        },
                        saturday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.saturday_open
                                }
                            )
                        },
                        saturday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.saturday_open
                                }
                            )
                        },
                        sunday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.sunday_open
                                }
                            )
                        },
                        sunday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure_configuration.sunday_open
                                }
                            )
                        }
                    }
                }
            }
        },
        methods: {

            async readStructureConfiguration() {

                const {
                    id
                } = this.$route.params

                try {

                    const structure_configuration = await this.axios.get(`user/structure_configurations/read/detail/${id}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.structure_configuration = {...structure_configuration.data}

                }
                catch(e) {

                    this.$toast.open({
                        message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'riprova piu tardi.'}` ,
                        type:    `error`
                    })

                    this.$router.go(-1)

                }
                finally {
                    this.loaders.reading = false
                }

            },
            async updateStructureConfiguration() {


                this.loaders.updating.structure_configuration = true
                this.validation.ko                            = false

                try {

                    // Aggiungiamo il title
                    this.form.structure_configuration.title = this.$route.params.title

                    const res = await this.axios.post(`user/structure_configurations/update/single`, this.form.structure_configuration, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture/modifica/${this.$route.params.title}`)

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko                            = message
                    this.loaders.updating.structure_configuration = false

                }
            },
            async readTests() {
                try {

                    let tests = await this.axios.get(`user/tests/read/actives`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.tests = tests.data.tests_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i tests. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },

        },
        mounted() {
            this.readTests()
            this.readStructureConfiguration()
        }
    }
</script>