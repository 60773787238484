import Vue          from 'vue'
import Vuex         from 'vuex'
import App          from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router       from './router'
import store        from './store'
import axios        from 'axios'
import VueAxios     from 'vue-axios'
import Vuelidate    from 'vuelidate'
import VueToast     from 'vue-toast-notification'

import './filters/date'
import 'vue-toast-notification/dist/theme-sugar.css'

import './jquery.js'
import 'bootstrap-italia/dist/js/bootstrap-italia.min.js'
import './registerServiceWorker'

import Pagination from 'vue-pagination-2';
import loader         from './components/loader.vue'

Vue.component('pagination', Pagination);

Vue.component('loader', loader);

Vue.use(
  BootstrapVue
)

Vue.use(
  VueAxios,
  axios,
  Vuex
)

Vue.use(
  Vuelidate
)

Vue.use(
  VueToast, {
    position: "top-right"
  }
)

Vue.config.productionTip = false

const URL = {
  test: `http://localhost:5002`,
  prod: `https://screening.aslroma2.it`
}


axios.defaults.withCredentials = true 
axios.defaults.baseURL         = `${URL.prod}/api/`

new Vue({
  components: {
    loader
  },  
  store,
  router,
  render: h => h(App)
}).$mount('#app')
