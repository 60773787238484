<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">

                <div class="row">
                    <div class="col-lg-6 offset-lg-3 mb-2">

                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <form @submit.prevent="updateStructure_type" autocomplete="off">

                                        <div class="form-group">

                                            <label for="title" class="active">Titolo</label>
                                            <input type="text" class="form-control text-uppercase" id="title" aria-describedby="title_info" v-model="form.structure_type.title" disabled>
                                            <small id="title_info" class="form-text text-muted">Il titolo non può essere modificato</small>
                                        
                                        </div>
                                        <div class="form-group">

                                            <label for="description" class="active">Descrizione</label>
                                            <textarea id="description" class="form-control border" rows="3" v-model.trim="$v.form.structure_type.description.$model" v-bind:class="{'is-invalid border-danger': (!$v.form.structure_type.description.required || !$v.form.structure_type.description.minLength) && $v.form.structure_type.description.$dirty}"></textarea>
                                            
                                            <small v-show="!$v.form.structure_type.description.$dirty || !$v.form.structure_type.description.$invalid" id="title_info" class="form-text text-muted">Fornire una breve descrizione</small>
                                            
                                            <div v-show="!$v.form.structure_type.description.required && $v.form.structure_type.description.$dirty" class="invalid-feedback">Descrizione richiesta</div>
                                            <div v-show="!$v.form.structure_type.description.minLength && $v.form.structure_type.description.$dirty" class="invalid-feedback">Digitare minimo 8 caratteri</div>

                                        </div>

                                        <h5 class="mb-1 pl-2 card-title c-435a70">Tipologia di accoglienza</h5>

                                        <div class="form-group border mb-1">

                                            <select class="form-control form-control-sm" id="tests" title="Scegli una opzione" v-model="form.structure_type.id_reception_type">
                                                <option disabled value="" selected>Seleziona un test</option>
                                                <option v-for="reception_type of DB.reception_types" :key="`reception_type_${reception_type.id}`" :value="reception_type.id">
                                                    {{reception_type.title}} - {{reception_type.description}}
                                                </option>
                                            </select>

                                            <small class="form-text text-muted">Seleziona il modo in cui un paziente potrà prenotare</small>

                                        </div>

                                        <div class="alert alert-danger" v-show="validation.ko">
                                            {{validation.ko}}
                                        </div>

                                        <div class="it-card-footer">
                                            <button
                                            type        = "submit"
                                            class       = "btn btn-primary"
                                            :disabled   = "
                                                $v.form.structure_type.$invalid ||
                                                loaders.structure_type.updating
                                            "
                                            v-if = "
                                                !function_disabled(
                                                    'Strutture',
                                                    'update'
                                                )
                                            ">
                                                    
                                                <span v-show="!loaders.structure_type.updating">
                                                    Modifica
                                                </span>

                                                <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.structure_type.updating">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                                

                                            </button>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li
                                v-if = "
                                    !function_disabled(
                                        'Strutture',
                                        'delete'
                                    )
                                "
                            >
                                <a class="list-item medium text-truncate" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                    
                                    <span class="mr-2">Elimina</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>

                                </a>
                                <div class="collapse bg-danger text-center text-white" id="answer">
                                    <p class="m-0 border-bottom border-white">
                                        <small class="font-weight-bold">
                                            Sicuro?
                                        </small>
                                    </p>
                                    <div class="container-fluid">

                                        <div class="row border-top">
                                            <div class="col-6 text-center border-right border-white cursor-pointer" @click="deleteStructure_type" v-bind:class="{'text-center disabled-AO': loaders.structure_type.deleting}">
                                                <small class="font-weight-bold " v-show="!loaders.structure_type.deleting">
                                                    Si
                                                </small>
                                                <div v-show="loaders.structure_type.deleting" class="progress-spinner progress-spinner-double size-sm progress-spinner-active">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                            </div>
                                            <div class="col-6 text-center cursor-pointer" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                                <small class="font-weight-bold">
                                                    No
                                                </small>
                                            </div>                                        
                                        </div>

                                    </div>
                                </div>
                            </li>
                            <li 
                                v-if = "
                                    !function_disabled(
                                        'Strutture',
                                        'update'
                                    )
                                "
                            >
                                <a @click="suspendStructure_type" class="list-item medium text-truncate" v-bind:class="{'text-center disabled': loaders.structure_type.suspending}">
                                    
                                    <span v-show="!loaders.structure_type.suspending && form.structure_type.state" class="mr-2">Rendi non visibile</span>

                                    <svg v-show="!loaders.structure_type.suspending && form.structure_type.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                    </svg>

                                    <span v-show="!loaders.structure_type.suspending  && !form.structure_type.state" class="mr-2">Rendi visibile</span>

                                    <svg v-show="!loaders.structure_type.suspending  && !form.structure_type.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-2" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                    </svg>

                                    <div v-show="loaders.structure_type.suspending" class="progress-spinner progress-spinner-double size-sm progress-spinner-active mt-1">
                                        <div class="progress-spinner-inner"></div>
                                        <div class="progress-spinner-inner"></div>
                                        <span class="sr-only">Caricamento...</span>
                                    </div>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength
    } from "vuelidate/lib/validators"

    import store from '@/store'

    export default {
        data() {
            return {
                form: {
                    structure_type: {
                        title:             null,
                        description:       null,
                        id_reception_type: null
                    }
                },
                loaders: {
                    structure_type: {
                        updating:   false,
                        reading:    true,
                        deleting:   false,
                        suspending: false
                    },
                    reception_types: {
                        reading: true
                    }
                },
                validation: {
                    ko: false
                },
                DB: {
                    tests:           [],
                    reception_types: []
                }
            }
        },
        validations() {
            return {
                form: {
                    structure_type: {
                        description: {
                            required,
                            minLength: minLength(8),
                            maxLength: maxLength(350)
                        }
                    }

                }
            }
        },
        methods: {
            async updateStructure_type() {

                this.loaders.structure_type.updating = true
                this.validation.ko         = false

                try {

                    const res = await this.axios.post(`user/structure_types/update/single`, this.form.structure_type, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture/tipologia`)

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko         = message
                    this.loaders.structure_type.updating = false

                }
            },
            async readStructure_type() {

                try {

                    const structure_type = await this.axios.get(`user/structure_types/read/detail/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.structure_type = structure_type.data.structure_type

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko         = message
                    this.loaders.structure_type.reading = false

                }
            },
            async deleteStructure_type() {

                this.loaders.structure_type.deleting = true

                try {

                    const res = await this.axios.delete(`user/structure_types/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture/tipologia`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.message,
                        type:   `error`
                    })

                    this.loaders.structure_type.deleting = false

                }

            },
            async suspendStructure_type() {

                this.loaders.structure_type.suspending = true

                try {

                    const res = await this.axios.get(`user/structure_types/suspend/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.form.structure_type.state = !this.form.structure_type.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.message,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.structure_type.suspending = false
                }

            },
            async readReceptionTypes() {

                try {

                    const reception_types = await this.axios.get(`user/reception_types/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.reception_types = reception_types.data.reception_types_db

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                }
                finally {
                    this.loaders.reception_types.reading = false
                }

            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }
        },
        async mounted() {
            await this.readStructure_type()
            this.readReceptionTypes()
        }
    }

</script>