<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">

                <div class="card-wrapper card-space">
                    <div class="card card-bg no-after">
                        <div class="card-body">

                            <form @submit.prevent="updateStructure" autocomplete="off">

                                <div class="row">
                                    <div class="col-lg-6 pr-3">

                                        <div class="form-group">

                                            <label for="title" class="active">Titolo</label>
                                            <input type="text" class="form-control text-uppercase" id="title" :value="form.structure.title" disabled>

                                        </div>
                                        <div class="form-group">

                                            <label for="address" class="active">Indirizzo</label>
                                            <input type="text" class="form-control" id="address" aria-describedby="address" v-model.trim="$v.form.structure.address.$model" v-bind:class="{'is-invalid': (!$v.form.structure.address.required || !$v.form.structure.address.minLength) && $v.form.structure.address.$dirty}">
                                            
                                            <small v-show="!$v.form.structure.address.$dirty || !$v.form.structure.address.$invalid" id="address" class="form-text text-muted">Digitare l'indirizzo della struttura</small>
                                            
                                            <div v-show="!$v.form.structure.address.required && $v.form.structure.address.$dirty" class="invalid-feedback">Indirizzo richiesto</div>
                                            <div v-show="!$v.form.structure.address.minLength && $v.form.structure.address.$dirty" class="invalid-feedback">Digitare minimo 8 caratteri</div>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-lg-4">
                                                <div class="form-group">

                                                    <label for="CAP" class="active">CAP</label>
                                                    <input type="text" class="form-control" id="CAP" maxlength="5" aria-describedby="CAP_help" v-model.trim="$v.form.structure.CAP.$model" v-bind:class="{'is-invalid': (!$v.form.structure.CAP.required || !$v.form.structure.CAP.minLength || !$v.form.structure.CAP.maxLength) && $v.form.structure.CAP.$dirty}">
                                                    
                                                    <small v-show="!$v.form.structure.CAP.$dirty || !$v.form.structure.CAP.$invalid" id="CAP_help" class="form-text text-muted">Digitare CAP struttura</small>
                                                    
                                                    <div v-show="!$v.form.structure.CAP.required && $v.form.structure.CAP.$dirty" class="invalid-feedback">CAP richiesto</div>
                                                    <div v-show="(!$v.form.structure.CAP.minLength || !$v.form.structure.CAP.maxLength) && $v.form.structure.CAP.$dirty" class="invalid-feedback">Digitare 5 caratteri</div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">

                                            <label for="description" class="active">Descrizione</label>
                                            <textarea id="description" class="form-control border" rows="2" v-model.trim="$v.form.structure.description.$model" v-bind:class="{'is-invalid border-danger': !$v.form.structure.description.minLength && $v.form.structure.description.$dirty}"></textarea>
                                            
                                            <small v-show="!$v.form.structure.description.$dirty || !$v.form.structure.description.$invalid" id="title_info" class="form-text text-muted">Fornire una breve descrizione</small>
                                            
                                            <div v-show="!$v.form.structure.description.minLength && $v.form.structure.description.$dirty" class="invalid-feedback">Digitare minimo 8 caratteri</div>

                                        </div>

                                    </div>
                                    <div class="col-lg-6 pl-3">

                                        <h5 class="card-title">Modifica la tipologia di struttura</h5>

                                        <div v-for="structure_type of DB.structure_types" :key="`structure_type_${structure_type.id}`" class="form-check form-check-group">
                                            <input name="structure_type" type="radio" :id="`structure_type_${structure_type.id}`" :aria-labelledby="`structure_type_help_${structure_type.id}`" :value="structure_type.id" v-model="form.structure.id_structure_type">
                                            <label :for="`structure_type_${structure_type.id}`">{{structure_type.title}}</label>
                                            <small :id="`structure_type_help_${structure_type.id}`" class="form-text">{{structure_type.description}}</small>
                                        </div>

                                    </div>
                                </div>

                                <div class="alert alert-warning" role="alert" v-show="!accoglienzaSequenziale">
                                    <strong>Attenzione!</strong> I giorni e gli orari di apertura per la struttura selezionata devono essere importati nel tab in basso "Giorni & orari"
                                </div>

                                <div v-show="accoglienzaSequenziale">

                                    <div class="alert alert-warning alert-dismissible fade show" role="alert">

                                        Imposta i giorni di apertura e degli orari

                                        <button type="button" class="close" data-dismiss="alert" aria-label="Chiudi avviso">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                    </div>

                                    <div class="row">
                                        <div class="col-lg-4 pr-lg-3">

                                            <!-- Lunedi -->
                                            <div class="form-group mb-3">

                                                <div class="form-check">
                                                    <input id="monday_open" type="checkbox" v-model="form.structure.monday_open">
                                                    <label for="monday_open">Lunedi aperto</label>
                                                </div>

                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="monday_h_start" class="active">Apertura mattina</label>
                                                        <input id="monday_h_start" type="time" :disabled="!form.structure.monday_open" v-model.trim="$v.form.structure.monday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.monday_h_start.required && $v.form.structure.monday_h_start.$dirty}">

                                                        <div v-show="!$v.form.structure.monday_h_start.required && $v.form.structure.monday_h_start.$dirty" class="invalid-feedback">Orario Apertura mattina richiesto</div>

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="monday_h_end" class="active">Chiusura mattina</label>
                                                        <input id="monday_h_end" type="time" :disabled="!form.structure.monday_open" v-model.trim="$v.form.structure.monday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.monday_h_end.required && $v.form.structure.monday_h_end.$dirty}">

                                                        <div v-show="!$v.form.structure.monday_h_end.required && $v.form.structure.monday_h_end.$dirty" class="invalid-feedback">Orario Chiusura mattina richiesto</div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="monday_h_start_aft" class="active">Apertura pomeriggio</label>
                                                        <input id="monday_h_start_aft" type="time" :disabled="!form.structure.monday_open" v-model="form.structure.monday_h_start_aft">

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="monday_h_end_aft" class="active">Chiusura pomeriggio</label>
                                                        <input id="monday_h_end_aft" type="time" :disabled="!form.structure.monday_open" v-model="form.structure.monday_h_end_aft">

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 pr-lg-3 pl-lg-3">

                                            <!-- Martedi -->
                                            <div class="form-group mb-3">

                                                <div class="form-check">
                                                    <input id="tuesday_open" type="checkbox" v-model="form.structure.tuesday_open">
                                                    <label for="tuesday_open">Martedi aperto</label>
                                                </div>

                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="tuesday_h_start" class="active">Apertura mattina</label>
                                                        <input id="tuesday_h_start" type="time" :disabled="!form.structure.tuesday_open" v-model.trim="$v.form.structure.tuesday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.tuesday_h_start.required && $v.form.structure.tuesday_h_start.$dirty}">

                                                        <div v-show="!$v.form.structure.tuesday_h_start.required && $v.form.structure.tuesday_h_start.$dirty" class="invalid-feedback">Orario Apertura mattina richiesto</div>

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="tuesday_h_end" class="active">Chiusura mattina</label>
                                                        <input id="tuesday_h_end" type="time" :disabled="!form.structure.tuesday_open" v-model.trim="$v.form.structure.tuesday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.tuesday_h_end.required && $v.form.structure.tuesday_h_end.$dirty}">

                                                        <div v-show="!$v.form.structure.tuesday_h_end.required && $v.form.structure.tuesday_h_end.$dirty" class="invalid-feedback">Orario Chiusura mattina richiesto</div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="tuesday_h_start_aft" class="active">Apertura pomeriggio</label>
                                                        <input id="tuesday_h_start_aft" type="time" :disabled="!form.structure.tuesday_open" v-model="form.structure.tuesday_h_start_aft">

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="tuesday_h_end_aft" class="active">Chiusura pomeriggio</label>
                                                        <input id="tuesday_h_end_aft" type="time" :disabled="!form.structure.tuesday_open" v-model="form.structure.tuesday_h_end_aft">

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 pl-lg-3">

                                            <!-- Mercoledi -->
                                            <div class="form-group mb-lg-3">

                                                <div class="form-check">
                                                    <input id="wednesday_open" type="checkbox" v-model="form.structure.wednesday_open">
                                                    <label for="wednesday_open">Mercoledi aperto</label>
                                                </div>

                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="wednesday_h_start" class="active">Apertura mattina</label>
                                                        <input id="wednesday_h_start" type="time" :disabled="!form.structure.wednesday_open" v-model.trim="$v.form.structure.wednesday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.wednesday_h_start.required && $v.form.structure.wednesday_h_start.$dirty}">

                                                        <div v-show="!$v.form.structure.wednesday_h_start.required && $v.form.structure.wednesday_h_start.$dirty" class="invalid-feedback">Orario Apertura mattina richiesto</div>

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="wednesday_h_end" class="active">Chiusura mattina</label>
                                                        <input id="wednesday_h_end" type="time" :disabled="!form.structure.wednesday_open" v-model.trim="$v.form.structure.wednesday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.wednesday_h_end.required && $v.form.structure.wednesday_h_end.$dirty}">

                                                        <div v-show="!$v.form.structure.wednesday_h_end.required && $v.form.structure.wednesday_h_end.$dirty" class="invalid-feedback">Orario Chiusura mattina richiesto</div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="wednesday_h_start_aft" class="active">Apertura pomeriggio</label>
                                                        <input id="wednesday_h_start_aft" type="time" :disabled="!form.structure.wednesday_open" v-model="form.structure.wednesday_h_start_aft">

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="wednesday_h_end_aft" class="active">Chiusura pomeriggio</label>
                                                        <input id="wednesday_h_end_aft" type="time" :disabled="!form.structure.wednesday_open" v-model="form.structure.wednesday_h_end_aft">

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 pr-lg-3">

                                            <!-- Giovedi -->
                                            <div class="form-group mb-lg-3">

                                                <div class="form-check">
                                                    <input id="thursday_open" type="checkbox" v-model="form.structure.thursday_open">
                                                    <label for="thursday_open">Giovedi aperto</label>
                                                </div>

                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="thursday_h_start" class="active">Apertura mattina</label>
                                                        <input id="thursday_h_start" type="time" :disabled="!form.structure.thursday_open" v-model.trim="$v.form.structure.thursday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.thursday_h_start.required && $v.form.structure.thursday_h_start.$dirty}">

                                                        <div v-show="!$v.form.structure.thursday_h_start.required && $v.form.structure.thursday_h_start.$dirty" class="invalid-feedback">Orario Apertura mattina richiesto</div>

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="thursday_h_end" class="active">Chiusura mattina</label>
                                                        <input id="thursday_h_end" type="time" :disabled="!form.structure.thursday_open" v-model.trim="$v.form.structure.thursday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.thursday_h_end.required && $v.form.structure.thursday_h_end.$dirty}">

                                                        <div v-show="!$v.form.structure.thursday_h_end.required && $v.form.structure.thursday_h_end.$dirty" class="invalid-feedback">Orario Chiusura mattina richiesto</div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="thursday_h_start_aft" class="active">Apertura pomeriggio</label>
                                                        <input id="thursday_h_start_aft" type="time" :disabled="!form.structure.thursday_open" v-model="form.structure.thursday_h_start_aft">

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="thursday_h_end_aft" class="active">Chiusura pomeriggio</label>
                                                        <input id="thursday_h_end_aft" type="time" :disabled="!form.structure.thursday_open" v-model="form.structure.thursday_h_end_aft">

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 pr-lg-3 pl-lg-3">

                                            <!-- Venerdi -->
                                            <div class="form-group mb-3">

                                                <div class="form-check">
                                                    <input id="friday_open" type="checkbox" v-model="form.structure.friday_open">
                                                    <label for="friday_open">Venerdi aperto</label>
                                                </div>

                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="friday_h_start" class="active">Apertura mattina</label>
                                                        <input id="friday_h_start" type="time" :disabled="!form.structure.friday_open" v-model.trim="$v.form.structure.friday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.friday_h_start.required && $v.form.structure.friday_h_start.$dirty}">

                                                        <div v-show="!$v.form.structure.friday_h_start.required && $v.form.structure.friday_h_start.$dirty" class="invalid-feedback">Orario Apertura mattina richiesto</div>

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="friday_h_end" class="active">Chiusura mattina</label>
                                                        <input id="friday_h_end" type="time" :disabled="!form.structure.friday_open" v-model.trim="$v.form.structure.friday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.friday_h_end.required && $v.form.structure.friday_h_end.$dirty}">

                                                        <div v-show="!$v.form.structure.friday_h_end.required && $v.form.structure.friday_h_end.$dirty" class="invalid-feedback">Orario Chiusura mattina richiesto</div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="friday_h_start_aft" class="active">Apertura pomeriggio</label>
                                                        <input id="friday_h_start_aft" type="time" :disabled="!form.structure.friday_open" v-model="form.structure.friday_h_start_aft">

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="friday_h_end_aft" class="active">Chiusura pomeriggio</label>
                                                        <input id="friday_h_end_aft" type="time" :disabled="!form.structure.friday_open" v-model="form.structure.friday_h_end_aft">

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 pl-lg-3">

                                            <!-- Sabato -->
                                            <div class="form-group mb-3">

                                                <div class="form-check">
                                                    <input id="saturday_open" type="checkbox" v-model="form.structure.saturday_open">
                                                    <label for="saturday_open">Sabato aperto</label>
                                                </div>

                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="saturday_h_start" class="active">Apertura mattina</label>
                                                        <input id="saturday_h_start" type="time" :disabled="!form.structure.saturday_open" v-model.trim="$v.form.structure.saturday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.saturday_h_start.required && $v.form.structure.saturday_h_start.$dirty}">

                                                        <div v-show="!$v.form.structure.saturday_h_start.required && $v.form.structure.saturday_h_start.$dirty" class="invalid-feedback">Orario Apertura mattina richiesto</div>

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="saturday_h_end" class="active">Chiusura mattina</label>
                                                        <input id="saturday_h_end" type="time" :disabled="!form.structure.saturday_open" v-model.trim="$v.form.structure.saturday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.saturday_h_end.required && $v.form.structure.saturday_h_end.$dirty}">

                                                        <div v-show="!$v.form.structure.saturday_h_end.required && $v.form.structure.saturday_h_end.$dirty" class="invalid-feedback">Orario Chiusura mattina richiesto</div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="saturday_h_start_aft" class="active">Apertura pomeriggio</label>
                                                        <input id="saturday_h_start_aft" type="time" :disabled="!form.structure.saturday_open" v-model="form.structure.saturday_h_start_aft">

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="saturday_h_end_aft" class="active">Chiusura pomeriggio</label>
                                                        <input id="saturday_h_end_aft" type="time" :disabled="!form.structure.saturday_open" v-model="form.structure.saturday_h_end_aft">

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4">

                                            <!-- Domenica -->
                                            <div class="form-group mb-3">

                                                <div class="form-check">
                                                    <input id="sunday_open" type="checkbox" v-model="form.structure.sunday_open">
                                                    <label for="sunday_open">Domenica aperto</label>
                                                </div>

                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-2">

                                                        <label for="sunday_h_start" class="active">Apertura mattina</label>
                                                        <input id="sunday_h_start" type="time" :disabled="!form.structure.sunday_open" v-model.trim="$v.form.structure.sunday_h_start.$model" v-bind:class="{'is-invalid': !$v.form.structure.sunday_h_start.required && $v.form.structure.sunday_h_start.$dirty}">

                                                        <div v-show="!$v.form.structure.sunday_h_start.required && $v.form.structure.sunday_h_start.$dirty" class="invalid-feedback">Orario Apertura mattina richiesto</div>

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-2">

                                                        <label for="sunday_h_end" class="active">Chiusura mattina</label>
                                                        <input id="sunday_h_end" type="time" :disabled="!form.structure.sunday_open" v-model.trim="$v.form.structure.sunday_h_end.$model" v-bind:class="{'is-invalid': !$v.form.structure.sunday_h_end.required && $v.form.structure.sunday_h_end.$dirty}">

                                                        <div v-show="!$v.form.structure.sunday_h_end.required && $v.form.structure.sunday_h_end.$dirty" class="invalid-feedback">Orario chiusura richiesto</div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">

                                                    <div class="form-group mb-lg-3">

                                                        <label for="sunday_h_start_aft" class="active">Apertura pomeriggio</label>
                                                        <input id="sunday_h_start_aft" type="time" :disabled="!form.structure.sunday_open" v-model="form.structure.sunday_h_start_aft">

                                                    </div>

                                                </div>
                                                <div class="col-lg-6">
                                                    
                                                    <div class="form-group mb-lg-3">

                                                        <label for="sunday_h_end_aft" class="active">Chiusura pomeriggio</label>
                                                        <input id="sunday_h_end_aft" type="time" :disabled="!form.structure.sunday_open" v-model="form.structure.sunday_h_end_aft" >

                                                    </div>

                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                </div>

                                <div class="alert alert-danger" v-show="validation.ko">
                                    {{validation.ko}}
                                </div>

                                <div class="it-card-footer mt-3">

                                    <button
                                    type        = "submit"
                                    class       = "btn btn-primary"
                                    :disabled   = "
                                        $v.form.structure.$invalid ||
                                        loaders.updating.structure
                                    "
                                    v-if = "
                                        !function_disabled(
                                            'Strutture',
                                            'update'
                                        )
                                    ">
                                        
                                        <span v-show="!loaders.updating.structure">
                                            Modifica
                                        </span>

                                        <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.updating.structure">
                                            <div class="progress-spinner-inner"></div>
                                            <div class="progress-spinner-inner"></div>
                                            <span class="sr-only">Caricamento...</span>
                                        </div>

                                    </button>

                                </div>

                            </form>

                        </div>
                    </div>
                </div>

                <div class="card-wrapper card-space" v-if = "
                    !function_disabled(
                        'Strutture',
                        'update'
                    )
                ">
                    <div class="card card-bg no-after">
                        <div class="card-body">

                            <ul class="nav nav-tabs" id="tabs" role="tablist">
                                <li class="nav-item" v-show="!accoglienzaSequenziale">
                                    <a
                                        class           = "nav-link"
                                        id              = "tab_days_times-padre"
                                        data-toggle     = "tab"
                                        href            = "#tab_days_times"
                                        role            = "tab"
                                        aria-controls   = "tab_days_times"
                                        aria-selected   = "false"
                                    >
                                        Giorni & orari
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class           = "nav-link active"
                                        id              = "test_associati-padre"
                                        data-toggle     = "tab"
                                        href            = "#structures_tests"
                                        role            = "tab"
                                        aria-controls   = "structures_tests"
                                        aria-selected   = "true"
                                >   
                                        Test associati
                                    </a>
                                </li>
                            </ul>

                            <div class="tab-content" id="tab_tests">

                                <div
                                    class           = "tab-pane fade show"
                                    id              = "tab_days_times"
                                    role            = "tabpanel"
                                    aria-labelledby = "tab_days_times-padre"
                                    v-show          = "!accoglienzaSequenziale"
                                >
                                    
                                    <div class="row">
                                        <div class="col-lg-10 pt-2 pb-2 h-max-300-px overflow-auto">

                                            <div class="alert alert-success" v-show="validation.structure_pianification_days.updating.ok">
                                                {{validation.structure_pianification_days.updating.ok}}, <b>ricarica la pagina</b> per visualizzarli!
                                            </div>
                                            <div class="alert alert-danger" v-show="validation.structure_pianification_days.updating.ko">
                                                {{validation.structure_pianification_days.updating.ko}}
                                            </div>

                                            <div v-if="form.structure.structure_pianification_days.length > 0">

                                                <table class="table table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Data</th>
                                                            <th scope="col">Orario</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="structure_pianification_day of form.structure.structure_pianification_days" :key="`structure_test_${structure_pianification_day.id}`">
                                                            <td>{{structure_pianification_day.date | data_slash}}</td>
                                                            <td>{{structure_pianification_day.time | data_ora}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                            <div v-else>
                                                <div class="alert alert-danger" v-show="!validation.structure_pianification_days.updating.ok">
                                                    Non sono presenti giorni e orari, clicca su importa xlsx per aggiungerne
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="sidebar-wrapper">
                                                <div class="sidebar-linklist-wrapper">
                                                    <div class="link-list-wrapper">
                                                        <ul class="link-list text-lg-right">
                                                            <li>
                                                                <h3 class="text-truncate pr-0">Funzioni</h3>
                                                            </li>
                                                            <li>
                                                                <label for="import_file" class="w-100 c-084f9b font-weight-bold cursor-pointer" v-bind:class="{'disabled': loaders.updating.structure_pianification_days}">
                                                                    
                                                                    <span class="mr-2">Importa xlsx</span>
                                                                    <input type="file" id="import_file" class="sr-only" accept=".xlsx" @change="importDateTimes">

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-xls mb-1" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"/>
                                                                    </svg>

                                                                    <div class="f-s-0_6-em" v-show="loaders.updating.structure_pianification_days">

                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-arrow-repeat rotating" viewBox="0 0 16 16">
                                                                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                                                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                                                        </svg> Caricamento..
                                                                        
                                                                    </div>

                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div
                                    class           = "tab-pane fade show active"
                                    id              = "structures_tests"
                                    role            = "tabpanel"
                                    aria-labelledby = "test_associati-padre"
                                >
                                    
                                    <div class="row">
                                        <div class="col-lg-10 pt-2 pb-2">

                                            <div v-if="form.structure.structures_tests.length > 0">

                                                <table class="table table-striped table-hover table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Titolo</th>
                                                            <!--<th scope="col">Durata appuntamento</th>-->
                                                            <th scope="col">Limite appuntamenti fissabili</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <router-link v-for="structure_test of form.structure.structures_tests" :key="`structure_test_${structure_test.test.id}`" tag="tr" :to="`/admin/strutture/${$route.params.title}/test/modifica/${structure_test.id}`">
                                                            <td>{{structure_test.test.title}}</td>
                                                            <!--<td>{{structure_test.appointment_duration}} minuti</td>-->
                                                            <td>{{structure_test.appointment_fixs ? structure_test.appointment_fixs : 'Nessun limite impostato'}}</td>
                                                        </router-link>
                                                    </tbody>
                                                </table>

                                            </div>
                                            <div v-else>
                                                <div class="alert alert-danger">
                                                    Non sono presenti test associati, clicca su aggiungi per crearne uno
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="sidebar-wrapper">
                                                <div class="sidebar-linklist-wrapper">
                                                    <div class="link-list-wrapper">
                                                        <ul class="link-list text-lg-right">
                                                            <li>
                                                                <h3 class="text-truncate pr-0">Funzioni</h3>
                                                            </li>
                                                            <li>
                                                                <router-link :to="`/admin/strutture/${$route.params.title}/test/aggiungi`" class="pr-0">

                                                                    <span class="mr-2">Aggiungi</span>

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle mb-1" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                                    </svg>
                                                                    
                                                                </router-link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li
                                v-if = "
                                    !function_disabled(
                                        'Strutture',
                                        'delete'
                                    )
                                "
                            >
                                <a class="list-item medium text-truncate" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                    
                                    <span class="mr-2">Elimina</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>

                                </a>
                                <div class="collapse bg-danger text-center text-white" id="answer">
                                    <p class="m-0 border-bottom border-white">
                                        <small class="font-weight-bold">
                                            Sicuro?
                                        </small>
                                    </p>
                                    <div class="container-fluid">

                                        <div class="row border-top">
                                            <div class="col-6 text-center border-right border-white cursor-pointer" @click="deleteStructure" v-bind:class="{'text-center disabled-AO': loaders.deleting.structure}">
                                                <small class="font-weight-bold " v-show="!loaders.deleting.structure">
                                                    Si
                                                </small>
                                                <div v-show="loaders.deleting.structure" class="progress-spinner progress-spinner-double size-sm progress-spinner-active">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                            </div>
                                            <div class="col-6 text-center cursor-pointer" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                                <small class="font-weight-bold">
                                                    No
                                                </small>
                                            </div>                                        
                                        </div>

                                    </div>
                                </div>
                            </li>
                            <li
                                v-if = "
                                    !function_disabled(
                                        'Strutture',
                                        'update'
                                    )
                                "
                            >
                                <a @click="suspendStructure" class="list-item medium text-truncate" v-bind:class="{'text-center disabled': loaders.suspending.structure}">
                                    
                                    <span v-show="!loaders.suspending.structure && form.structure.state" class="mr-2">Rendi non visibile</span>

                                    <svg v-show="!loaders.suspending.structure && form.structure.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                    </svg>

                                    <span v-show="!loaders.suspending.structure  && !form.structure.state" class="mr-2">Rendi visibile</span>

                                    <svg v-show="!loaders.suspending.structure  && !form.structure.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-2" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                    </svg>

                                    <div v-show="loaders.suspending.structure" class="progress-spinner progress-spinner-double size-sm progress-spinner-active mt-1">
                                        <div class="progress-spinner-inner"></div>
                                        <div class="progress-spinner-inner"></div>
                                        <span class="sr-only">Caricamento...</span>
                                    </div>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength,
        requiredIf
    } from "vuelidate/lib/validators"

    import store from '@/store'

    import * as XLSX   from 'xlsx'

    export default {
        data() {
            return {
                DB: {
                    tests:           [],
                    structure_types: []
                },
                form: {
                    structure: {
                        title:                 null,
                        address:               null,
                        CAP:                   null,
                        description:           null,
                        id_structure_type:     null,
                        monday_open:           false,
                        monday_h_start:        "",
                        monday_h_start_aft:    "",
                        monday_h_end:          "",
                        monday_h_end_aft:      "",
                        tuesday_open:          false,
                        tuesday_h_start:       "",
                        tuesday_h_start_aft:   "",
                        tuesday_h_end:         "",
                        tuesday_h_end_aft:     "",
                        wednesday_open:        false,
                        wednesday_h_start:     "",
                        wednesday_h_start_aft: "",
                        wednesday_h_end:       "",
                        wednesday_h_end_aft:   "",
                        thursday_open:         false,
                        thursday_h_start:      "",
                        thursday_h_start_aft:  "",
                        thursday_h_end:        "",
                        thursday_h_end_aft:    "",
                        friday_open:           false,
                        friday_h_start:        "",
                        friday_h_start_aft:    "",
                        friday_h_end:          "",
                        friday_h_end_aft:      "",
                        saturday_open:         false,
                        saturday_h_start:      "",
                        saturday_h_start_aft:  "",
                        saturday_h_end:        "",
                        saturday_h_end_aft:    "",
                        sunday_open:           false,
                        sunday_h_start:        "",
                        sunday_h_start_aft:    "",
                        sunday_h_end:          "",
                        sunday_h_end_aft:      "",
                        structures_tests:      []
                    }
                },
                loaders: {
                    updating: {
                        structure:       false,
                        structure_tests: false,
                        structure_pianification_days: false
                    },
                    deleting: {
                        structure_tests: false,
                        structure: false
                    },
                    suspending: {
                        structure: false
                    }
                },
                validation: {
                    ko: false,
                    structure_pianification_days: {
                        updating: {
                            ok: false,
                            ko: false
                        }
                    }
                }
            }
        },
        validations() {
            return {
                form: {
                    structure: {
                        title: {
                            required,
                            minLength: minLength(3)
                        },
                        description: {
                            minLength: minLength(8),
                            maxLength: maxLength(350)
                        },
                        address: {
                            required,
                            minLength: minLength(8)
                        },
                        CAP: {
                            required,
                            minLength: minLength(5),
                            maxLength: maxLength(5)
                        },
                        id_structure_type: {
                            required
                        },
                        monday_h_start: {
                            required: requiredIf(
                                () => {
                                    return true
                                }
                            )
                        },
                        monday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.monday_open
                                }
                            )
                        },
                        tuesday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.tuesday_open
                                }
                            )
                        },
                        tuesday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.tuesday_open
                                }
                            )
                        },
                        wednesday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.wednesday_open
                                }
                            )
                        },
                        wednesday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.wednesday_open
                                }
                            )
                        },
                        thursday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.thursday_open
                                }
                            )
                        },
                        thursday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.thursday_open
                                }
                            )
                        },
                        friday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.friday_open
                                }
                            )
                        },
                        friday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.friday_open
                                }
                            )
                        },
                        saturday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.saturday_open
                                }
                            )
                        },
                        saturday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.saturday_open
                                }
                            )
                        },
                        sunday_h_start: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.sunday_open
                                }
                            )
                        },
                        sunday_h_end: {
                            required: requiredIf(
                                () => {
                                    return this.form.structure.sunday_open
                                }
                            )
                        }
                    }
                }
            }
        },
        methods: {

            async readStructure() {

                try {

                    const structure = await this.axios.get(`user/structures/read/detail/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.structure = structure.data.structure

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko = message

                }

            },
            async updateStructure() {

                this.loaders.updating.structure = true
                this.validation.ko              = false

                // Ricerca id tipologia struttura dato che vue non permette passaggio di piu parametri da URL
                const title_URL = this.$route.params.title
                this.form.structure.id_structure_type_old = title_URL.substring(title_URL.indexOf('&') + 1)

                try {

                    const res = await this.axios.post(`user/structures/update/single`, this.form.structure, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture`)

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko              = message
                    this.loaders.updating.structure = false

                }
                
            },
            async deleteStructure() {

                this.loaders.deleting.structure = true

                try {

                    const res = await this.axios.delete(`user/structures/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.message,
                        type:   `error`
                    })

                    this.loaders.deleting.structure = false

                }

            },
            async suspendStructure() {

                this.loaders.suspending.structure = true

                try {

                    const res = await this.axios.get(`user/structures/suspend/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.form.structure.state = !this.form.structure.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.message,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.suspending.structure = false
                }

            },
            async readStructureTypes() {
                try {

                    const structureTypes = await this.axios.get(`user/structure_types/read/actives`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.structure_types = structureTypes.data.structure_types_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le strutture. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            async readTests() {
                try {

                    const tests = await this.axios.get(`user/tests/read/actives`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.tests = tests.data.tests_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i tests. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            async importDateTimes(event) {

                // File selezionato dal dispositivo
                const file = event.target.files[0]
                // Mostriamo il nome del file nella view
                // this.functions.import_xls.name_file = file.name
                // Richiamo del File reader
                const fr = new FileReader()
                // Variabile per le strutture
                //let strutture = {}
                // Dopo l'inizializzazione
                fr.onloadend = async e => {

                    this.validation.structure_pianification_days.updating.ok = false
                    this.loaders.updating.structure_pianification_days = true

                    let data      = new Uint8Array(e.target.result)
                    let workbook  = XLSX.read(data, {type: 'array', cellDates: true})

                    if(workbook.SheetNames.length > 1) {
                        this.$toast.open({
                            message: `Ci sono troppi fogli/strutture nell'excel caricato`,
                            type:    `error`
                        })
                    }
                    else {

                        const foglio       = workbook.SheetNames[0]
                        const appointments = XLSX.utils.sheet_to_json(workbook.Sheets[foglio])
                        
                        const {
                            title
                        } = this.form.structure

                        // Ricerca id tipologia struttura dato che vue non permette passaggio di piu parametri da URL
                        const title_URL = this.$route.params.title
                        const id_structure_type = title_URL.substring(title_URL.indexOf('&') + 1)

                        try {

                            const res = await this.axios.post(`user/structure_pianification_days/create/many/from_xlsx`, {appointments, title, id_structure_type}, {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            })

                            const {
                                message
                            } = res.data

                            this.$toast.open({
                                message,
                                type: `success`
                            })

                            this.validation.structure_pianification_days.updating.ok = message
                            
                        }
                        catch(e) {

                            if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                                this.$toast.open({
                                    message: `La tua sessione è scaduta, effettua nuovamente il login`,
                                    type:    `error`
                                })

                                this.$router.push('/sessionescaduta')
                            }
                            else {

                                this.$toast.open({
                                    message: `Non è momentaneamente possibile caricare gli appuntamenti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                                    type:    `error`
                                })

                                this.validation.structure_pianification_days.updating.ko = `Non è momentaneamente possibile caricare gli appuntamenti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`

                            }

                        }
                        finally {
                            this.loaders.updating.structure_pianification_days = false
                            // Reset del campo input
                            document.getElementById('import_file').value = ''
                        }

                    }

                    /*for(let struttura of workbook.SheetNames) {

                        const appuntamenti_caricati = XLSX.utils.sheet_to_json(workbook.Sheets[struttura])

                        strutture[struttura] = []

                        for(const appuntamento of appuntamenti_caricati) {
                            strutture[struttura].push(appuntamento)
                        }

                    }

                    this.functions.import_xls.structures = strutture

                    try {

                        await this.axios.post(`user/appointments/create/from_json`, strutture, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                        
                    }
                    catch(e) {
                        if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                            this.$toast.open({
                                message: `La tua sessione è scaduta, effettua nuovamente il login`,
                                type:    `error`
                            })

                            this.$router.push('/sessionescaduta')
                        }
                        /*else {

                            this.$toast.open({
                                message: `Non è momentaneamente possibile scaricare gli appuntamenti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                                type:    `error`
                            })

                        }*/

                    //}

                }

                fr.readAsArrayBuffer(file)

            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }

        },
        mounted() {
            this.readTests()
            this.readStructureTypes()
            this.readStructure()
        },
        computed: {
            unselectedTests() {

                // Paragoniamo le differenze e ritorniamo solo ciò che è diverso
                return this.DB.tests.filter(
                    test_db => !this.form.structure.structures_tests.some(
                        test_checked => test_db.id == test_checked.test.id
                    )
                )
            },
            accoglienzaSequenziale() {
                return this.DB.structure_types.find(
                    structure_type => structure_type.id == this.form.structure.id_structure_type && structure_type.id_reception_type == 1
                )
            }
        }
    }

</script>