<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">

                <div class="row">
                    <div class="col-lg-6 offset-lg-3 mb-2">

                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <form @submit.prevent="updateScreening" autocomplete="off">

                                        <div class="form-group">

                                            <label for="title" class="active">Titolo</label>
                                            <input type="text" class="form-control text-uppercase" id="title" aria-describedby="title_info" v-model="form.screening.title" disabled>
                                            <small id="title_info" class="form-text text-muted">Il titolo non può essere modificato</small>
                                        
                                        </div>
                                        <div class="form-group">

                                            <label for="description" class="active">Descrizione</label>
                                            <textarea id="description" class="form-control border" rows="3" v-model.trim="$v.form.screening.description.$model" v-bind:class="{'is-invalid border-danger': (!$v.form.screening.description.required || !$v.form.screening.description.minLength) && $v.form.screening.description.$dirty}"></textarea>
                                            
                                            <small v-show="!$v.form.screening.description.$dirty || !$v.form.screening.description.$invalid" id="title_info" class="form-text text-muted">Fornire una breve descrizione</small>
                                            
                                            <div v-show="!$v.form.screening.description.required && $v.form.screening.description.$dirty" class="invalid-feedback">Descrizione richiesta</div>
                                            <div v-show="!$v.form.screening.description.minLength && $v.form.screening.description.$dirty" class="invalid-feedback">Digitare minimo 8 caratteri</div>

                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">

                                                <label for="from_age" class="active">Età inizio copertura</label>
                                                <input type="number" class="form-control" id="from_age" min="1" aria-describedby="from_age" v-model.trim="$v.form.screening.from_age.$model" v-bind:class="{'is-invalid': (!$v.form.screening.from_age.required || !$v.form.screening.from_age.numeric || !$v.form.screening.from_age.minValue) && $v.form.screening.from_age.$dirty}">

                                                <small v-show="!$v.form.screening.from_age.$dirty || !$v.form.screening.from_age.$invalid" id="title_info" class="form-text text-muted">Specificare età paziente</small>

                                                <div v-show="!$v.form.screening.from_age.required && $v.form.screening.from_age.$dirty" class="invalid-feedback">Età richiesta</div>
                                                <div v-show="!$v.form.screening.from_age.numeric && $v.form.screening.from_age.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                                <div v-show="!$v.form.screening.from_age.minValue && $v.form.screening.from_age.$dirty" class="invalid-feedback">Età minima di 1 anno</div>

                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="to_age" class="active">Età fine copertura</label>
                                                <input type="number" class="form-control" id="to_age" :min="form.screening.from_age" :disabled="!$v.form.screening.from_age.required || !$v.form.screening.from_age.numeric || !$v.form.screening.from_age.minValue" aria-describedby="to_age" v-model.trim="$v.form.screening.to_age.$model" v-bind:class="{'is-invalid': (!$v.form.screening.to_age.required || !$v.form.screening.to_age.numeric || !$v.form.screening.to_age.minValue) && $v.form.screening.to_age.$dirty}">

                                                <small v-show="!$v.form.screening.to_age.$dirty || !$v.form.screening.to_age.$invalid" id="title_info" class="form-text text-muted">Specificare età paziente</small>

                                                <div v-show="!$v.form.screening.to_age.required && $v.form.screening.to_age.$dirty" class="invalid-feedback">Età richiesta</div>
                                                <div v-show="!$v.form.screening.to_age.numeric && $v.form.screening.to_age.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                                <div v-show="!$v.form.screening.to_age.minValue && $v.form.screening.to_age.$dirty" class="invalid-feedback">Età minima di {{form.screening.from_age}} anni</div>
                                            </div>
                                        </div>
                                        <div class="form-check">
                                            <input id="for_man" type="checkbox" v-model.trim="$v.form.screening.for_man.$model">
                                            <label for="for_man" v-bind:class="{'text-danger': !$v.form.screening.for_man.required}">Screening dedicato all'uomo</label>
                                        </div>
                                        <div class="form-check">
                                            <input id="for_woman" type="checkbox" v-model.trim="$v.form.screening.for_woman.$model">
                                            <label for="for_woman" v-bind:class="{'text-danger': !$v.form.screening.for_woman.required}">Screening dedicato alla donna</label>
                                        </div>

                                        <div v-show="!$v.form.screening.for_man.required || !$v.form.screening.for_woman.required" class="alert alert-danger">
                                            Selezionare almeno un sesso
                                        </div>

                                        <div class="form-group mt-4">

                                            <label for="color" class="active">Colore</label>
                                            <input type="color" class="form-control border-bottom" id="color" aria-describedby="color" v-model="form.screening.color">
                                            <small id="title_info" class="form-text text-muted">Il colore dovrà essere univoco</small>
                                        
                                        </div>

                                        <div class="alert alert-danger" v-show="validation.ko">
                                            {{validation.ko}}
                                        </div>

                                        <button
                                            type        = "submit"
                                            class       = "btn btn-primary"
                                            :disabled   = "
                                                $v.form.screening.$invalid ||
                                                loaders.screening.updating
                                            "
                                            v-if = "
                                                !function_disabled(
                                                    'Screenings',
                                                    'update'
                                                )
                                            "
                                        >
                                                
                                            <span v-show="!loaders.screening.updating">
                                                Modifica
                                            </span>

                                            <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.screening.updating">
                                                <div class="progress-spinner-inner"></div>
                                                <div class="progress-spinner-inner"></div>
                                                <span class="sr-only">Caricamento...</span>
                                            </div>
                                            

                                        </button>

                                    </form>

                                    <p class="font-weight-bold mt-4 mb-0">Modifica i test associati allo screening</p>
                                    <p>
                                        <small class="font-weight-bold text-danger text-underscored">
                                            <u>
                                                Le modifiche saranno istantanee
                                            </u>
                                        </small>
                                    </p>

                                    <div v-for="test_on of form.screening.screenings_tests" :key="test_on.test.id" class="form-check form-check-group">
                                        <input 
                                            :id                 = "test_on.test.title" 
                                            type                = "checkbox" 
                                            :aria-labelledby    = "`${test_on.test.title}-help`"
                                            :disabled            = "
                                                function_disabled(
                                                    'Screenings',
                                                    'update'
                                                ) ||
                                                loaders.screening.updating
                                            "
                                            @change             = "!function_disabled(
                                                'Screenings',
                                                'update'
                                            ) ? updateScreeningTest(
                                                $event,
                                                test_on.test
                                            ) : $toast.open({
                                                message:    `Funzione non abilitata`,
                                                type:       `warning`
                                            })" 
                                            checked 
                                        >
                                        <label :for="test_on.test.title">
                                            {{test_on.test.title}}
                                        </label>
                                        <small :id="`${test_on.test.title}-help`" class="form-text">
                                            {{test_on.test.description}}
                                        </small>
                                    </div>

                                    <div v-for="test of DB.tests" :key="test.id" class="form-check form-check-group">
                                        <input
                                            :id                 = "test.title"
                                            type                = "checkbox"
                                            :aria-labelledby    = "`${test.title}-help`"
                                            :disabled            = "
                                                function_disabled(
                                                    'Screenings',
                                                    'update'
                                                ) ||
                                                loaders.screening.updating
                                            "
                                            @change             = "!function_disabled(
                                                'Screenings',
                                                'update'
                                            ) ? updateScreeningTest(
                                                $event,
                                                test
                                            ) : $toast.open({
                                                message:    `Funzione non abilitata`,
                                                type:       `warning`
                                            })"
                                        >
                                        <label :for="test.title">{{test.title}}</label>
                                        <small :id="`${test.title}-help`" class="form-text">{{test.description}}</small>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <!--<li
                                v-if = "
                                    !function_disabled(
                                        'Screenings',
                                        'delete'
                                    )
                                "
                            >
                                <a class="list-item medium text-truncate" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                    
                                    <span class="mr-2">Elimina</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>

                                </a>
                                <div class="collapse bg-danger text-center text-white" id="answer">
                                    <p class="m-0 border-bottom border-white">
                                        <small class="font-weight-bold">
                                            Sicuro?
                                        </small>
                                    </p>
                                    <div class="container-fluid">

                                        <div class="row border-top">
                                            <div class="col-6 text-center border-right border-white cursor-pointer" @click="deleteScreening" v-bind:class="{'text-center disabled-AO': loaders.screening.deleting}">
                                                <small class="font-weight-bold " v-show="!loaders.screening.deleting">
                                                    Si
                                                </small>
                                                <div v-show="loaders.screening.deleting" class="progress-spinner progress-spinner-double size-sm progress-spinner-active">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                            </div>
                                            <div class="col-6 text-center cursor-pointer" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                                <small class="font-weight-bold">
                                                    No
                                                </small>
                                            </div>                                        
                                        </div>

                                    </div>
                                </div>
                            </li>-->
                            <li
                                v-if = "
                                    !function_disabled(
                                        'Screenings',
                                        'update'
                                    )
                                "
                            >
                                <a @click="suspendScreening" class="list-item medium text-truncate" v-bind:class="{'text-center disabled': loaders.screening.suspending}">
                                    
                                    <span v-show="!loaders.screening.suspending && form.screening.state" class="mr-2">Rendi non visibile</span>

                                    <svg v-show="!loaders.screening.suspending && form.screening.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                    </svg>

                                    <span v-show="!loaders.screening.suspending  && !form.screening.state" class="mr-2">Rendi visibile</span>

                                    <svg v-show="!loaders.screening.suspending  && !form.screening.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-2" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                    </svg>

                                    <div v-show="loaders.screening.suspending" class="progress-spinner progress-spinner-double size-sm progress-spinner-active mt-1">
                                        <div class="progress-spinner-inner"></div>
                                        <div class="progress-spinner-inner"></div>
                                        <span class="sr-only">Caricamento...</span>
                                    </div>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength,
        numeric,
        minValue
    } from "vuelidate/lib/validators"

    import store from '@/store'

    export default {
        data() {
            return {
                form: {
                    screening: {
                        title:       null,
                        description: null,
                        from_age:    1,
                        to_age:      null,
                        for_man:     false,
                        for_woman:   false,
                        color:       false
                    }
                },
                loaders: {
                    screening: {
                        updating:   false,
                        reading:    true,
                        deleting:   false,
                        suspending: false
                    },
                    screening_test: {
                        updating: false
                    }
                },
                validation: {
                    ko: false
                },
                DB: {
                    tests: []
                }
            }
        },
        validations() {
            return {
                form: {
                    screening: {
                        description: {
                            required,
                            minLength: minLength(8),
                            maxLength: maxLength(350)
                        },
                        from_age: {
                            required,
                            numeric,
                            minValue: minValue(1)
                        },
                        to_age: {
                            required,
                            minValue: minValue(this.form.screening.from_age),
                            numeric
                        },
                        for_man: {
                            required() {
                                return !(!this.form.screening.for_woman && !this.form.screening.for_man)
                            }
                        },
                        for_woman: {
                            required() {
                                return !(!this.form.screening.for_man && !this.form.screening.for_woman)
                            }
                        }
                    }

                }
            }
        },
        methods: {
            async updateScreening() {

                this.loaders.screening.updating = true
                this.validation.ko         = false

                try {

                    const res = await this.axios.post(`user/screenings/update/single`, this.form.screening, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/screenings`)

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko         = message
                    this.loaders.screening.updating = false

                }
            },
            async readScreening() {

                try {

                    const screening = await this.axios.get(`user/screenings/read/detail/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.screening = screening.data.screening

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko         = message
                    this.loaders.screening.reading = false

                }
                
            },
            async deleteScreening() {

                this.loaders.screening.deleting = true

                try {

                    const res = await this.axios.delete(`user/screenings/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/screenings`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.message,
                        type:   `error`
                    })

                    this.loaders.screening.deleting = false

                }

            },
            async suspendScreening() {

                this.loaders.screening.suspending = true

                try {

                    const res = await this.axios.get(`user/screenings/suspend/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.form.screening.state = !this.form.screening.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.message,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.screening.suspending = false
                }

            },
            async readTests() {
                try {

                    let tests = await this.axios.get(`user/tests/read/actives`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    for(let i = 0; i< tests.data.tests_db.length; i++) {
                        for(let screening_test of this.form.screening.screenings_tests) {
                            if(tests.data.tests_db[i].id == screening_test.test.id) {
                                tests.data.tests_db.splice(i, 1)
                                i--
                                break
                            }
                        }
                    }

                    this.DB.tests = tests.data.tests_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i tests. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            async createScreeningTest(value) {

                this.loaders.screening_test.updating = true
                this.validation.ko                   = false

                try {

                    const res = await this.axios.post(`user/screenings_tests/create/single`, {
                        id_screening: this.form.screening.id,
                        id_test:      value.id
                    },
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                }
                catch(e) {

                    console.log(e)

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko              = message
                    this.loaders.screening.updating = false

                }

            },
            async deleteScreeningTest(value) {
                
                this.loaders.screening_test.updating = true
                this.validation.ko                   = false

                try {

                    const res = await this.axios.post(`user/screenings_tests/delete/single`, {
                        id_screening: this.form.screening.id,
                        id_test:      value.id
                    },
                    {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                }
                catch(e) {

                    console.log(e)

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko              = message
                    this.loaders.screening.updating = false

                }

            },
            updateScreeningTest(event, test) {
                
                const state = event.target.checked

                state ? this.createScreeningTest(test) : this.deleteScreeningTest(test)

            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }
        },
        async mounted() {
            await this.readScreening()
            await this.readTests()
        }
    }
</script>