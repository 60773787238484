<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container" v-if="
                !loaders.READ.appointment
            ">
                <div class="card-space mb-3">
                    <div class="card card-bg no-after">
                        <div class="card-body">
                            <h3 class="card-title text-truncate m-0">
                                Dettaglio appuntamento
                            </h3>
                            <p class="card-text">
                                Puoi consultare le informazione dell'appuntamento cui info sul prenotatore e sul luogo della prenotazione
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card-space mb-3">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate m-0">
                                        Anagrafica
                                    </h3>

                                    <div class="mb-4">
                                        <p class="card-text m-0">
                                            Consultare le informazione del paziente richiedente
                                        </p>
                                        <span class="badge bg-primary" v-if="
                                            form.appointment.patient_screenings_tests.user.sipso_imported
                                        ">
                                            Paziente Sipso
                                        </span>
                                    </div>

                                    <div class="form-group mb-4">

                                        <label for="name" class="active">
                                            Nome
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control uppercase"
                                            id              = "name"
                                            :value          = "form.appointment.patient_screenings_tests.user.name"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="surname" class="active">
                                            Cognome
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control uppercase"
                                            id              = "surname"
                                            :value          = "form.appointment.patient_screenings_tests.user.surname"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="CF" class="active">
                                            Codice fiscale
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "CF"
                                            :value          = "form.appointment.patient_screenings_tests.user.CF"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="date_birth" class="active">
                                            Data di nascita
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "date_birth"
                                            :value          = "form.appointment.patient_screenings_tests.user.date_birth | data_slash"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="sex" class="active">
                                            Sesso
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "sex"
                                            :value          = "form.appointment.patient_screenings_tests.user.sex == 'M' ? 'Uomo' : 'Donna'"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="email" class="active">
                                            Email
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "email"
                                            :value          = "form.appointment.patient_screenings_tests.user.email"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group m-0">

                                        <label for="phone" class="active">
                                            Cellulare
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "phone"
                                            :value          = "form.appointment.patient_screenings_tests.user.phone"
                                            disabled
                                        >

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card-space mb-3">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate m-0">
                                        Agenda
                                    </h3>
                                    <div class="mb-4">
                                        <p class="card-text m-0">
                                            Consultare le informazione sull'appuntamento fissato
                                        </p>
                                        <span class="badge bg-primary" v-if="
                                            form.appointment.sipso_imported
                                        ">
                                            Appuntamento Sipso
                                        </span>
                                    </div>

                                    <div class="form-group mb-4">

                                        <label for="date" class="active">
                                            Stato
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "date"
                                            :value          = "state_of_appointment"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="date" class="active">
                                            Data
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "date"
                                            :value          = "form.appointment.date | data_slash"
                                            disabled
                                        >

                                    </div>                                    
                                    <div class="form-group mb-4" v-if="form.appointment.patient_screenings_tests.screenings.title == 'HCV'">

                                        <label for="time" class="active">
                                            Orario
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "time"
                                            :value          = "'DALLE ORE 8 ALLE ORE 10'"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4" v-else>

                                        <label for="time" class="active">
                                            Orario
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "time"
                                            :value          = "form.appointment.time | data_ora"
                                            disabled
                                        >

                                    </div>                                   
                                    <div class="form-group mb-4">

                                        <label for="structure_title" class="active">
                                            Struttura
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "structure_title"
                                            :value          = "form.appointment.structures.title"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="screening_title" class="active">
                                            Screening
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "screening_title"
                                            :value          = "form.appointment.patient_screenings_tests.screenings.title"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group m-0">

                                        <label for="test_title" class="active">
                                            Test
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "test_title"
                                            :value          = "form.appointment.patient_screenings_tests.tests.title"
                                            disabled
                                        >

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">
                                    Funzioni
                                </h3>
                            </li>
                            <li
                                v-if = "
                                    !function_disabled(
                                        'Agenda',
                                        'update'
                                    ) &&
                                    form.appointment.reply_sms != '0' && form.appointment.reply_sms != '1' &&
                                    !form.appointment.appointment_rejected &&
                                    !form.appointment.reminder_sms && loaders.ko
                                "
                            >
                                <a
                                    class           = "list-item medium text-truncate"
                                    data-toggle     = "collapse"
                                    href            = "#question_send_notify"
                                    role            = "button"
                                    aria-expanded   = "false"
                                    aria-controls   = "question_send_notify"
                                >

                                    <span class="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-envelope-exclamation mb-1" viewBox="0 0 16 16">
                                            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1.5a.5.5 0 0 1-1 0V11a.5.5 0 0 1 1 0Zm0 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"/>
                                        </svg> Invia SMS sollecito
                                    </span>

                                </a>
                                <div
                                    class   = "collapse bg-danger text-center text-white"
                                    id      = "question_send_notify"
                                >
                                    <p class="m-0 border-bottom border-white">
                                        <small class="font-weight-bold">
                                            Sicuro?
                                        </small>
                                    </p>
                                    <div class="container-fluid">

                                        <div class="row border-top">
                                            <div
                                                class   = "col-6 text-center border-right border-white cursor-pointer"
                                                data-toggle     = "collapse"
                                                aria-expanded   = "false"
                                                role            = "button"
                                                @click  = "notify_SMS"
                                                :class  = "{
                                                    'text-center disabled-AO': loaders.SEND.notify
                                                }"
                                            >
                                                <small
                                                    class   = "font-weight-bold "
                                                    v-show  = "!loaders.SEND.notify"
                                                >
                                                    Si
                                                </small>
                                                <div
                                                    v-show  = "loaders.SEND.notify"
                                                    class   = "progress-spinner progress-spinner-double size-sm progress-spinner-active"
                                                >
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                            </div>
                                            <div
                                                class           = "col-6 text-center cursor-pointer"
                                                data-toggle     = "collapse"
                                                href            = "#question_send_notify"
                                                role            = "button"
                                                aria-expanded   = "false"
                                                aria-controls   = "question_send_notify"
                                            >
                                                <small class="font-weight-bold">
                                                    No
                                                </small>
                                            </div>                                        
                                        </div>

                                    </div>
                                </div>
                            </li>
                            <li
                                v-if = "
                                    !function_disabled(
                                        'Agenda',
                                        'update'
                                    ) &&
                                    !form.appointment.appointment_rejected && form.appointment.reply_sms == '1'
                                    
                                "
                            >
                                <a
                                    class           = "list-item medium text-truncate"
                                    data-toggle     = "collapse"
                                    href            = "#question_rejection"
                                    role            = "button"
                                    aria-expanded   = "false"
                                    aria-controls   = "question_rejection"
                                >

                                    <span class="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-calendar2-x mb-1" viewBox="0 0 16 16">
                                            <path d="M6.146 8.146a.5.5 0 0 1 .708 0L8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 0 1 0-.708z"/>
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                                            <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                                        </svg> Annulla
                                    </span>

                                </a>
                                <div
                                    class   = "collapse bg-danger text-center text-white"
                                    id      = "question_rejection"
                                >
                                    <p class="m-0 border-bottom border-white">
                                        <small class="font-weight-bold">
                                            Sicuro?
                                        </small>
                                    </p>
                                    <div class="container-fluid">

                                        <div class="row border-top">
                                            <div
                                                class   = "col-6 text-center border-right border-white cursor-pointer"
                                                @click  = "reject"
                                                :class  = "{
                                                    'text-center disabled-AO': loaders.DELETE.appointment
                                                }"
                                            >
                                                <small
                                                    class   = "font-weight-bold "
                                                    v-show  = "!loaders.DELETE.appointment"
                                                >
                                                    Si
                                                </small>
                                                <div
                                                    v-show  = "loaders.DELETE.appointment"
                                                    class   = "progress-spinner progress-spinner-double size-sm progress-spinner-active"
                                                >
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                            </div>
                                            <div
                                                class           = "col-6 text-center cursor-pointer"
                                                data-toggle     = "collapse"
                                                href            = "#question_rejection"
                                                role            = "button"
                                                aria-expanded   = "false"
                                                aria-controls   = "question_rejection"
                                            >
                                                <small class="font-weight-bold">
                                                    No
                                                </small>
                                            </div>                                        
                                        </div>

                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'
    import moment from 'moment'

    export default {
        data() {
            return {
                form: {
                    appointment: null
                },
                loaders: {
                    READ: {
                        appointment: true
                    },
                    SEND: {
                        notify: false
                    },
                    DELETE: {
                        appointment: false
                    },
                    ko: true
                }
            }
        },
        methods: {
            async read_appointment() {
                try {

                    this.form.appointment = (
                        await this.axios.get(
                            `admin/appointments/read/single/${this.$route.params.id}`, 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data
                    
                }
                catch(e) {
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le info appuntamento. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
                finally {
                    this.loaders.READ.appointment = false
                }
            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            },
            async notify_SMS() {

                this.loaders.SEND.notify = true
                
                this.loaders.ko = true
                try {
                    const {
                        message
                    } = (
                        await this.axios.get(
                            `admin/appointments/send/notify/${this.$route.params.id}`, 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data

                    this.$toast.open({
                        message,
                        type: "success"
                    })

                    this.loaders.ko = false
                }
                catch(e) {
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Errore: ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })

                        this.loaders.ko = true
                    }
                }
                finally {
                    this.loaders.SEND.notify = false
                    this.loaders.ko = false
                }
            },
            async reject() {

                this.loaders.DELETE.appointment = true
                
                try {
                    const {
                        message
                    } = (
                        await this.axios.get(
                            `admin/appointments/reject/${this.$route.params.id}`, 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data

                    this.$toast.open({
                        message,
                        type: "success"
                    })
                }
                catch(e) {
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le info appuntamento. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
                finally {
                    this.loaders.DELETE.appointment = false
                }
            }
        },
        mounted() {
            this.read_appointment()
        },
        computed: {
            state_of_appointment() {

                if(
                    this.form.appointment.appointment_rejected
                ) return "ANNULLATO DA ADMIN"
                if(
                    this.form.appointment.reminder_sms == 0 && !this.form.appointment.appointment_rejected && this.form.appointment.confirm_sms != 3 && this.form.appointment.sent_sms == 1 && this.form.appointment.status_sms == 1 && this.form.appointment.reply_sms == '1'
                ) return "CONFERMATO VIA SMS"   
                if(
                    this.form.appointment.reminder_sms == 0 && !this.form.appointment.appointment_rejected && this.form.appointment.confirm_sms == 3 && this.form.appointment.sent_sms == 1 && this.form.appointment.status_sms == 1 && this.form.appointment.reply_sms == '1'
                ) return "CONFERMATO VIA SMS TEMPO DI CONFERMA SCADUTO"                   
                if(
                    this.form.appointment.reminder_sms == 0 && this.form.appointment.sent_sms == 1 && this.form.appointment.status_sms == 1 && this.form.appointment.reply_sms == '0'
                ) return "ANNULLATO VIA SMS"   
                if(
                    this.form.appointment.reminder_sms == 0 && this.form.appointment.sent_sms == 1 && this.form.appointment.status_sms == 1 && this.form.appointment.reply_sms && this.form.appointment.reply_sms != '1'  && this.form.appointment.reply_sms != '0'
                ) return "ANNULLATO PER RISPOSTA SMS ERRATA"
                if(
                    this.form.appointment.reminder_sms == 1 && this.form.appointment.reminder_reply_sms == '0' && this.form.appointment.sent_sms == 1 && this.form.appointment.status_sms == 1 && this.form.appointment.reply_sms && this.form.appointment.reply_sms != '1'  && this.form.appointment.reply_sms != '0'
                ) return "ANNULLATO VIA SMS SOLLECITO"  
                if(
                    this.form.appointment.reminder_sms == 1 && this.form.appointment.reminder_reply_sms == '1' && this.form.appointment.sent_sms == 1 && this.form.appointment.status_sms == 1 && this.form.appointment.reply_sms && this.form.appointment.reply_sms != '1'  && this.form.appointment.reply_sms != '0'
                ) return "CONFERMATO VIA SMS SOLLECITO"                               
                if(
                    this.form.appointment.sent_sms == 2
                ) return "ERRORE INVIO SMS: " + this.form.appointment.error_sms                                                                          
                if(
                    moment(
                        this.form.appointment.date
                    ).isBefore()
                ) return "SCADUTO"

                return "IN ATTESA DI CONFERMA"

            }
        }
    }
</script>