<template>
    <div>
        <div 
            style="
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                transition: 2s ease-in;
                border-bottom: 3px solid #084f9b;
            "
            class="
                d-flex
                justify-content-between
                bg-ASL_ROMA_2-red
                p-3
            "
            :class="{
                'nascondi_popup': nascondi_popup
            }"
            v-if="
                deferredPrompt
            "
        >
            <div class="m-auto text-center">
                <span>
                    E' anche possibile installare l'applicazione per il tuo dispositivo
                </span>
                <button
                    class   = "btn btn-primary my-2"
                    @click  = "install"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
                        <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                        <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                    </svg> Installa
                </button>
                <button
                    class   = "btn btn-secondary ml-1 my-2"
                    @click  = "nascondi_popup = true"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg> Non mi interessa
                </button>
            </div>
        </div>
        <div class="bg-gradient">
            <div class="container-fluid h-main-lg">
                <div class="row h-100">
                    <div class="col-lg-6 d-flex">
                        <div class="m-auto text-white p-5">
                            <h1>Screening oncologici</h1>
                            <h4>Benvenuto nel portale dedicato alla campagna di Screening offerto dall'ASL Roma 2</h4>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex pb-5 pb-lg-0">
                        <div class="m-auto">
                            <div>
                                <router-link tag="button" to="/accesso" class="btn btn-primary w-100">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-unlock mr-2" viewBox="0 0 16 16">
                                        <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
                                    </svg> Effettua il login

                                </router-link>
                            </div>
                            <p class="my-2 text-center font-weight-bold">
                                oppure
                            </p>
                            <div class="mb-2">
                                <router-link tag="button" to="/registrazione" class="btn btn-primary w-100">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-input-cursor-text mr-2" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.165 4.165 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.49 3.49 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.165 4.165 0 0 1-2.06-.566A4.561 4.561 0 0 1 8 13.65a4.561 4.561 0 0 1-.44.285 4.165 4.165 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.49 3.49 0 0 0-.436-.294A3.166 3.166 0 0 0 5.5 2.5.5.5 0 0 1 5 2z"/>
                                        <path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v1zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4z"/>
                                    </svg> Registrati

                                </router-link>
                            </div>
                            <hr>
                            <div>
                                <button class="btn btn-primary btn btn-primary d-flex align-items-center w-100" @click="spid">

                                    <svg id="spid-ico-circle-bb2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mr-2" viewBox="0 0 587.6 587.6">
                                        <path id="XMLID_3_" d="M587.6,293.8c0,162.3-131.5,293.8-293.8,293.8C131.6,587.6,0,456.1,0,293.8S131.6,0,293.8,0C456.1,0,587.6,131.5,587.6,293.8" fill="#fff"/>
                                        <path id="XMLID_2_" d="M294.6,319c-24.4,0-44.5-8.2-60.3-24.8q-23.7-24.75-23.7-61.4,0-36.75,23.6-61c15.7-16.2,35.7-24.3,60.2-24.3,24.4,0,44.3,8.2,59.6,24.9,15.3,16.6,23,37,23,61.5q0,36.45-23,60.8c-15.3,16.1-35,24.3-59.4,24.3" fill="#004d99"/>
                                        <path id="XMLID_1_" d="M210.6,439.1c0-24.5,7.9-44.8,23.5-61,15.7-16.2,35.7-24.3,60.4-24.3,24.4,0,44.3,8.2,59.5,24.9,15.3,16.7,23,37.1,23,61.5" fill="#004d99"/>
                                    </svg> Accedi con SPID

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row py-3 py-lg-0 m-t-neg_50-px-lg">
                <div class="col-lg-4 pr-lg-5 pl-lg-5">
                    <div class="card-wrapper card-space mb-2">
                        <div class="card card-bg card-big rounded border-bottom-card border-bottom-card-with-var-from-vue" :style="{'--color-from-vue': '#FF0FEB'}">

                            <div class="flag-icon" style="background: #FF0FEB"></div>

                            <div class="card-body">

                                <h5 class="card-title big-heading">
                                    Screening citologico
                                </h5>
                                <p class="card-text">
                                    Consiste nell'offerta attiva e gratuita di un test HPV ogni cinque anni o un Pap test ogni tre (a seconda della fascia d’età della donna), di eventuali esami di approfondimento e, se occorre, della terapia medica e chirurgica.
                                    Gli obiettivi sono la riduzione della mortalità per tumore della cervice uterina, attraverso la diagnosi precoce e l'offerta di un percorso diagnostico-terapeutico, che rispetti gli standard di qualità stabiliti dalle linee guida regionali e nazionali.
                                </p>
                                <a class="read-more" target="_blank" href="https://www.aslroma2.it/index.php/promozione-alla-salute/screening/screening-citologico-per-la-prevenzione-del-tumore-collo-dell-utero">
                                    <span class="text">Leggi di più</span>
                                    <svg class="icon">
                                    <use href="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-right"></use>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 pr-lg-5 pl-lg-5">
                    <div class="card-wrapper card-space mb-2">
                        <div class="card card-bg card-big rounded border-bottom-card border-bottom-card-with-var-from-vue" :style="{'--color-from-vue': '#8900FA'}">

                            <div class="flag-icon" style="background: #8900FA"></div>

                            <div class="card-body">
                                <h5 class="card-title big-heading">
                                    Screening mammografico
                                </h5>
                                <p class="card-text">
                                    Consiste nell'offerta attiva e gratuita di una mammografia ogni due anni, di eventuali esami di approfondimento e, se occorre, della terapia medica e chirurgica.
                                    Gli obiettivi sono la riduzione della mortalità per tumore della mammella, attraverso la diagnosi precoce e l'offerta di un percorso diagnostico-terapeutico, che rispetti gli standard di qualità stabiliti da linee guida regionali e nazionali.
                                </p>
                                <a class="read-more" target="_blank" href="https://www.aslroma2.it/index.php/promozione-alla-salute/screening/screening-citologico-per-la-prevemzione-del-tumore-della-mammella">
                                    <span class="text">Leggi di più</span>
                                    <svg class="icon">
                                    <use href="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-right"></use>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 pr-lg-5 pl-lg-5">
                    <div class="card-wrapper card-space mb-2">
                        <div class="card card-bg card-big rounded border-bottom-card border-bottom-card-with-var-from-vue" :style="{'--color-from-vue': '#31D510'}">

                            <div class="flag-icon" style="background: #31D510"></div>

                            <div class="card-body">
                                <h5 class="card-title big-heading">
                                    Screening colorettale
                                </h5>
                                <p class="card-text">
                                    Consiste nell'offerta attiva e gratuita di un test per la ricerca del sangue occulto nelle feci (SOF) ogni due anni, di eventuali esami di approfondimento e, se occorre, della terapia medica e chirurgica.
                                    Gli obiettivi sono la riduzione della mortalità per tumore del colon-retto, attraverso la diagnosi precoce e l'offerta di un percorso diagnostico-terapeutico, che rispetti gli standard di qualità stabiliti dalle linee guida regionali e nazionali.
                                </p>
                                <a class="read-more" target="_blank" href="https://www.aslroma2.it/index.php/promozione-alla-salute/screening/screening-citologico-per-la-prevemzione-del-tumore-colon-retto">
                                    <span class="text">Leggi di più</span>
                                    <svg class="icon">
                                        <use href="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-right"></use>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                deferredPrompt: null,
                nascondi_popup: false
            }
        },
        methods: {
            async spid() {
                
                try {
                    window.open(
                        (
                            await this.axios.get(`auth/register/test_spid/auth`)
                        ).data,
                        "_self"
                    )
                }
                catch{
                    this.$toast.open({
                        message: "Si è presentato un errore per l'accesso con lo SPID, riprova piu tardi",
                        type:    "error"
                    })
                }

            },
            async install() {
                this.deferredPrompt.prompt()
            }
        },
        created() {
            window.addEventListener(
                "beforeinstallprompt",
                e => {
                    e.preventDefault()
                    this.deferredPrompt = e
                }
            )
        }
    }
</script>

<style>
    .nascondi_popup {
        top: -100% !important;
    }
</style>