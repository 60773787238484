<template>
    <div class="container h-100-calc d-flex">
        <div class="row m-auto">

                <div class="col-lg-6 p-4 border-right-lg d-flex">

                    <div class="m-lg-auto text-lg-right">
                        
                        <h1 class="m-0">
                            Bentornato
                        </h1>
                        <p>
                            Compila il modulo di accesso
                        </p>

                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-right-circle d-none d-lg-inline" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                        </svg>

                    </div>

                </div>
                <div class="col-lg-6 p-4">

                    <form @submit.prevent="login" autocomplete="off">

                        <div class="form-group mb-4">
                            <label for="username">Username</label>
                            <input type="text" class="form-control text-lowercase" id="username" v-model.trim="$v.form.user.username.$model" v-bind:class="{'is-invalid': (!$v.form.user.username.required && $v.form.user.username.$dirty || this.message.login.ko)}">
                            <div class="invalid-feedback" v-show="!$v.form.user.username.required && $v.form.user.username.$dirty">Inserire l'username</div>
                        </div>
                        <div class="form-group mb-4">

                            <label for="password">Password</label>

                            <input :type="utilities.show_password" class="form-control input-password" id="password" v-model.trim="$v.form.user.password.$model" v-bind:class="{'is-invalid': (!$v.form.user.password.required && $v.form.user.password.$dirty || this.message.login.ko)}">
                            
                            <span class="password-icon" aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" v-show="utilities.show_password == 'password'" @click="utilities.show_password = 'text'">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" v-show="utilities.show_password == 'text'" @click="utilities.show_password = 'password'">
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                </svg>
                            </span>

                            <div 
                                class   = "invalid-feedback" 
                                v-show  = "
                                    !$v.form.user.password.required && 
                                    $v.form.user.password.$dirty
                                "
                            >
                                Inserire la password
                            </div>
                            
                        </div>

                        <div class="row">
                            <div class="col-lg-6 mb-2 mb-lg-0">
                                <router-link to="/" tag="button" type="button" class="btn btn-secondary mr-lg-2 btn-sm btn-block rounded" :disabled="loaders.reading.user">
                                    <span>Indietro</span>
                                </router-link>
                            </div>
                            <div class="col-lg-6">
                                <button class="btn btn-primary btn-sm btn-block rounded" :disabled="$v.$invalid || loaders.reading.user">

                                    <span v-show="!loaders.reading.user">
                                        Accedi
                                    </span>

                                    <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.reading.user">
                                        <div class="progress-spinner-inner"></div>
                                        <div class="progress-spinner-inner"></div>
                                        <span class="sr-only">Caricamento...</span>
                                    </div>

                                </button>
                            </div>
                        </div>

                    </form>

                    <div class="alert alert-danger text-center mt-3" v-if="this.message.login.ko">
                        {{this.message.login.ko}}
                    </div>

                </div>

        </div>
    </div>
</template>

<script>

    import { 
        required
    } from "vuelidate/lib/validators"

    import { 
        mapActions
    } from "vuex"

    import store from "@/store"

    export default {
        data() {
            return {
                form: {
                    user: {
                        username: ``,
                        password: ``
                    }
                },
                loaders: {
                    reading: {
                        user: false
                    }
                },
                message: {
                    login: {
                        ko: false,
                        ok: false
                    }
                },
                utilities: {
                    show_password: "password"
                }
            }
        },
        validations() {
            return {
                form: {
                    user: {
                        username: {
                            required
                        },
                        password: {
                            required
                        }
                    }
                }
            }
        },
        methods: {

            ...mapActions(["doLogin"]),

            async login() {

                this.loaders.reading.user = true

                try {

                    await this.doLogin(this.form.user)

                    store.getters.isAdmin ? this.$router.push(`/admin/appuntamenti`) : this.$router.push(`/user/percorsi`)

                }
                catch(e) {

                    const message = `Si è verificato un problema, ${e.response ? e.response.data.message + ' ' : 'riprova piu tardi.'}`

                    this.$toast.open({
                        message,
                        type:`error`
                    })

                    this.loaders.reading.user = false
                    this.message.login.ko         = message

                }
            }
        }
    }

</script>