<template>
    <div>
        <div class="container h-100-calc-lg d-flex py-3">
            <div class="row m-auto w-100">
                <div class="col-lg-4 p-4 border-right-lg d-flex">

                    <div class="m-lg-auto text-lg-right">

                        <h1 class="m-0">
                            Registrazione
                        </h1>
                        <p class="m-0">
                            Compila il modulo di registrazione <span class="d-lg-none">per accedere ai servizi screening</span>
                        </p>
                        
                        <p class="d-none d-lg-block">
                            per accedere ai servizi screening
                        </p>

                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-right-circle d-none d-lg-inline" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                        </svg>

                    </div>

                </div>
                <div class="col-lg-8 p-4">

                    <form @submit.prevent="readUser" autocomplete="off" v-if="slides == 0">

                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-4">
                                <label for="name">Nome</label>
                                <input type="text" class="form-control text-uppercase" id="name" v-model.trim="$v.form.user.name.$model" v-bind:class="{'is-invalid': (!$v.form.user.name.required && $v.form.user.name.$dirty)}">
                                <div class="invalid-feedback" v-show="!$v.form.user.name.required && $v.form.user.name.$dirty">Inserire il nome</div>
                            </div>
                            <div class="form-group col-md-6 col-lg-4">
                                <label for="surname">Cognome</label>
                                <input type="text" class="form-control text-uppercase" id="surname" v-model.trim="$v.form.user.surname.$model" v-bind:class="{'is-invalid': (!$v.form.user.surname.required && $v.form.user.surname.$dirty)}">
                                <div class="invalid-feedback" v-show="!$v.form.user.surname.required && $v.form.user.surname.$dirty">Inserire il cognome</div>
                            </div>
                            <div class="form-group col-md-6 col-lg-4">
                                <label class="active" for="date_birth">Data di nascita</label>
                                <input type="date" class="form-control" id="date_birth" v-model.trim="$v.form.user.date_birth.$model" v-bind:class="{'is-invalid': (!$v.form.user.date_birth.required && $v.form.user.date_birth.$dirty)}">
                                <div class="invalid-feedback" v-show="!$v.form.user.date_birth.required && $v.form.user.date_birth.$dirty">Inserire la data di nascita</div>
                            </div>
                            <div class="col-md-6 col-lg-12 pb-lg-5">
                                <p class="m-0 font-weight-bold">
                                    Sesso
                                </p>
                                <div class="row">
                                    <div class="col-md-6 col-lg-12">
                                        <div class="form-check">
                                            <input name="sex" type="radio" id="sex_man" value="M" v-model="form.user.sex">
                                            <label for="sex_man">Uomo</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-12">
                                        <div class="form-check">
                                            <input name="sex" type="radio" id="sex_woman" value="F" v-model="form.user.sex">
                                            <label for="sex_woman">Donna</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-lg-4">
                                <label for="CF">Codice fiscale/STP/ENI</label>
                                <input type="text" class="form-control text-uppercase" id="CF" maxlength="16" v-model.trim="$v.form.user.CF.$model" v-bind:class="{'is-invalid': ((!$v.form.user.CF.required || !$v.form.user.CF.minLength || !$v.form.user.CF.maxLength) && $v.form.user.CF.$dirty)}">
                                <div class="invalid-feedback" v-show="!$v.form.user.CF.required && $v.form.user.CF.$dirty">Inserire codice fiscale</div>
                                <div class="invalid-feedback" v-show="!$v.form.user.CF.minLength && $v.form.user.CF.$dirty">Lunghezza minima di 16 caratteri</div>
                                <div class="invalid-feedback" v-show="!$v.form.user.CF.maxLength && $v.form.user.CF.$dirty">Lunghezza massima di 16 caratteri</div>
                            </div>
                            <div class="form-group col-md-6 col-lg-6">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            8038000
                                        </div>
                                    </div>

                                    <label for="TS" class="active_AO">Tessera sanitaria</label>

                                    <input type="text" class="form-control text-uppercase" id="TS" maxlength="13" v-model.number="$v.form.user.TS.$model" v-bind:class="{'is-invalid': ((!$v.form.user.TS.required || !$v.form.user.TS.minLength || !$v.form.user.TS.maxLength || !$v.form.user.TS.numeric) && $v.form.user.TS.$dirty)}">

                                    <div class="invalid-feedback" v-show="!$v.form.user.TS.required && $v.form.user.TS.$dirty">Inserire tessera sanitaria</div>
                                    <div class="invalid-feedback" v-show="!$v.form.user.TS.minLength && $v.form.user.TS.$dirty">Lunghezza minima di 13 caratteri</div>
                                    <div class="invalid-feedback" v-show="!$v.form.user.TS.maxLength && $v.form.user.TS.$dirty">Lunghezza massima di 13 caratteri</div>
                                    <div class="invalid-feedback" v-show="!$v.form.user.TS.numeric && $v.form.user.TS.$dirty">Sono ammessi solo numeri</div>

                                </div>

                                <small id="phone_info" class="form-text text-muted" v-show="!$v.form.user.TS.$dirty">Controllo dedicato solo per gli assistiti della regione lazio</small>
                                
                            </div>
                            
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-4">

                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            +39
                                        </div>
                                    </div>

                                    <label for="phone">Cellulare</label>

                                    <input type="text" class="form-control" id="phone" minlength="8" maxlength="10" v-model.trim="$v.form.user.phone.$model" v-bind:class="{'is-invalid': ((!$v.form.user.phone.required || !$v.form.user.phone.minLength || !$v.form.user.phone.maxLength || !$v.form.user.phone.numeric) && $v.form.user.phone.$dirty)}">

                                    <div class="invalid-feedback" v-show="!$v.form.user.phone.required && $v.form.user.phone.$dirty">Inserire numero cellulare</div>
                                    <div class="invalid-feedback" v-show="!$v.form.user.phone.minLength && $v.form.user.phone.$dirty">Lunghezza minima di 8 caratteri</div>
                                    <div class="invalid-feedback" v-show="!$v.form.user.phone.maxLength && $v.form.user.phone.$dirty">Lunghezza massima di 10 caratteri</div>
                                    <div class="invalid-feedback" v-show="!$v.form.user.phone.numeric && $v.form.user.phone.$dirty">Sono ammessi solo numeri</div>

                                </div>
                                <small id="phone_info" class="form-text text-muted">Sarà confermato con OTP SMS</small>

                            </div>
                            <div class="form-group col-md-6 col-lg-6">

                                <label for="email">Email</label>

                                <input type="text" class="form-control text-lowercase" id="email" v-model.trim="$v.form.user.email.$model" v-bind:class="{'is-invalid': ((!$v.form.user.email.required || !$v.form.user.email.email) && $v.form.user.email.$dirty)}">

                                <div class="invalid-feedback" v-show="!$v.form.user.email.required && $v.form.user.email.$dirty">Inserire email</div>
                                <div class="invalid-feedback" v-show="!$v.form.user.email.email && $v.form.user.email.$dirty">Formattazzione errata</div>
                                <small id="phone_info" class="form-text text-muted">Sarà confermata con OTP mail</small>

                            </div>
                        </div>

                        <div class="alert alert-danger text-center" v-if="this.message.register.ko">
                            {{this.message.register.ko}}
                        </div>
                        <div class="alert alert-success text-center" v-if="this.message.register.ok">
                            {{this.message.register.ok}}
                        </div>

                        <router-link to="/" tag="button" type="button" class="btn btn-secondary mr-lg-2 w-100 w-lg-auto mb-2 mb-lg-0" :disabled="loaders.reading.user">

                            <span>Torna indietro</span>

                        </router-link>
                        <button class="btn btn-primary w-100 w-lg-auto" :disabled="$v.form.user.$invalid || loaders.reading.user">

                            <span v-show="!loaders.reading.user">
                                Prosegui registrazione
                            </span>

                            <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.reading.user">
                                <div class="progress-spinner-inner"></div>
                                <div class="progress-spinner-inner"></div>
                                <span class="sr-only">Caricamento...</span>
                            </div>

                        </button>                        

                    </form>
                    <form @submit.prevent="register" autocomplete="off" v-else-if="slides == 1">      

                        <div class="alert alert-warning">
                            Inserisci i codici di verifica che abbiamo inviato tramite <b>SMS</b> al numero di telefono <b>+39{{form.user.phone}}</b> e tramite <b>email</b> all'indirizzo <b>{{form.user.email}}</b>
                        </div>
                        <div class="alert alert-warning mb-4">
                            Inserendo correttamente i codici OTP le <b>credenziali di accesso</b> verrano <b>inviate</b> all'indirizzo email <b>{{form.user.email}}</b>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-5 mb-4">

                                <label for="phone_OTP">Codice cellulare</label>

                                <input :type="utilities.show_OTP_phone ? `text` : `password`" maxlength="6" class="form-control input-password" id="phone_OTP" aria-labelledby="info_phone_OTP" v-model.trim="$v.form.OTP_system.phone.$model" v-bind:class="{'is-invalid': (!$v.form.OTP_system.phone.required || !$v.form.OTP_system.phone.numeric || !$v.form.OTP_system.phone.maxLength) && $v.form.OTP_system.phone.$dirty}">

                                <span class="password-icon" aria-hidden="true" @click="utilities.show_OTP_phone = !utilities.show_OTP_phone">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" v-show="!utilities.show_OTP_phone">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" v-show="utilities.show_OTP_phone">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>
                                </span>

                                <div class="invalid-feedback" v-show="!$v.form.OTP_system.phone.required && $v.form.OTP_system.phone.$dirty">Inserire il codice</div>
                                <div class="invalid-feedback" v-show="(!$v.form.OTP_system.phone.maxLength) && $v.form.OTP_system.phone.$dirty">Lunghezza di 6 caratteri</div>
                                <div class="invalid-feedback" v-show="!$v.form.OTP_system.phone.numeric && $v.form.OTP_system.phone.$dirty">Sono ammessi solo numeri</div>
                                
                                <small id="info_phone_OTP" class="form-text text-muted" v-if="!loaders.updating.OTP.phone">
                                    Inserisci il codice ricevuto tramite SMS
                                </small>
                                <small id="info_phone_OTP" class="form-text text-muted" v-else-if="loaders.updating.OTP.phone">
                                    Invio codice di verifica..
                                </small>

                                <a 
                                    class   = "text-primary d-block pt-4 pl-2" 
                                    @click  = "requireNewPhoneOTP" 
                                    :class  = "{
                                        'disabled': loaders.updating.OTP.phone
                                    }"
                                    v-if    = "utilities.flag.OTP_requests.phone <= 3"
                                >
                                    <small class="f-s-0_777-em">
                                        Richiedi nuovo OTP SMS
                                    </small>
                                </a>
                                <p class="m-0 mb-3 text-truncate" v-if="utilities.flag.OTP_requests.phone > 2">
                                    <small class="form-text text-danger pt-0">
                                        Gentile richiedente, ha effettuato troppi tentativi per la verifica OTP del cellulare, effettui nuovamente la registrazione.
                                    </small>
                                </p>
                                <p class="m-0 mb-3 text-truncate" v-if="message.newOTP.phone.ko">
                                    <small class="form-text text-danger pt-0">
                                        {{message.newOTP.phone.ko}}
                                    </small>
                                </p>
                                <p class="m-0 mb-3 text-truncate" v-if="message.newOTP.phone.ok">
                                    <small class="form-text text-success pt-0">
                                        {{message.newOTP.phone.ok}}
                                    </small>
                                </p>

                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-5 mb-4">

                                <label for="email_OTP">Codice email</label>

                                <input :type="utilities.show_OTP_email ? `text` : `password`" maxlength="6" class="form-control input-password" id="email_OTP" aria-labelledby="info_email_OTP" v-model.trim="$v.form.OTP_system.email.$model" v-bind:class="{'is-invalid': (!$v.form.OTP_system.email.required || !$v.form.OTP_system.email.numeric || !$v.form.OTP_system.email.maxLength) && $v.form.OTP_system.email.$dirty}">

                                <span class="password-icon" aria-hidden="true" @click="utilities.show_OTP_email = !utilities.show_OTP_email">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" v-show="!utilities.show_OTP_email">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" v-show="utilities.show_OTP_email">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>
                                </span>

                                <div class="invalid-feedback" v-show="!$v.form.OTP_system.email.required && $v.form.OTP_system.email.$dirty">Inserire il codice</div>
                                <div class="invalid-feedback" v-show="(!$v.form.OTP_system.email.maxLength) && $v.form.OTP_system.email.$dirty">Lunghezza di 6 caratteri</div>
                                <div class="invalid-feedback" v-show="!$v.form.OTP_system.email.numeric && $v.form.OTP_system.email.$dirty">Sono ammessi solo numeri</div>
                                
                                <small id="info_email_OTP" class="form-text text-muted" v-if="!loaders.updating.OTP.email">
                                    Inserisci il codice ricevuto tramite email
                                </small>
                                <small id="info_email_OTP" class="form-text text-muted" v-else-if="loaders.updating.OTP.email">
                                    Invio codice di verifica..
                                </small>

                                <a 
                                    class   = "text-primary d-block pt-4 pl-2" 
                                    @click  = "requireNewEmailOTP" 
                                    :class  = "{
                                        'disabled': loaders.updating.OTP.email
                                    }"
                                    v-if    = "utilities.flag.OTP_requests.email <= 3"
                                >
                                    <small class="f-s-0_777-em">
                                        Richiedi nuovo OTP email
                                    </small>
                                </a>

                                <p class="m-0 mb-3 text-truncate" v-if="utilities.flag.OTP_requests.email > 2">
                                    <small class="form-text text-danger pt-0">
                                        Gentile richiedente, ha effettuato troppi tentativi per la verifica OTP della mail, effettui nuovamente la registrazione.
                                    </small>
                                </p>
                                <p class="m-0 text-truncate" v-if="message.newOTP.email.ko">
                                    <small class="form-text text-danger pt-0">
                                        {{message.newOTP.email.ko}}
                                    </small>
                                </p>
                                <p class="m-0 text-truncate" v-if="message.newOTP.email.ok">
                                    <small class="form-text text-success pt-0">
                                        {{message.newOTP.email.ok}}
                                    </small>
                                </p>

                            </div>
                        </div>

                        <div class="alert alert-danger text-center" v-if="this.message.register.ko">
                            {{this.message.register.ko}}
                        </div>
                        <div class="alert alert-success text-center" v-if="this.message.register.ok">
                            {{this.message.register.ok}}
                        </div>

                        <button class="btn btn-primary" :disabled="loaders.creating.user || $v.form.OTP_system.$invalid">

                            <span v-show="!loaders.creating.user">
                                Completa registrazione
                            </span>

                            <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.creating.user">
                                <div class="progress-spinner-inner"></div>
                                <div class="progress-spinner-inner"></div>
                                <span class="sr-only">Caricamento...</span>
                            </div>

                        </button>

                        <a class="text-primary d-block mt-2" @click="slides--">
                            <small>
                                Torna indietro
                            </small>
                        </a>

                    </form>
                    
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength,
        numeric,
        email
    } from "vuelidate/lib/validators"

    import { 
        mapActions
    } from "vuex"

    export default {
        data() {
            return {
                form: {
                    user: {
                        name:       ``,
                        surname:    ``,
                        date_birth: ``,
                        sex:        ``,
                        CF:         ``,
                        phone:      ``,
                        email:      ``,
                        TS:         ``
                    },
                    OTP_system: {
                        phone: ``,
                        email: ``
                    }
                },
                loaders: {
                    creating: {
                        user: false
                    },
                    reading: {
                        caps: false,
                        user: false
                    },
                    updating: {
                        OTP: {
                            phone: false,
                            email: false
                        }
                    }
                },
                message: {
                    register: {
                        ko: false,
                        ok: false
                    },
                    newOTP: {
                        phone: {
                            ko: false,
                            ok: false
                        },
                        email: {
                            ko: false,
                            ok: false
                        }
                    }
                },
                utilities: {
                    show_OTP_phone: false,
                    show_OTP_email: false,
                    OTP_sended:     false,
                    flag: {
                        OTP_requests: {
                            phone: 0,
                            email: 0
                        }
                    },
                    responses: {
                        OTP: [] // CEL - MAI
                    }
                },
                slides: 0
            }
        },
        validations() {
            return {
                form: {
                    user: {
                        name: {
                            required
                        },
                        surname: {
                            required
                        },
                        date_birth: {
                            required
                        },
                        sex: {
                            required
                        },
                        CF: {
                            required,
                            minLength: minLength(16),
                            maxLength: maxLength(16)
                        },
                        TS: {
                            required,
                            minLength: minLength(13),
                            maxLength: maxLength(13),
                            numeric
                        },
                        phone: {
                            required,
                            minLength: minLength(8),
                            maxLength: maxLength(10),
                            numeric
                        },
                        email: {
                            required,
                            email
                        }
                    },
                    OTP_system: {
                        phone: {
                            required,
                            numeric,
                            maxLength: maxLength(6)
                        },
                        email: {
                            required,
                            numeric,
                            maxLength: maxLength(6)
                        }
                    }
                }
            }
        },
        methods: {           
            
            ...mapActions(["doLogin"]),

            async register() {

                this.loaders.creating.user = true
                this.message.register.ko   = false
                this.message.register.ok   = false

                try {

                    // Fondiamo i due oggetti form da inviare
                    const OBJ = {
                        ...this.form.user,
                        OTP_phone: {
                            numero: this.form.OTP_system.phone,
                            token:  this.utilities.responses.OTP[0]
                        },
                        OTP_email: {
                            numero: this.form.OTP_system.email,
                            token:  this.utilities.responses.OTP[1]
                        }
                    }

                    let user = await this.axios.post(`auth/register/patient`, OBJ)

                    await this.doLogin(user.data.login)

                    this.$router.push(`/user/percorsi`)

                }
                catch(e) {

                    const message = `Si è verificato un problema, ${e.response ? e.response.data.message + ' ' : 'riprova piu tardi.'}`

                    this.$toast.open({
                        message,
                        type:`error`
                    })

                    this.message.register.ko   = message
                    this.loaders.creating.user = false

                }
                
            },
            async readUser() {

                if(this.utilities.OTP_sended) {
                    this.slide_next()
                }
                else {

                    this.loaders.reading.user = true
                    this.message.register.ko  = false

                    try {

                        const {
                            name,
                            surname,
                            phone,
                            email,
                            CF,
                            date_birth,
                            sex,
                            TS
                        } = this.form.user

                        const tokens = (
                            await this.axios.post(
                                "auth/register/first_step",
                                {
                                    name, 
                                    surname, 
                                    date_birth, 
                                    phone, 
                                    email, 
                                    CF, 
                                    sex, 
                                    TS
                                }
                            )
                        ).data

                        // Salvataggio token utili per il check
                        this.utilities.responses.OTP[0] = tokens[0]
                        this.utilities.responses.OTP[1] = tokens[1]

                        // Impostiamo il flag su OTP inviato a true, utile per bypassare invio OTP al click del bottone (ne lcaso in cui utente torna indietro)
                        this.utilities.OTP_sended = true
                        // Prossima slide
                        this.slide_next()

                    }
                    catch(e) {

                        const message = `Si è verificato un problema, ${e.response ? e.response.data.message : 'riprova piu tardi.'}`

                        this.$toast.open({
                            message,
                            type:`error`
                        })

                        this.message.register.ko  = message

                    }
                    finally {
                        this.loaders.reading.user = false
                    }

                }
                
            },
            async requireNewPhoneOTP() {

                this.loaders.updating.OTP.phone = true
                this.message.newOTP.phone.ko    = false
                this.message.newOTP.phone.ok    = false

                try {

                    const newOTP = await this.axios.post(`auth/register/new_otp_phone`, {
                        OTP_phone: {
                            token:  this.utilities.responses.OTP[0]
                        },
                        phone: this.form.user.phone
                    })

                    this.utilities.flag.OTP_requests.phone ++
                    this.message.newOTP.phone.ok = newOTP.data.message

                }
                catch(e) {

                    const message = `Si è verificato un problema, ${e.response ? e.response.data.message : 'riprova piu tardi.'}`

                    this.$toast.open({
                        message,
                        type:`error`
                    })

                    this.message.newOTP.phone.ko = message

                }
                finally {
                    this.loaders.updating.OTP.phone = false
                }

            },
            async requireNewEmailOTP() {

                this.loaders.updating.OTP.email = true
                this.message.newOTP.email.ko    = false
                this.message.newOTP.email.ok    = false

                try {

                    const newOTP = await this.axios.post(`auth/register/new_otp_email`, {
                        OTP_email: {
                            token:  this.utilities.responses.OTP[1]
                        },
                        email: this.form.user.email
                    })

                    this.utilities.flag.OTP_requests.email ++
                    this.message.newOTP.email.ok = newOTP.data.message

                }
                catch(e) {

                    const message = `Si è verificato un problema, ${e.response ? e.response.data.message : 'riprova piu tardi.'}`

                    this.$toast.open({
                        message,
                        type:`error`
                    })

                    this.message.newOTP.email.ko = message

                }
                finally {
                    this.loaders.updating.OTP.email = false
                }

            },
            length_for_check_db() {

                const {
                    CAP
                } = this.form.user

                if(CAP.length > 4) this.cap_exist(CAP)

            },
            slide_next() {
                this.slides ++
            }
        }
    }

</script>