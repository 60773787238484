<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">

                <div class="row">
                    <div class="col-lg-6 offset-lg-3 mb-2">

                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <form @submit.prevent="updateTest" autocomplete="off">

                                        <div class="form-group">

                                            <label for="title" class="active">Titolo</label>
                                            <input type="text" class="form-control text-uppercase" id="title" aria-describedby="title_info" v-model="form.test.title" disabled>
                                            <small id="title_info" class="form-text text-muted">Il titolo non può essere modificato</small>
                                        
                                        </div>
                                        <div class="form-group">

                                            <label for="description" class="active">Descrizione</label>
                                            <textarea id="description" class="form-control border" rows="3" v-model.trim="$v.form.test.description.$model" v-bind:class="{'is-invalid border-danger': (!$v.form.test.description.required || !$v.form.test.description.minLength) && $v.form.test.description.$dirty}"></textarea>
                                            
                                            <small v-show="!$v.form.test.description.$dirty || !$v.form.test.description.$invalid" id="title_info" class="form-text text-muted">Fornire una breve descrizione</small>
                                            
                                            <div v-show="!$v.form.test.description.required && $v.form.test.description.$dirty" class="invalid-feedback">Descrizione richiesta</div>
                                            <div v-show="!$v.form.test.description.minLength && $v.form.test.description.$dirty" class="invalid-feedback">Digitare minimo 8 caratteri</div>

                                        </div>
                                        <div class="form-group">

                                            <label for="time_expire" class="active">Richiamo (mesi)</label>
                                            <input type="number" class="form-control" id="time_expire" min="1" aria-describedby="time_expire_info" v-model.number="$v.form.test.time_expire.$model" v-bind:class="{'is-invalid': (!$v.form.test.time_expire.required || !$v.form.test.time_expire.minValue || !$v.form.test.time_expire.numeric) && $v.form.test.time_expire.$dirty}">
                                            
                                            <small v-show="!$v.form.test.time_expire.$dirty || !$v.form.test.time_expire.$invalid" id="time_expire_info" class="form-text text-muted">Digitare un tempo di attesa in mesi, prima di rieffettuare il test</small>
                                            
                                            <div v-show="!$v.form.test.time_expire.required && $v.form.test.time_expire.$dirty" class="invalid-feedback">Richiamo richiesto</div>
                                            <div v-show="!$v.form.test.time_expire.minValue && $v.form.test.time_expire.$dirty" class="invalid-feedback">Digitare minimo 1 mese</div>
                                            <div v-show="!$v.form.test.time_expire.numeric && $v.form.test.time_expire.$dirty" class="invalid-feedback">Digitare solo numeri</div>
                                        
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">

                                                <label for="from_age" class="active">Età inizio copertura</label>
                                                <input type="number" class="form-control" id="from_age" min="1" aria-describedby="from_age" v-model.number="$v.form.test.from_age.$model" v-bind:class="{'is-invalid': (!$v.form.test.from_age.required || !$v.form.test.from_age.numeric || !$v.form.test.from_age.minValue) && $v.form.test.from_age.$dirty}">

                                                <small v-show="!$v.form.test.from_age.$dirty || !$v.form.test.from_age.$invalid" id="title_info" class="form-text text-muted">Specificare età paziente</small>

                                                <div v-show="!$v.form.test.from_age.required && $v.form.test.from_age.$dirty" class="invalid-feedback">Età richiesta</div>
                                                <div v-show="!$v.form.test.from_age.numeric && $v.form.test.from_age.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                                <div v-show="!$v.form.test.from_age.minValue && $v.form.test.from_age.$dirty" class="invalid-feedback">Età minima di 1 anno</div>

                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="to_age" class="active">Età fine copertura</label>
                                                <input type="number" class="form-control" id="to_age" :min="form.test.from_age" :disabled="!$v.form.test.from_age.required || !$v.form.test.from_age.numeric || !$v.form.test.from_age.minValue" aria-describedby="to_age" v-model.number="$v.form.test.to_age.$model" v-bind:class="{'is-invalid': (!$v.form.test.to_age.required || !$v.form.test.to_age.numeric || !$v.form.test.to_age.minValue) && $v.form.test.to_age.$dirty}">

                                                <small v-show="!$v.form.test.to_age.$dirty || !$v.form.test.to_age.$invalid" id="title_info" class="form-text text-muted">Specificare età paziente</small>

                                                <div v-show="!$v.form.test.to_age.required && $v.form.test.to_age.$dirty" class="invalid-feedback">Età richiesta</div>
                                                <div v-show="!$v.form.test.to_age.numeric && $v.form.test.to_age.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                                <div v-show="!$v.form.test.to_age.minValue && $v.form.test.to_age.$dirty" class="invalid-feedback">Età minima di {{form.test.from_age}} anni</div>
                                            </div>
                                        </div>

                                        <div class="form-check">
                                            <input id="for_man" type="checkbox" v-model.trim="$v.form.test.for_man.$model">
                                            <label for="for_man" v-bind:class="{'text-danger': !$v.form.test.for_man.required}">Screening dedicato all'uomo</label>
                                        </div>
                                        <div class="form-check">
                                            <input id="for_woman" type="checkbox" v-model.trim="$v.form.test.for_woman.$model">
                                            <label for="for_woman" v-bind:class="{'text-danger': !$v.form.test.for_woman.required}">Screening dedicato alla donna</label>
                                        </div>

                                        <div v-show="!$v.form.test.for_man.required || !$v.form.test.for_woman.required" class="alert alert-danger">
                                            Selezionare almeno un sesso
                                        </div>

                                        <p class="font-weight-bold m-0">Modifica i requisiti di accertamento</p>

                                        <div class="alert alert-info">
                                            I requisiti saranno presentati al paziente durante la fase di prenotazione dello screening. Se il paziente non accetterà il requisito proposto, non potrà proseguire con la prenotazione.
                                        </div>                                        

                                        <div v-for="(request_db, index) of form.test.requests" v-bind:key="index">
                                            <div class="form-group mb-1">
                                                <div class="input-group border">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text border-bottom-0 border-right">
                                                            {{index}}
                                                        </div>
                                                    </div>
                                                    
                                                    <textarea class="form-control" :id="`request_${index}`" :name="`request_${index}`" v-model="request_db.request" />

                                                    <div class="input-group-append">
                                                        <button class="btn btn-danger rounded-0 border-0" type="button" :id="`pop_${index}`" @click="deleteRequest(request_db.id, index)" :disabled="loaders.test.deleting">
                                                            x
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="form-check">
                                                <input :id="`req_for_man_${index}`" type="checkbox" v-model="request_db.for_man">
                                                <label :for="`req_for_man_${index}`" class="m-0">Domanda dedicata all'uomo</label>
                                            </div>
                                            <div class="form-check mt-0">
                                                <input :id="`req_for_woman_${index}`" type="checkbox" v-model="request_db.for_woman">
                                                <label :for="`req_for_woman_${index}`" class="m-0">Domanda dedicata alla donna</label>
                                            </div>
                                            <div class="form-check mt-0">
                                                <input :id="`req_important_${index}`" type="checkbox" v-model="request_db.important">
                                                <label :for="`req_important_${index}`">Domanda importante</label>
                                            </div>
                                        </div>

                                        <a class="text-primary" @click="pushRequest">
                                            <small>
                                                + Aggiungi domanda
                                            </small>
                                        </a>

                                        <div class="alert alert-danger" v-show="validation.ko">
                                            {{validation.ko}}
                                        </div>

                                        <div class="it-card-footer">

                                            <button
                                            type        = "submit"
                                            class       = "btn btn-primary"
                                            :disabled   = "
                                                $v.form.test.$invalid ||
                                                loaders.test.updating ||
                                                inputRequestEmpty
                                            "
                                            v-if = "
                                                !function_disabled(
                                                    'Tests',
                                                    'update'
                                                )
                                            ">
                                                
                                                <span v-show="!loaders.test.updating">
                                                    Modifica
                                                </span>

                                                <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.test.updating">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                                

                                            </button>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li
                                v-if = "
                                    !function_disabled(
                                        'Tests',
                                        'delete'
                                    )
                                "
                            >
                                <a class="list-item medium text-truncate" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                    
                                    <span class="mr-2">Elimina</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>

                                </a>
                                <div class="collapse bg-danger text-center text-white" id="answer">
                                    <p class="m-0 border-bottom border-white">
                                        <small class="font-weight-bold">
                                            Sicuro{{form.test.requests.length > 0 ? ', con requisiti correlati' : null}}?
                                        </small>
                                    </p>
                                    <div class="container-fluid">

                                        <div class="row border-top">
                                            <div class="col-6 text-center border-right border-white cursor-pointer" @click="deleteTest" v-bind:class="{'text-center disabled-AO': loaders.test.deleting}">
                                                <small class="font-weight-bold " v-show="!loaders.test.deleting">
                                                    Si
                                                </small>
                                                <div v-show="loaders.test.deleting" class="progress-spinner progress-spinner-double size-sm progress-spinner-active">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                            </div>
                                            <div class="col-6 text-center cursor-pointer" data-toggle="collapse" href="#answer" role="button" aria-expanded="false" aria-controls="answer">
                                                <small class="font-weight-bold">
                                                    No
                                                </small>
                                            </div>                                        
                                        </div>

                                    </div>
                                </div>
                            </li>
                            <li
                                v-if = "
                                    !function_disabled(
                                        'Tests',
                                        'update'
                                    )
                                "
                            >
                                <a
                                    @click  = "suspendTest"
                                    class   = "list-item medium text-truncate"
                                    :class  = "{
                                        'text-center disabled': loaders.test.suspending
                                    }"
                                >
                                    
                                    <span v-show="!loaders.test.suspending && form.test.state" class="mr-2">Rendi non visibile</span>

                                    <svg v-show="!loaders.test.suspending && form.test.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
                                    </svg>

                                    <span v-show="!loaders.test.suspending  && !form.test.state" class="mr-2">Rendi visibile</span>

                                    <svg v-show="!loaders.test.suspending  && !form.test.state" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle ml-2" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                    </svg>

                                    <div v-show="loaders.test.suspending" class="progress-spinner progress-spinner-double size-sm progress-spinner-active mt-1">
                                        <div class="progress-spinner-inner"></div>
                                        <div class="progress-spinner-inner"></div>
                                        <span class="sr-only">Caricamento...</span>
                                    </div>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength,
        numeric,
        minValue
    } from "vuelidate/lib/validators"

    import store from '@/store'

    export default {
        data() {
            return {
                form: {
                    test: {
                        title:       null,
                        description: null,
                        time_expire: null,
                        from_age:    1,
                        to_age:      null,
                        for_man:     false,
                        for_woman:   false,
                    }
                },
                loaders: {
                    test: {
                        updating:   false,
                        reading:    true,
                        deleting:   false,
                        suspending: false
                    }
                },
                validation: {
                    ko: false
                }
            }
        },
        validations() {
            return {
                form: {
                    test: {
                        description: {
                            required,
                            minLength: minLength(8),
                            maxLength: maxLength(350)
                        },
                        time_expire: {
                            required,
                            numeric,
                            minValue: minValue(1)
                        },
                        from_age: {
                            required,
                            numeric,
                            minValue: minValue(1)
                        },
                        to_age: {
                            required,
                            minValue: minValue(this.form.test.from_age),
                            numeric
                        },
                        for_man: {
                            required() {
                                return !(!this.form.test.for_woman && !this.form.test.for_man)
                            }
                        },
                        for_woman: {
                            required() {
                                return !(!this.form.test.for_man && !this.form.test.for_woman)
                            }
                        }
                    }

                }
            }
        },
        methods: {
            async updateTest() {

                this.loaders.test.updating = true
                this.validation.ko         = false

                try {

                    const res = await this.axios.post(`user/tests/update/single`, this.form.test, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/tests`)

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko         = message
                    this.loaders.test.updating = false

                }
            },
            async readTest() {

                try {

                    const test = await this.axios.get(`user/tests/read/detail/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.form.test = test.data.test

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko         = message
                    this.loaders.test.reading = false

                }
            },
            async deleteTest() {

                this.loaders.test.deleting = true

                try {

                    const res = await this.axios.delete(`user/tests/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/tests`)
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.message,
                        type:   `error`
                    })

                    this.loaders.test.deleting = false

                }

            },
            async suspendTest() {

                this.loaders.test.suspending = true

                try {

                    const res = await this.axios.get(`user/tests/suspend/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.form.test.state = !this.form.test.state
                    
                }
                catch(e) {

                    this.$toast.open({
                        message: e.response.data.message,
                        type:   `error`
                    })

                }
                finally {
                    this.loaders.test.suspending = false
                }

            },
            async deleteRequest(id, key) {

                this.loaders.test.deleting = true

                // Se presente ID, ovvero è un campo del DB procedi con eliminazione nel DB altrimenti solo localmente
                if(id) {

                    try {

                        const res = await this.axios.delete(`user/requests/${id}`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        this.$toast.open({
                            message: res.data.message,
                            type:   `success`
                        })

                        this.form.test.requests.splice(key, 1)
                        
                    }
                    catch(e) {

                        this.$toast.open({
                            message: e.response.data.message,
                            type:   `error`
                        })

                    }
                    finally {
                        this.loaders.test.deleting = false
                    }

                }
                else {
                    this.form.test.requests.splice(key, 1)
                    this.loaders.test.deleting = false
                }
            },
            pushRequest() {

                this.form.test.requests.push({
                    request: ``
                })

            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }
        },
        mounted() {
            this.readTest()
        },
        computed: {
            inputRequestEmpty() {

                let empty = false

                for(let create of this.form.test.requests) {
                    if(!create.request) empty = true
                }

                return empty

            }
        }
    }
</script>