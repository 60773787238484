<template>
    <div>

        <div class="bg-cce6ff p-5" style="margin-left: -6px; margin-right: -6px">
            <h1>
                {{nominativo}}
            </h1>
            <p class="m-0">
                Età {{eta}} anni
            </p>
            <p class="m-0">
                Percorso per <span class="text-lowercase">{{sesso}}</span>
            </p>
        </div>

        <div class="container py-5">

            <div class="card-wrapper card-space" v-if="DB.screenings.length < 1 || DB.appointments.length > 0">
                <div class="card card-bg no-after">
                    <div class="card-body">
                        <div class="alert alert-danger m-0" v-if="DB.screenings.length < 1">
                            Attualmente non sono presenti degli screening
                        </div>
                        <div class="alert alert-success m-0" v-if="DB.appointments.length > 0">
                            Attualmente {{ DB.appointments.length == 1 ? `risulta ${DB.appointments.length} appuntamento prenotato` : `risultano ${DB.appointments.length} appuntamenti prenotati`}}: <router-link :to="`/user/appuntamenti`">consulta</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div v-for="screening of DB.screenings" :key="screening.id" of class="col-lg-4">
                    <div class="card-wrapper card-space">
                        <div class="card card-bg border-bottom-card border-bottom-card-with-var-from-vue" :style="{'--color-from-vue': screening.color}">
                            <div class="card-body">

                                <h5 class="card-title big-heading text-truncate">
                                    {{screening.title}}
                                </h5>

                                <p class="card-text m-0">Fascia {{screening.from_age}} - {{screening.to_age}} anni</p>
                                <p class="card-text">Sesso {{screening.for_man ? 'Uomo' : null}} {{(screening.for_woman && screening.for_man) ? 'e' : null}} {{screening.for_woman ? 'Donna' : null}}</p>
                                
                                <p class="card-text">
                                    {{screening.description}}
                                </p>

                                <h5 class="card-title">
                                    Test disponibili
                                </h5>

                                <p v-for="test_db of screening.screenings_tests" :key="test_db.id" class="card-text m-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                    </svg> {{test_db.test.title}}
                                </p>
                            
                                <router-link :to="`/user/percorsi/${screening.title}`" class="read-more" href="#">

                                    <span class="text">Seleziona</span>

                                    <svg class="icon">
                                        <use href="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-right"></use>
                                    </svg>

                                </router-link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import store from  '@/store'
    import moment from 'moment'

    export default {
        data() {
            return {
                DB: {
                    screenings:   [],
                    appointments: []
                }
            }
        },
        methods: {
            async readScreenings() {
                try {

                    const screenings = await this.axios.get(`user/screenings/read/customized`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.screenings = screenings.data.screenings_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le visite. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            async readAppointments() {
                try {

                    const appointments = await this.axios.get(`user/appointments/read/personal/todo`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.appointments = appointments.data.appointments_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                }
            }
        },
        mounted() {
            this.readScreenings()
            this.readAppointments()
        },
        computed: {
            nominativo() {
                return `${store.getters.infoUser.user.name} ${store.getters.infoUser.user.surname}`
            },
            eta() {
                return moment().diff(moment(store.getters.infoUser.user.date_birth), 'years')
            },
            sesso() {
                return store.getters.infoUser.user.sex == 'M' ? 'Uomo' : 'Donna'
            }
        }
    }
</script>