<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container" v-if="
                !loaders.READ.patient &&
                !loaders.READ.appointments
            ">
                <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="card-space mb-3">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate mb-4">
                                        Anagrafica
                                    </h3>
                                    <div class="form-group mb-4">

                                        <label for="CF" class="active">
                                            Codice fiscale
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "CF"
                                            :value          = "form.patient.CF"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="name" class="active">
                                            Nome
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "name"
                                            :value          = "form.patient.name"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="surname" class="active">
                                            Cognome
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "surname"
                                            :value          = "form.patient.surname"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="sex" class="active">
                                            Sesso
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "sex"
                                            :value          = "form.patient.sex == 'M' ? 'uomo' : 'donna'"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="date_birth" class="active">
                                            Data nascita
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "date_birth"
                                            :value          = "form.patient.date_birth"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="username" class="active">
                                            username
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "username"
                                            :value          = "form.patient.username"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group mb-4">

                                        <label for="email" class="active">
                                            email
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "email"
                                            :value          = "form.patient.email"
                                            disabled
                                        >

                                    </div>
                                    <div class="form-group m-0">

                                        <label for="phone" class="active">
                                            Cellulare
                                        </label>
                                        <input
                                            type            = "text"
                                            class           = "form-control"
                                            id              = "phone"
                                            :value          = "form.patient.phone"
                                            disabled
                                        >

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card-space mb-3">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate">
                                        Screening richiesti
                                    </h3>

                                    <div v-if="form.patient.patients_screenings_created.length < 1">
                                        <div class="alert alert-warning m-0">
                                            Nessuno screening effettuato
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="table-responsive">
                                            <table class="table table-striped table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            Screening
                                                        </th>
                                                        <th scope="col">
                                                            Test
                                                        </th>
                                                        <th scope="col">
                                                            Appuntamenti
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="patient_screening of form.patient.patients_screenings_created" :key="patient_screening.id">
                                                        <td>
                                                            {{patient_screening.screenings.title}}
                                                        </td>
                                                        <td>
                                                            {{patient_screening.tests.title}}
                                                        </td>
                                                        <td>
                                                            {{patient_screening.appointments.length}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="card-space mb-3">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate">
                                        Appuntamenti
                                    </h3>

                                    <div v-if="DB.appointments.length < 1">
                                        <div class="alert alert-warning m-0">
                                            Nessuno appuntamento richiesto
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="table-responsive">
                                            <table class="table table-striped table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            Data
                                                        </th>
                                                        <th scope="col">
                                                            Ora
                                                        </th>
                                                        <th scope="col">
                                                            Struttura
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <router-link
                                                        tag     = "tr"
                                                        :to     = "`/admin/appuntamenti/modifica/${appointment.id}`"
                                                        v-for   = "appointment of DB.appointments"
                                                        :key    = "appointment.id"
                                                    >
                                                        <td>
                                                            {{appointment.date | data_slash}}
                                                        </td>
                                                        <td>
                                                            {{appointment.time | data_ora}}
                                                        </td>
                                                        <td>
                                                            {{appointment.structures.title}}
                                                        </td>
                                                    </router-link>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">
                                    Funzioni
                                </h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">                                    
                                    <i class="text-muted">
                                        Nessuna funzione
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment'

    export default {
        data() {
            return {
                form: {
                    patient: null
                },
                DB: {
                    appointments: []
                },
                loaders: {
                    READ: {
                        patient: true,
                        appointments: true
                    }
                }
            }
        },
        methods: {
            async read_patient() {
                try {

                    this.form.patient = (
                        await this.axios.get(
                            `user/patients/read/single/${this.$route.params.CF}`, 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data.patient

                    // Formattazione data
                    this.form.patient.date_birth = moment(
                        this.form.patient.date_birth
                    ).format(
                        "DD/MM/YYYY"
                    )
                    
                }
                catch(e) {
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i pazienti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
                finally {
                    this.loaders.READ.patient = false
                }
            },
            async read_appointments() {
                try {

                    this.DB.appointments = (
                        await this.axios.get(
                            `user/patients/read/appointment_associated/${this.$route.params.CF}`, 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data.appointments_db

                    console.log(
                        "APPUNTAMENTI",
                        this.DB.appointments
                    )
                    // Formattazione data
                    //this.form.patient.date_birth = moment(
                    //    this.form.patient.date_birth
                    //).format(
                    //    "DD/MM/YYYY"
                    //)
                    
                }
                catch(e) {
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i pazienti. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
                finally {
                    this.loaders.READ.appointments = false
                }
            }
        },
        mounted() {
            this.read_patient()
            this.read_appointments()
        }
    }
</script>