<template>
    <div class="row">
        <div class="col-lg-10 pt-2 pb-2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="card-space">
                            <div class="card card-bg no-after mb-4">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate mb-1">
                                        Tipologia di utenza
                                    </h3>
                                    <p class="card-text m-0">
                                        Per una tipologia di utenza è possibile modificarne solo la descrizione
                                    </p>

                                    <hr class="py-2 w-25 ml-0">

                                    <form @submit.prevent="update_user_type" autocomplete="off">

                                        <div class="form-group mb-4">

                                            <label for="title" class="active">
                                                Nome
                                            </label>
                                            <input
                                                type    = "text"
                                                class   = "form-control text-uppercase"
                                                id      = "title"
                                                :value  = "form.user_type.title"
                                                disabled
                                            >

                                        </div>

                                        <div class="form-group mb-2">

                                            <label for="description" class="active">
                                                Descrizione
                                            </label>
                                            
                                            <textarea 
                                                class   = "form-control border"
                                                id      = "description"
                                                v-model = "$v.form.user_type.description.$model"
                                                :class  = "{
                                                    'is-invalid': !$v.form.user_type.description.required,
                                                    'is-valid': validations.update.user_type.description.OK
                                                }"
                                            ></textarea>

                                            <div class="invalid-feedback" v-show="
                                                !$v.form.user_type.description.required
                                            ">
                                                La descrizione è richiesta
                                            </div>
                                            <div class="valid-feedback" v-show="
                                                validations.update.user_type.description.OK
                                            ">
                                                {{
                                                    validations.update.user_type.description.OK
                                                }}
                                            </div>

                                        </div>

                                        <button class="btn btn-primary mt-3" :disabled="
                                            loaders.update.user_type ||
                                            $v.form.user_type.$invalid
                                        " v-if = "
                                            !function_disabled(
                                                'Autorizzazioni',
                                                'update'
                                            )
                                        ">
                                            <span v-show="
                                                !loaders.update.user_type
                                            ">
                                                Modifica
                                            </span>
                                            <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="
                                                loaders.update.user_type
                                            ">
                                                <div class="progress-spinner-inner"></div>
                                                <div class="progress-spinner-inner"></div>
                                                <span class="sr-only">Caricamento...</span>
                                            </div>

                                        </button>

                                    </form>

                                </div>
                            </div>
                        </div>
                        <div class="card-space" v-if = "
                                !function_disabled(
                                    'Autorizzazioni',
                                    'update'
                                )
                            ">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <ul class="nav nav-tabs" id="tabs" role="tablist">
                                        <li class="nav-item">
                                            <a
                                                class           = "nav-link active"
                                                id              = "btn_tab_authorizations"
                                                data-toggle     = "tab"
                                                href            = "#tab_authorizations"
                                                role            = "tab"
                                                aria-controls   = "tab_authorizations"
                                                aria-selected   = "true"
                                            >
                                                Autorizzazioni
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class           = "nav-link"
                                                id              = "btn_tab_users"
                                                data-toggle     = "tab"
                                                href            = "#tab_users"
                                                role            = "tab"
                                                aria-controls   = "tab_users"
                                                aria-selected   = "false"
                                            >
                                                Utenti
                                            </a>
                                        </li>
                                    </ul>

                                    <div class="tab-content py-2" id="tabs">
                                        <div
                                            class           = "tab-pane fade show active"
                                            id              = "tab_authorizations"
                                            role            = "tabpanel"
                                            aria-labelledby = "btn_tab_authorizations"
                                        >
                                            <div class="row">
                                                <div class="col-lg-10 py-2">
                                                    <div class="alert alert-warning" v-if="
                                                        form.user_type.users_software_functions < 1
                                                    ">
                                                        Questa tipologia di utente non ha autorizzazioni di utilizzo per il software
                                                    </div>
                                                    <div class="table-responsive" v-else>
                                                        <table class="table table-striped table-hover table-sm m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Funzionalità</th>
                                                                    <th scope="col">Creazione</th>
                                                                    <th scope="col">Lettura</th>
                                                                    <th scope="col">Modifica</th>
                                                                    <th scope="col">Eliminazione</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <router-link
                                                                    v-for   = "user_software_function of form.user_type.users_software_functions"
                                                                    :key    = "user_software_function.id"
                                                                    tag     = "tr"
                                                                    :to     = "`/admin/autorizzazioni/modifica/${$route.params.id}/modifica/${user_software_function.id}`"
                                                                >
                                                                    <td>
                                                                        {{user_software_function.software_function.title}}
                                                                    </td>
                                                                    <td :class="
                                                                        {
                                                                            'text-success': user_software_function.create,
                                                                            'text-danger': !user_software_function.create
                                                                        }
                                                                    ">
                                                                        {{user_software_function.create ? "attivo" : "inattivo"}}
                                                                    </td>
                                                                    <td :class="
                                                                        {
                                                                            'text-success': user_software_function.read,
                                                                            'text-danger': !user_software_function.read
                                                                        }
                                                                    ">
                                                                        {{user_software_function.read ? "attivo" : "inattivo"}}
                                                                    </td>
                                                                    <td :class="
                                                                        {
                                                                            'text-success': user_software_function.update,
                                                                            'text-danger': !user_software_function.update
                                                                        }
                                                                    ">
                                                                        {{user_software_function.update ? "attivo" : "inattivo"}}
                                                                    </td>
                                                                    <td :class="
                                                                        {
                                                                            'text-success': user_software_function.delete,
                                                                            'text-danger': !user_software_function.delete
                                                                        }
                                                                    ">
                                                                        {{user_software_function.delete ? "attivo" : "inattivo"}}
                                                                    </td>
                                                                </router-link>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2">
                                                    <div class="sidebar-wrapper">
                                                        <div class="sidebar-linklist-wrapper">
                                                            <div class="link-list-wrapper">
                                                                <ul class="link-list text-lg-right">
                                                                    <li>
                                                                        <h3 class="text-truncate pr-0">Funzioni</h3>
                                                                    </li>
                                                                    <li>
                                                                        <router-link :to="`/admin/autorizzazioni/modifica/${$route.params.id}/aggiungi`" class="pr-0">

                                                                            <span class="mr-2">Aggiungi</span>

                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle mb-1" viewBox="0 0 16 16">
                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                                            </svg>
                                                                            
                                                                        </router-link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div
                                            class           = "tab-pane fade show"
                                            id              = "tab_users"
                                            role            = "tabpanel"
                                            aria-labelledby = "btn_tab_users"
                                        >
                                            <div class="alert alert-warning" v-if="
                                                form.user_type.users < 1
                                            ">
                                                Questa tipologia di utente non è stata assegnata ad alcun utente
                                            </div>
                                            <div class="table-responsive" v-else>
                                                <table class="table table-striped table-hover table-sm m-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Nome</th>
                                                            <th scope="col">Cognome</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Telefono</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <router-link
                                                            tag     = "tr"
                                                            :to     = "`/admin/utenti/modifica/${user.id}`"
                                                            v-for   = "user of form.user_type.users"
                                                            :key    = "user.id"
                                                        >
                                                            <td>{{user.name}}</td>
                                                            <td>{{user.surname}}</td>
                                                            <td>{{user.email}}</td>
                                                            <td>{{user.phone}}</td>
                                                        </router-link>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">
                                    Funzioni
                                </h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">                                    
                                    <i class="text-muted">
                                        Nessuna funzione
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {
        required
    } from "vuelidate/lib/validators"

    import store from '@/store'

    export default {
        data() {
            return {
                form: {
                    user_type: {
                        title:          null,
                        description:    null
                    }
                },
                loaders: {
                    update: {
                        user_type: false
                    }
                },
                validations: {
                    update: {
                        user_type: {
                            description: {
                                OK: false,
                                KO: false
                            }
                        }
                    }
                }
            }
        },
        validations() {
            return {
                form: {
                    user_type: {
                        description: {
                            required
                        }
                    }
                }
            }
        },
        methods: {
            async read_user_type() {
                try {
                    this.form.user_type = (
                        await this.axios.get(
                            `user/user_types/read/detail/${this.$route.params.id}`, 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data
                }
                catch(e){
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le tipologie di utenza. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
            },
            async update_user_type() {

                this.loaders.update.user_type = true
                this.validations.update.user_type.description.OK = false
                this.validations.update.user_type.description.KO = false

                try {

                    await this.axios.post(
                        `user/user_types/update/detail/${this.$route.params.id}`,
                        this.form.user_type,
                        {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }
                    )

                    const message = "Tipologia utente modificata con successo"

                    this.$toast.open({
                        message,
                        type: "success"
                    })

                    this.validations.update.user_type.description.OK = message

                }
                catch(e) {

                    const {
                        message
                    } = e.response.data

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                }
                finally {
                    this.loaders.update.user_type = false
                }

            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }
        },
        mounted() {
            this.read_user_type()
        }
    }
</script>