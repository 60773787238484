<template>
    <div class="row">
        <div class="col-lg-10 pt-2 pb-2">
            <div class="container-fluid">
                <div class="row" v-show="view == 'cards'">
                    <div class="col-md-6 col-lg-4" v-for="screening of DB.screenings" :key="screening.id">
                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">
                                    
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="card-title text-truncate"><span class="ball" :style="`background: ${screening.color}`"></span> {{screening.title}}</h5>
                                        </div>
                                        <div class="col-md-4 text-right">
                                            <span v-if="screening.state" class="badge badge-primary">Visibile</span>
                                            <span v-else class="badge badge-danger">Non visibile</span>
                                        </div>
                                    </div>

                                    <p class="card-text m-0">Fascia {{screening.from_age}} - {{screening.to_age}} anni</p>
                                    <p class="card-text m-0">Sesso {{screening.for_man ? 'Uomo' : null}} {{screening.for_woman ? 'Donna' : null}}</p>
                                    <p class="card-text">{{screening.screenings_tests.length}} test {{screening.screenings_tests.length == 1 ? 'associato' : 'associati'}}</p>
                                    <p class="card-text m-0">{{screening.description}}</p>
                                    

                                    <div class="it-card-footer mt-3">
                                        <router-link
                                            class   = "btn btn-outline-primary btn-sm"
                                            tag     = "button"
                                            :to     = "`screenings/modifica/${screening.title}`"
                                            href    = "#"
                                            v-if   = "
                                                !function_disabled(
                                                    'Screenings',
                                                    'read'
                                                )
                                            "
                                        >
                                            Modifica
                                        </router-link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" v-show="view == 'table'">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Titolo</th>
                                <th scope="col">Descrizione</th>
                                <th scope="col">Fascia</th>
                                <th scope="col">Sesso</th>
                                <th scope="col">Visibilità</th>
                            </tr>
                        </thead>
                        <tbody>
                            <router-link 
                            tag     = "tr"
                            :to     = "!function_disabled(
                                'Screenings',
                                'read'
                            ) ? `screenings/modifica/${screening.title}` : 'screenings'"
                            v-for   = "screening of DB.screenings"
                            :key    = "screening.id"
                            >
                                <th scope="row"><span class="ball" :style="`background: ${screening.color}`"></span> {{screening.title}}</th>
                                <td>{{screening.description}}</td>
                                <td>{{screening.from_age}} - {{screening.to_age}} anni</td>
                                <td>{{screening.for_man ? 'Uomo' : null}} {{screening.for_woman ? 'Donna' : null}}</td>
                                <td>{{screening.state ? 'Visibile' : 'Non visibile'}}</td>
                            </router-link>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li>
                                <router-link
                                    class   = "list-item medium text-truncate"
                                    to      = "/admin/screenings/aggiungi"
                                    v-if    = "
                                        !function_disabled(
                                            'Screenings',
                                            'create'
                                        )
                                    "
                                >
                                    
                                    <span class="mr-2">Aggiungi</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>

                                </router-link>
                            </li>
                            <li>
                                <h3 class="text-truncate mt-3">Visualizzazione</h3>
                            </li>
                            <li v-show="view == `cards`">                                
                                <a class="list-item medium text-truncate" @click="view = `table`">
                                    
                                    <span class="mr-2">Tabellato</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table mb-1" viewBox="0 0 16 16">
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                                    </svg>

                                </a>
                            </li>
                            <li v-show="view == `table`">                                
                                <a class="list-item medium text-truncate" @click="view = `cards`">
                                    
                                    <span class="mr-2">Cards</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-text mb-1" viewBox="0 0 16 16">
                                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                        <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                                    </svg>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    export default {
        data() {
            return {
                DB: {
                    screenings: []
                },
                view: `cards`
            }
        },
        methods: {
            async readScreenings() {
                try {

                    const screenings = await this.axios.get(`user/screenings/read/all`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.screenings = screenings.data.screenings_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i screenings. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }
        },
        mounted() {
            this.readScreenings()
        }
    }
</script>