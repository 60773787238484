<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <div v-if="DB.tests.length > 0">

                                        <form @submit.prevent="createStructureTest" autocomplete="off">
                                            
                                            <h5 class="card-title">Seleziona il test da associare alla struttura</h5>

                                            <div v-for="test of DB.tests" :key="`test_${test.id}`" class="form-check form-check-group">
                                                <input name="test" type="radio" :id="`test_${test.id}`" :aria-labelledby="`test_help_${test.id}`" :value="test.id" v-model.number="$v.form.structure_test.id_test.$model">
                                                <label :for="`test_${test.id}`">{{test.title}}</label>
                                                <small :id="`test_help_${test.id}`" class="form-text">{{test.description}}</small>
                                            </div>

                                            <!--<div class="form-group mt-4">

                                                <label for="appointment_duration" class="active">Durata appuntamento (minuti)</label>
                                                <input type="number" class="form-control" id="appointment_duration" min="1" aria-describedby="appointment_duration" v-model.number="$v.form.structure_test.appointment_duration.$model" v-bind:class="{'is-invalid': (!$v.form.structure_test.appointment_duration.required || !$v.form.structure_test.appointment_duration.numeric || !$v.form.structure_test.appointment_duration.minValue) && $v.form.structure_test.appointment_duration.$dirty}">

                                                <small v-show="!$v.form.structure_test.appointment_duration.$dirty || !$v.form.structure_test.appointment_duration.$invalid" id="title_info" class="form-text text-muted">Specificare la durata di un appuntamento in minuti</small>

                                                <div v-show="!$v.form.structure_test.appointment_duration.required && $v.form.structure_test.appointment_duration.$dirty" class="invalid-feedback">Durata richiesta</div>
                                                <div v-show="!$v.form.structure_test.appointment_duration.numeric && $v.form.structure_test.appointment_duration.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                                <div v-show="!$v.form.structure_test.appointment_duration.minValue && $v.form.structure_test.appointment_duration.$dirty" class="invalid-feedback">Durata minima di 1 minuto</div>

                                            </div>-->

                                            <div class="form-group mt-4">

                                                <label for="appointment_fixs" class="active">limite appuntamenti fissabili</label>
                                                <input type="number" class="form-control" id="appointment_fixs" min="1" aria-describedby="appointment_fixs" v-model.number="$v.form.structure_test.appointment_fixs.$model" v-bind:class="{'is-invalid': (!$v.form.structure_test.appointment_fixs.required || !$v.form.structure_test.appointment_fixs.numeric || !$v.form.structure_test.appointment_fixs.minValue) && $v.form.structure_test.appointment_fixs.$dirty}">

                                                <small v-show="!$v.form.structure_test.appointment_fixs.$dirty || !$v.form.structure_test.appointment_fixs.$invalid" id="title_info" class="form-text text-muted">Specificare la quantità massima di un appuntamenti fissabili in una giornata</small>

                                                <div v-show="!$v.form.structure_test.appointment_fixs.required && $v.form.structure_test.appointment_fixs.$dirty" class="invalid-feedback">Quantità richiesta</div>
                                                <div v-show="!$v.form.structure_test.appointment_fixs.numeric && $v.form.structure_test.appointment_fixs.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                                <div v-show="!$v.form.structure_test.appointment_fixs.minValue && $v.form.structure_test.appointment_duration.$dirty" class="invalid-feedback">Quantità minima di 1 appuntamento</div>

                                            </div>
                                            
                                            <div class="alert alert-danger mt-3 mb-3" v-show="validation.ko">
                                                {{validation.ko}}
                                            </div>

                                            <div class="it-card-footer mt-0">

                                                <button type="submit" class="btn btn-primary">
                                                    
                                                    <span v-show="!loaders.creating.structure">
                                                        Aggiungi
                                                    </span>

                                                    <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.creating.structure">
                                                        <div class="progress-spinner-inner"></div>
                                                        <div class="progress-spinner-inner"></div>
                                                        <span class="sr-only">Caricamento...</span>
                                                    </div>
                                                    

                                                </button>

                                            </div>

                                        </form>

                                    </div>

                                    <div v-else>
                                        <div class="alert alert-danger m-0">
                                            Non sono presenti test da associare
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">
                                    
                                    <i class="text-muted">Nessuna funzione</i>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        numeric,
        minValue
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                DB: {
                    tests: []
                },
                form: {
                    structure_test: {
                        id_test: null,
                        //appointment_duration: 1,
                        appointment_fixs:  null,
                        title_structure:   this.$route.params.title,
                        id_structure_type: this.$route.params.id_structure_type,
                    }
                },
                loaders: {
                    creating: {
                        structure: false
                    }
                },
                validation: {
                    ko: false
                }
            }
        },
        validations() {
            return {
                form: {
                    structure_test: {
                        id_test: {
                            required
                        },
                        appointment_duration: {
                            //required,
                            numeric,
                            minValue: minValue(1)
                        },
                        appointment_fixs: {
                            //required,
                            numeric,
                            minValue: minValue(1)
                        }
                    }
                }
            }
        },
        methods: {

            async readTests() {
                try {

                    const tests = await this.axios.get(`user/structures_tests/read/tests/selectable/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.tests = tests.data.tests_db
                    
                }
                catch(e) {

                    console.error(e)

                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i tests. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            async createStructureTest() {

                this.loaders.creating.structure = true
                this.validation.ko              = false

                try {

                    const res = await this.axios.post(`user/structures_tests/create/single/${this.$route.params.title}`, this.form.structure_test, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture/modifica/${this.$route.params.title}`)
                    
                }
                catch(e) {

                    const message = `Non è momentaneamente possibile create l'associazione. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`

                    this.loaders.creating.structure = false
                    this.validation.ko              = message
                    
                    this.$toast.open({
                        message,
                        type: `error`
                    })

                }
            }


        },
        mounted() {
            this.readTests()
        }
    }
</script>