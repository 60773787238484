var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 pt-2 pb-2"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card-wrapper card-space"},[_c('div',{staticClass:"card card-bg no-after"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-hover table-sm m-0"},[_vm._m(0),_c('tbody',_vm._l((_vm.DB.user_types),function(user_type){return _c('router-link',{key:user_type.id,attrs:{"tag":"tr","to":!_vm.function_disabled(
                                            'Autorizzazioni',
                                            'create'
                                        ) ? ("autorizzazioni/modifica/" + (user_type.id)) : 'autorizzazioni'}},[_c('td',[_vm._v(_vm._s(user_type.title))]),_c('td',[_vm._v(_vm._s(user_type.description))]),_c('td',[_vm._v(_vm._s(user_type.users.length))])])}),1)])])])])])])]),_c('div',{staticClass:"col-lg-2 border-left-lg"},[_c('div',{staticClass:"sidebar-wrapper"},[_c('div',{staticClass:"sidebar-linklist-wrapper"},[_c('div',{staticClass:"link-list-wrapper"},[_c('ul',{staticClass:"link-list text-lg-right"},[_vm._m(1),_c('li',[(
                                    !_vm.function_disabled(
                                        'Autorizzazioni',
                                        'create'
                                    )
                                )?_c('router-link',{staticClass:"list-item medium text-truncate",attrs:{"to":"/admin/utenti/aggiungi"}},[_c('span',{staticClass:"mr-2"},[_vm._v("Aggiungi")]),_c('svg',{staticClass:"bi bi-plus-circle mb-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"}}),_c('path',{attrs:{"d":"M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"}})])]):_vm._e()],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Nome tipologia utente")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Descrizione")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Utenti")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('h3',{staticClass:"text-truncate"},[_vm._v(" Funzioni ")])])}]

export { render, staticRenderFns }