<template>
    <div class="py-3 container-fluid">
        <div class="row">

            <div class="offset-lg-4 col-lg-4">

                <div class="card-space h-100">
                    <div class="card card-bg no-after ml-0 h-100">
                        <div class="card-body">

                            <h5 class="card-title big-heading text-center">
                                ANNULLARE APPUNTAMENTO
                            </h5>
                            
                            <hr>

                            <h5 class="card-title big-heading text-center">
                                {{appointment.date | data_mese_scritto_v2}} {{appointment.time | data_ora}}
                            </h5>

                            <p class="card-text text-center mb-0">
                                Annullando l'appuntamento <b>perdi</b> il tuo <b>posto riservato</b>
                            </p>
                            <p class="card-text text-center">
                                Sei sicuro di voler procedere?
                            </p>

                            <form @submit.prevent="rejectAppointment" autocomplete="off">

                                <div class="form-group">

                                    <label for="motivation">
                                        Motivazioni
                                    </label>

                                    <textarea id="motivation" class="form-control border" rows="3" v-model.trim="$v.form.motivation.$model" v-bind:class="{'is-invalid border-danger': (!$v.form.motivation.required || !$v.form.motivation.minLength) && $v.form.motivation.$dirty}" />
                                    
                                    <small v-show="!$v.form.motivation.$dirty || !$v.form.motivation.$invalid" id="motivation_info" class="form-text text-muted">
                                        Fornisci una breve descrizione di almeno 8 caratteri
                                    </small>
                                    
                                    <div v-show="!$v.form.motivation.required && $v.form.motivation.$dirty" class="invalid-feedback">
                                        Descrizione richiesta
                                    </div>
                                    <div v-show="!$v.form.motivation.minLength && $v.form.motivation.$dirty" class="invalid-feedback"
                                    >Digitare minimo 8 caratteri
                                    </div>

                                </div>

                                <div class="alert alert-danger" v-show="alert.KO">
                                    {{alert.KO}}
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <router-link tag="button" :to="`/user/appuntamenti/modifica/${$route.params.id}`" class="btn btn-primary btn-lg btn-block rounded mb-2 mb-lg-0">No</router-link>
                                    </div>
                                    <div class="col-md-6">

                                        <button type="submit" class="btn btn-danger btn-lg btn-block rounded" :disabled="loaders.reject || $v.$invalid">

                                            <span v-show="!loaders.reject">
                                                Si
                                            </span>

                                            <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.reject">
                                                <div class="progress-spinner-inner"></div>
                                                <div class="progress-spinner-inner"></div>
                                                <span class="sr-only">Caricamento...</span>
                                            </div>
                                            
                                        </button>

                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
                        
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                appointment: {

                },
                form: {
                    motivation: null
                },
                loaders: {
                    reject: false
                },
                alert: {
                    KO: false
                }
            }
        },
        validations() {
            return {
                form: {
                    motivation: {
                        required,
                        minLength: minLength(8)
                    }
                }
            }
        },
        methods: {
            async readAppointment() {
                try {

                    const appointment = await this.axios.get(`user/appointments/read/single/${this.$route.params.id}`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.appointment = appointment.data.appointment
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: "La tua sessione è scaduta, effettua nuovamente il login",
                            type:    "error"
                        })

                        this.$router.push('/sessionescaduta')
                    }
                }
            },
            async rejectAppointment() {

                this.loaders.reject = true
                this.alert.KO       = false

                try {

                    const appointment = await this.axios.post(`user/appointments/reject`, {id: this.$route.params.id, ...this.form},  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: appointment.data.message,
                        type:    "success"
                    })

                    this.$router.push('/user/appuntamenti')

                }
                catch(e) {

                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')

                    }
                    else {

                        const {
                            message
                        } = e.response.data

                        this.alert.KO = message

                        this.$toast.open({
                            message,
                            type: "error"
                        })
                    }

                }
                finally {
                    this.loaders.reject = false
                }
                
            }
        },
        mounted() {
            this.readAppointment()
        }
    }
</script>