var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 pt-2 pb-2"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card-wrapper card-space"},[_c('div',{staticClass:"card card-bg no-after"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped table-hover table-sm m-0"},[_vm._m(0),_c('tbody',_vm._l((_vm.DB.users),function(user){return _c('router-link',{key:user.id,attrs:{"tag":"tr","to":!_vm.function_disabled(
                                            'Utenti',
                                            'read'
                                        ) ? ("utenti/modifica/" + (user.id)) : 'utenti'}},[_c('td',[_vm._v(_vm._s(user.name))]),_c('td',[_vm._v(_vm._s(user.surname))]),_c('td',[_vm._v(_vm._s(user.email))]),_c('td',[_vm._v(_vm._s(user.phone))]),_c('td',[_vm._v(_vm._s(user.user_type.title))])])}),1)])])])])])])]),_c('div',{staticClass:"col-lg-2 border-left-lg"},[_c('div',{staticClass:"sidebar-wrapper"},[_c('div',{staticClass:"sidebar-linklist-wrapper"},[_c('div',{staticClass:"link-list-wrapper"},[_c('ul',{staticClass:"link-list text-lg-right"},[_vm._m(1),(
                            !_vm.function_disabled(
                                'Utenti',
                                'create'
                            )
                        )?_c('li',[_c('router-link',{staticClass:"list-item medium text-truncate",attrs:{"to":"/admin/utenti/aggiungi"}},[_c('span',{staticClass:"mr-2"},[_vm._v("Aggiungi")]),_c('svg',{staticClass:"bi bi-plus-circle mb-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"}}),_c('path',{attrs:{"d":"M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"}})])])],1):_vm._e()])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Nome")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Cognome")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Email")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Telefono")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Tipologia")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('h3',{staticClass:"text-truncate"},[_vm._v(" Funzioni ")])])}]

export { render, staticRenderFns }