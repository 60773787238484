<template>
    <div>
        <div class="it-header-slim-wrapper">
            <div class="container">
                <div class="it-header-slim-wrapper-content">

                    <a class="d-none d-lg-block navbar-brand">Screening - Sistema prenotazione ASL Roma 2</a>

                    <div class="nav-mobile">
                        <nav>
                            <a class="it-opener d-lg-none">
                                <span>Screening - Sistema prenotazione</span>
                            </a>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-6 pl-lg-4">
                    <a class="navbar-brand" href>
                        <img src="../assets/LOGO_ASL_ROMA_2.png" class="w-50 w-lg-50">
                    </a>
                </div>
                <div class="col-6 text-right">
                    <a class="navbar-brand mr-0" href>
                        <img src="../assets/LOGO_REGIONE_LAZIO.png" class="w-50 w-lg-50">
                    </a>
                </div>
            </div>
        </div>
        <div class="bg-ASL_ROMA_2-red p-3" v-if="!isLogged">

        </div>
        <div class="it-header-slim-wrapper px-2" v-else>
            <div class="container-fluid">
                <div class="it-header-slim-wrapper-content">

                    <a
                        @click  = "$router.go(-1)"
                        class   = "mr-2 mb-1"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                        </svg>
                    </a>

                    <a class="d-none d-lg-block navbar-brand">
                        <b>{{sex == 'M' ? 'Benvenuto' : 'Benvenuta'}}</b> {{username}}
                    </a>

                    <div class="nav-mobile">
                        <nav>
                            <a class="it-opener d-lg-none" data-toggle="collapse" href="#menu1" role="button" aria-expanded="false" aria-controls="menu1">

                                <span>{{sex == 'M' ? 'Benvenuto' : 'Benvenuta'}} {{username}}</span>

                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                </svg>

                            </a>
                            <div class="link-list-wrapper collapse" id="menu1">
                                <ul class="link-list border-0 p-0 m-0">
                                    <li
                                        v-show = "isPatient"
                                    >

                                        <router-link
                                            to      = "/user/percorsi"
                                            class   = "list-item text-right pr-2"
                                            :class  = "{
                                                'active':
                                                $route.name == 'user_screenings_index'  ||
                                                $route.name == 'user_screenings_detail' ||
                                                $route.name == 'user_tests_detail'
                                            }"
                                        >
                                            Percorsi
                                        </router-link>

                                    </li>
                                    <li v-show="isPatient">
                                        <router-link
                                            to      = "/user/appuntamenti"
                                            class   = "list-item text-right pr-2" 
                                            :class  = "{
                                            'active': $route.name == 'user_appointments_index'
                                        }">                                            
                                            Appuntamenti <span class="badge badge-light mb-1 mr-0">{{DB.appointments.length}}</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to      = "/impostazioni"
                                            class   = "list-item text-right pr-2"
                                        >
                                            Impostazioni
                                        </router-link>
                                    </li>                                   
                                    <li>
                                        <router-link
                                            to      = "/profilo"
                                            class   = "list-item text-right pr-2"
                                        >
                                            Profilo
                                        </router-link>
                                    </li>
                                    <li>
                                        <a
                                            class   = "list-item text-right pr-2"
                                            href    = "#"
                                            @click  = "logout"
                                        >
                                            Disconnetti
                                        </a>
                                    </li>
                                    <!-- Copia dell'aside left -->
                                    <li class = "d-lg-none">
                                        <router-link
                                            class           = "list-item text-right pr-2"
                                            to              = "/admin/appuntamenti"
                                            data-toggle     = "collapse"
                                            href            = "#menu1"
                                            role            = "button"
                                            aria-expanded   = "false"
                                            aria-controls   = "menu1"
                                            v-if = "
                                                functionEnabled(
                                                    'Agenda'
                                                )
                                            "
                                        >
                                            Appuntamenti
                                        </router-link>
                                    </li>
                                    <li class = "d-lg-none">
                                        <router-link
                                            class           = "list-item text-right pr-2"
                                            to              = "/admin/uploads"
                                            data-toggle     = "collapse"
                                            href            = "#menu1"
                                            role            = "button"
                                            aria-expanded   = "false"
                                            aria-controls   = "menu1"
                                            v-if = "
                                                functionEnabled(
                                                    'Uploads'
                                                )
                                            "
                                        >
                                            Esito uploads
                                        </router-link>
                                    </li>                                    
                                    <li class = "d-lg-none">
                                        <router-link
                                            class           = "list-item text-right pr-2"
                                            to              = "/admin/screenings"
                                            data-toggle     = "collapse"
                                            href            = "#menu1"
                                            role            = "button"
                                            aria-expanded   = "false"
                                            aria-controls   = "menu1"
                                            v-if = "
                                                functionEnabled(
                                                    'Screenings'
                                                )
                                            "
                                        >
                                            Screenings
                                        </router-link>
                                    </li>
                                    <li class = "d-lg-none">
                                        <router-link
                                            class           = "list-item text-right pr-2"
                                            to              = "/admin/tests"
                                            data-toggle     = "collapse"
                                            href            = "#menu1"
                                            role            = "button"
                                            aria-expanded   = "false"
                                            aria-controls   = "menu1"
                                            v-if = "
                                                functionEnabled(
                                                    'Tests'
                                                )
                                            "
                                        >
                                            Tests
                                        </router-link>
                                    </li>
                                    <li class = "d-lg-none">
                                        <router-link
                                            class           = "list-item text-right pr-2"
                                            to              = "/admin/strutture"
                                            data-toggle     = "collapse"
                                            href            = "#menu1"
                                            role            = "button"
                                            aria-expanded   = "false"
                                            aria-controls   = "menu1"
                                            v-if = "
                                                functionEnabled(
                                                    'Strutture'
                                                )
                                            "
                                        >
                                            Strutture
                                        </router-link>
                                    </li>
                                    <li class = "d-lg-none">
                                        <router-link
                                            class           = "list-item text-right pr-2"
                                            to              = "/admin/strutture/tipologia"
                                            data-toggle     = "collapse"
                                            href            = "#menu1"
                                            role            = "button"
                                            aria-expanded   = "false"
                                            aria-controls   = "menu1"
                                            v-if    = "
                                                functionEnabled(
                                                    'Strutture'
                                                )
                                            "
                                        >
                                        Tipi struttura
                                        </router-link>
                                    </li>
                                    <li class = "d-lg-none">
                                        <router-link
                                            class           = "list-item text-right pr-2"
                                            to              = "/admin/pazienti" 
                                            data-toggle     = "collapse"
                                            href            = "#menu1"
                                            role            = "button"
                                            aria-expanded   = "false"
                                            aria-controls   = "menu1"
                                            v-if    = "
                                                functionEnabled(
                                                    'Pazienti'
                                                )
                                            "
                                        >
                                        Pazienti
                                        </router-link>
                                    </li>
                                    <li class = "d-lg-none">
                                        <router-link
                                            class           = "list-item text-right pr-2"
                                            to              = "/admin/utenti"
                                            data-toggle     = "collapse"
                                            href            = "#menu1"
                                            role            = "button"
                                            aria-expanded   = "false"
                                            aria-controls   = "menu1"
                                            v-if            = "
                                                functionEnabled(
                                                    'Utenti'
                                                )
                                            "
                                        >
                                            Utenti
                                        </router-link>
                                    </li>
                                    <li class = "d-lg-none">
                                        <router-link
                                            class           = "list-item text-right pr-2"
                                            to              = "/admin/autorizzazioni"
                                            data-toggle     = "collapse"
                                            href            = "#menu1"
                                            role            = "button"
                                            aria-expanded   = "false"
                                            aria-controls   = "menu1"
                                            v-if            = "
                                                functionEnabled(
                                                    'Autorizzazioni'
                                                )
                                            "
                                        >
                                            Autorizzazioni
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'
    
    export default {
        data() {
            return {
                DB: {
                    screenings:   [],
                    appointments: []
                }
            }
        },
        methods: {
            async logout() {
                try {

                    await store.dispatch('doCentralLogoutPatient')

                    this.$router.push('/')

                }
                catch(e) {
                    this.$toast.open({
                        message: `Non è momentaneamente possibile effettuare il logout`,
                        type:    `error`
                    })
                }
                
            },
            async readAppointments() {
                try {

                    const appointments = await this.axios.get(`user/appointments/read/personal/todo`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.appointments = appointments.data.appointments_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                }
            },
            functionEnabled(
                title
            ) {

                // Se sono presenti abilitazioni all'utilizzo delle funzioni software
                if(
                    store.getters.softwareFunctions
                ) {
                    for(
                        let sFunction of store.getters.softwareFunctions
                    ) {
                        // Se la funzione software corrisponde all'ID passato
                        if(
                            sFunction.software_function.title == title
                        ) return true
                    }
                }

                return false

            }
        },
        computed: {
            isLogged() {
                return store.getters.isLogged
            },
            username() {
                return store.getters.infoUser.user.username
            },
            sex() {
                return store.getters.infoUser.user.sex
            },
            isPatient() {
                return !store.getters.isAdmin
            },
        },
        mounted() {
            if(store.getters.isLogged && !store.getters.isAdmin) this.readAppointments()
        }
    }
    
</script>