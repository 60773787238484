<template>
    <div class="row">
        <div class="col-lg-10 pt-2 pb-2">
            <loader v-if="loaders.reading" />
            <div class="container-fluid" v-show="!loaders.reading">
                <div class="col-12 text-left f-family-monospace mb-2">
                    <span class="f-size-1-em">
                        Totale strutture: {{DB.structures.length}}
                    </span>
                </div>                  
                <div class="row" v-show="view == 'cards'">                  
                    <div class="col-md-6 col-lg-4" v-for="structure of DB.structures" :key="structure.id">                         
                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h5 class="card-title text-truncate">
                                                {{structure.title}}
                                            </h5>
                                        </div>
                                        <div class="col-md-4 text-right">
                                            <span v-if="structure.state" class="badge badge-primary">Visibile</span>
                                            <span v-else class="badge badge-danger">Non visibile</span>
                                        </div>
                                    </div>

                                    <p class="card-text m-0">Tipologia {{structure.typeTitle}}</p>
                                    <p class="card-text text-truncate">{{structure.address}}</p>
                                    <p class="card-text m-0">Screening: {{ structure.screeningsData }}</p>
                                    <p class="card-text m-0">Test: {{ structure.testsData }}</p>
                                    <p class="card-text m-0"><br />Descrizione:<br /> {{structure.description ? structure.description : `Nessuna descrizione`}}</p>

                                    <!--<div class="card-text m-0">SCREENING:</div>
                                    <div class="card-text m-0" v-for="tests of structure.structures_tests" :key="tests.id">
                                        <div class="card-text m-0" v-for="screening in tests.test.screenings_tests" :key="screening.id">
                                            <div class="card-text m-0" v-for="screeningTest in screening" :key="screeningTest.id">
                                                <span :class="`scrt${screeningTest.id}`">{{ screeningTest.title }}</span>
                                            </div>
                                        </div>
                                    </div>-->

                                    <div class="it-card-footer mt-3">
                                        <router-link
                                        class   = "btn btn-outline-primary btn-sm"
                                        tag     = "button"
                                        :to     = "`/admin/strutture/modifica/${structure.title}&${structure.id_structure_type}`"
                                        v-if   = "
                                            !function_disabled(
                                                'Strutture',
                                                'read'
                                            )
                                        "
                                    >
                                        Modifica
                                    </router-link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" v-show="view == 'table'">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Titolo</th>
                                <th scope="col">Tipologia</th>
                                <th scope="col">Indirizzo</th>
                                <th scope="col">Screening</th>
                                <th scope="col">Test</th>
                                <th scope="col">Descrizione</th>
                                <th scope="col">Visibilità</th>
                            </tr>
                        </thead>
                        <tbody>
                            <router-link
                                tag     = "tr"
                                v-for   = "structure of DB.structures"
                                :key    = "structure.id"
                                :to     = "!function_disabled(
                                    'Strutture',
                                    'read'
                                ) ? `strutture/modifica/${structure.title}` : 'strutture'"
                            >
                                <th scope="row">{{structure.title}}</th>
                                <td>{{structure.typeTitle}}</td>
                                <td>{{structure.address}}</td>
                                <td>{{structure.screeningsData}}</td>
                                <td>{{structure.testsData}}</td>
                                <td>{{structure.description ? structure.description : `Nessuna descrizione`}}</td>
                                <td>{{structure.state ? 'Visibile' : 'Non visibile'}}</td>
                            </router-link>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li>
                                <router-link
                                    class   = "list-item medium text-truncate"
                                    to      = "/admin/strutture/aggiungi"
                                    v-if    = "!function_disabled(
                                        'Strutture',
                                        'create'
                                    )"
                                >
                                    
                                    <span class="mr-2">Aggiungi</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle mb-1" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>

                                </router-link>
                            </li>
                            <li>
                                <h3 class="text-truncate mt-3">Visualizzazione</h3>
                            </li>
                            <li v-show="view == `cards`">                                
                                <a class="list-item medium text-truncate" @click="view = `table`">
                                    
                                    <span class="mr-2">Tabellato</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table mb-1" viewBox="0 0 16 16">
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                                    </svg>

                                </a>
                            </li>
                            <li v-show="view == `table`">                                
                                <a class="list-item medium text-truncate" @click="view = `cards`">
                                    
                                    <span class="mr-2">Cards</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-text mb-1" viewBox="0 0 16 16">
                                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                        <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                                    </svg>

                                </a>
                            </li>
                            <li>
                                <h3 class="text-truncate mt-3">Filtri di ricerca</h3>
                            </li>
                            <li class="mr-4">
                                <p class="text-muted mb-2 f-s-0_8-em">
                                    Compila i seguenti campi, la ricerca avverrà in automatico
                                </p>
                                <div class="row m-0">
                                    <div class="col-lg-12 mb-1">
                                        <select class="form-control selectForm text-uppercase mb-lg-0 mt-2" id="screenings" v-model="form.search.selectedScreening" @input="readStructuresFilters($event.target.value, test_id)">
                                            <option value="0" disabled>Screening</option>
                                            <option v-for="screening of screenings" :selected="screening.id" :key="`screening_${screening.id}`" :value="screening.id">
                                                {{screening.title}}
                                            </option>
                                        </select>                                        
                                    </div> 
                                    <div class="col-lg-12 mb-1">
                                        <select class="form-control selectForm text-uppercase mb-lg-0 mt-2" id="tests" :class="{ disabled : isDisabled,  selectFormDisabled : isDisabled}" v-model="form.search.selectedTest" @input="readStructuresFilters(screening_id, $event.target.value)">
                                            <option value="0" disabled>Test</option>
                                            <option v-for="test of tests" :selected="test.test.id" :key="`test_${test.test.id}`" :value="test.test.id">
                                                {{test.test.title}}
                                            </option>
                                        </select>                                        
                                    </div> 
                                 </div>
                            </li>                                                                              

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    export default {
        data() {
            return {
                DB: {
                    structures: []
                },
                view: `cards`,
                uniqPropValue: {},
                filters: {
                    screening: ``,
                    test: ``
                },
                screenings: [],
                tests: [],
                screening_id: '',
                test_id: '', 
                isDisabled: true,               
                form: {
                    search: {
                        selectedScreening: '0',
                        selectedTest: '0'
                    }
                }, 
                loaders: {
                    reading: true
                }                                                    
            }
        },    
        methods: {
            async readScreenings() {
                try {

                    const screenings = await this.axios.get(`user/screenings/read/all`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.screenings = screenings.data.screenings_db
                    
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i screenings. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            }, 
            async readScreeningTests(screeningId) {

                try {

                    let screening_tests = await this.axios.get(`user/screenings_tests/read/screening/${screeningId}`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    
                    this.tests = screening_tests.data.screening_tests_db

                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare i tests. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            async readStructures() {
                this.loaders.reading = true
                try {

                    const structures = await this.axios.get(`user/structures/read/all_structures`,  {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.structures = structures.data.structures_db

                    this.loaders.reading = false
     
                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le strutture. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }

                }
            },
            async readStructuresFilters(screeningId = '', testId = '') {
                if(screeningId != this.form.search.selectedScreening){
                    testId = '';
                } 

                this.loaders.reading = true
                this.screening_id = screeningId;
                this.test_id = testId;   

                if(screeningId == ''){
                    this.form.search.selectedScreening = '0';
                    this.form.search.selectedTest = '0';
                    this.isDisabled = true                    
                } else {
                    this.form.search.selectedScreening = screeningId;
                    this.form.search.selectedTest = this.test_id;
                    if(screeningId != this.form.search.selectedScreening){
                        this.form.search.selectedTest = this.test_id;
                    } 
                    this.isDisabled = false                  
                }

                if(this.isInSearching()) {

                    const where1 = screeningId;    
                    const where2 = parseInt(testId);    
                

                    if(screeningId != ''){
                        
                        this.readScreeningTests(screeningId)  
                        
                        for(let screening of this.screenings) {
                            // Se la funzione software corrisponde all'ID passato
                            if(screening.id == this.screening_id) {
                                this.titleScreening = screening.title
                            }
                        }

                        
                        if(this.tests.length > 0){
                            for (var i = 0; i <= this.tests.length; i++) {
                                for(let test of this.tests) {
                                    // Se la funzione software corrisponde all'ID passato
                                    
                                    if(test.id_test == this.test_id) {
                                        this.titleTest = test.test.title
                                    }
                                }                                
                                
                            }  
                        }                        
                    }
                
                    try {
                        this.DB.structures = []

                        const structures = await this.axios.post(`user/structures/read/all_structures_search`, {where1, where2},  {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })

                        this.DB.structures = structures.data.structures_db

                        this.loaders.reading = false
        
                    }
                    catch(e) {
                        if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                            this.$toast.open({
                                message: `La tua sessione è scaduta, effettua nuovamente il login`,
                                type:    `error`
                            })

                            this.$router.push('/sessionescaduta')
                        }
                        else {
                            this.$toast.open({
                                message: `Non è momentaneamente possibile scaricare le strutture. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                                type:    `error`
                            })
                        }

                    }
                }    
            },
            isInSearching() {
                return this.form.search.selectedScreening != 0  || this.form.search.selectedTest != 0
            },           
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }          
        },
        mounted() {
            this.readStructures()
            this.readScreenings()
        }
    }
</script>