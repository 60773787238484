<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">

                <div class="row">
                    <div class="col-lg-6 offset-lg-3 mb-2">

                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <form @submit.prevent="createStructure_type" autocomplete="off">

                                        <div class="form-group">

                                            <label for="title">Titolo</label>
                                            <input type="text" class="form-control text-uppercase" id="title" aria-describedby="title_info" v-model.trim="$v.form.structure_type.title.$model" v-bind:class="{'is-invalid': (!$v.form.structure_type.title.required || !$v.form.structure_type.title.minLength) && $v.form.structure_type.title.$dirty}">
                                            
                                            <small v-show="!$v.form.structure_type.title.$dirty || !$v.form.structure_type.title.$invalid" id="title_info" class="form-text text-muted">Il titolo digitato dovrà essere univoco</small>
                                            
                                            <div v-show="!$v.form.structure_type.title.required && $v.form.structure_type.title.$dirty" class="invalid-feedback">Titolo richiesto</div>
                                            <div v-show="!$v.form.structure_type.title.minLength && $v.form.structure_type.title.$dirty" class="invalid-feedback">Digitare minimo 3 caratteri</div>

                                        </div>
                                        <div class="form-group">

                                            <label for="description">Descrizione</label>
                                            <textarea id="description" class="form-control border" rows="2" v-model.trim="$v.form.structure_type.description.$model" v-bind:class="{'is-invalid border-danger': (!$v.form.structure_type.description.required || !$v.form.structure_type.description.minLength) && $v.form.structure_type.description.$dirty}"></textarea>
                                            
                                            <small v-show="!$v.form.structure_type.description.$dirty || !$v.form.structure_type.description.$invalid" id="title_info" class="form-text text-muted">Fornire una breve descrizione</small>
                                            
                                            <div v-show="!$v.form.structure_type.description.required && $v.form.structure_type.description.$dirty" class="invalid-feedback">Descrizione richiesta</div>
                                            <div v-show="!$v.form.structure_type.description.minLength && $v.form.structure_type.description.$dirty" class="invalid-feedback">Digitare minimo 8 caratteri</div>

                                        </div>

                                        <h5 class="mb-1 pl-2 card-title c-435a70">Tipologia di accoglienza</h5>

                                        <div class="form-group border mb-1">

                                            <select class="form-control form-control-sm" id="tests" title="Scegli una opzione" v-model="form.structure_type.id_reception_type">
                                                <option disabled value="" selected>Seleziona un test</option>
                                                <option v-for="reception_type of DB.reception_types" :key="`reception_type_${reception_type.id}`" :value="reception_type.id">
                                                    {{reception_type.title}} - {{reception_type.description}}
                                                </option>
                                            </select>

                                            <small class="form-text text-muted">Seleziona il modo in cui un paziente potrà prenotare</small>

                                        </div>
                                        
                                        <div class="alert alert-danger" v-show="validation.ko">
                                            {{validation.ko}}
                                        </div>

                                        <div class="it-card-footer">

                                            <button type="submit" class="btn btn-primary" :disabled="$v.form.structure_type.$invalid || loaders.creating.structure_type">
                                                
                                                <span v-show="!loaders.creating.structure_type">
                                                    Aggiungi
                                                </span>

                                                <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.creating.structure_type">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                                

                                            </button>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">
                                    
                                    <i class="text-muted">Nessuna funzione</i>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        minLength,
        maxLength
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                DB: {
                    tests:           [],
                    reception_types: []
                },
                form: {
                    structure_type: {
                        title:             null,
                        description:       null,
                        id_reception_type: null
                    }
                },
                loaders: {
                    creating: {
                        structure_type: false
                    },
                    reading: {
                        structure_type: true
                    }
                },
                validation: {
                    ko: false
                }
            }
        },
        validations() {
            return {
                form: {
                    structure_type: {
                        title: {
                            required,
                            minLength: minLength(3)
                        },
                        description: {
                            required,
                            minLength: minLength(8),
                            maxLength: maxLength(350)
                        }
                    }

                }
            }
        },
        methods: {

            async createStructure_type() {

                this.loaders.creating.structure_type = true
                this.validation.ko                   = false

                try {

                    const res = await this.axios.post(`user/structure_types/create/single`, this.form.structure_type, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/strutture/tipologia`)

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko         = message
                    this.loaders.creating.structure_type = false

                }
            },
            async readReceptionTypes() {

                try {

                    const reception_types = await this.axios.get(`user/reception_types/read/actives`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.reception_types = reception_types.data.reception_types_db

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                }
                finally {
                    this.loaders.reading.reception_types = false
                }

            }

        },
        mounted() {
            this.readReceptionTypes()
        }
    }

</script>