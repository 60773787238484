<template>
    <div>
        
        <div class="bg-cce6ff p-5" style="margin-left: -6px; margin-right: -6px">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6">

                        <h1>
                            {{nominativo}}
                        </h1>
                        <p class="m-0">
                            Età {{eta}} anni
                        </p>
                        <p class="m-0">
                            Percorso per <span class="text-lowercase">{{sesso}}</span>
                        </p>

                    </div>
                    <div class="col-lg-6">
                        <h1 class="text-truncate text-uppercase">
                            {{$route.params.title}}
                        </h1>
                        <p class="m-0">
                            Fascia età {{DB.screening.from_age}}/{{DB.screening.to_age}} anni
                        </p>
                        <p class="m-0">
                            Screening per {{DB.screening.for_man ? 'Uomo' : null}} {{(DB.screening.for_woman && DB.screening.for_man) ? 'e' : null}} {{DB.screening.for_woman ? 'Donna' : null}}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container py-5">

            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    
                    
                    <div class="card-space mb-3">
                        <div class="card card-bg no-after">
                            <div class="card-body">

                                <h5 class="card-title">
                                    Cos'è lo screening <u class="text-lowercase">{{DB.screening.title}}</u>?
                                </h5>

                                <p class="card-text m-0">
                                    {{DB.screening.description}}
                                </p>

                                <div class="alert alert-info mt-3 fade show" role="alert" v-if="DB.screening.screenings_tests.length > 0">

                                    <button type="button" class="close" data-dismiss="alert" aria-label="Chiudi avviso">
                                        <span aria-hidden="true">&times;</span>
                                    </button>

                                    Seleziona il test a cui ti vuoi sottoporre

                                </div>
                                <div class="alert alert-warning mt-3" v-else>
                                    Attualmente non sono disponibili dei test a cui si può sottoporre
                                </div>

                                <div class="text-center mt-3" v-if="
                                    DB.screening.screenings_tests.length > 0 ||
                                    DB.screening.screenings_tests_waiting.length > 0
                                ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                                    </svg>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div
                            class   = "col-lg-6"
                            v-for   = "screening_test of DB.screening.screenings_tests"
                            :key    = "`test_${screening_test.test.title}`"
                        >
                            <div class="card-wrapper card-space">
                                <div class="card card-bg">
                                    <div class="card-body">
                                        
                                        <h5 class="card-title">
                                            {{screening_test.test.title}}
                                        </h5>

                                        <p class="card-text m-0">
                                            {{screening_test.test.description}}
                                        </p>

                                        <router-link :to="`/user/percorsi/${DB.screening.title}/tests/${screening_test.test.title}`" class="read-more mt-3">

                                            <span class="text">Seleziona</span>

                                            <svg class="icon">
                                                <use href="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-right"></use>
                                            </svg>

                                        </router-link>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" v-for="test of DB.screening.screenings_tests_waiting" :key="`test_waiting_${test.title}`">

                            <div class="card-wrapper card-space">
                                <div class="card card-bg no-after border-bottom-card border-bottom-card-danger">
                                    <div class="card-body">

                                        <div class="alert alert-danger">
                                            Screening <b>già</b> richiesto per la data <b>{{test.last_appointment | data_slash}}</b>, <router-link :to="`/user/appuntamenti`">consulta i tuoi appuntamenti</router-link>
                                        </div>
                                        
                                        <h5 class="card-title">
                                            {{test.title}}
                                        </h5>

                                        <p class="card-text m-0">
                                            {{test.description}}
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import store from  '@/store'
    import moment from 'moment'

    export default {
        data() {
            return {
                DB: {
                    screening: null
                },
                loaders: {
                    screening: {
                        reading: true
                    }
                }
            }
        },
        methods: {
            async readScreening() {

                try {

                    const screening = await this.axios.get(`user/screenings/read/detail_filter_user/${this.$route.params.title}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.DB.screening = screening.data.screening

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko             = message
                    this.loaders.screening.reading = false

                }
            },
        },
        mounted() {
            this.readScreening()
        },
        computed: {
            nominativo() {
                return `${store.getters.infoUser.user.name} ${store.getters.infoUser.user.surname}`
            },
            eta() {
                return moment().diff(moment(store.getters.infoUser.user.date_birth), 'years')
            },
            sesso() {
                return store.getters.infoUser.user.sex == 'M' ? 'Uomo' : 'Donna'
            }
        }
    }

</script>