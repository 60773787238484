import Vue       from 'vue'
import VueRouter from 'vue-router'
import store     from '../store'
// Path generici
import home             from '../views/HomeView.vue'
import unauthorized     from '../views/UnauthorizedView.vue'
import expired          from '../views/ExpiredView.vue'
import notFound         from '../views/404View.vue'
import page_for_patient from '../views/PageForPatientView.vue'
import profile          from '../views/ProfileView.vue'
import settings          from '../views/SettingsView.vue'
// Path auth
import accesso       from '../views/auth/loginView.vue'
import registrazione from '../views/auth/registerView.vue'
import spid_auth     from '../views/auth/oauthStepView.vue'
// Path user
import user_screenings_index    from '../views/user/screenings/IndexView.vue'
import user_screenings_detail   from '../views/user/screenings/DetailView.vue'
import user_tests_detail        from '../views/user/tests/DetailView.vue'
import user_appointments_index  from '../views/user/appointments/IndexView.vue'
import user_appointments_update from '../views/user/appointments/UpdateView.vue'
import user_appointments_delete from '../views/user/appointments/DeleteView.vue'
// Path admin
import admin_appointments_index               from '../views/admin/appointments/IndexView.vue'
import admin_appointments_update              from '../views/admin/appointments/UpdateView.vue'
import admin_outbound                         from '../views/admin/appointments/OutboundView.vue'
import admin_screenings_index                 from '../views/admin/screenings/IndexView.vue'
import admin_screenings_create                from '../views/admin/screenings/CreateView.vue'
import admin_screenings_update                from '../views/admin/screenings/UpdateView.vue'
import admin_tests_index                      from '../views/admin/tests/IndexView.vue'
import admin_tests_create                     from '../views/admin/tests/CreateView.vue'
import admin_tests_update                     from '../views/admin/tests/UpdateView.vue'
import admin_structure_types_index            from '../views/admin/structure_types/IndexView.vue'
import admin_structure_types_create           from '../views/admin/structure_types/CreateView.vue'
import admin_structure_types_update           from '../views/admin/structure_types/UpdateView.vue'
import admin_structure_index                  from '../views/admin/structures/IndexView.vue'
import admin_structure_create                 from '../views/admin/structures/CreateView.vue'
import admin_structure_update                 from '../views/admin/structures/UpdateView.vue'
import admin_structure_configuration_index    from '../views/admin/structure_configurations/CreateView.vue'
import admin_structure_configuration_update   from '../views/admin/structure_configurations/UpdateView.vue'
import admin_structure_test_create            from '../views/admin/structures_tests/CreateView.vue'
import admin_structure_test_update            from '../views/admin/structures_tests/UpdateView.vue'
import admin_uploads_index                    from '../views/admin/uploads/IndexView.vue'
import admin_uploads_view                     from '../views/admin/uploads/DetailView.vue'
import admin_patients_index                   from '../views/admin/patients/IndexView.vue'
import admin_patients_update                  from '../views/admin/patients/UpdateView.vue'
import admin_users_index                      from '../views/admin/users/IndexView.vue'
import admin_users_create                     from '../views/admin/users/CreateView.vue'
import admin_users_update                     from '../views/admin/users/UpdateView.vue'
import admin_user_types_index                 from '../views/admin/user_types/IndexView.vue'
import admin_user_types_update                from '../views/admin/user_types/UpdateView.vue'
import admin_users_software_functions_create  from '../views/admin/users_software_functions/CreateView.vue'
import admin_users_software_functions_update  from '../views/admin/users_software_functions/UpdateView.vue'

Vue.use(
  VueRouter
)

const routes = [
  {
    path:     `/`,
    name:     `home`,
    component: home,
    meta: {
      requiresGuest: true,
      title:        `Screenings`
    }
  },
  {
    path:     `/sessionescaduta`,
    name:     `expired`,
    component: expired,
    meta: {
      requiresAuth: true,
      title:       `Expired`
    }
  },
  {
    path:     `/nonautorizzato`,
    name:     `unauthorized`,
    component: unauthorized,
    meta: {
      requiresAuth: true,
      title:       `Percorsi`
    }
  },
  {
    path:     `/pagina_per_pazienti`,
    name:     `page_for_patient`,
    component: page_for_patient,
    meta: {
      requiresAuth: true,
      title:       `Pagina per pazienti`
    }
  },  
  {
    path:     `/accesso`,
    name:     `accesso`,
    component: accesso,
    meta: {
      requiresGuest: true,
      title:        `Accesso`
    }
  },
  {
    path:     `/registrazione`,
    name:     `registrazione`,
    component: registrazione,
    meta: {
      requiresGuest: true,
      title:        `Registrazione`
    }
  },
  {
    path:     `/spid_auth`,
    name:     `spid_auth`,
    component: spid_auth,
    meta: {
      requiresGuest: true,
      title:        `spid_auth`
    }
  },
  {
    path:     `/profilo`,
    name:     `profile`,
    component: profile,
    meta: {
      requiresAuth: true,
      title:        `spid_auth`
    }
  }, 
  {
    path:     `/impostazioni`,
    name:     `settings`,
    component: settings,
    meta: {
      requiresAuth: true,
      title:        `Impostazioni`
    }
  },   
  {
    path:     `/user/percorsi`,
    name:     `user_screenings_index`,
    component: user_screenings_index,
    meta: {
      requiresUser: true,
      title:        `Percorsi user`
    }
  },
  {
    path:     `/user/percorsi/:title`,
    name:     `user_screenings_detail`,
    component: user_screenings_detail,
    meta: {
      requiresUser: true,
      title:       `Percorsi user`
    }
  },
  {
    path:     `/user/percorsi/:title_screening/tests/:title_test`,
    name:     `user_tests_detail`,
    component: user_tests_detail,
    meta: {
      requiresUser: true,
      title:       `Percorsi user`
    }
  },
  {
    path:     `/user/appuntamenti`,
    name:     `user_appointments_index`,
    component: user_appointments_index,
    meta: {
      requiresUser: true,
      title:       `Appuntamenti user`
    }
  },
  {
    path:     `/user/appuntamenti/modifica/:id`,
    name:     `user_appointments_update`,
    component: user_appointments_update,
    meta: {
      requiresAuth: false,
      title:       `Appuntamenti user`
    }
  },
  {
    path:     `/user/appuntamenti/annulla/:id`,
    name:     `user_appointments_delete`,
    component: user_appointments_delete,
    meta: {
      requiresAuth: true,
      title:       `Appuntamenti user`
    }
  },

  // Sezione admin
  {
    path:     `/admin/appuntamenti`,
    name:     `admin_appointments_index`,
    component: admin_appointments_index,
    meta: {
      requiresAdmin: true,
      title:        `Agenda`
    }
  },
  {
    path:     `/admin/appuntamenti/outbound`,
    name:     `admin_outbound`,
    component: admin_outbound,
    meta: {
      requiresAdmin: true,
      title:        `Agenda`
    }
  },
  {
    path:     `/admin/appuntamenti/modifica/:id`,
    name:     `admin_appointments_update`,
    component: admin_appointments_update,
    meta: {
      requiresAdmin: true,
      title:        `Agenda`
    }
  },
  {
    path:     `/admin/screenings`,
    name:     `admin_screenings_index`,
    component: admin_screenings_index,
    meta: {
      requiresAdmin: true,
      title:        `Screenings`
    }
  },
  {
    path:     `/admin/screenings/aggiungi`,
    name:     `admin_screenings_create`,
    component: admin_screenings_create,
    meta: {
      requiresAdmin: true,
      title:        `Screenings`
    }
  },
  {
    path:     `/admin/screenings/modifica/:title`,
    name:     `admin_screenings_update`,
    component: admin_screenings_update,
    meta: {
      requiresAdmin: true,
      title:        `Screenings`
    }
  },   
  {
    path:     `/admin/tests`,
    name:     `admin_tests_index`,
    component: admin_tests_index,
    meta: {
      requiresAdmin: true,
      title:        `Tests`
    }
  },
  {
    path:     `/admin/tests/aggiungi`,
    name:     `admin_tests_create`,
    component: admin_tests_create,
    meta: {
      requiresAdmin: true,
      title:        `Tests`
    }
  },
  {
    path:     `/admin/tests/modifica/:title`,
    name:     `admin_tests_update`,
    component: admin_tests_update,
    meta: {
      requiresAdmin: true,
      title:        `Tests`
    }
  },
  {
    path:     `/admin/strutture/tipologia`,
    name:     `admin_structure_types_index`,
    component: admin_structure_types_index,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/strutture/tipologia/aggiungi`,
    name:     `admin_structure_types_create`,
    component: admin_structure_types_create,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/strutture/tipologia/modifica/:title`,
    name:     `admin_structure_types_update`,
    component: admin_structure_types_update,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/strutture`,
    name:     `admin_structure_index`,
    component: admin_structure_index,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/strutture/aggiungi`,
    name:     `admin_structure_create`,
    component: admin_structure_create,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/strutture/modifica/:title`,
    name:     `admin_structure_update`,
    component: admin_structure_update,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/strutture/:title/pianificazioni/aggiungi`,
    name:     `admin_structure_configuration_index`,
    component: admin_structure_configuration_index,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/strutture/:title/pianificazioni/modifica/:id`,
    name:     `admin_structure_configuration_update`,
    component: admin_structure_configuration_update,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/strutture/:title/test/aggiungi`,
    name:     `admin_structure_test_create`,
    component: admin_structure_test_create,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/strutture/:title/test/modifica/:id`,
    name:     `admin_structure_test_update`,
    component: admin_structure_test_update,
    meta: {
      requiresAdmin: true,
      title:        `Strutture`
    }
  },
  {
    path:     `/admin/uploads`,
    name:     `admin_uploads_index`,
    component: admin_uploads_index,
    meta: {
      requiresAdmin: true,
      title:        `Esito uploads`
    }
  },
  {
    path:     `/admin/uploads/visualizza/:title/:token_import`,
    name:     `admin_uploads_view`,
    component: admin_uploads_view,
    meta: {
      requiresAdmin: true,
      title:        `Esito uploads`
    }
  },  
  {
    path:     `/admin/pazienti`,
    name:     `admin_patients_index`,
    component: admin_patients_index,
    meta: {
      requiresAdmin: true,
      title:        `Pazienti`
    }
  },
  {
    path:     `/admin/pazienti/modifica/:CF`,
    name:     `admin_patients_update`,
    component: admin_patients_update,
    meta: {
      requiresAdmin: true,
      title:        `Pazienti`
    }
  },
  {
    path:     `/admin/utenti`,
    name:     `admin_users_index`,
    component: admin_users_index,
    meta: {
      requiresAdmin: true,
      title:        `Utenti`
    }
  },
  {
    path:     `/admin/utenti/aggiungi`,
    name:     `admin_users_create`,
    component: admin_users_create,
    meta: {
      requiresAdmin: true,
      title:        `Utenti`
    }
  },
  {
    path:     `/admin/utenti/modifica/:id`,
    name:     `admin_users_update`,
    component: admin_users_update,
    meta: {
      requiresAdmin: true,
      title:        `Utenti`
    }
  },
  {
    path:     `/admin/autorizzazioni`,
    name:     `admin_user_types_index`,
    component: admin_user_types_index,
    meta: {
      requiresAdmin: true,
      title:        `Autorizzazioni`
    }
  },
  {
    path:     `/admin/autorizzazioni/modifica/:id`,
    name:     `admin_user_types_update`,
    component: admin_user_types_update,
    meta: {
      requiresAdmin: true,
      title:        `Autorizzazioni`
    }
  },
  {
    path:     `/admin/autorizzazioni/modifica/:id/aggiungi`,
    name:     `admin_users_software_functions_create`,
    component: admin_users_software_functions_create,
    meta: {
      requiresAdmin: true,
      title:        `Autorizzazioni`
    }
  },
  {
    path:     `/admin/autorizzazioni/modifica/:id_user_type/modifica/:id`,
    name:     `admin_users_software_functions_update`,
    component: admin_users_software_functions_update,
    meta: {
      requiresAdmin: true,
      title:        `Autorizzazioni`
    }
  },
  {
    path:     `/A/:id`,
    redirect: `/user/appuntamenti/modifica/:id`
  },
  {
    path:     `/:catchAll(.*)`,
    name:     `notFound`,
    component: notFound
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if(to.matched.some(record => record.meta.requiresAuth)) {

    if(!store.getters.isLogged) {
      next({
        name: `home`
      })
    }
    else {
      next()
    }

  }
  else if(to.matched.some(record => record.meta.requiresUser)) {

    if(!store.getters.isLogged) {
      next({
        name: `home`
      })
    }
    else if(store.getters.isAdmin){
      next({
        name: `page_for_patient`
      })
    }
    else {
      next()
    }

  }
  else if(to.matched.some(record => record.meta.requiresGuest)) {

    if(store.getters.isLogged) {
      if(store.getters.isAdmin) {
        next({
          name: `admin_appointments_index`
        })
      }
      else {
        next({
          name: `user_appointments_index`
        })
      }
    }
    else{
      next()
    }
    
  }
  else if(to.matched.some(record => record.meta.requiresAdmin)) {

    if(!store.getters.isLogged) {
      next({
        name: `home`
      })
    }
    else if(store.getters.isAdmin){
      next()
    }
    else {
      next({
        name: `unauthorized`
      })
    }
    
  }
  else {
    next()
  }

})

export default router