<template>
    <div class="row">
        <div class="col-lg-10 pt-2 pb-2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4 mt-3 mb-2 text-left">
                        Totale records: {{ DB.uploads.length }}
                    </div>
                    <div class="col-md-8 text-right">
                        <pagination :records="DB.uploads.length" v-model="page" :per-page="perPage" @paginate="callback"></pagination>        
                    </div>
                </div>
                <div class="row" v-show="view == 'cards'">
                    <div class="col-md-6 col-lg-4" v-for="upload of displayedRecords" :key="upload.id">
                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h5 class="card-title text-uppercase">
                                                {{upload.hasOwnProperty('patient_screenings_tests') ? upload.patient_screenings_tests.user.surname : upload.surname}} <br />{{upload.hasOwnProperty('patient_screenings_tests') ? upload.patient_screenings_tests.user.name : upload.name}}
                                                <br />
                                                <small class="f-s-0_8-em">{{upload.hasOwnProperty('patient_screenings_tests') ? upload.patient_screenings_tests.user.CF : upload.CF}}</small> 
                                            </h5>
                                            
                                        </div>
                                        <div class="col-md-6 text-right">
                                            Stato SMS: 
                                            <p class="card-text m-0" v-if="upload.status_sms == 1">
                                                <span class="text-success">CONSEGNATO</span><br />
                                                <small class="f-s-0_8-em text-default">{{upload.date_delivered_sms | data_time}}</small> 
                                            </p>
                                            <p class="card-text m-0 text-danger" v-else-if="upload.status_sms == 2">
                                                SCADUTO
                                            </p> 
                                            <p class="card-text m-0 text-danger" v-else-if="upload.status_sms == 3">
                                                FALLITO
                                            </p>   
                                            <p class="card-text m-0 text-danger" v-else-if="upload.status_sms == 4">
                                                RESPINTO
                                            </p> 
                                            <p class="card-text m-0 text-danger" v-else-if="upload.status_sms == 5">
                                                ACCETTATO
                                            </p>  
                                            <p class="card-text m-0 text-danger" v-else-if="upload.status_sms == 6">
                                                IN ATTESA
                                            </p>
                                            <p class="card-text m-0 text-danger" v-else-if="upload.status_sms == 7">
                                                ERRORE SCONOSCIUTO
                                            </p>   
                                            <p class="card-text m-0 text-danger" v-else-if="upload.status_sms == 0 && upload.sent_sms == 0">
                                                NON INVIATO
                                            </p> 
                                            <p class="card-text m-0 text-danger" v-else-if="upload.status_sms == 0 && upload.sent_sms == 1">
                                                INVIATO
                                            </p>                                                                                                                                                                                                                                                                                                                      
                                            <p class="card-text m-0 text-danger" v-else>
                                                ERRORE
                                            </p>                                                                                                                                    
                                        </div>
                                    </div>
                                    
                                    <p class="card-text m-0 font-weight-bold" v-if="upload.sent_sms == 1">Data invio SMS: {{upload.date_sent_sms | data_time}}</p>
                                    <p class="card-text m-0 text-success font-weight-bold">Cellulare: {{upload.hasOwnProperty('patient_screenings_tests') ? upload.patient_screenings_tests.user.phone : upload.phone}}</p>
                                    <div class="it-card-footer mt-3 btn btn-outline-warning btn-sm pointerText" v-if="upload.sent_sms == 1">
                                        ID Messaggio: {{upload.message_id_sms}}
                                    </div>                                        
                                    <br />
                                    <div v-if="upload.reply_sms">
                                        <p class="card-text m-0 text-success" v-if="upload.reply_sms == '1'">
                                            Risposta: <strong>1</strong> (CONFERMATO)<br />
                                            <span class="text-default">{{upload.date_reply_sms | data_time}}</span>
                                        </p>
                                        <p class="card-text m-0 text-danger" v-else-if="upload.reply_sms == '0'">
                                            Risposta: <strong>0</strong> (NON CONFERMATO)<br />
                                            <span class="text-default">{{upload.date_reply_sms | data_time}}</span>
                                        </p>                                       
                                        <p class="card-text m-0 text-danger" v-else>
                                            Risposta non conforme: <strong>{{upload.reply_sms}}</strong><br />
                                            <span class="text-default">{{upload.date_reply_sms | data_time}}</span>
                                        </p>
                                        <br />    
                                    </div>
                                    <div v-else-if="!upload.reply_sms && upload.titleScreening != 'COLON RETTO'">
                                        <p class="card-text m-0 text-danger">
                                            Risposta: (ATTESA DI RISPOSTA)<br />
                                            <span class="text-default">{{upload.date_reply_sms | data_time}}</span>
                                        </p>
                                        <br />    
                                    </div>
                                    <strong>Dettaglio appuntamento:</strong>
                                    <p class="card-text m-0" v-if="upload.titleScreening == 'HCV'">
                                        Appuntamento del: {{upload.date | data_slash}}
                                        dalle ore 08:00 alle 10:00
                                        <br />
                                        SCREENING: {{upload.titleScreening}}
                                        <br />
                                        TEST: {{upload.titleTest}}
                                        <br />
                                        STRUTTURA: {{upload.titleStructure}} ({{upload.descriptionStructure}})  
                                    </p>
                                    <p class="card-text m-0" v-else-if="upload.titleScreening == 'COLON RETTO' && upload.id_sms == '1'">
                                        Invio solleciti non rispondenti
                                        <br />
                                        SCREENING: {{upload.titleScreening}}
                                        <br />
                                        TEST: {{upload.titleTest}}                                          
                                    </p> 
                                    <p class="card-text m-0" v-else-if="upload.titleScreening == 'COLON RETTO' && upload.id_sms == '2'">
                                        Provetta non riconsegnata
                                        <br />
                                        SCREENING: {{upload.titleScreening}}
                                        <br />
                                        TEST: {{upload.titleTest}}                                          
                                    </p>                                                                            
                                    <p class="card-text m-0" v-else>
                                        Appuntamento del: {{upload.date | data_slash}}
                                        ore: {{upload.time | data_ora}}
                                        <br />
                                        SCREENING: {{upload.titleScreening}}
                                        <br />
                                        TEST: {{upload.titleTest}}
                                        <br />
                                        STRUTTURA: {{upload.titleStructure}} ({{upload.descriptionStructure}}) 
                                    </p>                                   
                                    
                                

                                    <!--<div class="it-card-footer mt-3">
                                        <a class="btn btn-outline-primary btn-sm" @click="view = `table`">
                                            Visualizza tutto
                                        </a>
                                    </div>-->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="DB.uploads.length > perPage">
                    <div class="col-md-4 mt-3 mb-2 text-left">
                        Totale records: {{ DB.uploads.length }}
                    </div>
                    <div class="col-md-8">
                        <pagination :records="DB.uploads.length" v-model="page" :per-page="perPage" @paginate="callback"></pagination>        
                    </div>
                </div>            
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate pl-2">Funzioni</h3>
                            </li>
                            <li v-if="view_detail == 'error_reply'">
                                <a class="list-item medium text-truncate pl-2 " @click="createXLS()">
                                    
                                    <span>Download XLS</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"/>
                                    </svg>

                                </a>
                            </li>                              
                            <li>
                                <h3 class="text-truncate">Filtri di ricerca</h3>
                            </li>
                            <li class="mr-4">
                                <p class="text-muted mb-3 f-s-0_8-em">
                                    Compila i campi che seguono, la ricerca avverrà in automatico
                                </p>
                                <div class="row m-0">
                                    <div class="col-lg-12 mb-1">
                                        <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readSmsDetailFilter" v-model="form.search.CF" placeholder="Codice fiscale">
                                    </div>
                                    <div class="col-lg-12 mb-1">
                                        <input class="form-control text-uppercase mb-1 mb-lg-0" type="text" @input="readSmsDetailFilter" v-model="form.search.name" placeholder="Nome">
                                    </div>
                                    <div class="col-lg-12 mb-1">
                                        <input class="form-control text-uppercase" type="text" @input="readSmsDetailFilter" v-model="form.search.surname" placeholder="Cognome">
                                    </div> 
                                    <div class="col-lg-12 mb-1">
                                        <input class="form-control text-uppercase" type="text" @input="readSmsDetailFilter" v-model="form.search.phone" placeholder="Cellulare">
                                    </div>  
                                    <div class="col-lg-12 mb-1" v-if="view_detail == 'sent_no_delivered'">
                                        <select class="form-control selectForm text-uppercase mb-lg-0 mt-2 pl-1" @change="readSmsDetailFilter" v-model="form.search.statusSms" id="smsStatus">
                                            <option value="0" selected disabled>Stato SMS</option>
                                            <!--<option value="1">CONSEGNATO</option>-->
                                            <option value="2">SCADUTO</option>
                                            <option value="3">FALLITO</option>
                                            <option value="4">RESPINTO</option>
                                            <option value="5">ACCETTATO</option>
                                            <option value="6">IN ATTESA</option>
                                            <option value="7">ERRORE SCONOSCIUTO</option>
                                            <option value="8">NON INVIATO</option>
                                            <option value="9">INVIATO</option>
                                            <option value="10">ERRORE</option>
                                            <option value="11">TUTTI</option>
                                        </select>  
                                    </div>                                                                                                                  
                                </div>
                            </li>                           
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'
    import * as XLSX   from 'xlsx'
    import moment      from 'moment'

    export default {
        data() {
            return {
                DB: {
                    uploads: []
                },
                page: 1,
                perPage: 10,                
                view: `cards`,
                view_detail: '',
                loaders: {
                    reading: true
                },               
                form: {
                    search: {
                        CF:      ``,
                        name:    ``,
                        surname: ``,
                        phone: ``,
                        statusSms: '0'
                    }
                }
            }
        },
        computed: {
            displayedRecords() {
                const startIndex = this.perPage * (this.page - 1);
                const endIndex = startIndex + this.perPage;
                return this.DB.uploads.slice(startIndex, endIndex);
            }
        },        
        methods: {     
            callback: function() {
                // no need for callback here since you have all your data loaded already
            },  
            async createXLS() {           
                
                const files = JSON.parse(JSON.stringify(this.DB.uploads));       
                const rows = files.map(row => ({
                    id: row.id,
                    date: moment(row.date).format("DD/MM/YYYY"),
                    time: moment(row.time).format("HH:mm"),
                    name: (row.name + ' ' + (row.surname ? row.surname : '')).toUpperCase(),
                    cf: row.CF,
                    screening: row.titleScreening,
                    test: row.titleTest,
                    structure: row.titleStructure + ' - ' + row.descriptionStructure,
                    phone: row.phone,
                    messageid: row.message_id_sms,
                    sentdate: moment(row.date_sent_sms).format("DD/MM/YYYY HH:mm:ss"),
                    reply: row.reply_sms,
                    replydate: moment(row.date_reply_sms).format("DD/MM/YYYY HH:mm:ss")
                }));

                const worksheet = XLSX.utils.json_to_sheet(rows);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

                XLSX.utils.sheet_add_aoa(worksheet, [["ID appuntamento", "Data", "Ora", "Nome e cognome", "Codice fiscale", "Screening", "Test", "Struttura", "Cellulare", "ID messaggio", "Data invio sms", "Risposta", "Data risposta"]], { origin: "A1" });
                var wscols = [
                    {wch:6},
                    {wch:10},
                    {wch:6},
                    {wch:20},
                    {wch:20},
                    {wch:20},
                    {wch:20},
                    {wch:40},
                    {wch:10},
                    {wch:35},
                    {wch:20},
                    {wch:50},
                    {wch:20}
                ];
                //const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
                worksheet["!cols"] = wscols;

                XLSX.writeFile(workbook, "Risposte_errate_appuntamenti_screening_aslroma2.xlsx", { compression: true });
               
                //this.loaders.reading = true
                //this.loaders.appointments = true
                //this.calendar.config.events = [];    

            },             

            async readSmsDetail() {

                this.view_detail = this.$route.params.title
                try {
                    const uploads = await this.axios.get(`user/uploads/read/detail/${this.$route.params.title}/${this.$route.params.token_import}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    this.DB.uploads = uploads.data.appointment_sms
                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })


                }

            },         
            async readSmsDetailFilter() {

                this.loaders.reading = true

                if(this.isInSearching()) {

                    for(let val in this.form.search) {
                        this.form.search[val] = this.form.search[val].toUpperCase()
                    }

                    const where1 = '%'+this.form.search.CF+'%'; 
                    const where2 = '%'+this.form.search.name+'%'; 
                    const where3 = '%'+this.form.search.surname+'%';  
                    const where4 = '%'+this.form.search.phone+'%'; 
                    const where5 = this.form.search.statusSms;     
                    
                    try {
                        const uploads = await this.axios.post(`user/uploads/read_detail/search/${this.$route.params.title}/${this.$route.params.token_import}`, {where1, where2, where3, where4, where5}, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })                     

                        this.DB.uploads = uploads.data
                        this.page = 1
                    }
                    catch(e) {

                        this.$toast.open({
                            message: `Si è verificato un problema, ${e.response ? e.response.data.msg : 'verifica la tua connessione.'}`,
                            type:    `error`
                        })

                    }
                    finally {
                        this.loaders.reading = false
                    }
                }
                else {
                    this.readSmsDetail()
                }
            },  

            isInSearching() {
                return this.form.search.CF.length >= 3 || this.form.search.name.length >= 3 || this.form.search.surname.length >= 3  || this.form.search.phone.length >= 3 || this.form.search.statusSms != 0
            },                      

            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }

        },
        mounted() {
            this.readSmsDetail()
        }
    }

</script>