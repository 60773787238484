<template>
    <div>
        
        <div class="bg-cce6ff p-5" style="margin-left: -6px; margin-right: -6px">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6">

                        <h1>
                            {{nominativo}}
                        </h1>
                        <p class="m-0">
                            Età {{eta}} anni
                        </p>
                        <p class="m-0">
                            Percorso per <span class="text-lowercase">{{sesso}}</span>
                        </p>

                    </div>
                    <div class="col-lg-6">

                        <h1 class="text-truncate text-uppercase">
                            {{DB.test.title}}
                        </h1>
                        <p class="m-0">
                            {{DB.test.description}}
                        </p>

                    </div>
                </div>
            </div>
        </div>

        <div class="container py-5">
            
            <div v-if="DB.test.structures_tests.length > 0">

                <div v-show="DB.test.requests.length > 0">

                    <div class="card-wrapper card-space">
                        <div class="card card-bg no-after">
                            <div class="card-body">
                                <div class="alert alert-info m-0">
                                    Per proseguire <b>verifichi</b> e <b>confermi</b> spuntando {{requests_filtered_by_sex.length == 1 ? 'la casella' : `le ${requests_filtered_by_sex.length} caselle`}}, di essere in possesso dei requisiti elencati.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-wrapper mb-2" v-for="(request, index) of requests_filtered_by_sex" :key="`request_${request.id}`">
                        <div class="card card-bg no-after">
                            <div class="card-body">
                                <div class="form-check form-check-group m-0 p-0" style="box-shadow: none !important">
                                    <input :id="`request_id_${request.id}`" type="checkbox" :aria-labelledby="`request_id_${request.id}_help`" v-model="request.selected">
                                    <label :for="`request_id_${request.id}`">{{index + 1}} Requisito</label>
                                    <small :id="`request_id_${request.id}_help`" class="form-text m-auto" style="opacity: 1">{{request.request}}</small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="domandeSelezionate">
                    <div class="card card-bg no-after mb-2">
                        <div class="card-body">

                            <div v-for="request of important_requests_filtered_by_sex" :key="`important_request_${request.id}`">
                                
                                <p class="font-weight-bold text-uppercase m-0">
                                    {{request.request}}
                                </p>

                                <hr class="w-25 my-4 mx-0">

                            </div>
                            
                            <button class="btn btn-success" @click="utility.domande_importanti_selezionate = true">
                                Ok, ho capito
                            </button>
                            
                        </div>
                    </div>
                </div>

                <div v-if="utility.domande_importanti_selezionate">

                    <div class="card card-bg no-after mb-2">
                        <div class="card-body">
                            <div class="alert alert-info m-0">
                                Hai confermato tutti i requisiti! Adesso scegli la struttura di tuo interesse. Scegli una struttura di tuo interesse.
                            </div>
                        </div>
                    </div>

                    <div class="card card-bg no-after mb-2" v-show="structureTestsSelectable.length < 1">
                        <div class="card-body">
                            <div class="alert alert-danger m-0" v-if="DB.test.requests.length > 0">
                                Non sono ancora disponibili delle strutture per il test selezionato, per maggiori informazioni contatta il numero <b>800 40 50 51</b>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3" v-for="structure_test of structureTestsSelectable" :key="`structure_test_${structure_test.structure.id}`">
                            <div class="card card-bg no-after mb-2">
                                <div class="card-body">

                                    <h5 class="card-title m-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hospital mb-1" viewBox="0 0 16 16">
                                            <path d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5Zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9h-.5Zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Z"/>
                                            <path d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1V1Zm2 14h2v-3H7v3Zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm0-14H6v1h4V1Zm2 7v7h3V8h-3Zm-8 7V8H1v7h3Z"/>
                                        </svg> {{structure_test.structure.title}}
                                    </h5>

                                    <p class="card-text">
                                        {{structure_test.structure.address}}
                                    </p>

                                    <div class="text-center text-lg-left">
                                        <div 
                                            v-if="structure_test.id != appointment.id_structure_test"
                                        >
                                            <button
                                                class       = "btn btn-primary btn-sm"
                                                @click      = "setParamsOfStructure(
                                                    structure_test.id
                                                )"
                                                :disabled   = "
                                                    structure_test.structure.structure_type.id_reception_type == 2 &&
                                                    Object.keys(structure_test.day_with_appointments).length == 0
                                                "
                                            >
                                                Seleziona
                                            </button>
                                        </div>
                                        <div v-else>
                                            <button class="btn btn-success btn-sm">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                </svg> Selezionato

                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-bg no-after" v-if="idReceptionType == 1">
                        <div class="card-body">

                            <div class="alert alert-success mt-3 fade show" role="alert">
                                A seguire ti mostriamo i giorni/orari di ricevimento della struttura selezionata
                            </div>

                            <div class="row">
                                <div class="col-lg-3" v-for="(date_time, index) of date_time_structure" :key="`week_day_${index}`">
                                    <div class="card card-bg no-after mb-2">
                                        <div class="card-body">
                                            <p class="m-0">
                                                {{date_time.w_day}} <span class="font-weight-bold" v-bind:class="{'text-success': date_time.open, 'text-danger': !date_time.open}">{{date_time.open ? "aperto" : "chiuso"}}</span>
                                            </p>
                                            <p class="m-0" v-if="date_time.open">
                                                {{date_time.h_open}} - {{date_time.h_close}} <br>
                                                {{date_time.h_open_aft}} - {{date_time.h_close_aft}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-success" v-show="validations.sendMail.OK">
                                {{validations.sendMail.OK}}
                            </div>
                            <div class="alert alert-danger" v-show="validations.sendMail.KO">
                                {{validations.sendMail.KO}}
                            </div>

                            <button class="btn btn-success" @click="sendWeekByEmail" v-show="!validations.sendMail.OK" :disabled="loaders.sendingMail">

                                <span v-show="!loaders.sendingMail">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                                    </svg> Invia orari al tuo indirizzo di registrazione

                                </span>

                                <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.sendingMail">
                                    <div class="progress-spinner-inner"></div>
                                    <div class="progress-spinner-inner"></div>
                                    <span class="sr-only">Caricamento...</span>
                                </div>

                            </button>

                        </div>
                    </div>

                    <div v-else-if="idReceptionType == 2">


                        <div class="card card-bg no-after mb-2">
                            <div class="card-body">
                                <div
                                    class = "alert alert-success m-0"
                                    v-if  = "avaiableDates"
                                >
                                    Struttura selezionata con successo! Adesso scegli una delle date disponibili.
                                </div>
                                <div
                                    class = "alert alert-warning m-0"
                                    v-else
                                >
                                    Siamo desolati, purtroppo non sono presenti delle date disponibili per la struttura selezionata. Per maggiori informazioni contattare il numero <b>800 40 50 51</b>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-2" v-for="(avaiableDate, index) of avaiableDates" :key="`avaiable_date_${avaiableDate.id}`">
                                <div class="card card-bg no-after mb-2">
                                    <div class="card-body text-center p-3">                                
                                        <h4 class="card-title m-0">
                                            {{index | data_giorno}}
                                        </h4>
                                        <p class="text-uppercase">
                                            {{index | data_mese_scritto}}
                                        </p>
                                        <button v-if="index != appointment.date" class="btn btn-primary btn-sm" @click="setAppointmentDate(index)">
                                            Seleziona
                                        </button>                                        
                                        <div v-else>
                                            <button class="btn btn-success btn-sm">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                </svg> Selezionato

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="appointment.date">
                            <div class="card card-bg no-after mb-2">
                                <div class="card-body">
                                    <div
                                        class   = "alert alert-success m-0"
                                        v-if    = "avaiableTimes.length > 0"
                                    >
                                        Data selezionata con successo! Per finire scegli uno degli orari disponibili.
                                    </div>
                                    <div
                                        class = "alert alert-warning m-0"
                                        v-else
                                    >
                                        Siamo desolati, purtroppo non sono presenti degli orari disponibili nella data selezionata. Per maggiori informazioni contattare il numero <b>800 40 50 51</b>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-2" v-for="(time, index) of avaiableTimes" :key="`avaiable_time_${index}`">
                                    <div class="card card-bg no-after mb-2">
                                        <div class="card-body text-center p-3">                                
                                            <h4 class="card-title">
                                                {{time}}
                                            </h4>
                                            <button v-if="time != appointment.time" class="btn btn-primary btn-sm" @click="appointment.time = time" >
                                                Seleziona
                                            </button>
                                            <div v-else>
                                                <button class="btn btn-success btn-sm">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                                    </svg> Selezionato

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="appointment.time" class="row">
                            <div class="col-lg-6">
                                <div class="card card-bg no-after mb-2">
                                    <div class="card-body">

                                        <h5 class="card-title">
                                            Riepilogo
                                        </h5>

                                        <p class="card-text m-0">
                                            Screening: <b>{{appointment.title_screening}}</b>
                                        </p>
                                        <p class="card-text m-0">
                                            Test: <b>{{appointment.title_test}}</b>
                                        </p>
                                        <p class="card-text m-0">
                                            Struttura: <b>{{structureSelected}}</b>
                                        </p>
                                        <p class="card-text mb-3">
                                            Data: <b>{{appointment.date | data_slash}} {{appointment.time}}</b>
                                        </p>

                                        <button class="btn btn-success" @click="createAppointment()">

                                            <span v-show="!loaders.appointment.creating">
                                                Effettua la prenotazione
                                            </span>

                                            <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.appointment.creating">
                                                <div class="progress-spinner-inner"></div>
                                                <div class="progress-spinner-inner"></div>
                                                <span class="sr-only">Caricamento...</span>
                                            </div>

                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    
                    </div>

                </div>

            </div>
            <div v-else>
                <div>
                    <div class="card-wrapper card-space">
                        <div class="card card-bg no-after">
                            <div class="card-body">
                                <div class="alert alert-warning m-0">
                                    Non sono presenti strutture per il test {{DB.test.title}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</template>

<script>

    import store  from '@/store'
    import moment from 'moment'
    import $      from 'jquery'
    //import VueCal from 'vue-cal'

    import 'vue-cal/dist/i18n/it.js'
    import 'vue-cal/dist/vuecal.css'

    import { 
        required
    } from "vuelidate/lib/validators"

    $(document).ready(function () {
        $('.it-date-datepicker').datepicker({
            inputFormat: ['dd/MM/yyyy'],
            outputFormat: 'dd/MM/yyyy',
        })
    })

    const min_date = moment().add(3, `d`).format(`YYYY-MM-DD`)

    export default {
        data() {
            return {
                DB: {
                    test: {
                        title:            null,
                        description:      null,
                        structures_tests: []
                    }
                },
                loaders: {
                    screening: {
                        reading: true
                    },
                    appointment: {
                        creating: false
                    },
                    sendingMail: false
                },
                appointment: {
                    date:              null,
                    time:              null,
                    id_structure:      null,
                    id_structure_test: null,
                    title_screening:   this.$route.params.title_screening,
                    title_test:        this.$route.params.title_test
                },
                calendar: {
                    config: {
                        hide_week_days: [],
                        min_date,
                        disable_days: []
                    }
                },
                validations: {
                    sendMail: {
                        OK: false,
                        KO: false
                    }
                },
                utility: {
                    domande_importanti_selezionate: false
                }
            }
        },
        validations() {
            return {
                appointment: {
                    date: {
                        required
                    }/*,
                    time: {
                        required
                    }*/
                }
            }
        },
        methods: {
            async readTest() {

                try {

                    const test = await this.axios.get(`user/tests/read/time_slot_detail_mod/${this.$route.params.title_test}`, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    // Oggetto per info sul test
                    this.DB.test = test.data.test
                    // Istanziamo ID per l'appuntamento
                    this.appointment.id_test = test.data.test.id

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko             = message
                    this.loaders.screening.reading = false

                }
            },
            async createAppointment() {

                this.loaders.appointment.creating = true

                try {

                    const res = await this.axios.post(`user/appointments/create/single`, this.appointment, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push("/user/appuntamenti")

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko                = message

                }
                finally {
                    this.loaders.appointment.creating = false
                }

            },
            async sendWeekByEmail() {

                this.loaders.sendingMail     = true
                this.validations.sendMail.OK = false
                this.validations.sendMail.KO = false

                try {

                    const res = await this.axios.post(
                        
                        "user/appointments/sendWeekByEmail",

                        {
                            weekDays: this.date_time_structure,
                            structure: this.structureSelected
                        },

                        {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }

                    )

                    const {
                        message
                    } = res.data

                    this.$toast.open({
                        message,
                        type:   `success`
                    })

                    this.validations.sendMail.OK = message

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validations.sendMail.KO = message

                }
                finally {
                    this.loaders.sendingMail = false
                }

            },
            setAppointmentDate(date) {
                this.appointment.date = moment(date).format(`YYYY-MM-DD`)
                this.appointment.time = null
            },
            setParamsOfStructure(id_structure_test) {

                this.appointment.id_structure_test = id_structure_test
                this.appointment.date = null
                this.appointment.time = null
                
                for(let structure_test of this.DB.test.structures_tests) {

                    if(structure_test.id == id_structure_test) {

                        this.appointment.id_structure = structure_test.structure.id

                        // Se la struttura presenta la chiave date da disabilitare
                        if(structure_test.date_disabled) this.calDisableDays(structure_test.date_disabled)
                    }

                }

            },
            calDisableDays(arrDays) {
                
                for(let day of arrDays) {
                    this.calendar.config.disable_days.push(
                        moment(day).format(`YYYY-MM-DD`)
                    )
                }

            }
        },
        mounted() {
            this.readTest()
        },
        computed: {
            nominativo() {
                return `${store.getters.infoUser.user.name} ${store.getters.infoUser.user.surname}`
            },
            eta() {
                return moment().diff(moment(store.getters.infoUser.user.date_birth), 'years')
            },
            sesso() {
                return store.getters.infoUser.user.sex == 'M' ? 'Uomo' : 'Donna'
            },
            domandeSelezionate() {

                let selezionate = true

                for(let request of this.requests_filtered_by_sex) {

                    if(!request.selected) selezionate = false

                }

                return selezionate

            },
            hideWeekDays() {

                let days = []

                for(let structure_test of this.DB.test.structures_tests) {
                    
                    // Effettua il controllo solo se la tipologia di accoglienza è uguale ad 1
                    // if(structure_test.structure.id == this.appointment.id_structure && structure_test.structure.structure_type.id_reception_type == 1) {
                    // Codice commentato, dato che non visualizziamo il calendario nelle altre casistiche

                    if(structure_test.structure.id == this.appointment.id_structure) {

                        if(!structure_test.structure.monday_open) days.push(1)
                        if(!structure_test.structure.tuesday_open) days.push(2)
                        if(!structure_test.structure.wednesday_open) days.push(3)
                        if(!structure_test.structure.thursday_open) days.push(4)
                        if(!structure_test.structure.friday_open) days.push(5)
                        if(!structure_test.structure.saturday_open) days.push(6)
                        if(!structure_test.structure.sunday_open) days.push(7)

                    }

                }

                return days
            },
            structureSelected() {

                let title

                for(let structure_test of this.DB.test.structures_tests) {
                    if(structure_test.structure.id == this.appointment.id_structure) title = structure_test.structure.title
                }

                return title
            },
            structureSlotTimes() {

                let arr = []

                if(this.appointment.id_structure_test) {
                    for(let structure_test of this.DB.test.structures_tests) {

                        if(structure_test.id == this.appointment.id_structure_test) {

                            if(structure_test.day_with_appointments[this.appointment.date]) {
                                arr = structure_test.day_with_appointments[this.appointment.date]
                            }
                            else {
                                arr = structure_test.slot_times_for_week[
                                    moment(this.appointment.date).day()
                                ]
                            }

                        }

                    }
                }

                return arr

            },
            idReceptionType() {

                // ID Selezionato
                const id_se = this.appointment.id_structure
                // ID Tipologia ricevimento pazienti
                let id_re = null
                // Ciclo relazione strutture e test
                for(const structure_test of this.DB.test.structures_tests) {
                    // ID Struttura ciclata
                    const id_st = structure_test.structure.id
                    // Se id struttura ciclata con quella selezionata
                    if(id_se == id_st) id_re = structure_test.structure.structure_type.id_reception_type
                }
                // Return ID tipologia ricevimento pazienti
                return id_re

            },
            avaiableDates() {
                // Array delle date da visualizzare al paziente
                let dates = []
                // Ciclo relazione strutture e test
                for(const structure_test of this.DB.test.structures_tests) {
                    // Se id struttura ciclata con quella selezionata
                    if(
                        this.appointment.id_structure == structure_test.structure.id
                    ) dates = structure_test.day_with_appointments
                    
                }
                // Return delle date
                return dates
            },
            avaiableTimes() {
                // ID Selezionato
                const id_se = this.appointment.id_structure
                // Data selezionata
                const date_se  = this.appointment.date
                // Array degli orari da visualizzare al paziente
                let times = []
                // Ciclo relazione strutture e test
                for(const structure_test of this.DB.test.structures_tests) {
                    // ID Struttura ciclata
                    const id_st = structure_test.structure.id
                    // Se id struttura ciclata con quella selezionata
                    if(id_se == id_st) {
                        for(const date in structure_test.day_with_appointments) {
                            // Se la data selezionata combacia con quella dell'interazione
                            if(date_se == date) times = structure_test.day_with_appointments[date]
                        }
                    }
                }
                // Return degli orari
                return times
            },
            structureTestsSelectable() {

                let structures = []

                for(const structure_test of this.DB.test.structures_tests) {

                    if(
                        structure_test.structure.structure_type.id_reception_type == 1 ||
                        (
                            structure_test.structure.structure_type.id_reception_type == 2 &&
                            Object.keys(structure_test.day_with_appointments).length > 0
                        )
                    ) {
                        structures.push(structure_test)
                    }

                }

                return structures
            },
            date_time_structure() {

                // ID Selezionato
                const id_se = this.appointment.id_structure

                let date_times = []

                for(const structure_test of this.DB.test.structures_tests) {

                    const structure = structure_test.structure
                    // ID Struttura ciclata
                    const id_st = structure_test.structure.id
                    // Se id struttura ciclata con quella selezionata
                    if(id_se == id_st) {
                        
                        // Se aperta lunedi inserisci orari altrimenti null
                        if(structure_test.structure.monday_open) {
                            date_times.push({
                                w_day:  "Lunedi",
                                open:    true,
                                h_open:      moment(structure.monday_h_start).format("HH:mm"),
                                h_close:     moment(structure.monday_h_end).format("HH:mm"),
                                h_open_aft:  moment(structure.monday_h_start_aft).format("HH:mm") == "00:00" ? "" : moment(structure.monday_h_start_aft).format("HH:mm"),
                                h_close_aft:  moment(structure.monday_h_end_aft).format("HH:mm") == "00:00" ? "" :moment(structure.monday_h_end_aft).format("HH:mm") 
                            })
                        }
                        else {
                            date_times.push({
                                w_day:  "Lunedi",
                                open:    false,
                            })
                        }

                        // Se aperta martedi inserisci orari altrimenti null
                        if(structure_test.structure.tuesday_open) {
                            date_times.push({
                                w_day:  "Martedi",
                                open:    true,
                                h_open:      moment(structure.tuesday_h_start).format("HH:mm"),
                                h_close:     moment(structure.tuesday_h_end).format("HH:mm"),
                                h_open_aft:  moment(structure.tuesday_h_start_aft).format("HH:mm") == "00:00" ? "" : moment(structure.tuesday_h_start_aft).format("HH:mm"),
                                h_close_aft:  moment(structure.tuesday_h_end_aft).format("HH:mm") == "00:00" ? "" :moment(structure.tuesday_h_end_aft).format("HH:mm") 
                            })
                        }
                        else {
                            date_times.push({
                                w_day:  "Martedi",
                                open:    false,
                            })
                        }

                        // Se aperta mercoledi inserisci orari altrimenti ""
                        if(structure_test.structure.wednesday_open) {
                            date_times.push({
                                w_day:  "Mercoledi",
                                open:    true,
                                h_open:      moment(structure.wednesday_h_start).format("HH:mm"),
                                h_close:     moment(structure.wednesday_h_end).format("HH:mm"),
                                h_open_aft:  moment(structure.wednesday_h_start_aft).format("HH:mm") == "00:00" ? "" : moment(structure.wednesday_h_start_aft).format("HH:mm"),
                                h_close_aft:  moment(structure.wednesday_h_end_aft).format("HH:mm") == "00:00" ? "" :moment(structure.wednesday_h_end_aft).format("HH:mm") 
                            })
                        }
                        else {
                            date_times.push({
                                w_day:  "Mercoledi",
                                open:    false,
                            })
                        }

                        // Se aperta giovedi inserisci orari altrimenti ""
                        if(structure_test.structure.thursday_open) {
                            date_times.push({
                                w_day:  "Giovedi",
                                open:    true,
                                h_open:      moment(structure.thursday_h_start).format("HH:mm"),
                                h_close:     moment(structure.thursday_h_end).format("HH:mm"),
                                h_open_aft:  moment(structure.thursday_h_start_aft).format("HH:mm") == "00:00" ? "" : moment(structure.thursday_h_start_aft).format("HH:mm"),
                                h_close_aft:  moment(structure.thursday_h_end_aft).format("HH:mm") == "00:00" ? "" :moment(structure.thursday_h_end_aft).format("HH:mm") 
                            })
                        }
                        else {
                            date_times.push({
                                w_day:  "Giovedi",
                                open:    false,
                            })
                        }

                        // Se aperta venerdi inserisci orari altrimenti ""
                        if(structure_test.structure.friday_open) {
                            date_times.push({
                                w_day:  "Venerdi",
                                open:    true,
                                h_open:      moment(structure.friday_h_start).format("HH:mm"),
                                h_close:     moment(structure.friday_h_end).format("HH:mm"),
                                h_open_aft:  moment(structure.friday_h_start_aft).format("HH:mm") == "00:00" ? "" : moment(structure.friday_h_start_aft).format("HH:mm"),
                                h_close_aft:  moment(structure.friday_h_end_aft).format("HH:mm") == "00:00" ? "" :moment(structure.friday_h_end_aft).format("HH:mm") 
                            })
                        }
                        else {
                            date_times.push({
                                w_day:  "Venerdi",
                                open:    false,
                            })
                        }

                        // Se aperta sabato inserisci orari altrimenti ""
                        if(structure_test.structure.saturday_open) {
                            date_times.push({
                                w_day:  "Sabato",
                                open:    true,
                                h_open:      moment(structure.saturday_h_start).format("HH:mm"),
                                h_close:     moment(structure.saturday_h_end).format("HH:mm"),
                                h_open_aft:  moment(structure.saturday_h_start_aft).format("HH:mm") == "00:00" ? "" : moment(structure.saturday_h_start_aft).format("HH:mm"),
                                h_close_aft:  moment(structure.saturday_h_end_aft).format("HH:mm") == "00:00" ? "" :moment(structure.saturday_h_end_aft).format("HH:mm") 
                            })
                        }
                        else {
                            date_times.push({
                                w_day:  "Sabato",
                                open:    false,
                            })
                        }

                        // Se aperta domenica inserisci orari altrimenti ""
                        if(structure_test.structure.sunday_open) {
                            date_times.push({
                                w_day:  "Domenica",
                                open:    true,
                                h_open:      moment(structure.sunday_h_start).format("HH:mm"),
                                h_close:     moment(structure.sunday_h_end).format("HH:mm"),
                                h_open_aft:  moment(structure.sunday_h_start_aft).format("HH:mm") == "00:00" ? "" : moment(structure.sunday_h_start_aft).format("HH:mm"),
                                h_close_aft:  moment(structure.sunday_h_end_aft).format("HH:mm") == "00:00" ? "" :moment(structure.sunday_h_end_aft).format("HH:mm") 
                            })
                        }
                        else {
                            date_times.push({
                                w_day:  "Domenica",
                                open:    false,
                            })
                        }
                    }

                }

                return date_times

            },
            requests_filtered_by_sex() {

                const {
                    sex
                } = store.getters.infoUser.user

                return (
                    this.DB.test.requests.filter(
                        request => sex == "M"                       ? 
                        request.for_man     && !request.important   : 
                        request.for_woman   && !request.important
                    )
                )

            },
            important_requests_filtered_by_sex() {

                const {
                    sex
                } = store.getters.infoUser.user

                return (
                    this.DB.test.requests.filter(
                        request => (sex == "M" ? request.for_man : request.for_woman) && request.important
                    )
                )
                
            }
        }
    }

</script>