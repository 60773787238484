<template>
    <div class="row">
        <div class="col-lg-10 pt-4 pb-2">
            <div class="container">

                <div class="row">
                    <div class="col-lg-6 offset-lg-3 mb-2">

                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <form @submit.prevent="createTest" autocomplete="off">

                                        <div class="form-group">

                                            <label for="title">Titolo</label>
                                            <input type="text" class="form-control text-uppercase" id="title" aria-describedby="title_info" v-model.trim="$v.form.test.title.$model" v-bind:class="{'is-invalid': (!$v.form.test.title.required || !$v.form.test.title.minLength) && $v.form.test.title.$dirty}">
                                            
                                            <small v-show="!$v.form.test.title.$dirty || !$v.form.test.title.$invalid" id="title_info" class="form-text text-muted">Il titolo digitato dovrà essere univoco</small>
                                            
                                            <div v-show="!$v.form.test.title.required && $v.form.test.title.$dirty" class="invalid-feedback">Titolo richiesto</div>
                                            <div v-show="!$v.form.test.title.minLength && $v.form.test.title.$dirty" class="invalid-feedback">Digitare minimo 3 caratteri</div>
                                        
                                        </div>
                                        <div class="form-group">

                                            <label for="description">Descrizione</label>
                                            <textarea id="description" class="form-control border" rows="2" v-model.trim="$v.form.test.description.$model" v-bind:class="{'is-invalid border-danger': (!$v.form.test.description.required || !$v.form.test.description.minLength) && $v.form.test.description.$dirty}"></textarea>
                                            
                                            <small v-show="!$v.form.test.description.$dirty || !$v.form.test.description.$invalid" id="title_info" class="form-text text-muted">Fornire una breve descrizione</small>
                                            
                                            <div v-show="!$v.form.test.description.required && $v.form.test.description.$dirty" class="invalid-feedback">Descrizione richiesta</div>
                                            <div v-show="!$v.form.test.description.minLength && $v.form.test.description.$dirty" class="invalid-feedback">Digitare minimo 8 caratteri</div>

                                        </div>
                                        <div class="form-group">

                                            <label for="time_expire" class="active">Richiamo (mesi)</label>
                                            <input type="number" class="form-control" id="time_expire" min="1" aria-describedby="time_expire_info" v-model.number="$v.form.test.time_expire.$model" v-bind:class="{'is-invalid': (!$v.form.test.time_expire.required || !$v.form.test.time_expire.minValue || !$v.form.test.time_expire.numeric) && $v.form.test.time_expire.$dirty}">
                                            
                                            <small v-show="!$v.form.test.time_expire.$dirty || !$v.form.test.time_expire.$invalid" id="time_expire_info" class="form-text text-muted">Digitare un tempo di attesa in mesi, prima di rieffettuare il test</small>
                                            
                                            <div v-show="!$v.form.test.time_expire.required && $v.form.test.time_expire.$dirty" class="invalid-feedback">Richiamo richiesto</div>
                                            <div v-show="!$v.form.test.time_expire.minValue && $v.form.test.time_expire.$dirty" class="invalid-feedback">Digitare minimo 1 mese</div>
                                            <div v-show="!$v.form.test.time_expire.numeric && $v.form.test.time_expire.$dirty" class="invalid-feedback">Digitare solo numeri</div>
                                        
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">

                                                <label for="from_age" class="active">Età inizio copertura</label>
                                                <input type="number" class="form-control" id="from_age" min="1" aria-describedby="from_age" v-model.trim="$v.form.test.from_age.$model" v-bind:class="{'is-invalid': (!$v.form.test.from_age.required || !$v.form.test.from_age.numeric || !$v.form.test.from_age.minValue) && $v.form.test.from_age.$dirty}">

                                                <small v-show="!$v.form.test.from_age.$dirty || !$v.form.test.from_age.$invalid" id="title_info" class="form-text text-muted">Specificare età paziente</small>

                                                <div v-show="!$v.form.test.from_age.required && $v.form.test.from_age.$dirty" class="invalid-feedback">Età richiesta</div>
                                                <div v-show="!$v.form.test.from_age.numeric && $v.form.test.from_age.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                                <div v-show="!$v.form.test.from_age.minValue && $v.form.test.from_age.$dirty" class="invalid-feedback">Età minima di 1 anno</div>

                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="to_age">Età fine copertura</label>
                                                <input type="number" class="form-control" id="to_age" :min="form.test.from_age" :disabled="!$v.form.test.from_age.required || !$v.form.test.from_age.numeric || !$v.form.test.from_age.minValue" aria-describedby="to_age" v-model.trim="$v.form.test.to_age.$model" v-bind:class="{'is-invalid': (!$v.form.test.to_age.required || !$v.form.test.to_age.numeric || !$v.form.test.to_age.minValue) && $v.form.test.to_age.$dirty}">

                                                <small v-show="!$v.form.test.to_age.$dirty || !$v.form.test.to_age.$invalid" id="title_info" class="form-text text-muted">Specificare età paziente</small>

                                                <div v-show="!$v.form.test.to_age.required && $v.form.test.to_age.$dirty" class="invalid-feedback">Età richiesta</div>
                                                <div v-show="!$v.form.test.to_age.numeric && $v.form.test.to_age.$dirty" class="invalid-feedback">Ammessi solo numeri</div>
                                                <div v-show="!$v.form.test.to_age.minValue && $v.form.test.to_age.$dirty" class="invalid-feedback">Età minima di {{form.test.from_age}} anni</div>
                                            </div>
                                        </div>

                                        <div class="form-check">
                                            <input id="for_man" type="checkbox" v-model.trim="$v.form.test.for_man.$model">
                                            <label for="for_man" v-bind:class="{'text-danger': !$v.form.test.for_man.required}">Screening dedicato all'uomo</label>
                                        </div>
                                        <div class="form-check">
                                            <input id="for_woman" type="checkbox" v-model.trim="$v.form.test.for_woman.$model">
                                            <label for="for_woman" v-bind:class="{'text-danger': !$v.form.test.for_woman.required}">Screening dedicato alla donna</label>
                                        </div>

                                        <div v-show="!$v.form.test.for_man.required || !$v.form.test.for_woman.required" class="alert alert-danger">
                                            Selezionare almeno un sesso
                                        </div>

                                        <p class="font-weight-bold mb-0 mt-3">Associa dei requisiti di accertamento</p>

                                        <div class="alert alert-info">
                                            I requisiti saranno presentati al paziente durante la fase di prenotazione dello screening. Se il paziente non accetterà la condizione proposta, non potrà proseguire con la prenotazione.
                                        </div>                                        

                                        <div v-for="(request_db, index) of form.test.requests.create" v-bind:key="index">

                                            <hr>

                                            <div class="form-group mb-1">
                                                <div class="input-group border">

                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text border-bottom-0 border-right">
                                                            {{index}}
                                                        </div>
                                                    </div>
                                                    
                                                    <textarea class="form-control" :id="`request_${index}`" :name="`request_${index}`" v-model="request_db.request" />

                                                    <div class="input-group-append">
                                                        <button class="btn btn-danger rounded-0 border-0" type="button" :id="`pop_${index}`" @click="popRequest(index)">
                                                            x
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="form-check">
                                                <input :id="`req_for_man_${index}`" type="checkbox" v-model="request_db.for_man">
                                                <label :for="`req_for_man_${index}`" class="m-0">Domanda dedicata all'uomo</label>
                                            </div>
                                            <div class="form-check mt-0">
                                                <input :id="`req_for_woman_${index}`" type="checkbox" v-model="request_db.for_woman">
                                                <label :for="`req_for_woman_${index}`" class="m-0">Domanda dedicata alla donna</label>
                                            </div>
                                            <div class="form-check mt-0">
                                                <input :id="`req_important_${index}`" type="checkbox" v-model="request_db.important">
                                                <label :for="`req_important_${index}`">Domanda importante</label>
                                            </div>

                                        </div>

                                        <a class="text-primary" @click="pushRequest">
                                            <small>
                                                + Aggiungi domanda
                                            </small>
                                        </a>

                                        <div class="alert alert-danger" v-show="validation.ko">
                                            {{validation.ko}}
                                        </div>

                                        <div class="it-card-footer">

                                            <button type="submit" class="btn btn-primary" :disabled="$v.form.test.$invalid || loaders.creating.test || inputRequestEmpty">
                                                
                                                <span v-show="!loaders.creating.test">
                                                    Aggiungi
                                                </span>

                                                <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="loaders.creating.test">
                                                    <div class="progress-spinner-inner"></div>
                                                    <div class="progress-spinner-inner"></div>
                                                    <span class="sr-only">Caricamento...</span>
                                                </div>
                                                

                                            </button>

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">Funzioni</h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">
                                    
                                    <i class="text-muted">Nessuna funzione</i>

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        
        minLength,
        maxLength,
        numeric,
        minValue
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {
                    test: {
                        title:       null,
                        description: null,
                        time_expire: 1,
                        from_age:    1,
                        to_age:      null,
                        for_man:     false,
                        for_woman:   false,
                        requests: {
                            create: []
                        }
                    }
                },
                loaders: {
                    creating: {
                        test: false
                    }
                },
                validation: {
                    ko: false
                }
            }
        },
        validations() {
            return {
                form: {
                    test: {
                        title: {
                            required,
                            minLength: minLength(3)
                        },
                        description: {
                            required,
                            minLength: minLength(8),
                            maxLength: maxLength(350)
                        },
                        time_expire: {
                            required,
                            numeric,
                            minValue: minValue(1)
                        },
                        from_age: {
                            required,
                            numeric,
                            minValue: minValue(1)
                        },
                        to_age: {
                            required,
                            minValue: minValue(this.form.test.from_age),
                            numeric
                        },
                        for_man: {
                            required() {
                                return !(!this.form.test.for_woman && !this.form.test.for_man)
                            }
                        },
                        for_woman: {
                            required() {
                                return !(!this.form.test.for_man && !this.form.test.for_woman)
                            }
                        }
                    }

                }
            }
        },
        methods: {

            async createTest() {

                this.loaders.creating.test = true
                this.validation.ko         = false

                try {

                    const res = await this.axios.post(`user/tests/create/single`, this.form.test, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    this.$toast.open({
                        message: res.data.message,
                        type:   `success`
                    })

                    this.$router.push(`/admin/tests`)

                }
                catch(e) {

                    const message = e.response.data.message

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validation.ko         = message
                    this.loaders.creating.test = false

                }
            },

            pushRequest() {

                this.form.test.requests.create.push({
                    request: ``,
                    for_man: true,
                    for_woman: true,
                    important: false
                })

            },
            popRequest(key) {
                this.form.test.requests.create.splice(key, 1)
            }

        },
        computed: {
            inputRequestEmpty() {

                let empty = false

                for(let create of this.form.test.requests.create) {
                    if(!create.request) empty = true
                }

                return empty

            }
        }
    }
</script>