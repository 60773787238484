<template>
    <div class="row">
        <div class="col-lg-10 pt-2 pb-2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate m-0">
                                        Anagrafica
                                    </h3>
                                    <p class="card-text mb-4">
                                        Per un utente è possibile modificarne solo la tipologia di utenza
                                    </p>

                                    <form @submit.prevent="update_user" autocomplete="off">

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group mb-4">

                                                    <label for="name" class="active">
                                                        Nome
                                                    </label>
                                                    <input
                                                        type    = "text"
                                                        class   = "form-control text-uppercase"
                                                        id      = "name"
                                                        :value  = "form.user.name"
                                                        disabled
                                                    >

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group mb-4">

                                                    <label for="surname" class="active">
                                                        Cognome
                                                    </label>
                                                    <input
                                                        type    = "text"
                                                        class   = "form-control text-uppercase"
                                                        id      = "surname"
                                                        :value  = "form.user.surname"
                                                        disabled
                                                    >

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group mb-4">

                                                    <label for="email" class="active">
                                                        Email
                                                    </label>
                                                    <input
                                                        type    = "text"
                                                        class   = "form-control"
                                                        id      = "email"
                                                        :value  = "form.user.email"
                                                        disabled
                                                    >

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group mb-4">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                +39
                                                            </div>
                                                        </div>

                                                        <label for="phone" class="active">
                                                            Cellulare
                                                        </label>
                                                        <input
                                                            type    = "text"
                                                            class   = "form-control"
                                                            id      = "phone"
                                                            :value  = "form.user.phone"
                                                            disabled
                                                        >
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">

                                                <label class="ml-2 font-weight-bold">
                                                    Tipologia utente
                                                </label>
                                                
                                                <div class="form-group border mb-0">
                                                    <select
                                                        class   = "form-control form-control-sm"
                                                        id      = "user_types"
                                                        title   = "Scegli una opzione"
                                                        v-model = "form.user.id_user_type"
                                                    >
                                                        <option disabled value="" selected>
                                                            Seleziona una tipologia
                                                        </option>
                                                        <option
                                                            v-for   = "user_type of DB.user_types"
                                                            :key    = "user_type.id"
                                                            :value  = "user_type.id"
                                                        >
                                                            {{user_type.title}} | {{user_type.description}}
                                                        </option>
                                                    </select>
                                                </div>

                                                <small class="form-text text-muted" style="font-size: .777rem">
                                                    In base alla tipologia di utente le funzioni software cambieranno
                                                </small>
                                                
                                            </div>
                                        </div>

                                        <button
                                            class       = "btn btn-primary mt-3"
                                            :disabled   = "
                                                loaders.update.user
                                            "
                                            v-if = "
                                                !function_disabled(
                                                    'Utenti',
                                                    'update'
                                                )
                                            "
                                        >
                                            <span v-show="
                                                !loaders.update.user
                                            ">
                                                Modifica
                                            </span>
                                            <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="
                                                loaders.update.user
                                            ">
                                                <div class="progress-spinner-inner"></div>
                                                <div class="progress-spinner-inner"></div>
                                                <span class="sr-only">Caricamento...</span>
                                            </div>

                                        </button>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">
                                    Funzioni
                                </h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">                                    
                                    <i class="text-muted">
                                        Nessuna funzione
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import store from '@/store'

    export default {
        data() {
            return {
                form: {
                    user: {
                        name:           null,
                        surname:        null,
                        email:          null,
                        phone:          null,
                        id_user_type:   null
                    }
                },
                DB: {
                    user_types: []
                },
                loaders: {
                    update: {
                        user: false
                    }
                }
            }
        },
        methods: {
            async read_user_types() {
                try {
                    this.DB.user_types = (
                        await this.axios.get(
                            "user/user_types/read/all", 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data
                }
                catch(e){
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le tipologie di utenza. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
            },
            async read_user() {
                try {
                    this.form.user = (
                        await this.axios.get(
                            `user/users/read/detail/${this.$route.params.id}`, 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data.user
                }
                catch(e){
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le tipologie di utenza. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
            },
            async update_user() {

                this.loaders.update.user        = true

                try {

                    await this.axios.post(
                        `user/users/update/detail/${this.$route.params.id}`,
                        this.form.user,
                        {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }
                    )

                    this.$toast.open({
                        message: "Utente modificato con successo",
                        type: "success"
                    })

                    this.$router.push(
                        "/admin/utenti"
                    )

                }
                catch(e) {

                    const {
                        message
                    } = e.response.data

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.loaders.update.user = false

                }
            },
            function_disabled(
                title,
                action
            ) {
                for(
                    let sf of store.getters.softwareFunctions
                ) {
                    if(
                        sf.software_function.title == title &&
                        !sf[
                            action
                        ]
                    ) return true
                }
            }
        },
        mounted() {
            this.read_user_types()
            this.read_user()
        }
    }
</script>