<template>
    <div class="row">
        <div class="col-lg-10 pt-2 pb-2">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-8 offset-lg-2">
                        <div class="card-wrapper card-space">
                            <div class="card card-bg no-after">
                                <div class="card-body">

                                    <h3 class="card-title text-truncate m-0">
                                        Anagrafica
                                    </h3>
                                    <p class="card-text mb-4">
                                        Per aggiungere un nuovo utente è necessario compilare i campi che seguono
                                    </p>

                                    <form @submit.prevent="create_user" autocomplete="off">

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group mb-4">

                                                    <label for="name">
                                                        Nome
                                                    </label>
                                                    <input
                                                        type         = "text"
                                                        class        = "form-control text-uppercase"
                                                        id           = "name"
                                                        v-model.trim = "$v.form.user.name.$model"
                                                        :class = "{
                                                            'is-invalid': (
                                                                !$v.form.user.name.required &&
                                                                $v.form.user.name.$dirty
                                                            )
                                                        }"
                                                    >
                                                    <div 
                                                        v-show = "
                                                            !$v.form.user.name.required &&
                                                            $v.form.user.name.$dirty
                                                        "
                                                        class = "invalid-feedback"
                                                    >
                                                        Nome richiesto
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group mb-4">

                                                    <label for="surname">
                                                        Cognome
                                                    </label>
                                                    <input
                                                        type         = "text"
                                                        class        = "form-control text-uppercase"
                                                        id           = "surname"
                                                        v-model.trim = "$v.form.user.surname.$model"
                                                        :class = "{
                                                            'is-invalid': (
                                                                !$v.form.user.surname.required &&
                                                                $v.form.user.surname.$dirty
                                                            )
                                                        }"
                                                    >
                                                    <div 
                                                        v-show = "
                                                            !$v.form.user.surname.required &&
                                                            $v.form.user.surname.$dirty
                                                        "
                                                        class = "invalid-feedback"
                                                    >
                                                        Cognome richiesto
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">

                                                    <label for="email">
                                                        Email
                                                    </label>
                                                    <input
                                                        type         = "text"
                                                        class        = "form-control"
                                                        id           = "email"
                                                        v-model.trim = "$v.form.user.email.$model"
                                                        :class = "{
                                                            'is-invalid': (
                                                                (
                                                                    !$v.form.user.email.required ||
                                                                    !$v.form.user.email.email
                                                                ) &&
                                                                $v.form.user.email.$dirty
                                                            )
                                                        }"
                                                    >
                                                    
                                                    <div 
                                                        v-show = "
                                                            !$v.form.user.email.required &&
                                                            $v.form.user.email.$dirty
                                                        "
                                                        class = "invalid-feedback"
                                                    >
                                                        Email richiesta
                                                    </div>
                                                    <div 
                                                        v-show = "
                                                            !$v.form.user.email.email &&
                                                            $v.form.user.email.$dirty
                                                        "
                                                        class = "invalid-feedback"
                                                    >
                                                        Formattazione errata
                                                    </div>
                                                    <small class="form-text text-muted">
                                                        <b>Attenzione</b>, verrano recapitate le credenziali di accesso
                                                    </small>

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">

                                                    <div class="input-group">

                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                +39
                                                            </div>
                                                        </div>

                                                        <label for="phone">
                                                            Cellulare
                                                        </label>
                                                        <input
                                                            type         = "text"
                                                            class        = "form-control"
                                                            id           = "phone"
                                                            minlength    = "8"
                                                            maxlength    = "10"
                                                            v-model.trim = "$v.form.user.phone.$model"
                                                            :class = "{
                                                                'is-invalid': !$v.form.user.phone.numeric
                                                            }"
                                                        >
                                                    </div>
                                                    <div 
                                                        v-show = "!$v.form.user.phone.numeric"
                                                        class  = "invalid-feedback"
                                                    >
                                                        Ammessi solo numeri
                                                    </div>
                                                    <small class="form-text text-muted">
                                                        Numero a solo scopo informativo
                                                    </small>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">

                                                <label class="ml-2 font-weight-bold">
                                                    Tipologia utente
                                                </label>
                                                
                                                <div class="form-group border mb-0">
                                                    <select
                                                        class   = "form-control form-control-sm"
                                                        id      = "user_types"
                                                        title   = "Scegli una opzione"
                                                        v-model = "$v.form.user.id_user_type.$model"
                                                    >
                                                        <option disabled value="" selected>
                                                            Seleziona una tipologia
                                                        </option>
                                                        <option
                                                            v-for   = "user_type of DB.user_types"
                                                            :key    = "user_type.id"
                                                            :value  = "user_type.id"
                                                        >
                                                            {{user_type.title}} | {{user_type.description}}
                                                        </option>
                                                    </select>
                                                </div>

                                                <small class="form-text text-muted" style="font-size: .777rem">
                                                    In base alla tipologia di utente le funzioni software cambieranno
                                                </small>
                                                
                                            </div>
                                        </div>

                                        <div class="alert alert-warning mt-4">
                                            Le credenziali e l'username utente verranno generate automaticamente ed inviate all'utente tramite l'indirizzo email fornito
                                        </div>

                                        <div class="alert alert-danger" v-if="
                                            validations.create.user.KO
                                        ">
                                            {{
                                                validations.create.user.KO
                                            }}
                                        </div>

                                        <button class="btn btn-primary" :disabled="
                                            $v.form.user.$invalid ||
                                            loaders.create.user
                                        ">
                                            <span v-show="
                                                !loaders.create.user
                                            ">
                                                Aggiungi
                                            </span>
                                            <div class="progress-spinner progress-spinner-double size-sm progress-spinner-active" v-show="
                                                loaders.create.user
                                            ">
                                                <div class="progress-spinner-inner"></div>
                                                <div class="progress-spinner-inner"></div>
                                                <span class="sr-only">Caricamento...</span>
                                            </div>

                                        </button>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
            </div>
        </div>
        <div class="col-lg-2 border-left-lg">
            <div class="sidebar-wrapper">
                <div class="sidebar-linklist-wrapper">
                    <div class="link-list-wrapper">
                        <ul class="link-list text-lg-right">
                            <li>
                                <h3 class="text-truncate">
                                    Funzioni
                                </h3>
                            </li>
                            <li>
                                <a class="list-item medium text-truncate disabled">                                    
                                    <i class="text-muted">
                                        Nessuna funzione
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { 
        required,
        email,
        numeric,
        minLength,
        maxLength
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {
                    user: {
                        name:           null,
                        surname:        null,
                        email:          null,
                        phone:          null,
                        id_user_type:   null
                    }
                },
                DB: {
                    user_types: []
                },
                loaders: {
                    create: {
                        user: false
                    }
                },
                validations: {
                    create: {
                        user: {
                            KO: false,
                            OK: false
                        }
                    }
                }
            }
        },
        validations() {
            return {
                form: {
                    user: {
                        name: {
                            required
                        },
                        surname: {
                            required
                        },
                        email: {
                            required,
                            email
                        },
                        phone: {
                            numeric,
                            minLength: minLength(8),
                            maxLength: maxLength(10)
                        },
                        id_user_type: {
                            required
                        }
                    }
                }
            }
        },
        methods: {
            async read_user_types() {

                try {
                    this.DB.user_types = (
                        await this.axios.get(
                            "user/user_types/read/all", 
                            {
                                headers:{
                                    Authorization: `bearer ${this.$store.state.auth.token}`
                                }
                            }
                        )
                    ).data
                }
                catch(e){
                    if(
                        e.response.status     == 401 &&
                        e.response.statusText == "Unauthorized"
                    ) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')
                    }
                    else {
                        this.$toast.open({
                            message: `Non è momentaneamente possibile scaricare le tipologie di utenza. ${e.response.data.message ? e.response.data.message + '. ' : 'Riprovi più tardi.'}`,
                            type:    `error`
                        })
                    }
                }
            },
            async create_user() {

                this.loaders.create.user        = true
                this.validations.create.user.KO = false

                try {

                    await this.axios.post(
                        "user/users/create/single",
                        this.form.user,
                        {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }
                    )

                    this.$toast.open({
                        message: "Utente creato con successo",
                        type: "success"
                    })

                    this.$router.push(
                        "/admin/utenti"
                    )

                }
                catch(e) {

                    const {
                        message
                    } = e.response.data

                    this.$toast.open({
                        message,
                        type: `error`
                    })

                    this.validations.create.user.KO = message
                    this.loaders.create.user        = false

                }
            }
        },
        mounted() {
            this.read_user_types()
        }
    }
</script>